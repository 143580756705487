const React = require('react');
const {campaign} = require('../lib/campaign.js');
import {getBookMapDetals} from './stdedit.jsx';
import Tooltip from '@material-ui/core/Tooltip';

class Rendersource extends React.Component {
    constructor(props) {
        super(props);
        this.state= {};
    }

    render() {
        const {entry,className,noClick} = this.props;
        const source = campaign.getSourceName(entry.source);
        const {bval} = this.state;
        const BookDialog=bval?require('./book.jsx').BookDialog:null;

        const bmd = getBookMapDetals();
        const bmdVal=bmd[stringFromEntry(entry)];
        let res=[];
        if (bmdVal && bmdVal.length) {
            const shown={};
            for (let i in bmdVal) {
                const md=bmdVal[i];
                if (!shown[md.name]) {
                    if (res.length) {
                        res.push(", ");
                    }
                    res.push(<a key={i} onClick={noClick?null:this.showBook.bind(this, md)}>{md.displayName}</a>);
                    shown[md.name]=1;
                }
            }
        }
        //console.log("source", bmdVal);

        if (source || entry.edited) {
            return <div className={className||"mv1 titletext"}>
                <i>{source?<Tooltip title={source}><b>Source. </b></Tooltip>:<b>Source. </b>} {res.length?res:source} {source?null:"My Creation"} {source&&entry.edited?<span className="red">(edited)</span>:null}</i>
                {bval?<BookDialog open bookname={bval.name} chapter={bval.chapter} section={bval.section} subsection={bval.subsection} onClose={this.showBook.bind(this,null)}/>:null}
            </div>;
        }

        return null;
    }

    showBook(bval,evt) {
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        this.setState({bval});
    }
}

function stringFromEntry(it) {
    if (it.id && it.type) {
        return "i:"+it.id.toLowerCase();
    }
    if (it.className) {
        return ("c:"+it.className.toLowerCase())+
               (it.subclassName?"sc:"+it.subclassName.toLowerCase():"");
    }
    return (it.name||"").toLowerCase();
}

export {
    Rendersource
}