import { initializeApp } from "firebase/app"

import { getAuth,updateProfile } from "firebase/auth";
import {initializeFirestore, getFirestore, enableMultiTabIndexedDbPersistence,clearIndexedDbPersistence, serverTimestamp} from 'firebase/firestore';


var config = {
    apiKey: "AIzaSyBKGldtgwFiR4MR5zGqPVTk3i-GdHb3nHY",
    authDomain: "shard-alley.firebaseapp.com",
    databaseURL: "https://shard-alley.firebaseio.com",
    projectId: "shard-alley",
    storageBucket: "shard-alley.appspot.com",
    messagingSenderId: "706498819570"
};

var app = initializeApp(config);
const enablePersistence = false;//(localStorage.enablePersistence=="true");
let currentEnablePersistence = enablePersistence;

function getCurrentEnablePersistence() {
    return currentEnablePersistence;
}

function setCurrentEnablePersistence(enable) {
    currentEnablePersistence = !!enable;
    localStorage.setItem("enablePersistence", enable?"true":"false");
}

function mismatchedPersistence() {
    return enablePersistence != currentEnablePersistence;
}

initializeFirestore(app, {experimentalForceLongPolling: true /*, experimentalAutoDetectLongPolling:true*/, merge:true,  ignoreUndefinedProperties: true})

if (enablePersistence) {
    //console.log("enable offline storage")
    enableMultiTabIndexedDbPersistence(getFirestore(app)).catch((err) => {
        if (err.code == 'failed-precondition') {
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
            // ...
            console.log("offline failed precondition")
        } else if (err.code == 'unimplemented') {
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
            console.log("offline failed unimplemented")
        }
    });
} else {
    //console.log("disable offline storage");
    clearIndexedDbPersistence(getFirestore(app));
}
export {
    app as firebase,
    getAuth,
    serverTimestamp,
    getCurrentEnablePersistence,
    setCurrentEnablePersistence,
    mismatchedPersistence,
    updateProfile
};