const React = require('react');
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
const {displayMessage} = require('./notification.jsx');
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {campaign} = require('../lib/campaign.js');
const {firebase} = require("./firebase.jsx");
const {nameEncode} = require('../lib/stdvalues.js');
const {ClipboardCopy,TextBasicEdit} = require('./stdedit.jsx');
const {GenCharacterName} = require('./randomname.jsx');
const {PickCharacter} = require('./renderplayers.jsx');

class SendInvitation extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && this.props.show != prevProps.show) {
            const prefs = campaign.getPrefs();
            var key = prefs.joinKey;

            if (!key) {
                key = campaign.newUid();
                campaign.setPrefs({joinKey:key,userDisplayName:campaign.currentUser.displayName});
            } else if (!prefs.userDisplayName) {
                campaign.setPrefs({userDisplayName:campaign.currentUser.displayName});
            }
            const userId = campaign.currentUser.uid;
            campaign.upsertCampaignInvite(key, {user:userId, userDisplayName:campaign.currentUser.displayName, userEmail:campaign.currentUser.email, campaign:campaign.getCurrentCampaign()});

            const joinURL = window.location.origin+"/#join?key="+key;
            this.setState({joinURL:joinURL, key, mailto:this.getMailToURL(joinURL)});
        }
    }

    getMailToURL(joinURL) {
        const subject="Invitation to join "+campaign.getPrefs().displayName;
        const body = 'To join the game click this link: '+
            joinURL+ '\n\nThis game is hosted on Shard Tabletop, the best way to play the world’s greatest role-playing game.\n\nThanks';
        
        return "mailto:?subject="+encodeURIComponent(subject)+"&body="+encodeURIComponent(body);
    }

    handleClose(event) {
        this.props.onClose();
        event.stopPropagation();
    };

    render() {
        if (!this.props.show)
            return null;
        
        return <Dialog
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this)}>Campaign Invite</DialogTitle>
            <DialogContent>
                <div className="titlecolor f3">
                    Send key to players to allow them to join the campaign.  <a href={this.state.mailto}>(Send Email)</a><br/>
                    <p>
                    If you would like to invite someone using something other than email, then you can copy the link and paste it into your favorite form of communication.
                    </p>
                    <p>Join key for campaign:</p>
                    <ClipboardCopy text={this.state.joinURL}>{this.state.joinURL}</ClipboardCopy>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

class SendWatchInvitation extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && this.props.show != prevProps.show) {
            var key = generateWatchKey();

            const joinURL = window.location.origin+"/#watch?id="+key;
            this.setState({joinURL:joinURL, key, mailto:this.getMailToURL(joinURL)});
        }
    }

    getMailToURL(joinURL) {
        const subject="Invitation to watch "+campaign.getPrefs().displayName;
        const body = 'To watch the game click this link: '+
            joinURL+ '\n\nThis game is hosted on Shard Tabletop, the best way to play the world’s greatest role-playing game.\n\nThanks';
        
        return "mailto:?subject="+encodeURIComponent(subject)+"&body="+encodeURIComponent(body);
    }

    handleClose(event) {
        this.props.onClose();
        event.stopPropagation();
    };

    render() {
        if (!this.props.show)
            return null;
        
        return <Dialog
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this)}>Campaign Invite</DialogTitle>
            <DialogContent>
                <div className="titlecolor f3">
                    Send link to players to allow them to watch the game.  <a href={this.state.mailto}>(Send Email)</a><br/>
                    <p>
                    If you would like to invite someone using something other than email, then you can copy the link and paste it into your favorite form of communication.
                    </p>
                    Watch key for campaign:
                    <ClipboardCopy text={this.state.joinURL}>{this.state.joinURL}</ClipboardCopy>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

function generateWatchKey() {
    const prefs = campaign.getPrefs();
    var key = prefs.watchKey;

    if (!key) {
        key = campaign.newUid();
        campaign.setPrefs({watchKey:key});
    }
    const userId = campaign.currentUser.uid;
    campaign.upsertCampaignWatchInvite(key, {
        user:userId, 
        userDisplayName:campaign.currentUser.displayName, 
        userEmail:campaign.currentUser.email, 
        campaign:campaign.getCurrentCampaign(),
        campaignDisplayName:prefs.displayName
    });

    return key;
}

class JoinedDialog extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && this.props.show != prevProps.show) {
        }
    }

    render() {
        if (!this.props.open)
            return null;

        const cInfo = campaign.getCampaignInfo(this.props.joinedCampaign);
        const myChars = campaign.getMyCharacters();
        const players = campaign.getPlayers();
        let claimable = false;

        for (let i in players) {
            const p=players[i];
            if (p.claimable) {
                claimable=true;
            }
        }

        return <Dialog
            open
        >
            <DialogTitle onClose={this.props.onClose.bind(this)}>Thank You For Joining</DialogTitle>
            <DialogContent>
                <div className="stdcontent">
                    <p>You have joined <i>{cInfo.displayName}</i>.  The campaign has been added to your player campaigns.</p>
                    {myChars.length?<div className="tc">
                        <Button onClick={this.onPickCharacter.bind(this)} color="primary" variant="outlined">
                            Join My Character to Campaign
                        </Button>
                    </div>:null}
                    <div className="tc mv2">
                        <Button onClick={this.onNewCharacter.bind(this)} color="primary" variant="outlined">
                            Create a New Character for Campaign
                        </Button>
                    </div>
                    {claimable?<div className="tc mv2">
                        <Button onClick={this.onPickPregenCharacter.bind(this)} color="primary" variant="outlined">
                            Pick a Pregenerated Character
                        </Button>
                    </div>:null}
                    <div className="tc mv2">
                        <Button onClick={this.onClose.bind(this)} color="primary" variant="outlined">
                            Decide Later
                        </Button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
            <GenCharacterName show={this.state.showNewPC} title="Create New Character" label="Character Name" onChange={this.onNewPC.bind(this)}/>
            <PickCharacter open={this.state.showPickPregenCharacter} onClose={this.doPickPregenCharacter.bind(this)} type="players" claimable single/>
            <PickCharacter open={this.state.showPickMyCharacter} onClose={this.pickCharacter.bind(this)} type="mycharacters" single/>

            {this.state.loading?<Dialog open>
                <DialogContent>
                    Adding Character...
                </DialogContent>
            </Dialog>:null}

        </Dialog>;
    }

    onPickPregenCharacter() {
        if (campaign.getMyCharacters().length >= campaign.maxCharacters) {
            displayMessage(<span>You have reached your limit of {campaign.maxCharacters} characters that you can create.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to change your limits.</span>);
            return;
        }

        this.setState({showPickPregenCharacter:true});
    }

    doPickPregenCharacter(name) {
        if (name) {
            const t=this;
            const newCharacter = Object.assign({}, campaign.getPlayerInfo(name));

            t.setState({showPickPregenCharacter:false, loading:true});
            newCharacter.name = campaign.newUid();
            delete newCharacter.pregen;
            delete newCharacter.diceRolls;
            delete newCharacter.lastRoll;
            delete newCharacter.claimable;
            campaign.updateCampaignContent("mycharacters", newCharacter).then(function () {
                campaign.joinCharacterToSharedCampaign(newCharacter.name, t.props.joinedCampaign).then(function (){
                    t.setState({loading:false});
                    t.props.onClose();
                    campaign.deleteCampaignContent("players",name);
                    campaign.addUserMRUList("mruCharacters", {description:newCharacter.name});
                    window.location.href = "/#play?mode=character&campaign="+encodeURIComponent(t.props.joinedCampaign)+"&character="+encodeURIComponent(newCharacter.name);
                }, function (err){
                    t.setState({loading:false});
                    t.props.onClose();
                    displayMessage("Error joining campaign. "+err);
                });
        
            }, function (err) {
                t.setState({loading:false});
                displayMessage("Error claiming character. "+err);
            });
        } else {
            this.setState({showPickPregenCharacter:false});
        }
    }

    onClose() {
        this.props.onClose();
        window.location.href = "/#play?mode=character&campaign="+encodeURIComponent(this.props.joinedCampaign);
    }

    onPickCharacter() {
        this.setState({showPickMyCharacter:true});
    }

    pickCharacter(name) {
        if (name) {
            const t=this;
            
            campaign.joinCharacterToSharedCampaign(name, this.props.joinedCampaign).then(function (){
                campaign.addUserMRUList("mruCharacters", {description:name});
                t.props.onClose();
                window.location.href = "/#play?mode=character&campaign="+encodeURIComponent(t.props.joinedCampaign)+"&character="+encodeURIComponent(name);
            }, function (err){
                t.props.onClose();
                displayMessage("Error joining campaign. "+err);
            });
        }
        this.setState({showPickMyCharacter:false});
    }

    onNewCharacter(){
        this.setState({showNewPC:true});
    }

    async onNewPC(name) {
        this.setState({showNewPC:false});
        if (name) {
            try {
                const char = {name:campaign.newUid(), displayName:name, full:true};
                await campaign.updateCampaignContent("mycharacters", char);
                await campaign.joinCharacterToSharedCampaign(char.name, this.props.joinedCampaign)
                campaign.addUserMRUList("mruCharacters", {description:char.name});
                this.props.onClose();
                window.location.href = "/#play?mode=character&campaign="+encodeURIComponent(this.props.joinedCampaign)+"&character="+encodeURIComponent(char.name);
            } catch (err){
                this.props.onClose();
                displayMessage("Error joining campaign. "+err);
            };
        }
    }
}

export {
    SendInvitation,
    SendWatchInvitation,
    JoinedDialog,
    generateWatchKey
};