const React = require('react');
import Snackbar from '@material-ui/core/Snackbar';
const {Dialog} = require('./responsivedialog.jsx');
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

let showMessageFn=null;
let addSnackMessageFn = null;

function displayMessage(message, fn) {
    if (showMessageFn) {
        showMessageFn(message, fn);
    } else {
        console.log("no show message", message);
        window.alert(message);
        if (fn) {
            fn();
        }
    }
}

function snackMessage(message) {
    if (addSnackMessageFn) {
        addSnackMessageFn(message);
    } else {
        console.log("no add snack message", message);
    }
}

class ShowMessage extends React.Component {
    constructor(props) {
        super(props);

        this.callbackFn = this.showMessage.bind(this);
        this.addSnackMessageFn = this.addSnackMessage.bind(this);
	    this.state= {
        };
        this.snackMessages = [];
    }

    componentDidMount() {
        if (!showMessageFn || (showMessageFn == this.callbackFn)) {
            showMessageFn = this.callbackFn;
        } else {
            console.log("why is there already a show message fn?")
        }
        if (!addSnackMessageFn || (addSnackMessageFn == this.addSnackMessageFn)) {
            addSnackMessageFn = this.addSnackMessageFn;
        } else {
            console.log("why is there already a add snack message fn?")
        }
    }

    componentWillUnmount() {
        if (showMessageFn == this.callbackFn) {
            showMessageFn = null;
        }
        if (addSnackMessageFn == this.addSnackMessageFn) {
            addSnackMessageFn = null;
        }
    }

    showMessage(message, fn) {
        if (this.state.message) {
            window.alert(message)
            if (fn) {
                fn();
            }
            console.log("overlapping messages");
        }
        this.setState({message, fn});
    }

    addSnackMessage(message) {
        this.snackMessages.push(message);
        this.setState({snackMessages:this.snackMessages.concat([])});
    }

    finishSnackMessage() {
        this.snackMessages.shift();
        this.setState({snackMessages:this.snackMessages.concat([])});
    }

    handleClose() {
        if (this.state.fn) {
            this.state.fn();
        }
        this.setState({message:null, fn:null});
    }

    render() {
        const curSnackMessage = this.snackMessages[0]||null;

        if (!this.state.message && !curSnackMessage) 
            return null;
        
        const snackbar = <Snackbar
            key="snackMessage"
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
            open={!!curSnackMessage}
            autoHideDuration={6000}
            onClose={this.finishSnackMessage.bind(this)}
            message={curSnackMessage}
            action={
            <React.Fragment>
                <span className="fas fa-times f3 pa1" onClick={this.finishSnackMessage.bind(this)}/>
            </React.Fragment>
            }
        />;

        if (!this.state.message) {
            return snackbar;
        }

        return  <span>
            <Dialog
                className="nodrag"
                style={{zIndex:2000}}
                open
                onClose={this.handleClose.bind(this)}
            >
                <DialogContent>
                    <div className="mb1 tc f3">
                        {this.state.message}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this)} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>
            {snackbar}
        </span>;
    }
}

export {
    displayMessage,
    ShowMessage,
    snackMessage
};