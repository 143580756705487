const {campaign} = require('../lib/campaign.js');
const React = require('react');
const Parser = require("../lib/dutils.js").Parser;
const {Renderentry,Renderstring} = require('./entityeditor.jsx');
const {Rendersource} = require("./rendersource.jsx");
const {RenderFeature} = require('./features.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
const {escapeRegExp} = require('./stdedit.jsx');

const builtinMap = {
    "skills":Parser.SKILL_JSON_TO_FULL,
    "actions":Parser.ACTION_JSON_TO_FULL,
    "senses":Parser.SENSE_JSON_TO_FULL,
    "conditions":Parser.CONDITIONS_JSON_TO_FULL
}

function getSortedGenericList(type) {
    const builtin = builtinMap[type];
    if (builtin) {
        const ret = [];
        for (let i in builtin) {
            ret.push({name:i, entries:builtin[i]});
        }
        return ret;
    } else {
        return [];
    }
}

class GenericList extends React.Component {
    constructor(props) {
        super(props);

        var filter = {name:""};
        this.state= {filter:filter};
    }

    changeFilter(e) {
        var filter = Object.assign({}, this.state.filter)
        filter.name = e.target.value;
        this.setState({filter:filter})
    }

    handleChange(e) {
        var filter = Object.assign({}, this.state.filter)
        filter[e.target.name] = e.target.value;
        this.setState({filter:filter})
    };

	render() {
        return <div className="notecontent" key="items">
            <div className="flex notetext f2 items-end">
                {(getSortedGenericList(this.props.type).length < 30)?null:
                    <TextField 
                        className="flex-auto"
                        label="Name"
                        defaultValue={this.state.filter.name}
                        onChange={this.changeFilter.bind(this)}
                    />
                }
            </div>
            {getList(this.state.filter.name, this.props.type)}
        </div>;
    }
}

function getList(name, type) {
    var ret = [];
    const l = getSortedGenericList(type);
    const il = [];
    var f;

    if (name)
        f=new RegExp(escapeRegExp(name), "i");

    for (var i in l) {
        const it = l[i];
        if (!f || f.test(it.name)) {
            il.push(it)
        }
    }

    const base ="#"+type+"?id=";

    for (let i in il) {
        const it = il[i];
        ret.push(<tr key={it.name}><td className="hoverhighlight" onClick={onClick}>{it.name}</td></tr>);

        function onClick() {
            window.location.href = base+encodeURIComponent(it.name);
        }
    }

    return <div className="stdlist">
        <table className="w-100">
            <tbody>
                {ret}
            </tbody>
        </table>
    </div>;
}


class Generic extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

	render() {
        const type = this.props.type;
        let it;

        const builtin = builtinMap[type];
        const id = this.props.id.toLowerCase();
        if (builtin) {
            if (type=="skills"){
                // check through skills extensions
                const extensions = campaign.getAllExtensions();
        
                for (let i in extensions) {
                    const e = extensions[i];
                    if (e.skills) {
                        for (let s of e.skills) {
                            if ((s.name||"").toLowerCase()==id) {
                                it = {name:s.name, entries:[s.description||""]};
                            }
                        }
                    }
                }
            }
            for (let i in builtin) {
                if (id == i.toLowerCase()) {
                    it = {name:i, entries:builtin[i]};
                }
            }
        } else if (type=="coins") {
            it = Parser.COINS_Entries[id];
        } else {
            it = null;
        }

        if (!it) {
            return <div>{this.props.id} not found</div>;
        }

        return <div className="stdcontent f4" key={this.props.id}>
            {this.props.notitle?null:<div className="f1 b titlecolor ttc">
                {it.displayName||it.name}
                {it.title?<span>, {it.title}</span>:null}
            </div>}
            {(it.description)?<div className="mb1"><Renderstring entry={it.description}/></div>:null}
            <Renderentry entry={{entries:it.entries||[]}} depth={2} character={this.props.character}/>
            {this.props.nosource?null:<Rendersource entry={it}/>}
        </div>;
    }
}

class GenericDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const {PickRandomEncounterInfoDialog} = require('./renderrandomtables.jsx');

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth={true}
            open
        >
            {this.props.entry||this.props.feature?<DialogTitle onClose={this.props.onClose}>
                {this.props.entry?this.props.entry.name:
                    this.props.feature?this.props.feature.name:null
                }
            </DialogTitle>:null}
            <DialogContent>
                {this.props.entry?<div className="stdcontent"><Renderentry character={this.props.character} showInstantRoll entry={this.props.entry.entries} depth={this.props.depth}  /></div>:
                    this.props.feature?<div className="stdcontent"><RenderFeature noTitle  showInstantRoll feature={this.props.feature} character={this.props.character} doRoll={this.props.doRoll} doSubRoll={this.props.doSubRoll} addSpellToken={this.props.addSpellToken} getDiceRoller={this.props.getDiceRoller}/></div>:
                    <Generic type={this.props.type} id={this.props.id} character={this.props.character} doRoll={this.props.doRoll} doSubRoll={this.props.doSubRoll} addSpellToken={this.props.addSpellToken} getDiceRoller={this.props.getDiceRoller}/>
                }
            </DialogContent>
            <DialogActions>
                {this.props.getDiceRoller?this.props.getDiceRoller():null}
                {(this.props.addToEncounter&&this.props.type=="coins")?<Button onClick={this.clickAddItem.bind(this)} color="primary">
                    ADD TO ENCOUNTER
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <PickRandomEncounterInfoDialog open={this.state.showAddItem} row={this.state.addItemRow} onClose={this.closeAddItem.bind(this)} addToEncounter={this.props.addToEncounter}/>
        </Dialog>;
    }

    clickAddItem() {
        const {getDiceFromString} = require('../src/diceroller.jsx');
        const row = {coins:{}};
        const {previousDice} = this.props;
        row.coins[this.props.id]= getDiceFromString(previousDice||"1",0,true);;
        this.setState({showAddItem:true, addItemRow:row});
    }

    closeAddItem(row) {
        if (row){
            this.props.addToEncounter(row);
            this.props.onClose();
        }
        this.setState({showAddItem:false, addItemRow:null});
    }

}

export {
    GenericDialog,
    Generic,
    GenericList,
}