'use strict';
const {firebase, getAuth} = require("../src/firebase.jsx");
import {getFirestore, doc,collection, getDoc, updateDoc, deleteDoc, getDocs, setDoc,onSnapshot} from 'firebase/firestore';
const {globalDataListener, isOwned,httpAuthRequestWithRetry,enforcePermissions} = require('../lib/campaign.js');
const {defaultSettings,defaultStorylines, packageTypes, defaultRuleset} = require('../lib/stdvalues.js');

const tipProduct = "8qrr17w4sa8aluxx";
const giftCertificateProduct = "66ua05ih4evkm40e";
const dollarProducts = [tipProduct,giftCertificateProduct];
const tipProductInfo = {name:tipProduct, displayName:"Gamemaster Tip", text:"", purchaseType:"single", packages:{}}

class marketplaceObject {
    constructor(){
        this.unsubscribeList = [];
        this.loaded = false;
        this.userLoaded = false;
        this.cartLoaded = false;
        this.cart={};
    }

    setReferral(userId, code) {
        this.referral = {userId,code};
    }

    getReferral() {
        return this.referral||null;
    }

    unsubscribeAll() {
        const u = this.unsubscribeList || [];

        for (let i in u) {
            u[i]();
        }
        this.unsubscribeList = [];
    }

    get userId() {
        return getAuth(firebase).currentUser.uid;
    }

    get displayName() {
        return getAuth(firebase).currentUser.displayName;
    }

    get email() {
        return getAuth(firebase).currentUser.email;
    }

    load() {
        if (this.loaded) {
            return new Promise(function(resolve, reject) {
                setTimeout(function() {
                  resolve();
                }, 1);
            });
        }

        this.subscriptionUpgrades=null;
        this.watchAuthState();
        const t=this;
        const db = getFirestore(firebase);
        const marketRef = collection(db,"products");
        this.products = {};
        const ret = getDocs(marketRef).then(function (products){
            products.forEach(function (c) {
                const data = c.data();
                t.products[data.name] = data;
            });
            t.loaded=true;
            globalDataListener.postEvent("products");
            t.subscriptionUpgrades=null;

            onSnapshot(marketRef,function (snapshot) {
                snapshot.docChanges().forEach(function (change){
                    const d = change.doc.data();
                    switch (change.type) {
                        case "modified":
                        case "added":
                            t.products[d.name]=d;
                            break;
                        case "removed":
                            delete t.products[d.name];
                            break;
                    }
                    t.subscriptionUpgrades=null;
                    globalDataListener.postEvent("products");
                });
            });
        });

        this.publishers = {};
        onSnapshot(collection(db,"publisher"), function (snapshot) {
            snapshot.docChanges().forEach(function (change){
                const d = change.doc.data();
                const uid = change.doc.id;
                switch (change.type) {
                    case "modified":
                    case "added":
                        t.publishers[uid]=d;
                        break;
                    case "removed":
                        delete t.publishers[uid];
                        break;
                }
                globalDataListener.postEvent("products");
            });
        });

        this.fetchFeatured();
        this.fetchPopularity();
        return ret;
    }

    fetchFeatured() {
        const t=this;

        const db = getFirestore(firebase);
        const featuredAds = doc(db,"ads","featured");

        onSnapshot(featuredAds,function (doc){
            if (doc.exists()) {
                t.featuredAds = doc.data()||{};
            } else {
                t.featuredAds = {};
            }
            globalDataListener.postEvent("products");
        }, function (err) {if (err) console.log("start featured onsnapshot error", err)});
    }

    fetchPopularity() {
        const t=this;

        const db = getFirestore(firebase);
        const featuredAds = doc(db,"purchases","sales");

        onSnapshot(featuredAds,function (doc){
            if (doc.exists()) {
                t.popularityData = doc.data()||{};
            } else {
                t.popularityData = {};
            }
            globalDataListener.postEvent("products");
        }, function (err) {if (err) console.log("start popularity onsnapshot error", err)});
    }

    setFeatured(featured) {
        const db = getFirestore(firebase);
        const featuredAds = doc(db,"ads","featured");

        setDoc(featuredAds,featured).catch(function(err){
            console.log("error updating featured products", err);
        });
    }

    getPublisherInfo(uid) {
        return (this.publishers||{})[uid];
    }

    getProductFeaturedWeight(product) {
        return ((this.featuredAds || {})[product]||{}).weight || 0;
    }

    getFeaturedAds() {
        return this.featuredAds || {};
    }

    async publishProduct(productRaw) {
        const product = Object.assign({}, productRaw);
        const currentUser = getAuth(firebase).currentUser;
        const userId = currentUser.uid;

        product.userId = userId;
        if (!product.publisher) {
            product.publisher = currentUser.displayName;
        }

        await httpAuthRequestWithRetry("POST", "/search?cmd=publishproduct", JSON.stringify(product));
        return;
    }

    getProductInfo(name) {
        return (this.products||{})[name] || ((name==tipProduct)?tipProductInfo:null);
    }

    async loadProductInfo(productId) {
        const db = getFirestore(firebase);
        const marketRef = doc(db,"products",productId);
        const pdoc = await getDoc(marketRef);
        if (pdoc.exists()) {
            const product = pdoc.data();
            if (!this.products) {
                this.products={};
            }
            this.products[product.name]=product;
            this.subscriptionUpgrades=null;
            return product;
        }
        throw(new Error("Product not found"));
    }

    watchAuthState() {
        const t=this;
        getAuth(firebase).onAuthStateChanged(
            function (user) {
                t.getPurchases();
                globalDataListener.postEvent("products");
                globalDataListener.postEvent("purchases");
            }
        );
    }

    get owned() {
        return (this.purchaseData||{}).products||{};
    }

    get publisher() {
        return (this.purchaseData||{}).publisher||false;
    }

    isOwned(product_id, entry_type, entry_id) {
        return isOwned(this.owned, product_id, entry_type, entry_id)
    }

    isProductSubscriptionOwned(product) {
        const product_id = product.name;
        if (this.isOwned(product_id)){
            return true;
        }
        if (!this.subscriptionUpgrades) {
            // calculate the list of subscriptions that each subscription can be upgraded to
            const subscriptionUpgrades={};
            const products=this.products||{};
            for (let i in products){
                const p = products[i];
                if (p.purchaseType=="subscription" && p.subscriptionUpgrade) {
                    for (let x in p.subscriptionUpgrade) {
                        const s = p.subscriptionUpgrade[x];
                        if (!subscriptionUpgrades[s]){
                            subscriptionUpgrades[s]={};
                        }
                        subscriptionUpgrades[s][i]=true;
                    }
                }
            }
            this.subscriptionUpgrades=subscriptionUpgrades;
        }
        const su = this.subscriptionUpgrades[product_id];
        if (su) {
            for (let i in su) {
                if (this.isOwned(i)){
                    return true;
                }
            }
        }
        if (product.purchaseType=="subscription") {
            return false;
        }
        return this.checkAllPackagesOwned(product);
    }

    getLatestSubscriptionOwned(product_id) {
        let latestDate;
        const start = this.owned[product_id];
        if (start) {
            latestDate = start.end_date.toDate().getTime();
        }
        const su = this.subscriptionUpgrades[product_id];
        if (su) {
            for (let i in su) {
                const owned = this.owned[i];
                if (owned) {
                    const dt = owned.end_date.toDate().getTime();
                    if (!latestDate || (dt>latestDate)) {
                        latestDate = dt;
                    }
                }
            }
        }
        return latestDate;
    }

    checkAllPackagesOwned(product) {
        const purchases = marketplace.purchaseData||{};
        const allPackages = (product.includedPackages||[]).concat(product.bonusPackages||[]);
        const plist = purchases.packageList||[];
        const bonusPackages = purchases.bonusPackages||{};
        const purchasedPackages = purchases.purchasedPackages||{};
        let foundSome=false;
    
        for (let i in allPackages) {
            const p = allPackages[i];
            if (!plist.includes(p) && !bonusPackages[p] && !(purchasedPackages[p] && !purchasedPackages[p].entryTypes && !purchasedPackages[p].entryIds)) {
                return false;
            }
            foundSome=true;
        }
        return foundSome;
    }

    get purchasedPackages() {
        return (this.purchaseData||{}).purchasedPackages||{};
    }

    get canPurchaseFree() {
        return enforcePermissions?((this.purchaseData||{}).canPurchaseFree||false):true;
    }

    get freeLimit() {
        return 4;
    }

    get availableFreePurchases() {
        const avail = this.freeLimit-this.recentFreePurchases;
        return (avail>0)?avail:0;
    }

    get recentFreePurchases() {
        const products = (this.purchaseData||{}).products||{};
        const now = Date.now();
        const recentTime = 28*24*60*60*1000;
        let recent = 0;

        for (let i in products) {
            const p = products[i];
            if (!p.amount && p.addDate) {
                const buy = p.addDate.toDate().getTime();
                if (((now - buy) < recentTime) && !p.coupon_code) {
                    recent++;
                }
            }
        }
        return recent;
    }

    get purchaseCredits() {
        return (this.purchaseData||{}).credits||0;
    }

    async getPurchases() {
        const t=this;
        const currentUser = getAuth(firebase).currentUser;
        const oldCart = this.cart||{};
        this.unsubscribeAll();
        this.purchaseData = {};
        this.cart=this.cart||{};
        this.packageCache={};
        this.adminStatus={};
        this.userLoaded = false;
        this.cartLoaded = false;
        if (!currentUser) {
            return;
        }
        const db = getFirestore(firebase);
        const userId = currentUser.uid;

        const pdoc = await getDoc(doc(db,"purchases",userId));
        if (pdoc.exists()) {
            this.purchaseData = pdoc.data();
        } else {
            this.purchaseData = {};
        }
        this.userLoaded = true;

        this.unsubscribeList.push(onSnapshot(doc(db,"purchases",userId),function (doc){
            if (doc.exists()) {
                t.purchaseData = doc.data();
            } else {
                t.purchaseData = {};
            }
            globalDataListener.postEvent("products");
            globalDataListener.postEvent("purchases");
        }), function (err) {if (err) console.log("purchases onsnapshot error", err)});
        globalDataListener.postEvent("products");

        const cart = await getDoc(doc(db,"cart",userId));
        if (cart.exists()) {
            this.cart = cart.data();
        } else {
            this.cart = {};
        }
        this.cartLoaded = true;
        this.addOldCoupons(oldCart.coupons);

        this.unsubscribeList.push(onSnapshot(doc(db,"cart",userId),function (doc){
            const oldCart = t.cart||{};
            if (doc.exists()) {
                t.cart = doc.data();
            } else {
                t.cart = {};
            }
            t.addOldCoupons(oldCart.coupons);
            globalDataListener.postEvent("cart");
        }), function (err) {if (err) console.log("cart onsnapshot error", err)});
        globalDataListener.postEvent("cart");

        const admin = doc(db,"admins",userId);

        getDoc(admin).then(function (doc) {
            if (doc.exists()) {
                t.adminStatus = doc.data();
                globalDataListener.postEvent("products");
            }
        });

    }

    addOldCoupons(coupons) {
        if (coupons) {
            if (!this.cart.coupons) {
                this.cart.coupons = coupons;
            } else {
                for (let i in coupons) {
                    if (!this.cart.coupons.includes(coupons[i])) {
                        this.cart.coupons.push(coupons[i]);
                    }
                }
            }
        }
    }

    isAdmin() {
        return this.adminStatus && this.adminStatus.level;
    }

    async loadOwnedProducts() {
        const currentUser = getAuth(firebase).currentUser;
        if (!currentUser) {
            return null;
        }

        const responseText = await httpAuthRequestWithRetry("GET", "/search?cmd=owned", null);
        const history = JSON.parse(responseText);
        return history;
    }

    async setCart(cart) {
        const db = getFirestore(firebase);
        this.cart = cart;
        const currentUser = getAuth(firebase).currentUser;
        if (!currentUser) {
            return null;
        }
        await setDoc(doc(db,"cart",currentUser.uid),cart).catch(function(err){
            globalDataListener.errorMessage("Error updating cart: "+err.message);
        });
        globalDataListener.postEvent("cart");

    }

    async getPackages(pkgs) {
        const db = getFirestore(firebase);
        const pkref = collection(db,"packages");
        const promises = [];
        const map = [];
        const packageCache = this.packageCache||{};
        for (let i in pkgs) {
            const p = pkgs[i];

            if (packageCache[p]) {
                map[p]=packageCache[p]
            } else {
                promises.push(new Promise(async function (resolve, reject) {
                    try {
                        const gdoc = await getDoc(doc(pkref,p));
                        if (gdoc.exists()) {
                            const d = gdoc.data();
                            map[d.id]=d;
                            packageCache[d.id]=d;
                        } else {
                            console.log("could not find package", p);
                        }
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                }));
            }
        }
        await Promise.all(promises);
        return map;
    }

    async getSales() {
        const currentUser = getAuth(firebase).currentUser;
        if (!currentUser) {
            return null;
        }

        const responseText = await httpAuthRequestWithRetry("GET", "/search?cmd=sales", null);
        return JSON.parse(responseText);
    }

    async loadCoupons(code) {
        if (!this.loadingCouponCode) {
            this.loadingCouponCode={};
        }
        if (code && !this.loadingCouponCode[code]) {
            this.loadingCouponCode[code]=true;
            try {
                const responseText = await httpAuthRequestWithRetry("POST", "/search?cmd=couponinfo", JSON.stringify({code}));
                const coupons = JSON.parse(responseText);
                this.couponCache[code.toLowerCase()]= {coupons, date:Date.now()};
                globalDataListener.postEvent("products");
            } catch (err) {
                console.log("error reading coupon info", code, err)
            }
            delete this.loadingCouponCode[code];
        }
    }

    getCoupons(code) {
        if (!code) {
            return null;
        }
        if (!this.couponCache) {
            this.couponCache = {};
        }
        const val=this.couponCache[code.toLowerCase()];
        if (val && val.date && ((val.date+10*60*1000)>Date.now())) {
            return val.coupons;
        }
        this.loadCoupons(code);
        return null;
    }

    getCouponProductDiscount(product) {
        const coupons = (this.cart||{}).coupons;
        let discount=0;
        if (dollarProducts.includes(product.name)) {
            return 0;
        }
        if (coupons) {
            for (let i in coupons) {
                const couponList = this.getCoupons(coupons[i]);
                if (couponList) {
                    for (let c of couponList) {
                        if (c.discount > discount) {
                            if (c.products) {
                                if (c.products.includes(product.name)) {
                                    discount = c.discount;
                                }
                            } else  if (c.user_id == product.userId) {
                                discount = c.discount;
                            }
                        }
                    }
                }
            }
        }
        return discount;
    }

    getAllVals() {
        const types={}, settings={}, storylines={},rulesets={};

        rulesets[defaultRuleset]=1;
        
        addValues(packageTypes,types);
        addValues(defaultStorylines,storylines);
        addValues(defaultSettings,settings);
        for (let i in this.products) {
            const p = this.products[i];
            addValues(p.type, types);
            addValues(p.storyline, storylines);
            addValues(p.setting, settings);
            addValues(p.rulesets, rulesets);
        }
        return {types:getSortedKeys(types), storylines:getSortedKeys(storylines), settings:getSortedKeys(settings),rulesets:getSortedKeys(rulesets)};
    }
}

function addValues(v,a){
    for (let i in v){
        const tv = v[i];

        if (tv.length < 40) {
            a[tv]=1;
        }
    }
}

function getSortedKeys(list) {
    const ret = Object.keys(list);
    ret.sort();
    return ret;
}

const marketplace = new marketplaceObject();
export {marketplace};