const React = require('react');
const {campaign} = require('../lib/campaign.js');
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {TextLabelEdit} = require('./stdedit.jsx');

const stdvalues = require('../lib/stdvalues.js');

class SessionHistoryLog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Session Log for {this.props.character.displayName}</DialogTitle>
            <DialogContent>
                <div className="w-100">
                    <SessionLogList character={this.props.character}/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }    
}
    
class SessionLogEntryDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Session Log for {this.props.character.displayName}</DialogTitle>
            <DialogContent>
                <div className="minvh-70">
                    <SessionLogEntry editable noNew character={this.props.character} sessionLogId={this.props.sessionLogId}/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    close
                </Button>
            </DialogActions>
        </Dialog>;
    }    
}
    
class SessionLogEntry extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {id:props.sessionLogId};
    }

    componentDidUpdate(prevProps) {

        if (this.props.sessionLogId != prevProps.sessionLogId) {
            this.setState({id:this.props.sessionLogId})
        }
    }

    changeEntry(id, key, value) {
        const character=this.props.character;
        const log = Object.assign({}, character.getSessionLog(id));

        if (value) {
            log[key] = value;
        } else {
            delete log[key];
        }
        character.setSessionLog(id, log);
    }

	render() {
        const character=this.props.character;
        const editable = this.props.editable;
        const id = this.state.id || character.getCurrentSessionLog();
        const log = character.getSessionLog(id);
        const dt = new Date(Number(id));
        const coins = log.coins||{};
        const equipment = log.equipment||{};
        const elist = [];

        for (let i in coins) {
            const name = stdvalues.coinNames[i];
            const q = coins[i];
            if (q) {
                elist.push(<tr key={i}>
                    <td className="tr w3">{((q>=0)?"+":"")+q}</td>
                    <td >{name}</td>
                </tr>);
            }
        }

        for (let i in equipment) {
            const it = equipment[i];
            const q = (it.quantity||1);
            var adjust = "";

            if (q > 0) {
                if (q != 1) {
                    adjust ="+"+q;
                } else {
                    adjust ="+";
                }
            } else {
                if (q != -1) {
                    adjust = q;
                } else {
                    adjust = "-";
                }
            }

            elist.push(<tr key={i}>
                <td className="tr w3">{adjust}</td>
                <td >{it.displayName||it.name}</td>
            </tr>);
        }

        return <div className={this.props.onClick?"hoverhighlight":null} onClick={this.props.onClick}>
            <div className="titlecolor titletext f4 b mv1 flex titlebordercolor bb">
                <div className="flex-auto">
                    {!this.props.noName?"Session Log ":null}{dt.toLocaleString("en",{year:"numeric", month:"short", day:"numeric", weekday:"short", hour:"numeric", minute:"2-digit"})}
                </div>
                {!editable || this.props.noNew?null:<Button className="mb1" color="primary" size="small" variant="outlined" onClick={this.newSession.bind(this)}>New Session</Button>}
            </div>
            <div className="stdcontent">
                <div className="flex">
                    <div className="flex-auto"><TextLabelEdit editable={editable} label="Adventure Title" text={log.adventureTitle} onChange={this.changeEntry.bind(this, id, "adventureTitle")} size="fill"/></div>
                    <div className="mh1"><TextLabelEdit editable={editable} label="Session" text={log.session} onChange={this.changeEntry.bind(this, id, "session")} size="md"/></div>
                </div>
                <div className="flex">
                    <div className="flex-auto"><TextLabelEdit editable={editable} label="Location" text={log.location} onChange={this.changeEntry.bind(this, id, "location")} size="fill"/></div>
                    <div className="ml1 flex-auto"><TextLabelEdit editable={editable} label="DM" text={log.dm} onChange={this.changeEntry.bind(this, id, "dm")} size="fill"/></div>
                </div>
                <TextLabelEdit label="Levels" text={log.levels}/>
                <TextLabelEdit label="Experience Points" text={log.xp}/>
                {editable || log.notes?<div className="f5 b notetext titleborder">Session Notes</div>:null}
                {editable?<EntityEditor onChange={this.changeEntry.bind(this, id, "notes")} entry={log.notes} depth={2} saveDelay={this.props.saveDelay}/>:null}
                {!editable&&log.notes?<Renderentry entry={log.notes} depth={2} noDice/>:null}
            </div>
            {(elist.length)?<table className="w-100 mt1 stdcontent">
                <tbody>
                    <tr>
                        <td></td>
                        <td className="b">Equipment & Treasure Changes</td>
                    </tr>
                    {elist}
                </tbody>
            </table>:null}
        </div>;
    }

    changeLogEntry(event) {
        this.setState({id:event.target.value});
    }

    newSession() {
        const character=this.props.character;
        character.newSessionLog();
        this.setState({id:null});
    }

    onSelect(name) {
        this.setState({selectedHistory:name});
    }
}

class SessionLogList extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

	render() {
        const character=this.props.character;
        const curid = character.getCurrentSessionLog();
        const sl = character.sessionLog;
        const slKeys = Object.keys(sl).sort(function(a, b){return Number(b)-Number(a)});
        const list = [];

        if (!slKeys.length || slKeys[0]!=curid) {
            list.push(<SessionLogEntry key={curid} sessionLogId={curid} editable character={character} saveDelay={this.props.saveDelay}/>);
        }

        for (let i in slKeys) {
            const id = slKeys[i];
            if (!this.props.maxNum || list.length<this.props.maxNum) {
                list.push(<SessionLogEntry noName={list.length} key={id} sessionLogId={id} editable={curid==id} character={character} onClick={(curid==id)?null:this.showLogEntry.bind(this, id)} saveDelay={this.props.saveDelay}/>);
            }
        }

        if (this.props.skipFirst) {
            list.shift();
        }

        return <div>
            {list}
            {(!this.props.noMore && this.props.maxNum && slKeys.length>this.props.maxNum)?<div className="hoverhighlight mb2" onClick={this.showFullLog.bind(this,true)}>({slKeys.length-this.props.maxNum} more entries...)</div>:null}
            <SessionLogEntryDialog open={this.state.showEntry} sessionLogId={this.state.sessionLogId} onClose={this.closeEntry.bind(this)} character={character}/>
            <SessionHistoryLog open={this.state.showFullLog} onClose={this.showFullLog.bind(this,false)} character={character}/>
        </div>;
    }

    showLogEntry(id) {
        this.setState({showEntry:true, sessionLogId:id});
    }

    showFullLog(show) {
        this.setState({showFullLog:show});
    }

    closeEntry() {
        this.setState({showEntry:false});
    }

    newSession() {
        const character=this.props.character;
        character.newSessionLog();
        this.setState({sessionLogId:null});
    }
}

export {
    SessionLogEntry,
    SessionLogEntryDialog,
    SessionHistoryLog,
    SessionLogList
}