const React = require('react');
import sizeMe from 'react-sizeme';
const {campaign,globalDataListener} = require('../lib/campaign.js');
const {Character} = require('../lib/character.js');
const {MonObj} = require('../lib/monobj.js');
const {displayMessage} = require('./notification.jsx');
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {TextPlusEdit, PickVal, TextVal, SelectVal, CheckVal, NumberAdjust, DeleteWithConfirm,HDivider} = require('./stdedit.jsx');
const {ChatButton,RenderChat} = require('./renderchat.jsx');
const {MonsterPicker, MonsterBlock, getMonsterArtList,setupCompanion} = require("./rendermonster.jsx");
const {PickCondition,addConditionInfoToConditions} = require('./conditions.jsx');
const {ArtZoomList} = require('./renderart.jsx');

const Parser = require("../lib/dutils.js").Parser;
const {
} = require('../lib/stdvalues.js');

class CompanionsBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state= {};
    }

    render() {
        const {character,readonly} = this.props;
        const compList = character.companionsList;
        const companions = character.companions;
        const w = this.props.size.width-20;
        const count = Math.trunc(w / 210)||1;
        const width = w / count;
        const list=[];
        const hideMap = character.characterType != "players";

        for (let i in compList) {
            const m = compList[i];
            let tokenURL;
            if (m.imageURL) {
                tokenURL=m.imageURL;
            } else if (m.tokenArt) {
                const art = campaign.getArtInfo(m.tokenArt);
                if (art) {
                    tokenURL = art.url;
                }
            }
    
            const detailsClick = this.showCompanion.bind(this,m.id);
            const onMap = campaign.isDefaultCampaign()||this.isOnMap(m.id);
            const monObj = new MonObj(m,null, true, "noupdate");

            list.push(<div key={i} className="cursor-context-menu pb1 pr1 flex overflow-hidden" style={{width}}>
                <img height="70" width="70" src={tokenURL || "/blankmonster.png"} onClick={detailsClick}/>
                <div className="flex-auto ph1">
                    <div className="flex">
                        <div className="hoverhighlight flex-auto" onClick={detailsClick}>
                            <div className="f3 titletext titlecolor">
                                <div>{m.displayName}</div>
                            </div>
                            <div className="f6 near-black i">
                                <span>{Parser.sizeAbvToFull(monObj.size)} </span>
                                <span>{Parser.monTypeToFullObj(m.type).asText}</span>
                            </div>
                        </div>
                        {readonly?null:<div>
                            <DeleteWithConfirm className="pa1 titlecolor" name={m.displayName} onClick={this.deleteCompanion.bind(this, m.id)}/>
                        </div>}
                    </div>
                    <div className="f5 notetext titlecolor pt1">
                        <span className="dib titleborder ba br1 mb1 pa--2 nowrap hoverhighlight" onClick={detailsClick}>
                            AC {monObj.ac}
                        </span> {readonly?<span className="dib titleborder mb1 ba br1 pa--2 nowrap">HP {monObj.curHP||0}/{monObj.maxHP||1}</span>
                        :<NumberAdjust 
                            value={monObj.curHP} 
                            altText={<span className="dib titleborder mb1 ba br1 pa--2 nowrap hoverhighlight">HP {monObj.curHP||0}/{monObj.maxHP||1}</span>} 
                            noneg 
                            onChange={this.damage.bind(this,m.id)}
                        />} {campaign.isDefaultCampaign()||readonly||hideMap?null:<span className="dib mb1 pa--2 nowrap hoverhighlight" onClick={onMap?this.removeCompanion.bind(this, m.id):this.addCompanion.bind(this, m.id)}>
                            <span className={onMap?"far fa-check-square pa1":"far fa-square pa1"}/>On Map
                        </span>}
                    </div>
                </div>
            </div>);
        }

        return <div className="companionBlock avoidBreak">
            <div>
                <div className="flex theader flex-wrap items-center">
                    <span className="flex-auto ttitle">Creature Companions</span>
                    {readonly?null:<Button className="mh1 minw2" onClick={this.showMonsterPicker.bind(this)} color="primary" variant="outlined" size="small">Add</Button>}
                </div>
                <div className="stdcontent flex flex-wrap items-stretch">
                    {list}
                </div>
                <MonsterPicker includeCount noCharacters onClose={this.closeMonsterPicker.bind(this)} open={this.state.showMonsterPicker}/>
                <CompanionDetails monster={companions[this.state.curCompanion]} onChange={this.onChangeCompanion.bind(this)} open={this.state.curCompanion} onClose={this.showCompanion.bind(this,null)} character={character} addSpellToken={this.props.addSpellToken} eventSync={this.props.eventSync}/>
            </div>
        </div>;
    }

    damage(id, value, adjust) {
        const character = this.props.character;
        const companions = Object.assign({}, character.companions);
        const monObj = new MonObj(companions[id],null, true, "noupdate");
        monObj.damageHeal(adjust);

        companions[id] = monObj.state;
        character.companions = companions;
    }

    isOnMap(id) {
        const adventure = campaign.getAdventure();
        const character = this.props.character;
    
        const combatants = adventure.combatants;
    
        for (let i in combatants) {
            const c = combatants[i];
            if ((c.ctype=="cmonster") && (c.name==character.name) && (c.companionId==id)) {
                return true;
            }
        }
        return false
    }

    deleteCompanion(id) {
        const character = this.props.character;
        const companions = Object.assign({}, character.companions);
        delete companions[id];

        if (!campaign.isDefaultCampaign()) {
            const adventure = Object.assign({}, campaign.getAdventure());
            const character = this.props.character;
            let changed;
        
            const combatants = adventure.combatants = adventure.combatants.concat([]);
        
            for (let i in combatants) {
                const c = combatants[i];
                if ((c.ctype=="cmonster") && (c.name==character.name) && (c.companionId==id)) {
                    combatants.splice(i,1);
                    changed = true;
                }
            }
            if (changed) {
                campaign.versionUpdateCampaignContent("adventure", adventure);
            }
        }
        character.companions = companions;
    }

    onChangeCompanion(mon) {
        const {character} = this.props;
        setupCompanion(mon,character);
        const companions = Object.assign({}, character.companions);
        companions[this.state.curCompanion] = mon;
        character.companions = companions;
    }

    showMonsterPicker() {
        this.setState({showMonsterPicker:true});
    }

    showCompanion(curCompanion) {
        this.setState({curCompanion});
    }

    closeMonsterPicker(selList) {
        if (selList) {
            this.props.character.addCompanions(selList);
        }
        this.setState({showMonsterPicker:false});
    }

    addCompanion(id) {
        const {findFreeSpot,fixupCombatants} =require('./encountermonsterlist.jsx');
        const adventure = Object.assign({}, campaign.getAdventure());
        const character = this.props.character;
    
        const combatants = adventure.combatants = adventure.combatants.concat([]);
        const nrow = {id:campaign.newUid(), ctype:"cmonster", name:character.name, companionId:id};
        let characterRow;
    
        for (let i in combatants) {
            const c = combatants[i];
            if ((c.ctype == "pc") && (character.name==c.name)) {
                characterRow = c;
            } else if ((c.ctype=="cmonster") && (c.name==character.name) && (c.companionId==id)) {
                return;
            }
        }

        combatants.push(nrow);

        if (characterRow && characterRow.tokenMap) {
            nrow.tokenMap = characterRow.tokenMap;
            nrow.tokenX = characterRow.tokenX||0;
            nrow.tokenY = characterRow.tokenY||0;
            findFreeSpot(characterRow.tokenMap, combatants, characterRow.tokenX, characterRow.tokenY, nrow, combatants.length-1);
        }
        fixupCombatants(combatants);
        campaign.versionUpdateCampaignContent("adventure", adventure);
    }

    removeCompanion(id) {
        const adventure = Object.assign({}, campaign.getAdventure());
        const character = this.props.character;
    
        const combatants = adventure.combatants = adventure.combatants.concat([]);
        let found;
    
        for (let i in combatants) {
            const c = combatants[i];
            if ((c.ctype=="cmonster") && (c.name==character.name) && (c.companionId==id)) {
                found=true;
                combatants.splice(i,1);
            }
        }
        if (found) {
            campaign.versionUpdateCampaignContent("adventure", adventure);
        }
    }
}

class CompanionDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open!= prevProps.open) {
            this.setState({editable:false});
        }
    }

	render() {
        let mon = this.state.editable?this.state.monster:this.props.monster;
        if (!this.props.open || !mon) {
            return null;
        }

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>
                {this.state.editable?<TextVal    
                    text={mon.displayName}
                    fullWidth
                    inputProps={{className:"f1 titletext titlecolor ignoreDrag"}}
                    onChange={this.onChangeName.bind(this)}
                />:<span className="ignoreDrag" onClick={this.showMenu.bind(this,true)}>{mon.displayName}</span>}
            </DialogTitle>
            <DialogContent>
                <div>
                    <MonsterBlock noname inlineEdit noSource showInstantRoll monster={mon} editable={this.state.editable} onChange={this.onChange.bind(this)} onChangeInline={this.props.onChange} character={this.props.character} rollable addSpellToken={this.props.addSpellToken} eventSync={this.props.eventSync} onClickToken={this.showExtraArt.bind(this)}/>
                </div>
            </DialogContent>
            <DialogActions>
                {!this.state.editable?<ChatButton character={this.props.character}/>:null}
                {!this.state.editable?<Button onClick={this.clickEdit.bind(this)} color="primary">
                    Edit
                </Button>:null}
                {this.state.editable?<Button onClick={this.showExtraArt.bind(this)} color="primary">
                    Select Artwork
                </Button>:null}
                {(this.state.editable)?<Button disabled={!mon.displayName} onClick={this.clickSave.bind(this)} color="primary">
                    Save
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            {this.getMenu()}
            <ArtZoomList editable open={this.state.showExtraArtDialog} artList={getMonsterArtList(mon)} onClose={this.onSaveArtwork.bind(this)} pickToken defaultArt={mon.defaultArt} defaultToken={mon.tokenArt} defaultSearch={mon.displayName} defaultType="Monster Token"/>
        </Dialog>;
    }

    showExtraArt() {
        if (this.state.editable) {
            this.setState({showExtraArtDialog:true});
        }
    }
    
    onSaveArtwork(artList, defaultArt, defaultToken) {
        if (artList) {
            let mon=Object.assign({}, this.state.monster);
            mon.artList=artList||null;
            mon.defaultArt=defaultArt||null;
            mon.tokenArt = defaultToken||null;
            if (defaultToken) {
                delete mon.imageURL;
            }
            this.onChange(mon);
        }
        this.setState({showExtraArtDialog:false})
    }

    getMenu() {
        if (!this.state.showmenu) {
            return null;
        }

        return <Menu
            anchorEl={this.state.anchorEl||null}
            open
            onClose={this.showMenu.bind(this,false)}
        >
            {!this.state.editable?<MenuItem onClick={this.clickEdit.bind(this)}>Edit</MenuItem>:null}
            <PickCondition onAddCondition={this.closeAddCondition.bind(this)}/>
        </Menu>;
    }

    showMenu(showmenu,e) {
        this.setState({showmenu, anchorEl:showmenu&&e&&e.currentTarget});
    }

    closeAddCondition(conditionInfo) {
        if (conditionInfo) {
            const mon = Object.assign({},this.props.monster);
            let c = Object.assign({}, mon.conditions||{});
            addConditionInfoToConditions(c, conditionInfo);
            mon.conditions=c;
            this.props.onChange(mon);
        }
        this.setState({showmenu:false});
    }

    onChangeName(name){
        const mon = Object.assign({},this.state.monster);
        mon.displayName=name;
        this.setState({monster:mon});
    }

    onChange(monster){
        this.setState({monster});
    }
    
    clickSave() {
        this.props.onChange(this.state.monster);
        this.props.onClose();
    }
    
    clickEdit() {
        this.setState({editable:true, monster:this.props.monster});
    }
}
const minTraySize = 120;

class CompanionViewBase extends React.Component {

    constructor(props) {
        super(props);

        this.hparentRef = React.createRef();
        const {characterSplit} = campaign.getUserSettings();

        this.state= {hPercent:characterSplit??20};
    }

    render() {
        const {addSpellToken, size,readonly,softSelected} = this.props;
        const {width,height} = size;
        if (width > 800) {
            let chatWidth = Math.max(320, Math.min(700, width * 0.35));
            const charWidth = Math.min(width-chatWidth, 850);
            if ((charWidth+chatWidth) < width) {
                chatWidth=Math.min(700, width-charWidth);
            }

            return <div className="h-100 w-100 flex">
                <div className="flex-auto"/>
                <div style={{width:charWidth}} className="overflow-y-auto pa1 defaultbackground"><RenderCompanion {...this.props}/></div>
                <div style={{width:chatWidth}}><RenderChat width={chatWidth} softSelected={softSelected} addSpellToken={addSpellToken}/></div>
                <div className="flex-auto"/>
            </div>;
        } 
        const cHeight = (Math.min(80,this.state.hPercent||0))*0.01*height;
        return <div className="h-100 flex flex-column" ref={this.hparentRef}>
            <div className="flex-auto h1 overflow-y-auto overflow-x-hidden pa1 defaultbackground">
                <RenderCompanion {...this.props}/>
            </div>
            <HDivider onSlide={this.onHSlide.bind(this)} getSections={this.getHSections.bind(this)} parentRef={this.hparentRef}/>
            <div style={{height:cHeight,minHeight:minTraySize}}>
                <RenderChat allSmall={cHeight<125} width={width} softSelected={softSelected} addSpellToken={addSpellToken}/>
            </div>
        </div>
    }

    getHSections(pos) {
        const pRect = this.hparentRef.current.getBoundingClientRect();
        const height = pRect.height;

        const bottom = Math.min(height*0.8,Math.max(minTraySize, height-pos));
        const top = height-bottom;

        return [top,bottom];
    }

    onHSlide(pos) {
        const pRect = this.hparentRef.current.getBoundingClientRect();
        const height = pRect.height;
        const hPercent = Math.min(80, (height - pos)/height*100);

        campaign.updateUserSettings({characterSplit:hPercent});
        this.setState({hPercent});
    }
}

class RenderCompanion extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnDataChange = this.onDataChange.bind(this);
	    this.state= {};
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "players");
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "adventure");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "players");
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "adventure");
    }

    onDataChange() {
        this.setState({refresh:campaign.newUid()});
    }

	render() {
        const player = campaign.getPlayerInfo(this.props.name);
        if (player) {
            const character = new Character(player, "players",);
            const monVal = character.companions[this.props.companionId];

            if (monVal){

                return <div key={this.props.companionId} >
                    <MonsterBlock inlineEdit noSource eventSync={this.props.eventSync} monster={monVal} onChangeInline={this.onChangeCompanion.bind(this)} character={character} rollable addSpellToken={this.props.addSpellToken}/>
                </div>;
            }
        }
        return <div>Monster not found</div>;
    }
    
    onChangeCompanion(mon) {
        const player = campaign.getPlayerInfo(this.props.name);
        if (player && !this.props.readonly) {
            const character = new Character(player, "players");
            const companions = Object.assign({}, character.companions);
            companions[this.props.companionId] = mon;
            character.companions = companions;
        }
    }
}

const CompanionsBlockSize = sizeMe({monitorHeight:true, monitorWidth:true})(CompanionsBlock);
const CompanionView = sizeMe({monitorHeight:true, monitorWidth:true})(CompanionViewBase);
export {
    CompanionsBlockSize as CompanionsBlock,
    RenderCompanion,
    CompanionView
}