const {campaign,globalDataListener, getDirectDownloadUrl, httpAuthRequestWithRetry,newUid} = require('../lib/campaign.js');
import { PayPalButtons,PayPalScriptProvider,usePayPalScriptReducer } from "@paypal/react-paypal-js";
const {marketplace} = require('../lib/marketplace.js');
const {Authenticate} = require('./authenticate.jsx');
const React = require('react');
const {displayMessage} = require('./notification.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {SelectVal, DateVal,PriceVal,TextVal,ClipboardCopy,getDateStrFromDate,DisplayTable,TextBasicEdit} = require('./stdedit.jsx');
const {packageGenres, packageTypes} = require('../lib/stdvalues.js');
const {excludeTypes} = require('./packages.jsx');

import {Elements} from '@stripe/react-stripe-js';
import {useStripe, useElements,CardElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const tipProduct = "8qrr17w4sa8aluxx";
const giftCertificateProduct = "66ua05ih4evkm40e";
const dollarProducts = [tipProduct,giftCertificateProduct];

let stripeProductionPromise = null;
let stripeTestPromise = null;

function initStripe() {
    if (!stripeTestPromise) {
        stripeTestPromise = loadStripe("pk_test_51I48BAD2UpOwHJEYmjxlgGkUpZxEaiGGY20nQdKGqyOlzZfM55HvrsP7vIJT29pVD96YJKFFyugK0L4BfDE8nZWJ00tsq42HLh");
        stripeProductionPromise = loadStripe("pk_live_51I48BAD2UpOwHJEYqaPLsOzJd6Col5l0yBCPUlthjERskJtuWp8j2uJfo9W79ljFS4sBt8W6bkMCPIccKRzTPaMr00G6hd9ckY");
    }
}

function getPaypalClientId(test) {
    if (test) {
        return "ARuzoyjYIOdj_-WgWsVQjsoRINuOulcepDj0spg8L9OL42_gzcrOZKrv3uBQhq34S9G7sm4prcXWVoW8";
    }
    return "AdoFkHdKvl6Z4kQEYleyizw-gQ0BDIPEjmpjMsUUBRm1wTiMA7NKfDJiUbwjMFvT7LgQllqefbVLDtO0";
}

class BuyButton extends React.Component {
    constructor(props) {
        super(props);
        initStripe();

        this.state= {buyInfo:null,dollarValue:props.defaultDollarValue||25};
    }

    componentDidMount() {
        if (!this.props.product) {
            this.updateCartFn = this.updateCart.bind(this);
            globalDataListener.on("cart",this.updateCartFn);
        }
    }

    componentWillUnmount() {
        if (!this.props.product) {
            globalDataListener.removeListener("cart",this.updateCartFn);
        }
    }

    updateCart() {
        this.setState({cart:marketplace.cart});
    }

    render() {
        const product = this.props.product||{};
        const productList = this.props.productList;
        const hidden=this.props.hidden;
        const subscribed = product.purchaseType=="subscription";
        const directPurchase = this.props.directPurchase;
        const packagesOwned = !directPurchase && !subscribed && marketplace.checkAllPackagesOwned(product);
        const productOwned = this.props.useCampaign?campaign.isOwned(product.name):marketplace.isOwned(product.name);
        const owned = !directPurchase && !this.props.readonly && (this.props.useCampaign?productOwned:(productOwned || packagesOwned));
        const isPickValue = (!this.props.readonly||true) && !hidden && dollarProducts.includes(product.name);
        const shouldGift=this.props.product&&((owned&&!subscribed)||(product.name==giftCertificateProduct));
        let stripePromise=null;
        if (this.state.getChargeInformation) {
            if (this.state.buyInfo.test) {
                stripePromise=stripeTestPromise;
            } else {
                stripePromise=stripeProductionPromise;
            }
        }

        return <span>
            {(isPickValue&&!hidden&&!this.props.readonly)?<SelectVal className="mr2 mt1" value={this.state.dollarValue} isNum values={dollarValueChoices} onClick={this.changeDollarValue.bind(this)}/>:null}
            {productList?<Button onClick={this.onBuyList.bind(this, productList)} variant="contained" color="primary">{this.props.label}</Button>:
             (hidden || (owned && this.props.noGift))?null:product.name?<span>
                <Button onClick={this.props.readonly?null:this.onBuy.bind(this, shouldGift)} variant="contained" color={shouldGift?"secondary":"primary"} disabled={this.props.readonly}>
                    {this.props.label || ((shouldGift)?"Give Gift":subscribed?"Subscribe":(product.salePrice||isPickValue)?"Buy":"Get it free")}
                </Button> {owned?(productOwned?<span className="b i">(owned)</span>:<span className="b i">(components owned)</span>):null}
            </span>:
            marketplace.cart && marketplace.cart.products?<span className="fas fa-shopping-cart pa1 hoverhighlight" onClick={this.onBuy.bind(this,false)}/>:null}

            {this.state.doAuth?<Authenticate onClose={this.onCloseAuth.bind(this)}/>:null}
            {this.state.getChargeInformation?<Elements stripe={stripePromise}><ElementChargeDialog open onClose={this.onCloseCharge.bind(this)} client_secret={this.state.buyInfo.client_secret}/></Elements>:null}
            {this.state.confirmPurchase?<ConfirmBuyDialog open buyInfo={this.state.buyInfo} onClose={this.onConfirmBuy.bind(this)} directPurchase={directPurchase}/>:null}
            {this.state.showGiftKey?<GiftKey open giftkey={this.state.giftKey} onClose={this.setState.bind(this, {showGiftKey:false}, null, null)}/>:null}
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Processing...
                </DialogContent>
            </Dialog>:null}
        </span>;
    }

    showConfirmPurchase() {
        this.onBuy();
    }

    changeDollarValue(dollarValue) {
        this.setState({dollarValue})
    }

    onCloseCharge(save, usePayPal) {
        if (save) {
            const buyInfo = Object.assign({}, this.state.buyInfo);
            if (usePayPal) {
                buyInfo.usePayPal = true;
            } else {
                delete buyInfo.usePayPal;
            }
            this.sendBuy(buyInfo);
        } else if (this.props.onComplete) {
            this.props.onComplete();
        }
        this.setState({getChargeInformation:false})
    }

    onCloseAuth() {
        const user = campaign.currentUser;
        if (user) {
            const {productList} = this.state;
            if (productList) {
                this.onBuyList(productList);
            } else {
                this.onBuy(false);
            }
        } else if (this.props.onComplete) {
            this.props.onComplete();
        }
        this.setState({doAuth:false});
    }

    onBuyList(productList) {
        if (!productList) {
            return;
        }
        const user = campaign.currentUser;
        if (!user) {
            this.setState({doAuth:true, shouldGift:false, productList});
        } else {
            const cart = marketplace.cart;
            let products = cart.products||[];
            if (cart?.gift) {
                return;
            }

            for (let p of productList) {
                if (!marketplace.isOwned(p.name)) {
                    addToProducts(products, p.name, !!p.yearlySalePrice, null, null, null, 0,0);
                }
            }
            this.sendBuy({products,gift:false,needcheckout:true, coupons:cart.coupons||null});
        }
    }

    onBuy(shouldGift) {
        const user = campaign.currentUser;
        if (!user) {
            this.setState({doAuth:true, shouldGift, productList:null});
        } else {
            const {directPurchase, product} = this.props;
            const cart = directPurchase?{}:marketplace.cart;
            let gift = (cart&&cart.gift) || shouldGift || false;
            let products = cart.products||[];
            let freeProds = 0;

            for (let p of products) {
                if (!p.price && !p.bonusProduct) {
                    freeProds++;
                }
            }

            if (!directPurchase && product && !marketplace.canPurchaseFree && (product.purchaseType!="subscription") && !product.salePrice && (marketplace.availableFreePurchases < (freeProds+1))) {
                displayMessage(<span>You have used your 4 purchases of free products in the last month.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to choose a subscription that allows unlimited free products.</span>);
                return;
            }

            if (product) {
                addToProducts(products, product.name, !!product.yearlySalePrice, null, null, null, this.state.dollarValue,this.props.tipUser);
            }
            if (products.length) {
                this.sendBuy({products,gift,needcheckout:!directPurchase, coupons:cart.coupons||null});
            }
        }
    }

    onConfirmBuy(buyInfo) {
        if (buyInfo) {
            this.sendBuy(buyInfo);
        } else {
            if (this.props.onComplete) {
                this.props.onComplete();
            }
            this.setState({confirmPurchase:false});
        }
        this.setState({buyInfo:buyInfo||{}});
    }
    
    async sendBuy(buyInfo) {
        const t=this;
        const directPurchase = this.props.directPurchase;
        this.setState({loading:true});
        if (!buyInfo.referral && !directPurchase) {
            buyInfo.referral = marketplace.getReferral();
        }

        const products = buyInfo.products;

        for (let i=(products?.length||0)-1; i>=0; i--) {
            const p=products[i];
            const product = marketplace.getProductInfo(p.id);
            if (product?.publishState=="discontinued") {
                //console.log("removed discontinued", p, products);
                products.splice(i,1);
            }
        }

        try {
            const responseText = await httpAuthRequestWithRetry("POST", "/search?cmd=buy", JSON.stringify(buyInfo));
            const res = JSON.parse(responseText);
            //console.log("got result", res);
            t.setState({loading:false});
            if (res.completed) {
                t.setState({confirmPurchase:false});
                if (this.props.onComplete) {
                    this.props.onComplete(this.state.dollarValue);
                } else if (!directPurchase) {
                    marketplace.setCart({});
                }
        
                instrumentPurchase(buyInfo);
                if (res.giftKey) {
                    t.setState({showGiftKey:true, giftKey:res.giftKey});
                } else if (!this.props.onComplete) {
                    displayMessage("Product purchased");
                }
            } else {
                switch (res.action) {
                    case "configurecard": {
                        if (!res.usePayPal) {
                            t.setState({getChargeInformation:true, buyInfo:res,confirmPurchase:false});
                            return;
                        }
                        //fall through to checkout
                    }

                    case "checkout":
                    case "confirm":{
                        if (t.props.onComplete) {
                            t.props.onComplete();
                        } else {
                            t.setState({confirmPurchase:true, buyInfo:res});
                        }            

                        return
                    }

                    case "action": {
                        t.setState({loading:true,confirmPurchase:false});
                        authorizePayment(res.client_secret, res.payment_method, res.test).then(function () {
                            t.sendBuy(res);
                        }, function (err) {
                            t.setState({loading:false});
                            displayMessage("Error buying product: "+err.message);
                            if (t.props.onComplete) {
                                t.props.onComplete();
                            }            
                            console.log("error", err)
                        });
                        return;
                    }

                    case "canceled": {
                        t.setState({loading:false,confirmPurchase:false});
                        displayMessage("Purchase canceled");
                        if (t.props.onComplete) {
                            t.props.onComplete();
                        }
                        return;
                    }
                }
                displayMessage("Not done yet");
            }
        } catch (err) {
            t.setState({loading:false});
            displayMessage("Error buying products: "+err.message);
            if (t.props.onComplete) {
                t.props.onComplete();
            }            
            console.log("error", err)
        }
    }
}

function instrumentPurchase(buyInfo) {
    const products = buyInfo.products;
    const list = [];

    for (let i in products) {
        const p=products[i];
        const product = marketplace.getProductInfo(p.id);
        if (product) {
            list.push({
                id:p.id,
                name:product.displayName,
                quantity:1,
                price:p.price-(p.discount||0),
            })
        }
    }
    gtag('event', 'purchase', {
        "transaction_id": newUid(),
        "value": buyInfo.total_amount,
        "currency": "USD",
        "tax": buyInfo.sales_tax,
        "shipping": 0,
        "items": list
    });
}

async function authorizePayment(clientSecret, paymentMethod, test) {
    let stripePromise;
    initStripe();

    if (test) {
        stripePromise=stripeTestPromise;
    } else {
        stripePromise=stripeProductionPromise;
    }

    const stripe = await stripePromise;

    return stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod
    });
}

function ElementChargeDialog(props) {
    const stripe = useStripe();
    const elements = useElements();

    return <ChargeDialog open={props.open} noPay={props.noPay} onClose={props.onClose} stripe={stripe} elements={elements} client_secret={props.client_secret}/>;
}

class ChargeDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {name:campaign.currentUser.displayName||""};
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const {stripe,elements, noPay} = this.props;
        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false,false)}>Credit Card Information</DialogTitle>
            <DialogContent>
                <TextVal helperText="Name on Card" text={this.state.name} onChange={this.setName.bind(this)} fullWidth className="mb2"/>
                <div className="pa1 ba titleborder br2">
                    <CardElement/>
                </div>
                <div className="mt2 hk-well">
                    Credit card information will be securely stored for future purchases and subscription renewal.
                </div>
            </DialogContent>
            <DialogActions>
                {!noPay?<Button disabled={!stripe||!elements} onClick={this.handleClose.bind(this, true,true)} color="primary">
                    Pay with PayPal
                </Button>:null}
                <Button disabled={!stripe||!elements} onClick={this.handleClose.bind(this, true,false)} color="primary">
                    {noPay?"Register Card":"Pay with Card"}
                </Button>
                <Button onClick={this.handleClose.bind(this, false,false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Processing...
                </DialogContent>
            </Dialog>:null}
        </Dialog>;
    }

    setName(name) {
        this.setState({name});
    }

    handleClose(save,usePayPal) {
        const t=this;
        const {stripe,elements,client_secret} = this.props;
        if (save) {
            if (usePayPal) {
                this.props.onClose(true, true);
                return;
            }
            const cardElement = elements.getElement(CardElement);
            this.setState({loading:true});
            //console.log("stripe", stripe, elements, cardElement);
            stripe.confirmCardSetup(client_secret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: this.state.name,
                    }
                }
            }).then(function (result){
                t.setState({loading:false});
                if (result.error) {
                    displayMessage(result.error.message);
                } else {
                    t.props.onClose(true,false);
                }
            }, function (err) {
                t.setState({loading:false});
                console.log("got error");
                displayMessage("Error saving card information: "+err.message);
            });
            return;
        }
        this.props.onClose(false,false);
    }

}

class ConfirmBuyDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {buyInfo:props.buyInfo};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.buyInfo != prevProps.buyInfo)) {
            this.loadProductPackages();
            if (!(this.props.buyInfo.products||[]).length) {
                const t = this;
                displayMessage("No products in cart",function (){
                    t.handleClose(false,false);
                })
            }
            this.setState({buyInfo:this.props.buyInfo});
        }
    }

    componentDidMount() {
        const buyInfo = this.state.buyInfo;
        const products = buyInfo.products;
        let anyOwned = false;

        for (let i in products) {
            const p=products[i];
            const product = marketplace.getProductInfo(p.id);
            const subscription = product.purchaseType=="subscription";
            const owned = !this.props.directPurchase && marketplace.isOwned(p.id);
            if (owned && !subscription) {
                anyOwned=true;
            }
        }
        if (anyOwned && !buyInfo.gift) {
            this.setGift(true);
        } else {
            this.loadProductPackages();
        }
    }

    async loadProductPackages() {
        const buyInfo = this.state.buyInfo;
        const products = buyInfo.products;

        if (!buyInfo.gift) {
            let pkgs = [];
            for (let i in products) {
                const p=products[i];
                const product = marketplace.getProductInfo(p.id);
                const subscription = product.purchaseType=="subscription";

                if (!subscription && product.includedPackages) {
                    pkgs = pkgs.concat(product.includedPackages);
                }
            }
            this.setState({loading:true});
            let packageMap;
            try {
                packageMap = await marketplace.getPackages(pkgs);
            } catch(err){
                console.log("error getting packages", err);
            }
            const dc = !this.props.directPurchase?(new DependencyChecker(buyInfo)):null;
            this.setState({loading:false, packageMap, dc});
        }
    }

    handleClose(save, confirmed) {
        if (!save) {
            if (!this.props.directPurchase) {
                const newCart = Object.assign({}, marketplace.cart);
                if (newCart.products) {
                    marketplace.setCart({});
                }
            }
   
            this.props.onClose(null);
            return;
        }

        const buyInfo=Object.assign({}, this.state.buyInfo);

        if (buyInfo.needcheckout) {
            delete buyInfo.needcheckout;
        } else {
            buyInfo.confirmed = confirmed;
        }
        this.props.onClose(buyInfo);
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const {buyInfo,packageMap, dc} = this.state;
        const products = buyInfo.products;
        const needcheckout = buyInfo.needcheckout;
        const list = [];
        let anyOwned = false;

        for (let i in products) {
            const p=products[i];
            const product = marketplace.getProductInfo(p.id);
            const owned = !this.props.directPurchase && marketplace.isOwned(p.id);
            const upgradeList =[];
            const subscription = product.purchaseType=="subscription";

            if (owned && !subscription) {
                anyOwned=true;
            }

            const yearly = p.yearly;
            let savings=0;
            let missing;
            let price = (subscription?product.yearlySalePrice:product.salePrice)||0;
            if (subscription) {
                price = (yearly?product.yearlySalePrice:product.monthlySalePrice)||0;
                if (product.yearlySalePrice && product.monthlySalePrice) {
                    savings = 1 - product.yearlySalePrice/(product.monthlySalePrice*12);
                    if (savings < 0) {
                        savings=0;
                    }
                }
            } else {
                price = product.salePrice;
                if (dc && !buyInfo.gift && !anyOwned && !p.entry_name && !p.entry_type) {
                    missing = dc.getProductMissingDependencies(product, packageMap);
                }
            }

            if (p.upgradeList) {
                for (let x in p.upgradeList) {
                    const up = marketplace.getProductInfo(p.upgradeList[x]);
                    if (up) {
                        upgradeList.push(up.displayName);
                    }
                }
            }

            list.push(<tr key={i}>
                <td className="w-100">
                    {product.displayName} {p.entry_type?<span className="f5">({p.entry_name?(p.entry_name+", "):"all "}{p.entry_type})</span>:null}
                    {missing?<div className="mv1 hk-well">
                        <div className="mb1">
                            Some dependent content required to use {product.displayName?<i>{product.displayName}</i>:"this product"} is missing.
                        </div>
                        <Button onClick={this.showGetDependencies.bind(this,missing)} color="primary" size="small" variant="outlined">
                            Get Missing Content
                        </Button>
                    </div>:null}
                    {upgradeList.length?<div className="mv1 f5">
                        <div className="i mb1">Upgrade from {upgradeList.join(", ")}</div>
                        <div className="hk-well">Remaining subscription credits have been applied to the new subscription.</div>
                    </div>:null}
                    {(subscription&&p.renewalEnd)?<div className="mv1 f5">
                        {p.bonus_months?
                            <span>{p.bonus_months} Month Subscription</span>:
                            <span>Renew from {(new Date(p.renewalStart)).toLocaleDateString(undefined,dateOptions)} - {(new Date(p.renewalEnd)).toLocaleDateString(undefined,dateOptions)}</span>
                        }
                    </div>:null}
                    {(p.discount&&p.discountSource&&p.discountSource.length)?<div className="mt1 ml2 i">Purchase credit since you own {p.discountSource}</div>:null}
                    {(p.extraDiscount&&p.extraDiscount.length)?<div className="mt1 ml2 i">{p.extraDiscount}</div>:null}
                    {subscription&&!p.bonusProduct?<div className="mt1">
                        {product.monthlySalePrice?<div className="hoverhighlight" onClick={this.setSub.bind(this,i,false)}>
                            <span className={"pa1 far "+(yearly?"fa-circle":"fa-dot-circle")}/>
                            &nbsp;${product.monthlySalePrice.toFixed(2)}/month
                        </div>:null}
                        {product.yearlySalePrice?<div className="hoverhighlight" onClick={this.setSub.bind(this,i,true)}>
                            <span className={"pa1 far "+(yearly?"fa-dot-circle":"fa-circle")}/>&nbsp;${product.yearlySalePrice.toFixed(2)}/year
                            {savings?<span>&nbsp;(save {(savings*100).toFixed(0)}%)</span>:null}
                        </div>:null}
                    </div>:null}
                    {!p.bonusProduct?<div className="mv1 ml3">
                        <Button onClick={this.removeProduct.bind(this, i)} color="primary" size="small" variant="outlined">
                            Remove
                        </Button>
                    </div>:null}
                </td>
                <td className="tr v-top numbers">
                    ${(p.price||0).toFixed(2)}
                    {p.discount?<div className="mt1">-${(p.discount||0).toFixed(2)}</div>:null}
                </td>
            </tr>);
        }

        const tax = buyInfo.sales_tax;
        const total = buyInfo.total_amount;
        const credit_usage = buyInfo.credit_usage;
        const charge_amount = buyInfo.charge_amount;
        const cartTooSmall = (charge_amount>0) && (charge_amount < 1.99);

        return <PayPalScriptProvider options={{
            "client-id": getPaypalClientId(buyInfo.test),
            currency: "USD",
            intent: "capture"
        }}>
            <Dialog
                open
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle onClose={this.shopMore.bind(this)}>{needcheckout?"Shopping Cart":"Checkout"}</DialogTitle>
                <DialogContent>
                    {cartTooSmall?<div className="mb1 hk-well">Cart must be at least $1.99 to checkout</div>:null}               
                    <div className="ba titleborder br2 pa1 f3">
                        <table className="w-100">
                            <tbody>
                                {list}
                                {tax?<tr>
                                    <td className="w-100 tr">Sales Tax&nbsp;</td>
                                    <td className="tr bb numbers">${(tax).toFixed(2)}</td>
                                </tr>:null}
                                {tax?<tr>
                                    <td className="w-100 tr">Total&nbsp;</td>
                                    <td className="tr numbers">${(total).toFixed(2)}</td>
                                </tr>:null}
                                {credit_usage?<tr>
                                    <td className="minh2"></td>
                                    <td className="minh2"></td>
                                </tr>:null}
                                {credit_usage?<tr>
                                    <td className="w-100 tr">Credits&nbsp;</td>
                                    <td className="tr numbers">-${(credit_usage).toFixed(2)}</td>
                                </tr>:null}
                                {credit_usage?<tr>
                                    <td className="w-100 tr">Total Charge&nbsp;</td>
                                    <td className="tr numbers">${(charge_amount).toFixed(2)}</td>
                                </tr>:null}
                            </tbody>
                        </table>
                    </div>
                    {this.props.directPurchase?null:anyOwned?
                        <div className="mv1 f3 red">Purchase as a gift, since you already own some of these products.</div>
                    :
                        <div className="mv1">
                            <div>Select One: (required)</div>
                            <div className="f3 hoverhighlight" onClick={this.setGift.bind(this,false)}>
                                <span className={"pa1 far "+(!buyInfo.gift?"fa-dot-circle":"fa-circle")}/>
                                Purchase for myself
                            </div>
                            <div className="f3 hoverhighlight" onClick={this.setGift.bind(this,true)}>
                                <span className={"pa1 far "+(buyInfo.gift?"fa-dot-circle":"fa-circle")}/>
                                Purchase as a gift
                            </div>
                        </div>
                    }
                    <div className="hk-well mv1">
                        <div className="red mb2 tl">All purchases are for digital products for use on shardtabletop.com. You will NOT receive physical item(s). Digital items are not returnable or refundable.</div>
                        <div className="red tl">All transactions are in U.S. Dollars (USD)</div>
                    </div>
                    {!needcheckout&&charge_amount&&!buyInfo.usePayPal?<div>
                        {buyInfo.last4?<div className="pv1 f3">
                            Pay using card from {buyInfo.billingName} ending in {buyInfo.last4}.
                            <Button onClick={this.deleteCard.bind(this)} size="small" variant="outlined" className="ml2">
                                Change Card
                            </Button>
                        </div>:null}
                    </div>:null}
                    {(!this.props.directPurchase&&!marketplace.canPurchaseFree && !total)?<div className="pv2 f3">
                        {marketplace.availableFreePurchases} free product purchases remaining
                    </div>:null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.addCoupon.bind(this)} color="primary">
                        Add Coupon
                    </Button>
                    
                    {needcheckout?<Button disabled={cartTooSmall} onClick={this.handleClose.bind(this, true, true)} color="primary">
                        Checkout
                    </Button>:null}
                    {!needcheckout&&buyInfo.usePayPal&&charge_amount&&!cartTooSmall?<PayPalButton 
                        style={{label:"pay"}}
                        createOrder={this.paypalCreateOrder.bind(this, charge_amount)} 
                        onApprove={this.paypalApproveOrder.bind(this)} 
                        onCancel={function(data, actions) {console.log("cancel", data,actions)}}
                        onError={function(data, actions) {console.log("error", data,actions)}}
                        onShippingChange={this.paypalShippingChange.bind(this)}
                    />:null}
                    {!needcheckout&&(!buyInfo.usePayPal||!charge_amount || cartTooSmall)?<Button disabled={cartTooSmall} onClick={this.handleClose.bind(this, true, true)} color="primary">
                        Purchase
                    </Button>:null}
                    <Button onClick={this.shopMore.bind(this)} color="primary">
                        {this.props.directPurchase?"Cancel":"Shop More"}
                    </Button>
                </DialogActions>
                <GetPackageDependencies open={this.state.showGetDependencies} onClose={this.closeGetDependencies.bind(this)} missing={this.state.missing} packageMap={packageMap}/>
                {this.state.loading?<Dialog open>
                    <DialogContent>
                        Processing...
                    </DialogContent>
                </Dialog>:null}
                <TextBasicEdit filter={/\W/} show={this.state.showCouponCode} onChange={this.closeCouponCode.bind(this)} label="Coupon Code"/>
            </Dialog>
        </PayPalScriptProvider>;
    }

    addCoupon() {
        this.setState({showCouponCode:true});
    }

    paypalCreateOrder(charge_amount, data, actions) {
        // Set up the transaction
        const purchaseUnit = {
            amount: {
                currency_code: 'USD',
                value: (charge_amount).toFixed(2)
            }
        };

        const ret = actions.order.create({
            purchase_units: [purchaseUnit]
        });
        return ret;
    }

    paypalShippingChange(data, actions) {
        const buyInfo = Object.assign({}, this.state.buyInfo);
        const sa = data.shipping_address;
        if (sa) {
            buyInfo.address = {country:sa.country_code, state:sa.state, zip:sa.postal_code};
        }

        return this.updateBuyAndPatch(buyInfo, actions);
    }

    async updateBuyAndPatch(buyInfo, actions) {
        const responseText = await httpAuthRequestWithRetry("POST", "/search?cmd=buy", JSON.stringify(buyInfo));
        buyInfo =  JSON.parse(responseText);
        const charge_amount = buyInfo.charge_amount;

        this.setState({buyInfo});
        await actions.order.patch([
            {
                op: 'replace',
                path: "/purchase_units/@reference_id=='default'/amount",
                value: {
                    currency_code: 'USD',
                    value: charge_amount.toFixed(2)
                }
            }
        ]);
    }

    paypalApproveOrder(data, actions) {
        const buyInfo = Object.assign({}, this.state.buyInfo);
        buyInfo.orderId = data.orderID;
        buyInfo.confirmed = true;
        this.props.onClose(buyInfo);
        return new Promise(function (resolve,reject) {resolve()});
    }

    closeCouponCode(val) {
        this.setState({showCouponCode:false})
        if (val) {
            const buyInfo = Object.assign({},this.state.buyInfo);
            const coupons = (buyInfo.coupons||[]).concat([]);
            if (!coupons.includes(val)) {
                coupons.push(val);
            }
            buyInfo.coupons = coupons;
            this.props.onClose(buyInfo);
        }
    }

    closeGetDependencies(products) {
        const buyInfo = Object.assign({},this.state.buyInfo);
        buyInfo.products = buyInfo.products.concat([]);
        for (let i in products) {
            const p=products[i];
            addToProducts(buyInfo.products, p.id, p.yearly, p.entry_type, p.entry_id, p.entry_name);
        }
        this.setState({showGetDependencies:false});
        this.props.onClose(buyInfo);
    }

    showGetDependencies(missing) {
        this.setState({showGetDependencies:true, missing});
    }

    setGift(gift) {
        const buyInfo = Object.assign({},this.state.buyInfo);
        buyInfo.gift = !!gift;
        buyInfo.confirmed = false;
        this.props.onClose(buyInfo);
    }

    removeProduct(i) {
        const buyInfo = Object.assign({},this.state.buyInfo);
        const products = buyInfo.products.concat([]);
        buyInfo.products = products;
        products.splice(i,1);
        if (products.length) {
            this.props.onClose(buyInfo);
        } else {
            this.handleClose(false, false);
        }
    }

    shopMore() {
        if (!this.props.directPurchase) {
            const buyInfo = this.state.buyInfo;
            const products = buyInfo.products;
            const newCart = Object.assign({}, marketplace.cart);
            newCart.products = products.concat([]);
            newCart.gift = buyInfo.gift||false;
            newCart.coupons = buyInfo.coupons||null;
            marketplace.setCart(newCart);
        }
        this.props.onClose(null);
    }

    setSub(i, yearly) {
        const buyInfo = Object.assign({}, this.state.buyInfo);
        buyInfo.products=buyInfo.products.concat([]);
        buyInfo.products[i] = Object.assign({}, buyInfo.products[i]);
        buyInfo.products[i].yearly=yearly;
        buyInfo.confirmed = false;
        this.props.onClose(buyInfo);
    }

    async deleteCard() {
        const t=this;
        this.setState({loading:true});
        try {
            await httpAuthRequestWithRetry("POST", "/search?cmd=deletecard", null);
            t.handleClose(true, false);
        } catch (err) {
            displayMessage("Error changing card: "+err.message);
            console.log("error", err)
        }
        this.setState({loading:false});
    }
}

function PayPalButton(props) {
    const [{ isPending }] = usePayPalScriptReducer();
    if (isPending) {
        return <span>Loading...</span>;
    }

    return <PayPalButtons 
        style={props.style}
        createOrder={props.createOrder} 
        onApprove={props.onApprove} 
        onCancel={props.onCancel}
        onError={props.onError}
        onShippingChange={props.onShippingChange}
        fundingSource={paypal.FUNDING.PAYPAL}
    />
}

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

function addToProducts(products, product_id, yearly, entry_type, entry_id, entry_name, dollarValue, tipUser) {
    if (products.length) {
        if (!dollarProducts.includes(product_id) && isInProducts(products, product_id, entry_type, entry_id)){
            // already included
            return;
        }
        for (let i=products.length-1; i>0; i--) {
            const p = products[i];

            if (p.id == product_id) {
                if (!entry_type) {
                    products.splice(i, 1)
                } else if (p.entry_type == entry_type) {
                    if (!entry_id) {
                        products.splice(i, 1)
                    }
                }
            }
        }
    }
    products.push({id:product_id, yearly:yearly||false, entry_type:entry_type||null, entry_id:entry_id||null, entry_name:entry_name||null, dollarValue:dollarValue||0, tipUser:tipUser||null});
}

function isInProducts(products, product_id, entry_type, entry_id) {
    for (let i in products) {
        const p = products[i];

        if (p.id == product_id) {
            if (!p.entry_type) {
                return true;
            }
            if (p.entry_type == entry_type) {
                if (!p.entry_id || (p.entry_id==entry_id)) {
                    return true;
                }
            }
        }
    }
    return false;
}

function addToCart(product_id, yearly, entry_type, entry_id, entry_name) {
    const newCart = Object.assign({}, marketplace.cart);
    newCart.products = (newCart.products||[]).concat([]);
    addToProducts(newCart.products, product_id, yearly, entry_type, entry_id, entry_name);
    marketplace.setCart(newCart);
}

function isInCart(product_id, entry_type, entry_id) {
    return isInProducts(marketplace.cart.products||[], product_id, entry_type, entry_id)
}


class GetPackageDependencies extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.findProducts();
        }
    }

    findProducts() {
        const products = marketplace.products;
        const missing = this.props.missing;
        const pkgid = Object.keys(missing)[0];
        let partialProducts;
        let matchedProducts = [];

        for (let id in products) {
            const product=products[id];
            if ((product.publishState == "public")&& (product.purchaseType!="subscription")) {
                const included = product.includedPackages;
                if (included && included.includes(pkgid)) {
                    const partialPrice = product.partialPrice;
                    if (!partialProducts && partialPrice) {
                        //check to buy parts
                        let price=0;
                        let failed;
                        let pmatch=[];
                        for (let entry_type in missing[pkgid]) {
                            const pp = partialPrice[entry_type]
                            if (pp && !failed && (pp.individual || pp.group)) {
                                const ilist=[];
                                if (pp.individual) {
                                    const ml = missing[pkgid][entry_type];
                                    for (let entry_id in ml) {
                                        addToProducts(ilist, id, false, entry_type, entry_id, ml[entry_id]);
                                    }
                                }
                                if (pp.group && (!pp.individual || ((ilist.length*pp.individual)>=pp.group))) {
                                    addToProducts(pmatch, id, false, entry_type);
                                    price +=pp.group;
                                } else {
                                    price += ilist.length*pp.individual;
                                    pmatch=pmatch.concat(ilist);
                                }
                            } else {
                                failed=true;
                            }
                        }
                        if (!failed) {
                            if (price < product.salePrice) {
                                partialProducts = {products:pmatch, price};
                            }
                        }
                    }
                    matchedProducts.push(product);
                }
            }
        }
        this.setState({partialProducts, matchedProducts})
    }

    onClose() {
        this.props.onClose();
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.onClose.bind(this)}>Get Product Dependencies</DialogTitle>
            <DialogContent>
                {this.getPartialPurchase()}
                {this.getProductPurchases()}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Loading Purchase History...
                </DialogContent>
            </Dialog>:null}
            <GiftKey open={this.state.showGiftKey} giftkey={this.state.giftKey} onClose={this.setState.bind(this, {showGiftKey:false}, null, null)}/>
        </Dialog>;
    }

    getPartialPurchase() {
        const partialProducts = this.state.partialProducts;
        if (!partialProducts) {
            return null;
        }

        const list = [];
        const {products, price} = partialProducts;
        const product = marketplace.products[products[0].id];

        for (let i in products) {
           const p = products[i];
           list.push(<tr key={i}>
               <td>{p.entry_type}</td>
               <td className="w-100">{p.entry_name||"All"}</td>
           </tr>);
        }

        return <div>
            <div className="tc pa2 f2 titletext titlecolor">Individual Components</div>
            <div className="mv1 ba bordercolor br2 pa1">
                <div className="f2 mb1">
                    <Button className="mr1" onClick={this.pickPartial.bind(this)} variant="contained" color="primary">
                        Add to cart
                    </Button>
                    <span className="pr1 red">${price.toFixed(2)}</span>
                    {product.displayName}
                </div>
                <table>
                    <tbody>
                        <tr className="b"><td>Type</td><td>Name</td></tr>
                        {list}
                    </tbody>
                </table>
            </div>
        </div>
    }

    pickPartial() {
        this.props.onClose(this.state.partialProducts.products);
    }

    getProductPurchases() {
        const matchedProducts = this.state.matchedProducts;
        if (!matchedProducts) {
            return null;
        }

        const list = [];

        for (let i in matchedProducts) {
           const p = matchedProducts[i];
           list.push(<tr key={i}>
                <td>
                    <Button className="nowrap" onClick={this.pickProduct.bind(this, p.name)} variant="contained" color="primary">
                        Add to cart
                    </Button>
                </td>
                <td><ProductPrice price={p.listPrice} sale={p.salePrice}/></td>
                <td>{p.displayName}</td>
           </tr>);
        }

        return <div>
            {list.length>1?
                <div className="tc pa2 f2 titletext titlecolor">{this.state.partialProducts?"OR ":null}Pick One of These Products</div>
            :this.state.partialProducts?<div className="tc pa2 f2 titletext titlecolor">OR Pick Product</div>:null
            }
            <div className="mv1 ba bordercolor br2 pa1 f2">
                <table>
                    <tbody>
                        {list}
                    </tbody>
                </table>
            </div>
        </div>
    }

    pickProduct(id) {
        this.props.onClose([{id}]);
    }
}

class PurchaseHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.readPurchaseHistory();
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const historyList = this.state.historyList||[];
        const list=[];
        const userId = campaign.userId;

        for (let i in historyList) {
            const c = historyList[i];
            const k = c.charge_id || i;
            let psum = 0;
            list.push(<tr key={k} className="bg-light-gray">
                <td className="nowrap pa1">
                    {c.createDate.toLocaleDateString(undefined,dateOptions)}
                    {c.charge_id?<span className="f5">&nbsp;(order: {c.charge_id})</span>:null}
                </td>
                <td className="tr nowrap ma1">
                    {c.gift_key?(c.gift_user_id?"Gift Claimed":<a onClick={this.setState.bind(this,{showGiftKey:true,giftKey:c.gift_key}, null, null)}>Unclaimed Gift</a>):null}
                </td>
            </tr>);
            for (let x in c.purchases) {
                const p = c.purchases[x];
                const subscription = p.subscription;
                
                if (!(c.gift_key&&(c.gift_user_id==userId))) {
                    psum+= (p.amount||0);
                }

                list.push(<tr key={p.purchase_id}>
                    <td className="w-100 pa1">
                        <a className={p.active?null:"strike"} href={"/marketplace#product?id="+p.product_id}>{p.display_name}</a>
                        {(subscription && (!c.gift_key || (c.gift_user_id==userId)))?<span className="ml2 f5">(subscription {(new Date(p.start_date)).toLocaleDateString(undefined, dateOptions)}-{(new Date(p.end_date)).toLocaleDateString(undefined, dateOptions)})</span>:null}
                    </td>
                    <td className="tr nowrap pa1 v-top numbers">
                        {p.key_id?"Unlock Key":(p.amount?("$"+(p.amount||0).toFixed(2)):"FREE")}
                    </td>
                </tr>);
            }
            const extraCredits = psum+(c.sales_tax||0)-(c.total_amount||0);
            if ((c.total_amount||(extraCredits>=0.01)) && (c.gift_user_id!=userId)) {
                if (c.sales_tax) {
                    list.push(<tr key={k+".st"}>
                        <td className="w-100 ph1 tr">
                            Sales Tax
                        </td>
                        <td className="tr nowrap ph1 numbers">
                            ${c.sales_tax.toFixed(2)}
                        </td>
                    </tr>);
                }

                if (extraCredits>=0.01) {
                    list.push(<tr key={k+".cr"}>
                        <td className="w-100 ph1 tr">
                            Upgrade Credits
                        </td>
                        <td className="tr nowrap ph1 numbers">
                            $-{extraCredits.toFixed(2)}
                        </td>
                    </tr>);
                }
    
                if (c.total_amount != c.charge_amount){
                    if (c.credit_usage) {
                        list.push(<tr key={k+".cr"}>
                            <td className="w-100 ph1 tr">
                                Credits
                            </td>
                            <td className="tr nowrap ph1 numbers">
                                ${c.credit_usage.toFixed(2)}
                            </td>
                        </tr>);
                    }
                    if (c.charge_amount) {
                        list.push(<tr key={k+".ch"}>
                            <td className="w-100 ph1 tr">
                                Charge Card
                            </td>
                            <td className="tr nowrap ph1 numbers">
                                ${c.charge_amount.toFixed(2)}
                            </td>
                        </tr>);
                    }
                }

                list.push(<tr key={k+".t"}>
                    <td className="w-100 ph1 tr">
                        Total
                    </td>
                    <td className="tr nowrap ph1 numbers">
                        ${c.total_amount.toFixed(2)}
                    </td>
                </tr>);
            }
            list.push(<tr key={k+".s"}>
                <td className="w-100 pa1 tr minh2">
                </td>
                <td className="tr nowrap pa1">
                </td>
            </tr>);
        }

        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Purchase History</DialogTitle>
            <DialogContent>
                {this.state.credits?<div className="f2 titletext titlecolor mb2">
                    <table>
                        <tbody>
                            <tr><td>Current Credits</td><td className="tr numbers">${this.state.credits.toFixed(2)}</td></tr>
                        </tbody>
                    </table>
                </div>:null}
                {list.length?<table className="w-100 f3">
                    <tbody>
                        {list}
                    </tbody>
                </table>:<div>No Purchases</div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Loading Purchase History...
                </DialogContent>
            </Dialog>:null}
            <GiftKey open={this.state.showGiftKey} giftkey={this.state.giftKey} onClose={this.setState.bind(this, {showGiftKey:false}, null, null)}/>
        </Dialog>;
    }

    readPurchaseHistory() {
        const t=this;
        this.setState({loading:true});
        marketplace.loadOwnedProducts().then(function(results) {
            const {purchases, charges, credits} = results;
            const history ={};
            const historyList = [];
            for (let i in charges) {
                const c = Object.assign({},charges[i]);
                c.createDate = new Date(c.create_date);
                history[c.charge_id]=c;
            }

            for (let i in purchases) {
                const p = purchases[i];
                const c = history[p.charge_id];
                if (c) {
                    if (!c.purchases) {
                        c.purchases = [];
                    }
                    c.purchases.push(p);
                } else {
                    history[p.purchase_id]={createDate:new Date(p.create_date), purchases:[p]};
                }
            }
            for (let i in history) {
                historyList.push(history[i]);
            }
            historyList.sort(function(a,b) {
                return b.createDate.getTime()-a.createDate.getTime();
            })

            t.setState({loading:false, historyList, credits})
        }, function (err) {
            console.log("error loading purchase history", err);
            displayMessage("Could not read purchase history", t.props.onClose);
        });
    }
}

class GiftKey extends React.Component {
	render() {
        if (!this.props.open) {
            return null;
        }

        const key = "https://play.shardtabletop.com/#home?giftkey="+(this.props.giftkey||"")

        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Gift Share Key</DialogTitle>
            <DialogContent>
                <div>
                    Gift Key: 
                    <ClipboardCopy text={key}>{key}</ClipboardCopy>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }
}


class Subscriptions extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    componentDidMount() {
        globalDataListener.onChangeProducts(this.handleOnDataChange);
    }

    componentWillUnmount() {
        globalDataListener.removeProductsListener(this.handleOnDataChange);
    }

    onDataChange() {
        if (this.props.open) {
            this.readSubscriptionHistory();
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.readSubscriptionHistory();
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const {ProductDialog} = require('./products.jsx')
        const subscriptions = this.state.subscriptions||{};
        const list=[];

        for (let i in subscriptions) {
            const s = subscriptions[i];

            list.push(<tr key={i} className="bg-light-gray">
                <td className="nowrap pa1 w-100 v-top">
                    <a className="b" onClick={this.showSubscription.bind(this, s.product_id)}>{s.display_name}</a>
                    {!s.active?<Button className="ml2 minw2" color="secondary" variant="outlined" size="small" onClick={this.showSubscription.bind(this, s.product_id)}>Renew</Button>:null}
                    {s.active?<div className="mt1">Paid through {s.end_date.toLocaleDateString(undefined, dateOptions)}</div>:null}
                </td>
                <td className="tr nowrap pa1 v-top tc">
                    {s.active?<div className="green">Active</div>:<div className="red">Discontinued</div>}
                    {s.active&&s.renewable?<div className="mt2">
                        renews
                        <div>{s.yearly?"yearly":"monthly"}</div>
                    </div>:null}
                </td>
            </tr>);
            for (let x in s.purchases) {
                const p = s.purchases[x];
                list.push(<tr key={i+"."+x}>
                    <td className="w-100 pv1 ph3">
                        {(new Date(p.start_date)).toLocaleDateString(undefined, dateOptions)}-{(new Date(p.end_date)).toLocaleDateString(undefined, dateOptions)}
                    </td>
                    <td className="tr nowrap pa1 v-top numbers">
                        ${(p.amount||0).toFixed(2)}
                    </td>
                </tr>);
            }
            list.push(<tr key={i+".s"}>
                <td className="w-100 pa1 tr minh2">
                </td>
                <td className="tr nowrap pa1">
                </td>
            </tr>);
        }

        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Subscriptions</DialogTitle>
            <DialogContent>
                {list.length?<table className="w-100 f3">
                    <tbody>
                        {list}
                    </tbody>
                </table>:<div>
                    No subscriptions
                </div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Loading Subscriptions...
                </DialogContent>
            </Dialog>:null}
            <ProductDialog open={this.state.showSubscription} productId={this.state.showSubscriptionId} onClose={this.hideShowSubscription.bind(this)} purchasable useCampaign noGift/>
        </Dialog>;
    }

    hideShowSubscription() {
        this.setState({showSubscription:false})
    }

    showSubscription(showSubscriptionId) {
        this.setState({showSubscriptionId, showSubscription:true});
    }

    async readSubscriptionHistory() {
        const t=this;
        try {
            this.setState({loading:true});

            const results = await marketplace.loadOwnedProducts();

            const purchases = results.purchases;
            const userId = campaign.userId;
            const subscriptions ={};
            const subscriptionsList = [];

            for (let i in purchases) {
                const p = purchases[i];
                if (p.subscription && (p.user_id==userId)) {
                    let s = subscriptions[p.product_id];
                    if (!s) {
                        s = Object.assign({}, p);
                        s.purchases=[];
                        subscriptions[p.product_id] = s;
                        s.end_date = new Date(s.end_date);
                        s.yearly = p.renews_yearly;
                    }
                    s.purchases.push(p);
                    if (p.active) {
                        s.active=true;

                        if (s.end_date.getTime() < (new Date(p.end_date)).getTime()) {
                            s.renewable=p.renewable;
                            s.yearly = p.renews_yearly;
                            s.end_date = new Date(p.end_date);
                        }
                    }
                }
            }

            for (let i in subscriptions) {
                const s=subscriptions[i];
                s.purchases.sort(function(a,b) {
                    return (new Date(a.create_date)).getTime()-(new Date(b.create_date)).getTime();
                });
                subscriptionsList.push(s);
            }
            subscriptionsList.sort(function (a,b) {
                const aa=a.active?1:0;
                const ba=b.active?1:0;
                if (ba != aa) {
                    return ba-aa;
                }
                return (new Date(a.end_date)).getTime()-(new Date(b.end_date)).getTime();
            })

            t.setState({loading:false, subscriptions:subscriptionsList})
        } catch (err) {
            console.log("error loading subscriptions", err);
            displayMessage("Could not read subscriptions", t.props.onClose);
        };
    }
}

class PurchaseSubscriptions extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:true,loadingMore:false};
    }

    componentDidMount() {
        this.readSubscriptionHistory();
    }

    componentWillUnmount() {
    }

	render() {
        const {ProductDialog} = require('./products.jsx')
        const {loading, subscriptions,foundPurchases,paymentMethod,getChargeInformation,test, client_secret} = this.state;
        const list=[];
        let stripePromise;

        if (getChargeInformation) {
            if (test) {
                stripePromise=stripeTestPromise;
            } else {
                stripePromise=stripeProductionPromise;
            }
        }

        if (!loading) {
            let hasShardSubscription;
            for (let i in subscriptions) {
                const s = subscriptions[i];
                const hasHigher = campaign.hasHigherShardSubscription(s.product_id);

                if (s.active && campaign.isShardSubscription(s.product_id)) {
                    hasShardSubscription=true;
                }

                if (s.active) {
                    list.push(<div className="mv1" key={i}>
                        <a className="b" onClick={this.showSubscription.bind(this, s.product_id)}>{s.display_name}</a> Paid
                        {s.start_date?" from "+s.start_date.toLocaleDateString(undefined, dateOptions):null} through {s.end_date.toLocaleDateString(undefined, dateOptions)}
                        {s.renewable?(", renews "+(s.yearly?"yearly":"monthly")):hasHigher?null:<span>, <span className="red">Will Not Renew</span></span>}
                        {(s.renewable)?<Button className="ml2" onClick={this.setRenewal.bind(this, s.product_id, false, true)} size="small" variant="outlined">Cancel Renewal</Button>:
                            <Button className="ml2" onClick={this.setRenewal.bind(this, s.product_id, true, true)} size="small" variant="outlined">Enable Renewal</Button>
                        }
                    </div>);
                } else {
                    list.push(<div className="mv1" key={i}>
                        <a className="b" onClick={this.showSubscription.bind(this, s.product_id)}>{s.display_name}</a> <span className={hasHigher?"":"red"}>Discontinued</span>
                        {hasHigher?null:<Button href={"/marketplace#product?id="+s.product_id+"&add="+s.product_id} className="ml2" size="small" variant="outlined">Restart Subscription</Button>}
                    </div>);
                }
            }
            if (!hasShardSubscription) {
                list.push(<div className="mv1" key="shard">
                    Take your game to the next level with a <a href="/marketplace/#shardsubscriptions">Shard Tabletop subscription</a>.  
                </div>);
            } else {
                list.push(<div className="mv1" key="shard">
                    Find other subscription options in the <a href="/marketplace#?p=subscription">Shard Tabletop marketplace</a>.  
                </div>);
            }
        } else {
            list.push(<div className="mv1" key="shard">
                Loading...  
            </div>);
        }

        return <div>
            {!loading?<div>
                <h2>Payment Method</h2>
                {paymentMethod?<p>
                    You have a credit card registered{(paymentMethod.card&&paymentMethod.card.last4)?(" ending in "+paymentMethod.card.last4):null}.
                    <Button onClick={this.deleteCard.bind(this)} size="small" variant="outlined" className="ml2">
                        Delete Card
                    </Button>
                    <Button onClick={this.assignCard.bind(this)} size="small" variant="outlined" className="ml2">
                        Change Card
                    </Button>
                </p>:<p>
                    Register a credit card to automatically renew subscriptions and quickly checkout making purchases.
                    <Button onClick={this.assignCard.bind(this)} size="small" variant="outlined" className="ml2">
                        Register Card
                    </Button>
                </p>}
            </div>:null}
            <h2>Subscriptions</h2>
            {list}
            {foundPurchases?<div>
                <h2>Purchases</h2>
                <p>
                    <a onClick={this.showPurchaseHistory.bind(this,true)}>Purchase History</a>: View your past purchases.
                </p>
            </div>:null}

            <ProductDialog open={this.state.showSubscription} productId={this.state.showSubscriptionId} onClose={this.hideShowSubscription.bind(this)} purchasable useCampaign noGift/>
            <PurchaseHistory open={this.state.showPurchaseHistory} onClose={this.showPurchaseHistory.bind(this,false)}/>
            {getChargeInformation?<Elements stripe={stripePromise}><ElementChargeDialog open noPay onClose={this.getChargeInformation.bind(this,false)} client_secret={client_secret}/></Elements>:null}
            <Dialog open={this.state.loadingMore}>
                <DialogContent>
                    Processing...
                </DialogContent>
            </Dialog>
        </div>;
    }

    async assignCard() {
        initStripe();
        this.setState({loadingMore:true});
        try {
            const res = await httpAuthRequestWithRetry("POST", "/search?cmd=resetcard", "");
            const results = JSON.parse(res);
            this.setState({getChargeInformation:true, test:results.test||false, client_secret:results.client_secret});
        } catch (error) {
            displayMessage("Error updating card:"+error.message);
        }
        this.setState({loadingMore:false});
    }

    async getChargeInformation(getChargeInformation) {
        this.setState({getChargeInformation});
        if (!getChargeInformation) {
            this.setState({loadingMore:true});
            try {
                await this.readSubscriptionHistory();
            } catch(error) {
                console.log("error updating products/subscriptions");
            }
            this.setState({loadingMore:false});
        }
    }

    async setRenewal(product_id, renewable, renews_yearly) {
        this.setState({loadingMore:true});
        try {
            await httpAuthRequestWithRetry("POST", "/search?cmd=setsubscription", JSON.stringify({
                renewable,
                renews_yearly,
                product_id
            }));
            await this.readSubscriptionHistory();
        } catch (error) {
            displayMessage("Error updating subscription: "+error.message);
        }

        this.setState({loadingMore:false});
    }

    async deleteCard() {
        this.setState({loadingMore:true});
        try {
            await httpAuthRequestWithRetry("POST", "/search?cmd=deletecard", null);
            await this.readSubscriptionHistory();
        } catch (err) {
            displayMessage("Error deleting card: "+err.message);
            console.log("error", err)
        }
        this.setState({loadingMore:false});
    }


    hideShowSubscription() {
        this.setState({showSubscription:false})
    }

    showSubscription(showSubscriptionId) {
        this.setState({showSubscriptionId, showSubscription:true});
    }

    showPurchaseHistory(showPurchaseHistory) {
        this.setState({showPurchaseHistory});
    }

    async readSubscriptionHistory() {
        const t=this;
        try {
            this.setState({loading:true});

            const results = await marketplace.loadOwnedProducts();
            if (!results) {
                return;
            }
            const {purchases,paymentMethod} = results;
            const userId = campaign.userId;
            const subscriptions ={};
            const subscriptionsList = [];
            let foundPurchases=false;

            for (let i in purchases) {
                const p = purchases[i];
                foundPurchases=true;
                if (p.subscription && (p.user_id==userId)) {
                    let s = subscriptions[p.product_id];
                    if (!s) {
                        s = Object.assign({}, p);
                        s.purchases=[];
                        subscriptions[p.product_id] = s;
                        const start = new Date(s.start_date);
                        if (start.getTime()>Date.now()) {
                            s.start_date = start;
                        } else {
                            delete s.start_date;
                        }
                        s.end_date = new Date(s.end_date);
                        s.yearly = p.renews_yearly;
                    }
                    s.purchases.push(p);
                    if (p.active) {
                        s.active=true;

                        if (s.end_date.getTime() < (new Date(p.end_date)).getTime()) {
                            s.renewable=p.renewable;
                            s.yearly = p.renews_yearly;
                            s.end_date = new Date(p.end_date);
                        }
                        const start = new Date(s.start_date);
                        if (start.getTime()>Date.now() && (!s.start_date || (start.getTime() < s.start_date.getTime()))) {
                            s.start_date = start;
                        }

                    }
                }
            }

            for (let i in subscriptions) {
                const s=subscriptions[i];
                s.purchases.sort(function(a,b) {
                    return (new Date(a.create_date)).getTime()-(new Date(b.create_date)).getTime();
                });
                subscriptionsList.push(s);
            }
            subscriptionsList.sort(function (a,b) {
                const aa=a.active?1:0;
                const ba=b.active?1:0;
                if (ba != aa) {
                    return ba-aa;
                }
                return (new Date(a.end_date)).getTime()-(new Date(b.end_date)).getTime();
            })

            //console.log("results",results, subscriptionsList);
            t.setState({loading:false, subscriptions:subscriptionsList,foundPurchases, paymentMethod:(paymentMethod&&paymentMethod.card)?paymentMethod:null})
        } catch (err) {
            console.log("error loading subscriptions", err);
        };
    }
}

class SalesResults extends React.Component {
    constructor(props) {
        super(props);

        const now =getDateStrFromDate(new Date());
        this.state= {loading:false, start:now,end:now};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.readSalesHistory();
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const list=[];
        const payouts = this.state.payouts||[];
        const sales = this.state.sales||[];
        const startTime =(new Date(this.state.start)).getTime();
        const endTime = (new Date(this.state.end)).getTime()+(24*60*60*1000); // add one day so that it is at end of day.
        const allSales=[];
        let payoutAmount=0;
        let payoutCount=0;

        const report ={};
        const subs = {};
        const sreport=[];

        for (let i in sales) {
            const s = sales[i];
            const createTime = (new Date(s.create_date)).getTime();

            if ((createTime >= startTime) && (createTime < endTime)) {
                if (!report[s.product_id]) {
                    report[s.product_id]= Object.assign({},s);
                } 
                const r = report[s.product_id];
                r.count = (r.count||0)+1;
                r.totalSales = (r.totalSales||0)+(s.amount||0);
                r.totalCredit = (r.totalCredit||0)+(s.credit_amount||0);
                if (s.user && s.active && s.subscription && !subs[s.user+s.product_id]) {
                    if (dateRange(s.start_date, s.end_date) > 32) {
                        r.annually = (r.annually||0)+1;
                    } else {
                        r.monthly = (r.monthly||0)+1;
                    }
                    subs[s.user+s.product_id]=true;
                }
                allSales.push(s);
            }
        }
        for (let i in report) {
            sreport.push(report[i]);
        }
        sreport.sort(function (a,b){return (b.count||0)-(a.count||0)});

        let totalSales=0;
        let totalCredit=0;
        let totalCount = 0;
        for (let i in sreport) {
            const s = sreport[i];

            totalCount+=(s.count||0);
            totalCredit+=(s.totalCredit||0);
            totalSales +=(s.totalSales||0);
            list.push(<tr key={i}>
                <td className="nowrap pa1 w-100 v-top">
                    <a href={"/marketplace#product?id="+s.product_id}>{s.display_name}</a>
                </td>
                <td className="tr nowrap pa1 v-top">
                    {s.count||0}
                </td>
                <td className="tr nowrap pa1 v-top numbers">
                    ${(s.totalSales||0).toFixed(2)}
                </td>
                <td className="tr nowrap pa1 v-top numbers">
                    ${(s.totalCredit||0).toFixed(2)}
                </td>
            </tr>);
            if (s.monthly) {
                list.push(<tr key={"m"+i}>
                    <td className="nowrap pa1 w-100 v-top tr">
                        Monthly
                    </td>
                    <td className="tr nowrap pa1 v-top">
                        {s.monthly}
                    </td>
                </tr>);
    
            }
            if (s.annually) {
                list.push(<tr key={"a"+i}>
                    <td className="nowrap pa1 w-100 v-top tr">
                        Annual
                    </td>
                    <td className="tr nowrap pa1 v-top">
                        {s.annually}
                    </td>
                </tr>);
    
            }
        }
        list.push(<tr key="total">
            <td className="nowrap pa1 w-100 v-top b">
                Totals 
            </td>
            <td className="tr nowrap pa1 v-top b titleborder bt">
                {totalCount}
            </td>
            <td className="tr nowrap pa1 v-top numbers b titleborder bt">
                ${totalSales.toFixed(2)}
            </td>
            <td className="tr nowrap pa1 v-top numbers b titleborder bt">
                ${totalCredit.toFixed(2)}
            </td>
        </tr>);

        for (let i in payouts) {
            const createTime = (new Date(payouts[i].create_date)).getTime();
            if ((createTime >= startTime) && (createTime < endTime)) {
                payoutCount++;
                payoutAmount += payouts[i].payout_amount;
            }
        }

        return <Dialog
            maxWidth="md"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Sales Report</DialogTitle>
            <DialogContent>
                {this.state.credits?<div className="f2 titletext titlecolor mb2">
                    <table>
                        <tbody>
                            <tr><td>Current Credits</td><td className="tr numbers">${this.state.credits.toFixed(2)}</td></tr>
                            <tr><td>Distributable Credits</td><td className="tr numbers">${this.state.claimable.toFixed(2)}</td></tr>
                        </tbody>
                    </table>
                </div>:null}
                <div className="mv2">
                    <DateVal date={this.state.start} onChange={this.onChangeDate.bind(this, "start")} helperText="start"/>
                    <DateVal className="ml2" date={this.state.end} onChange={this.onChangeDate.bind(this, "end")} helperText="end"/>
                </div>
                <table className="w-100 f3">
                    <tbody>
                        <tr>
                            <td className="b tc">Product</td>
                            <td className="b tc">Units</td>
                            <td className="b tc">Net</td>
                            <td className="b tc">Royalty</td>
                        </tr>
                        {list}
                    </tbody>
                </table>
                {payoutCount?<div className="f3 titletext titlecolor mb2">
                    <table>
                        <tbody>
                            <tr><td>{payoutCount} Payouts for </td><td className="tr numbers">${payoutAmount.toFixed(2)}</td></tr>
                        </tbody>
                    </table>
                </div>:null}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.showTable.bind(this,true)} color="primary">
                    Details
                </Button>
                {this.state.claimable?<Button onClick={this.setShowDistribute.bind(this,true)} color="primary">
                    Payout Credits
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Loading Sales...
                </DialogContent>
            </Dialog>:null}
            <DistributeCredits open={this.state.showDistribute} onClose={this.setShowDistribute.bind(this,false)}/>
            <DisplayTable open={this.state.showTable} title="Sales Report" onClose={this.showTable.bind(this,false)} description="allsales" columns={salesReportColumns} list={allSales}/>
        </Dialog>;
    }

    onChangeDate(t,v) {
        const set = {};
        set[t]=v;
        this.setState(set);
    }

    showTable(showTable){
        this.setState({showTable});
    }

    setShowDistribute(showDistribute) {
        if (!showDistribute) {
            this.readSalesHistory();
        }
        this.setState({showDistribute});
    }

    readSalesHistory() {
        const t=this;
        this.setState({loading:true,credits:null, claimable:null, payouts:null, sales:null});
        marketplace.getSales().then(function(results) {
            const {sales, credits, claimable, payouts} = results;
            let minDateOffset, minDate;
            let start,end;

            for (let i in sales) {
                const s = sales[i];
                const dateOffset = (new Date(s.create_date)).getTime();
                if (!minDateOffset || dateOffset<minDateOffset) {
                    minDateOffset = dateOffset;
                    minDate = s.create_date;
                }
            }

            for (let i in payouts) {
                const s = payouts[i];
                const dateOffset = (new Date(s.create_date)).getTime();
                if (!minDateOffset || dateOffset<minDateOffset) {
                    minDateOffset = dateOffset;
                    minDate = s.create_date;
                }
            }
    
            if (minDateOffset) {
                const d = new Date();
                end = getDateStrFromDate(d);
                start = getDateStrFromDate(new Date(minDate));
            }
    
            t.setState({loading:false, credits, claimable, payouts, sales,start,end})
        }, function (err) {
            console.log("error reading sales history", err);
            displayMessage("Could not read sales history", t.props.onClose);
        });
    }
}
const salesReportColumns=["create_date","subscription","amount", "credit_amount", "display_name", "entry_name", "start_date", "end_date", "active", "product_id", "user", "referral_user", "referral_code", "coupon_code"];

function dateRange(start, end) {
    const s = (new Date(start)).getTime();
    const e = (new Date(end)).getTime();
    const range = (e-s)/(1000*60*60*24);

    return range;
}

class Accounting extends React.Component {
    constructor(props) {
        super(props);

        const d = new Date();
        const end = getDateStrFromDate(d);
        d.setDate(1);
        const start = getDateStrFromDate(d);
        this.state= {loading:false, start, end};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            const d = new Date();
            const end = getDateStrFromDate(d);
            d.setDate(1);
            const start = getDateStrFromDate(d);
            this.setState({start, end});
              
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Accounting Reports</DialogTitle>
            <DialogContent>
                <div>
                    <DateVal date={this.state.start} onChange={this.onChangeDate.bind(this, "start")} label="start"/>
                    <DateVal className="ml2" date={this.state.end} onChange={this.onChangeDate.bind(this, "end")} label="end"/>
                </div>
                <div className="mv2">
                    <Button onClick={this.getReport.bind(this,"sales")} color="primary" size="small" variant="outlined">
                        Get Sales Report
                    </Button>
                    &nbsp;&nbsp;
                    <Button onClick={this.getReport.bind(this,"salestax")} color="primary" size="small" variant="outlined">
                        Get Sales Tax Report
                    </Button>
                    &nbsp;&nbsp;
                    <Button onClick={this.getReport.bind(this,"salesall")} color="primary" size="small" variant="outlined">
                        Get All Sales Report
                    </Button>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Loading Data...
                </DialogContent>
            </Dialog>:null}
            <DisplayTable open={this.state.showTable} title="Accounting Report" onClose={this.hideTable.bind(this)} description={this.state.description} columns={this.state.showColumns} list={this.state.showList}/>
        </Dialog>;
    }

    onChangeDate(t,v) {
        const set = {};
        set[t]=v;
        this.setState(set);
    }

    hideTable() {
        this.setState({showTable:false});
    }

    async getReport(type) {
        const {start,end} = this.state;
        this.setState({loading:true});
        try {
            let cmd,desc,cols;
            switch (type) {
                case "sales":
                    desc="sales ";
                    cmd = "/search?cmd=accountingsales";
                    cols=accountSalesCols;
                    break;
                case "salestax":
                    desc="sales tax ";
                    cmd = "/search?cmd=accountingsalestax";
                    cols=accountSalesTaxCols;
                    break;
                case "salesall":
                    desc="all sales ";
                    cmd = "/search?cmd=accountingallsales";
                    cols=accountAllSalesCols;
                    break;
             }
            const responseText = await httpAuthRequestWithRetry("POST", cmd, JSON.stringify({start,end}));
            const res = JSON.parse(responseText);

            desc = desc+start+" to "+end;
            this.setState({showTable:true, showColumns:cols, showList:res, description:desc});
        } catch (err) {
            console.log("error loading", err.message);
        }
        this.setState({loading:false});
    }
}

const accountAllSalesCols = [
    "date",
    "user_id",
    "email",
    "product_id",
    "display_name",
    "subscription_months",
    "publisher_id",
    "publisher_email",
    "amount",
    "referral_user_id",
    "referral_code",
    "coupon_code",
    "key_id"
];


const accountSalesCols = [
    "date",
    "sales_total_amount",
    "sales_amount",
    "sales_tax",
    "sales_charge_amount",
    "sales_paypal_amount",
    "sales_credit_usage",
    "sales_marketplace_fee",
    "shard_credits",
    "other_credits",
    "sales_processing_fee",
    "payout_amount",
    "payout_fee",
    "payout_total_amount",
    "key_marketplace_fee",
    "adventurer",
    "gamemaster",
    "gamemaster_pro"
];

const accountSalesTaxCols = [
    "order_id",
    "date",
    "gross_sales",
    "taxable_sales",
    "tax",
    "description",
    "shipping",
    "shipping_tax",
    "origin_line1",
    "origin_line2",
    "origin_city",
    "origin_state",
    "origin_zip",
    "shipping_line1",
    "shipping_line2",
    "shipping_city",
    "shipping_state",
    "shipping_zip"
];

class DistributeCredits extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({payout:0, email:campaign.email})
            this.readSalesHistory();
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        const {credits, claimable, tips, payout, email, verifyemail} = this.state;
        const fee = getPayoutFee(claimable, tips, payout);

        return <Dialog
            maxWidth="xs"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Payout Credits</DialogTitle>
            <DialogContent>
                {credits?<div className="f2 titletext titlecolor mb2">
                    <table>
                        <tbody>
                            <tr><td>Current Credits</td><td className="tr numbers">${credits.toFixed(2)}</td></tr>
                            <tr><td>Distributable Credits</td><td className="tr numbers">${claimable.toFixed(2)}</td></tr>
                            {tips?<tr><td className="tr i">Included Tips</td><td className="tr numbers">${tips.toFixed(2)}</td></tr>:null}
                        </tbody>
                    </table>
                </div>:null}
                <div className="tc">
                    <PriceVal label="Amount to distribute" price={this.state.payout||0} onChange={this.changePayout.bind(this)}/>
                    {payout && (payout>claimable)?<div className="red mv1">* Distribution amount greater than available</div>:null}
                    {payout && (payout<=claimable) && (payout < 5)?<div className="red mv1">* Distribution amount too low</div>:null}
                    {payout && (payout<=claimable) && (payout >= 5)?<div className="f2 mv2">Net payout: ${(payout-fee).toFixed(2)}</div>:null}
                    <div className="mv1 i">* Includes ${payoutProcessingFee.toFixed(2)} processing fee</div>
                    {tips?<div className="mv1 i">* Tips have a additional {tipPayoutPercent*100}% processing fee</div>:null}
                </div>
                <TextVal className="mb2" text={this.state.email||""} fullWidth onChange={this.changeEmail.bind(this)} helperText="PayPal Email Address"/>
                <TextVal className="mb2" text={this.state.verifyemail||""} fullWidth onChange={this.changeVerifyEmail.bind(this)} helperText="Verify PayPal Email Address"/>
                {email!=verifyemail?<div className="red">Verify email must match</div>:<div>Addresses match</div>}
            </DialogContent>
            <DialogActions>
                <Button disabled={!email || (email != verifyemail) || !payout || (payout < 5) || (payout > claimable)} onClick={this.payout.bind(this)} color="primary">
                    Payout
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Loading Sales...
                </DialogContent>
            </Dialog>:null}
        </Dialog>;
    }

    async payout() {
        const t=this;
    
        this.setState({loading:true});
        try {
            await httpAuthRequestWithRetry("POST", "/search?cmd=payout", JSON.stringify({
                amount:t.state.payout,
                email:t.state.email
            }));
            displayMessage("Payout sent", function (){t.props.onClose()});
        } catch (err) {
            displayMessage("Error making payout: "+err.message);
        }
        this.setState({loading:false});
    }

    changeEmail(email) {
        this.setState({email});
    }

    changeVerifyEmail(verifyemail) {
        this.setState({verifyemail});
    }

    changePayout(payout) {
        this.setState({payout});
    }

    readSalesHistory() {
        const t=this;
        this.setState({loading:true});
        marketplace.getSales().then(function(results) {
            const {credits, claimable,tips} = results;

            t.setState({loading:false, credits, claimable, tips, payout:claimable})
        }, function (err) {
            console.log("error loading sales history", err);
            displayMessage("Could not read credits", t.props.onClose);
        });
    }
}

// Keep in sync with the server code
const payoutProcessingFee = 1.00;
const tipPayoutPercent = 0.1;

function getPayoutFee(claimable, tips, payout) {
    const noFee = claimable - tips;
    if (payout <= noFee) {
        return payoutProcessingFee;
    }
    return payoutProcessingFee + (payout - noFee - payoutProcessingFee)*tipPayoutPercent;
}


class ProcessingCharge extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidMount() {
        this.updateFn = this.update.bind(this);
        globalDataListener.onChangeCampaignSettings(this.updateFn);
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignSettingsListener(this.updateFn);
    }

    update() {
        const purchases = campaign.ownedPackages||{};

        this.setState({purchases});
    }

    render() {
        const purchases = campaign.ownedPackages;

        if (!purchases.outstandingCharges) {
            return null;
        }
        return <span className="red pa1 fas fa-cart-plus hoverhighlight" onClick={showMarketplace}/>;
    }
}
function showMarketplace() {
    window.location.href = "/marketplace";
}

class FailedPurchases extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.loadCharges();
        }
    }

    async loadCharges() {
        this.setState({loading:true});
        try {
            const chargesText = await httpAuthRequestWithRetry("POST", "/search?cmd=getcharges", null);
            const charges = JSON.parse(chargesText);
            this.setState({charges, loading:false});
        } catch (err) {
            displayMessage("Error loading products: "+err.message);
            this.props.onClose();
            this.setState({loading:false});
            console.log("error getting charges", err);
        }
    }

	render() {
        const list=[];

        if (this.props.open) {
            const charges = this.state.charges||{};
            for (let i in charges) {
                const c = charges[i];
                const k = c.chargeId || i;
                list.push(<tr key={k} className="bg-light-gray">
                    <td className="nowrap pa1">
                        {(new Date(c.createDate)).toLocaleDateString(undefined,dateOptions)}
                        {c.chargeId?<span className="f5">&nbsp;(order: {c.chargeId})</span>:null}
                        {c.client_secret?<Button onClick={this.authorizePurchase.bind(this, i)} color="primary" size="small" variant="outlined" className="mh1">
                            Authorize
                        </Button>:<Button onClick={this.retryPurchase.bind(this, i)} color="primary" size="small" variant="outlined" className="mh1">
                            Retry
                        </Button>}
                        <Button onClick={this.cancelPurchase.bind(this, i)} color="primary" size="small" variant="outlined">
                            Cancel
                        </Button>
                    </td>
                    <td className="tr nowrap ma1">
                        {c.gift?"Gift":null}
                    </td>
                </tr>);
                for (let x in c.products) {
                    const p = c.products[x];
                    const subscription = p.subscription;
                    let yearly;

                    if (subscription) {
                        yearly = p.subscription_months > 11;
                    }
                    list.push(<tr key={k+p.id}>
                        <td className="w-100 pa1">
                            <a href={"/marketplace#product?id="+p.id}>{p.display_name}</a>
                            {subscription?<span className="ml2 f5">({yearly?"yearly":"monthly"} subscription</span>:null}
                        </td>
                        <td className="tr nowrap pa1 v-top numbers">
                            {p.price?("$"+(p.price||0).toFixed(2)):"FREE"}
                        </td>
                    </tr>);
                }
                if (c.total_amount) {
                    if (c.sales_tax) {
                        list.push(<tr key={k+".st"}>
                            <td className="w-100 ph1 tr">
                                Sales Tax
                            </td>
                            <td className="tr nowrap ph1 numbers">
                                ${c.sales_tax.toFixed(2)}
                            </td>
                        </tr>);
                    }
                    if (c.total_amount != c.charge_amount){
                        list.push(<tr key={k+".cr"}>
                            <td className="w-100 ph1 tr">
                                Credits
                            </td>
                            <td className="tr nowrap ph1 numbers">
                                ${c.credit_usage.toFixed(2)}
                            </td>
                        </tr>);
                        list.push(<tr key={k+".ch"}>
                            <td className="w-100 ph1 tr">
                                Charge Card
                            </td>
                            <td className="tr nowrap ph1 numbers">
                                ${c.charge_amount.toFixed(2)}
                            </td>
                        </tr>);
                    }
                    list.push(<tr key={k+".t"}>
                        <td className="w-100 ph1 tr">
                            Total
                        </td>
                        <td className="tr nowrap ph1 numbers">
                            ${c.total_amount.toFixed(2)}
                        </td>
                    </tr>);
                }
                list.push(<tr key={k+".s"}>
                    <td className="w-100 pa1 tr minh2">
                    </td>
                    <td className="tr nowrap pa1">
                    </td>
                </tr>);
            }
        }

        return <span>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={!!this.props.open}
            >
                <DialogTitle onClose={this.props.onClose}>Incomplete Purchases</DialogTitle>
                <DialogContent>
                    {list.length?<table className="w-100 f3">
                        <tbody>
                            {list}
                        </tbody>
                    </table>:this.state.loading?null:<div>
                        All incomplete charges have been handled
                    </div>}
                </DialogContent>
                <DialogActions>
                    {list.length?null:<Button onClick={this.props.onClose} color="primary">
                        Close
                    </Button>}
                </DialogActions>
            </Dialog>
            <BuyButton ref={this.saveBuy.bind(this)} hidden onComplete={this.onCompleteBuy.bind(this)}/>
            {this.state.loading?<Dialog open>
                <DialogContent>
                    Loading Purchases...
                </DialogContent>
            </Dialog>:null}
        </span>;
    }

    async onCompleteBuy() {
        await this.loadCharges();
        const charges = this.state.charges||{};
        if (!Object.keys(charges).length) {
            this.props.onClose();
        }
    }

    saveBuy(ref) {
        this.buy=ref;
    }

    authorizePurchase(i) {
        const charges = this.state.charges||{};
        const c = charges[i];
        this.buy.sendBuy(c);
    }

    retryPurchase(i) {
        const charges = this.state.charges||{};
        const c = Object.assign({},charges[i]);
        c.cancelCharge=true;
        this.buy.sendBuy(c);
    }

    cancelPurchase(i) {
        const charges = this.state.charges||{};
        const c = Object.assign({},charges[i]);
        c.cancelCharge=true;
        delete c.products;
        this.buy.sendBuy(c);
    }
}

class DependencyChecker {
    constructor(cart) {
        this.owned = {};

        if (cart && cart.products) {
            for (let i in cart.products) {
                const p = cart.products[i];
                const product = marketplace.getProductInfo(p.id);
                if (product) {
                    const includedPackages = (product.includedPackages||[]);
                    const bonusPackages = (product.bonusPackages||[]);

                    for (let x in includedPackages) {
                        this.addPackage(includedPackages[x], p.entry_type, p.entry_id);
                    }

                    for (let x in bonusPackages) {
                        this.addPackage(bonusPackages[x], p.entry_type, p.entry_id);
                    }
                }
            }
        }

        this.addPackageList(campaign.alwaysIncludePackages());
        this.addPackageList(campaign.defaultOwnedPackages());

        const purchaseData = marketplace.purchaseData;
        this.addPackageList(purchaseData.packageList);
        this.addPackagesObj(purchaseData.purchasedPackages);
        this.addPackagesObj(purchaseData.bonusPackages);
    }

    getProductMissingDependencies(product, packageMap) {
        if (product.purchaseType=="subscription") {
            return null;
        }

        const includedPackages = product.includedPackages;
        const missing = {};

        if (!includedPackages || !packageMap) {
            return null;
        }
        for (let i in includedPackages) {
            this.getMissingDependencies(packageMap[includedPackages[i]], missing)
        }
        return Object.keys(missing).length?missing:null;
    }

    getMissingDependencies(pkg, missingBase) {
        if (!pkg) {
            return null;
        }
        const dd = pkg.dependenciesDetails;
        if (!dd) {
            return null;
        }
        const missing = missingBase || {};

        for (let p in dd) {
            const pinfo = dd[p];
            const op = this.owned[p]
            if (!op || !op.all) {
                for (let entry_type in pinfo) {
                    if (!op || !op.entry_types || !op.entry_types[entry_type]) {
                        for (let entry_id in pinfo[entry_type]) {
                            if (!op || !op.entry_ids || !op.entry_ids[entry_id]) {
                                addMissing(p, entry_type, entry_id, pinfo[entry_type][entry_id].displayName);
                            }
                        }
                    }
                }
            }
        }
        return Object.keys(missing).length?missing:null;

        function addMissing(pkg, entry_type, entry_id, displayName) {
            if (entry_type == "Art") {
                return;
            }
            if (!missing[pkg]) {
                missing[pkg]={};
            }
            const p = missing[pkg];
            if (!excludeTypes[entry_type] && !p.all) {
                if (!p[entry_type]) {
                    p[entry_type]={};
                }
                p[entry_type][entry_id] = displayName;
            } else {
                missing[pkg]={all:true};
            }
        }
    }

    addPackageList(pkgs) {
        if (pkgs) {
            for (let i in pkgs) {
                this.addPackage(pkgs[i]);
            }
        }
    }

    addPackagesObj(pkgs) {
        if (pkgs) {
            for (let i in pkgs) {
                const p = pkgs[i];
                if (p.entryIds || p.entryTypes) {
                    if (p.entryIds) {
                        for (let x in p.entryIds) {
                            this.addPackage(i,null, x);
                        }
                    }
                    if (p.entryTypes) {
                        for (let x in p.entryTypes) {
                            this.addPackage(i, x, null);
                        }
                    }
                } else {
                    this.addPackage(i);
                }
            }
        }
    }

    addPackage(pkg, entry_type, entry_id) {
        if (!this.owned[pkg]) {
            this.owned[pkg]={};
        }
        const pInfo = this.owned[pkg];
        if (entry_type) {
            if (entry_id) {
                if (!pInfo.entry_ids) {
                    pInfo.entry_ids={};
                }
                pInfo.entry_ids[entry_id]=true;
            } else {
                if (!pInfo.entry_types) {
                    pInfo.entry_types ={};
                }
                pInfo.entry_types[entry_type] = true;
            }
        } else {
            pInfo.all=true;
        }
    }
}

class ProductPrice extends React.Component {
	render() {
        const price = this.props.price||0;
        const sale = this.props.sale||0;
        const discount = sale?this.props.discount:0;
        const discountPrice = Math.max(0,discount?Math.trunc((sale-discount)*100)/100:0);

        return <span>
            {(price!=sale)?<span><span className="strike">${price.toFixed(2)}</span>&nbsp;</span>:null}
            <span className={discount?"strike":(price!=sale)?"red":""}>{sale?("$"+sale.toFixed(2)):"FREE"}</span>
            {discount?<span className="red">&nbsp;{discountPrice?("$"+discountPrice.toFixed(2)):"FREE"}</span>:null}
        </span>;
    }
}

function getPopularity(p, cweight, aweight) {
    const popularity = ((marketplace.popularityData||{}).products||{})[p.name]||{};
    let csum= ((popularity.dayCount||0) * 15) + ((popularity.weekCount||0) * 2) + ((popularity.monthCount||0) * .25)+((popularity.oldCount||0) * .05);
    let asum= ((popularity.dayAmount||0) * 7) + ((popularity.weekAmount||0) * 2) + ((popularity.monthAmount||0) * 1)+((popularity.oldAmount||0) * .25);
    return csum*(cweight||1) + asum*(aweight||1)/2;
}

const dollarValueChoices = {
    5:"$5",
    10:"$10",
    15:"$15",
    20:"$20",
    25:"$25",
    30:"$30",
    40:"$40",
    50:"$50",
    75:"$75",
    100:"$100"
};

export {
    BuyButton,
    PurchaseHistory,
    Subscriptions,
    PurchaseSubscriptions,
    DistributeCredits,
    SalesResults,
    GiftKey,
    ProcessingCharge,
    FailedPurchases,
    isInCart,
    isInProducts,
    addToCart,
    ProductPrice,
    Accounting,
    getPopularity,
    tipProduct,
    giftCertificateProduct,
    dollarProducts,
    addToProducts
}