const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { set } from 'immutable';
const Parser = require("../lib/dutils.js").Parser;
const {entryToText} = require('./entityeditor.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
const {SelectVal, SelectTextVal,TextVal, CheckVal, PickVal, NumberAdjust,DeleteWithConfirm} = require('./stdedit.jsx');
const {conditionList, conditionLevelsList, conditionExtraList, bonusList, signedNum,durationSuggestions} = require('../lib/stdvalues.js');
const {SpellDetails,getSpellAttributes} = require('./renderspell.jsx');
const {CustomPicker,CustomDialog} = require('./customtable.jsx');
const {RenderFeatureDialog} = require('./features.jsx');

class ConditionList extends React.Component {
    constructor(props) {
        super(props);
        this.state= {};
    }

	render() {
        const {conditions,readonly} = this.props;
        const clist = Object.keys(conditions||{});
        clist.sort(function (a,b){return a.toLowerCase().localeCompare(b.toLowerCase())});
        var list = [];

        for (let i of clist) {
            const c = conditions[i];
            list.push(<Condition className={list.length?"ml--3":null} key={i} name={i} condition={c} onChange={this.onChangeCondition.bind(this, i)} character={this.props.character} doSubRoll={this.props.doSubRoll} readonly={readonly}/>)
        }

        if (!list.length) {
            return null;
        }

        return <span className={this.props.className}>
            {this.props.label?<b>{this.props.label} </b>:null}
            {list}
        </span>
    }

    onChangeCondition(i, c) {
        const conditions = Object.assign({}, this.props.conditions||{});
        if (!c) {
            delete conditions[i];
        } else {
            conditions[i]=c;
        }
        this.props.onChange(conditions);
    }
}

class Condition extends React.Component {
    constructor(props) {
        super(props);
        this.state= {};
    }

	render() {
        const {name,character, doSubRoll, separateName,className,condition,readonly}=this.props;
        const {showConditionMenu,anchorEl,showSpell,showCondition,showFeature,showEditCondition,showEditDuration} = this.state;

        const c = getConditionStruc(condition);
        const isDefault = campaign.isDefaultCampaign();
        const showDetails = c.selectedCondition||c.selectedSpell||c.spell?.spellId||c.feature;

        return <span className={className} onClick={function(evt){evt.stopPropagation();evt.preventDefault()}}>
            {separateName?<a onClick={readonly?null:this.showDetails.bind(this, c)}>{name}</a>:null} {separateName&c.spell?.spellLevel?(Parser.spLevelToFull(c.spell.spellLevel) + "-level"):null} {(!separateName||c.duration||c.durationText||!isDefault)?<span className="truncate mw5 condition hoverhighlight" onClick={readonly?null:this.onClickCondition.bind(this)}>
                {separateName?(!isDefault?(<span className={c.hideIndicator?(c.duration||c.durationText?null:"fas fa-minus"):"fas fa-exclamation-triangle"}/>):null):name+" "}
                {!separateName&&c.level?<NumberAdjust altText={" (L"+c.level+")"} value={c.level} onChange={readonly?null:this.onChangeLevel.bind(this)}/>:null}
                {c.duration?(c.duration + (separateName?" rounds":"")):c.durationText||null}
            </span>:null}
            {showConditionMenu?<Menu open anchorEl={anchorEl} onClose={this.hideDetails.bind(this)}>
                {!separateName?<MenuItem onClick={this.showDetails.bind(this, c)}>{showDetails?"Details":"Edit"}</MenuItem>:null}
                {!separateName||showDetails?<MenuItem onClick={this.showEditDuration.bind(this)}>Edit Duration</MenuItem>:null}
                {!separateName?<MenuItem onClick={this.deleteCondition.bind(this)}>End</MenuItem>:null}
                {!isDefault?<MenuItem onClick={this.toggleIndicator.bind(this,!c.hideIndicator)}>{c.hideIndicator?"Show Indicator":"Hide Indicator"}</MenuItem>:null}
            </Menu>:null}
            <SpellDetails open={showSpell} spell={c.selectedSpell||c.spell?.spellId} character={character} doSubRoll={doSubRoll} onClose={this.hideDetails.bind(this)} disableEdit/>
            <CustomDialog open={showCondition} id={c.selectedCondition} type="Conditions" character={character} doSubRoll={doSubRoll} onClose={this.hideDetails.bind(this)}/>
            <RenderFeatureDialog open={showFeature} feature={c.feature} character={character} doSubRoll={doSubRoll} onClose={this.hideDetails.bind(this)}/>
            <EditCondition open={showEditCondition} onClose={this.closeEditCondition.bind(this)} condition={c} name={name}/>
            <EditDuration open={showEditDuration} onClose={this.closeEditDuration.bind(this)} duration={c.duration||c.durationText}/>
        </span>
    }

    hideDetails() {
        this.setState({showSpell:false, showCondition:false, showFeature:false, showConditionMenu:false});
    }

    showDetails(c) {
        if (c.selectedCondition) {
            this.setState({showConditionMenu:false, showCondition:true});
        } else if (c.selectedSpell||c.spell?.spellId) {
            this.setState({showConditionMenu:false, showSpell:true});
        } else if (c.feature) {
            this.setState({showConditionMenu:false, showFeature:true});
        } else {
            this.setState({showConditionMenu:false, showEditCondition:true});
        }
    }

    showEditDuration() {
        this.setState({showConditionMenu:false, showEditDuration:true});
    }

    onClickCondition(e) {
        const {separateName}=this.props;

        if (separateName && campaign.isDefaultCampaign()) {
            // just edit duration
            this.showEditDuration();
            return;
        }
        this.setState({showConditionMenu:true, anchorEl:e.target});
    }

    closeEditCondition(condInfo) {
        if (condInfo) {
            this.props.onChange(condInfo.duration);
        }
        this.setState({showEditCondition:false});
    }

    closeEditDuration(duration) {
        if (duration != null) {
            const c =Object.assign({}, getConditionStruc(this.props.condition));
            c.duration=getDurationFromText(duration);
            c.durationText = duration;
            this.props.onChange(c);
        }
        this.setState({showEditDuration:false});
    }

    deleteCondition() {
        this.setState({showConditionMenu:false});
        this.props.onChange(null);
    }

    onChangeLevel(level) {
        this.setState({showConditionMenu:false});
        if (!level || level<0) {
            this.props.onChange(null);
        } else {
            const c =Object.assign({}, getConditionStruc(this.props.condition));
            c.level=Math.min(level, c.maxLevel||9);
            this.props.onChange(c);
        }
    }

    onChangeMaxHP(maxHP) {
        this.setState({showConditionMenu:false});
        if (!maxHP) {
            this.props.onChange(null);
        } else {
            const c =Object.assign({}, getConditionStruc(this.props.condition));
            c.maxHP=maxHP;
            this.props.onChange(c);
        }
    }

    toggleIndicator(hideIndicator) {
        this.setState({showConditionMenu:false});
        const c =Object.assign({}, getConditionStruc(this.props.condition));
        c.hideIndicator=hideIndicator;
        this.props.onChange(c);
    }
}

class EditCondition extends React.Component {
    constructor(props) {
        super(props);
        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open!= prevProps.open) {
            const {condition,name} = this.props;
            let {duration,durationText, level, acBonus, damageBonus, maxHP,hideIndicator} = condition||{};
            if (duration) {
                duration=duration+" rounds";
            } else {
                duration=durationText;
            }
            this.setState({condition:name||null, duration:duration||"", level:level||0, acBonus:acBonus||0, damageBonus:damageBonus||0, maxHP:maxHP||0, hideIndicator:hideIndicator||false});
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        return <Dialog
            open
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle onClose={this.closeAddCondition.bind(this, false)}>
                {this.props.name?("Edit Condition: "+this.props.name):"Create Condition"}
            </DialogTitle>
            <DialogContent>
                <div className="mb2">
                    {this.props.name?null:<TextVal fullWidth text={this.state.condition} onChange={this.changeCondition.bind(this)} helperText="Condition Name"/>}
                    <SelectTextVal fullWidth text={this.state.duration||""} onChange={this.changeDuration.bind(this)} values={durationSuggestions} helperText="Duration"/>
                </div>
                <SelectVal className="minw4 mr1" value={this.state.level||"none"} values={conditionLevelsList} onClick={this.changeLevel.bind(this, "level")}  helperText="Condition Level"/>
                <SelectVal className="minw4 mh1" value={this.state.acBonus||0} values={bonusList} onClick={this.changeLevel.bind(this, "acBonus")}  helperText="AC Bonus"/>
                <SelectVal className="minw4 mh1" value={this.state.damageBonus||0} values={bonusList} onClick={this.changeLevel.bind(this, "damageBonus")}  helperText="Damage Bonus"/>
                <NumberAdjust value={this.state.maxHP||0} altText={<span className="nowrap">
                    <Button color="primary" variant="outlined">
                        Adjust Max Hit Points
                    </Button>
                    {this.state.maxHP?<span> {signedNum(this.state.maxHP)} to maximum hit points</span>:null}
                </span>} onChange={this.changeLevel.bind(this, "maxHP")}/>
                <div>
                    <CheckVal value={this.state.hideIndicator} onChange={this.changeIndicator.bind(this)} label="No condition indicater on token"/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={!this.state.condition} onClick={this.closeAddCondition.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.closeAddCondition.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    changeCondition(condition) {
        this.setState({condition});
    }

    changeLevel(field, level) {
        if (level == "none") {
            level=0;
        } else {
            level=Number(level);
        }
        const newState={};
        newState[field]=level;
        this.setState(newState);
    }

    changeIndicator(hideIndicator) {
        this.setState({hideIndicator});
    }

    changeDuration(duration) {
        if (duration == "none") {
            duration=0;
        } else {
            duration=duration;
        }
        this.setState({duration});
    }

    closeAddCondition(save) {
        if (save) {
            const duration=this.state.duration;
            const level=this.state.level||0;
            const condition=this.state.condition;
            const acBonus = this.state.acBonus||0;
            const damageBonus=this.state.damageBonus||0;
            const hideIndicator = this.state.hideIndicator||false;
            const maxHP=this.state.maxHP||0;
            const fullCondition = {
                condition, 
                duration:{duration:getDurationFromText(duration), durationText:duration, level, damageBonus, acBonus,maxHP,hideIndicator}
            };

            if (!this.props.name) {
                const description = condition+(duration?" "+duration:"")+(level?" L"+level:"")+(maxHP?" max hp "+maxHP:"");
                campaign.addUserMRUList("conditionpickmru", {description, object:fullCondition});
            }
            this.props.onClose(fullCondition);
        } else {
            this.props.onClose();
        }
    }
}

class EditDuration extends React.Component {
    constructor(props) {
        super(props);
        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open!= prevProps.open) {
            let {duration} = this.props;
            if (duration && !isNaN(duration)) {
                duration=duration+" rounds";
            }
            this.setState({duration:duration||""});
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        return <Dialog
            open
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle onClose={this.close.bind(this, false)}>
            </DialogTitle>
            <DialogContent>
                <SelectTextVal fullWidth text={this.state.duration||""} onChange={this.changeDuration.bind(this)} values={durationSuggestions} helperText="Duration"/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.close.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.close.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    changeDuration(duration) {
        if (duration == "none") {
            duration=0;
        } else {
            duration=duration;
        }
        this.setState({duration});
    }

    close(save) {
        if (save) {
            const duration=this.state.duration;
            this.props.onClose(duration||0);
        } else {
            this.props.onClose();
        }
    }
}

function getDurationFromText(duration) {
    if (!duration) {
        return 0;
    }

    let ret = 0;
    if (duration.match(/\s*\d+\s*rounds?\s*/i)) {
        const l = duration.match(/\d+/);
        ret= Number(l[0]);
    } else if (duration.match(/\s*\d+\s*turns?\s*/i)) {
        const l = duration.match(/\d+/);
        ret= Number(l[0]);
    } else if (duration.match(/\s*\d+\s*minutes?\s*/i)) {
        const l = duration.match(/\d+/);
        ret= Number(l[0]*10);
    } else if (duration.match(/\s*\d+\s*min\s*/i)) {
        const l = duration.match(/\d+/);
        ret= Number(l[0]*10);
    }
    if (ret < 30) {
        return ret;
    }
    return 0;
}

class PickCondition extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.showMenu && (prevState.showMenu != this.state.showMenu)) {
            this.setState({});
        }
    }

    render() {
        const list=[];
        const uc = campaign.getUserMRUList("conditionpickmru");

        for (let i in uc) {
            const c = uc[i];
            list.push(<MenuItem key={i} onClick={this.clickMRU.bind(this, c)}>
                 <span className="flex-auto">{c.description}</span>
                 <DeleteWithConfirm className="ml1 pa1" name={c.description} onClick={this.deleteMRU.bind(this,i)}/>
            </MenuItem>);
        }

        return  <span>
            {this.props.useButton?<span className="ba titleborder br1 ph1 hoverhighlight mv--2 dib" onClick={this.showMenu.bind(this)}>Add Condition</span>:<MenuItem onClick={this.showMenu.bind(this)}>Add Condition</MenuItem>}
            <span onClick={ignoreEvents} onKeyDown={ignoreEvents} onKeyPress={ignoreEvents} onKeyUp={ignoreEvents}>
                <Menu open={this.state.showMenu||false} 
                    disableAutoFocusItem 
                    anchorEl={this.state.anchorEl} 
                    transitionDuration={0}
                    onClose={this.closeMenu.bind(this)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right',}}
                >
                    <MenuItem onClick={this.onPickCondition.bind(this)}>Pick Condition...</MenuItem>
                    <MenuItem onClick={this.onNewCondition.bind(this)}>Custom Condition...</MenuItem>
                    {list.length?<Divider/>:null}
                    {list}
                </Menu>
                <EditCondition open={this.state.showAddCondition} onClose={this.closeAddCondition.bind(this)}/>
                <AddCondition open={this.state.showPickCondition} onClose={this.closeAddCondition.bind(this)}/>
            </span>
        </span>
    }

    onNewCondition() {
        this.setState({showMenu:false, showAddCondition:true});
    }

    onPickCondition() {
        this.setState({showMenu:false, showPickCondition:true});
    }

    closeAddCondition(conditionInfo) {
        this.setState({showMenu:false, showAddCondition:false, showPickCondition:false});
        if (conditionInfo) {
            this.props.onAddCondition(conditionInfo);
        }
    }

    clickMRU(mru) {
        this.setState({showMenu:false, showPickCondition:false});
        campaign.addUserMRUList("conditionpickmru", mru);
        this.props.onAddCondition(mru.object);
    }

    deleteMRU(i) {
        campaign.removeUserMRUList("conditionpickmru", i);
        this.setState({mruList:campaign.getUserMRUList("conditionpickmru")})
    }

    showMenu(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({showMenu:true, anchorEl:e.target});
    }

    closeMenu() {
        this.setState({showMenu:false});
    }

    onClickImage(m) {
        this.props.onClick(m);
        this.setState({showMenu:false});
    }
}

class AddCondition extends React.Component {
    constructor(props) {
        super(props);
        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open!= prevProps.open) {
            this.setState({condition:null, duration:"", showPickCondition:true});
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const {condition, duration} = this.state;
        const conditionInfo = campaign.getCustom("Conditions", condition);

        return <Dialog
            open
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle onClose={this.closeAddCondition.bind(this, false)}>
                Add Condition
            </DialogTitle>
            <DialogContent>
                <div className="mb2">
                    <Button onClick={this.pickCondition.bind(this)} color="primary" variant="outlined">
                        Pick Condition
                    </Button> {conditionInfo&&conditionInfo.displayName}
                </div>
                <SelectTextVal fullWidth text={duration} onChange={this.changeDuration.bind(this)} className="minw5" values={durationSuggestions} helperText="Duration"/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.closeAddCondition.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.closeAddCondition.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <CustomPicker 
                open={this.state.showPickCondition}
                type="Conditions" 
                known={1}
                onClose={this.onCloseCustomPicker.bind(this)}
            />
        </Dialog>;
    }

    pickCondition() {
        this.setState({showPickCondition:true});
    }

    onCloseCustomPicker(selected) {
        this.setState({showPickCondition:false});
        if (selected) {
            const condition = Object.keys(selected)[0]||null;
            if (condition) {
                this.setState({condition});
                return;
            }
        }
        
        if (!this.state.condition) {
            this.closeAddCondition(false);
        }
    }

    changeDuration(duration) {
        if (duration =="none") {
            duration = "";
        }
        this.setState({duration});
    }

    closeAddCondition(save) {
        if (save) {
            const {condition, duration} = this.state;
            const setc = getConditionInfo(condition);
            if (!setc) {
                return this.props.onClose();
            }
            if (duration) {
                setc.duration =getDurationFromText(duration)
                setc.durationText = duration;
            }

            const fullCondition = {
                condition:setc.displayName, 
                duration:setc
            };

            const description = setc.displayName+(duration?" "+duration:"");
            campaign.addUserMRUList("conditionpickmru", {description, object:fullCondition});
            this.props.onClose(fullCondition);
        } else {
            this.props.onClose();
        }
    }
}

function addTempHPCondition(conditions, duration) {
    return addCondition(conditions, "Temp HP", duration);
}

function addCondition(conditions, name, duration) {
    if (duration) {
        conditions = Object.assign({}, conditions||{});
        conditions[name] = {duration:getDurationFromText(duration), durationText:duration, hideIndicator:true};
    } else if (conditions) {
        conditions = Object.assign({}, conditions||{});
        delete conditions[name];
    }
    return conditions;
}

function getConditionInfo(i) {
    const c = campaign.getCustom("Conditions", i);
    if (c) {
        const setc = {selectedCondition:i, displayName:c.displayName};
        if (c.hideIndicator) {
            setc.hideIndicator = true;
        }
        if (c.conditionLevel) {
            setc.level=1;
            setc.maxLevel = c.conditionLevel;
        }
        return setc;
    }
    return null;
}

function addConditionInfoToConditions(conditions, c) {
    const curc = conditions[c.condition]
    if (curc && curc.level && (typeof c.duration == "object") && c.duration.level) {
        const val = Object.assign({}, c.duration);
        val.level += curc.level;
        conditions[c.condition] = val;
    } else {
        conditions[c.condition] = c.duration;
    }
}

function addMaxHPToConditions(conditions, name, maxHP, durationText) {
    conditions = Object.assign({}, conditions||{});
    let cur = conditions[name];
    const duration = getDurationFromText(durationText);
    const cval = {duration:duration||null, durationText:durationText||null, hideIndicator:true, maxHP:maxHP||0};
    if (cur?.maxHP) {
        cval.maxHP+=cur.maxHP;
    }
    conditions[name] = cval
    return conditions;
}

function addConditionsToConditions(conditions, newc) {
    if (!newc) {
        return conditions;
    }
    conditions = Object.assign({}, conditions||{});
    for (let i in newc.conditions) {
        const setc = getConditionInfo(i);
        if (setc) {
            if (newc.duration) {
                setc.duration =getDurationFromText(newc.duration)
                setc.durationText = newc.duration;
            }
            if (setc.level) {
                const curc = conditions[setc.displayName];
                if (curc && curc.level) {
                    setc.level = curc.level+1;
                }
            }
            conditions[setc.displayName] = setc;
        }
    }
    for (let i in newc.features) {
        const f = newc.features[i];
        conditions[f.name] = newc.duration?{duration:getDurationFromText(newc.duration), durationText:newc.duration, feature:f, hideIndicator:true}:{feature:f, hideIndicator:true};
    }
    for (let i in newc.spells) {
        const f = newc.spells[i];
        conditions[f.name] = newc.duration?{duration:getDurationFromText(newc.duration), durationText:newc.duration, selectedSpell:f.id}:{selectedSpell:f.id};
    }
    return conditions;
}

function getConditionStruc(condition) {
    if (typeof condition == "object"){
        return condition;
    }
    return {duration:condition};
}



function ignoreEvents(e){
    e.stopPropagation();
}

const conditionLevelList = [
    0,1,2,3,4,5,6,7,8,9
];

export {
    ConditionList,
    Condition,
    PickCondition,
    getDurationFromText,
    addTempHPCondition,
    addCondition,
    addConditionsToConditions,
    getConditionStruc,
    conditionLevelList,
    addConditionInfoToConditions,
    addMaxHPToConditions
}