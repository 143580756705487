const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';

const {DeleteWithConfirm,getAnchorPos} = require('./stdedit.jsx');
const {ArtPicker} = require('./renderart.jsx');
const {Renderentry} = require('./entityeditor.jsx');
import Divider from '@material-ui/core/Divider';
const {FeedbackDialog} = require('./renderfeedback.jsx');
const {Chat} = require('../lib/chat.js');

class PickHandout extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
        this.anchorRef = React.createRef();
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.showMenu && (prevState.showMenu != this.state.showMenu)) {
            this.setState({});
        }
    }

    render() {
        const mru=[];

        if (this.state.showMenu) {
            const mruList = campaign.getHandouts().mru||[];
            for (let i in mruList) {
                const m=mruList[i];
                mru.push(<MenuItem key={i} onClick={this.onClickMRU.bind(this, m)}>
                    <span className="flex-auto">{m.description}</span>
                    {this.props.allowDelete?<DeleteWithConfirm className="ml1 pa1" onClick={this.deleteHandout.bind(this,i)}/>:null}
                </MenuItem>);
            }
        }
        
        return  <span>
            {this.props.children?
                <span ref={this.anchorRef} onClick={this.showMenu.bind(this)}>{this.props.children}</span>
                :<Button ref={this.anchorRef} className="mh1 minw2" color={this.props.color||"secondary"} variant="outlined" size="small" onClick={this.showMenu.bind(this)}>Handouts</Button>
            }
            {this.state.showMenu?<span onClick={function (e){e.preventDefault(); e.stopPropagation()}}>
                <Menu open
                    disableAutoFocusItem 
                    anchorEl={this.anchorRef.current} 
                    transitionDuration={0} 
                    onClose={this.closeAll.bind(this)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left',}}
                >
                    {this.props.noPick?null:<MenuItem onClick={this.showDialog.bind(this)}>Pick...</MenuItem>}
                    {(mru.length > 0)&&!this.props.noPick?<Divider/>:null}
                    {mru}
                </Menu>
            </span>:null}
            {this.state.showImagePicker?<ArtPicker defaultType="Picture" open onClose={this.onPickImage.bind(this)}/>:null}
        </span>
    }

    closeAll() {
        this.setState({
            showMenu:false,
            showImagePicker:false
        });
    }

    deleteHandout(i) {
        const handouts = Object.assign({}, campaign.getHandouts());
        var mru = (handouts.mru||[]).concat([]);
    
        mru.splice(i, 1);

        handouts.mru = mru;
        handouts.showHandout = false;
        campaign.updateCampaignContent("adventure", handouts);
    
        this.setState({showMenu:false});
    }

    showDialog() {
        this.setState({showImagePicker:true, showMenu:false});
    }

    showMenu(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({showMenu:true});
    }

    closeMenu() {
        this.setState({showMenu:false});
    }

    onClickMRU(ho) {
        this.closeAll();
        if (!this.props.noPick) {
            addToHandoutMRU(ho, this.props.setActive);
        }
        if (this.props.onClick) {
            this.props.onClick(ho);
        }
    }

    onPickImage(art) {
        this.closeAll();

        if (art) {
            const ho = {
                type:"art",
                description:art.displayName,
                art:art.name,
                url:art.url,
                imgHeight:art.imgHeight,
                imgWidth:art.imgWidth
            };
            addToHandoutMRU(ho, this.props.setActive);
            if (this.props.onClick) {
                this.props.onClick(ho);
            }
        }
    }

}

function addToHandoutMRU(ho,setActive) {
    const handouts = Object.assign({}, campaign.getHandouts());
    var mru = (handouts.mru||[]).concat([]);

    const fi = mru.findIndex(function (m) {return m.description==ho.description});
    if (fi >= 0) {
        mru.splice(fi, 1);
    }
    mru.unshift(ho);
    if (mru.length > 10) {
        mru = mru.slice(0,9);
    }
    handouts.mru = mru;
    if (setActive) {
        Chat.addHandout(ho);
        handouts.showHandout = true;
    }
    campaign.updateCampaignContent("adventure", handouts);
}

class ShowHandout extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
    }
    
    render() {
        const {handout, onClick,character} = this.props;
        const size = this.props.size;
        const border = 15;

        if (handout.type == "art") {
            const viewRatio = size.width/size.height;
            const imgWidth = handout.imgWidth||10;
            const imgHeight = handout.imgHeight||10;
            const imgRatio = imgWidth/imgHeight;
            let height, width;

            if (viewRatio > imgRatio) {
                // view is wider than image so bound by height
                height = size.height - border;
                width = height * imgWidth / imgHeight;
            } else {
                // view is taller than image so bound by width
                width = size.width - border;
                height = width * imgHeight / imgWidth;
            }

            return <div style={{position:"absolute", height:size.height, width:size.width, top:0, left:0, backgroundColor:"rgba(64, 64, 64, .67)"}} onClick={onClick}>
                <img height={height} width={width} style={{position:"absolute", top:(size.height-height)/2, left:(size.width-width)/2}} src={handout.url}/>
            </div>;
        } else if (handout.type == "html") {
            return <div style={{position:"absolute", height:size.height, width:size.width, top:0, left:0, backgroundColor:"rgba(64, 64, 64, .67)"}} onClick={onClick}>
                <div className="h-100 w-100 flex">
                    <div className="flex-auto"/>
                    <div className="flex-auto w-100 mw7 flex flex-column">
                        <div className="flex-auto"/>
                        <div className="stdcontent pa2 maxh-75 br1 ma2 defaultbackground overflow-y-auto relative" onClick={eatClicks}>
                            <div style={{position:"absolute", right:0, top:0}} onClick={onClick}><span className="titlecolor fas fa-times pa1 hoverhighlight"/></div>
                            <blockquote>
                                <Renderentry entry={handout.html} character={character}/>
                            </blockquote>
                        </div>
                        <div className="flex-auto"/>
                    </div>
                    <div className="flex-auto"/>
                </div>
            </div>;
        } else if (handout.type == "book") {
            const {SubBook} = require('./book.jsx');

            return <div style={{position:"absolute", height:size.height, width:size.width, top:0, left:0, backgroundColor:"rgba(64, 64, 64, .67)"}} onClick={onClick}>
                <div className="h-100 w-100 flex">
                    <div className="flex-auto"/>
                    <div className="flex-auto w-100 mw7 flex flex-column">
                        <div className="flex-auto"/>
                        <div className="stdcontent pa2 maxh-75 br1 ma2 defaultbackground overflow-y-auto relative" onClick={eatClicks}>
                            <div style={{position:"absolute", right:0, top:0}} onClick={onClick}><span className="titlecolor fas fa-times pa1 hoverhighlight"/></div>
                            <SubBook bookname={handout.bookname} fragment={handout.fragment} character={character} showName/>
                        </div>
                        <div className="flex-auto"/>
                    </div>
                    <div className="flex-auto"/>
                </div>
            </div>;
        } else if (handout.type == "feedback") {
            return <div style={{position:"absolute", height:size.height, width:size.width, top:0, left:0, backgroundColor:"rgba(64, 64, 64, .67)"}} onClick={onClick}>
                <div className="h-100 w-100 flex">
                    <div className="flex-auto"/>
                    <div className="flex-auto w-100 mw7 flex flex-column">
                        <div className="flex-auto"/>
                        <div className="stdcontent pa2 maxh-75 br1 ma2 defaultbackground overflow-y-auto tc relative" onClick={eatClicks}>
                            <div style={{position:"absolute", right:0, top:0}} onClick={onClick}><span className="titlecolor fas fa-times pa1 hoverhighlight"/></div>
                            <div className="f2">Your gamemaster would like some feedback.</div>
                            {campaign.isPlayerMode()?<div className="mv2"><Button color="primary" variant="outlined" onClick={this.showFeedback.bind(this,true)}>Give Feedback</Button></div>:null}
                            <FeedbackDialog open={this.state.showFeedback} onClose={this.showFeedback.bind(this,false)}/>
                        </div>
                        <div className="flex-auto"/>
                    </div>
                    <div className="flex-auto"/>
                </div>
            </div>;
        }
        return null;
    }

    showFeedback(showFeedback, event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (!showFeedback) {
            this.props.onClick(); // this should hide the handout
        }
        this.setState({showFeedback});
    }
}

function eatClicks(evt) {
    evt.preventDefault();
    evt.stopPropagation();
}

class ChatHandout extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
    }
    
    render() {
        const {EditJournal} = require('./renderjournal.jsx');

        const {chat,character} = this.props;
        const {handout} = chat;

        if (handout.type == "art") {
            const {RenderImage} = require('./renderart.jsx');

            const imgWidth = handout.imgWidth||10;
            const imgHeight = handout.imgHeight||10;
            const imgRatio = imgWidth/imgHeight;
            const dimension = 50;
            let height, width;

            if (imgRatio > 1) {
                // image is wider so bound by height
                height = dimension;
                width = height * imgWidth / imgHeight;
            } else {
                // image is taller so bound by width
                width = dimension;
                height = width * imgHeight / imgWidth;
            }

            return <div className="hoverhighlight" onClick={this.showMenu.bind(this,true)}>
                <img className="pa--2" height={height} width={width} src={handout.url} />
                <RenderImage imgHeight={imgHeight} imgWidth={imgWidth} url={handout.url} open={this.state.showImage} onClose={this.showImage.bind(this,false)}/>
                <EditJournal character={character} open={this.state.showAddToJournal} onClose={this.showAddToJournal.bind(this,false)} newJournal={this.state.newJournal}/>
                {this.state.showMenu?<Menu open disableAutoFocusItem anchorPosition={this.state.anchorPos} anchorReference="anchorPosition" transitionDuration={0} onClose={this.showMenu.bind(this,false)}>
                    <MenuItem onClick={this.showImage.bind(this,true)}>View</MenuItem>
                    <MenuItem onClick={this.showAddToJournal.bind(this,true)}>Add to Journal</MenuItem>
                    {this.props.onDelete?<MenuItem onClick={this.doDeleteChat.bind(this)}>Delete</MenuItem>:null}
                </Menu>:null}
            </div>;
        } else if (handout.type == "html") {
            const {GenericDialog} = require('./generic.jsx');

            return<div className="stdcontent llc-3 overflow-hidden tl relative">
                <blockquote onClick={this.showMenu.bind(this,true)}>
                    <Renderentry entry={handout.html} character={character}/>
                </blockquote>
                {this.state.showMenu?<Menu open disableAutoFocusItem anchorPosition={this.state.anchorPos} anchorReference="anchorPosition" transitionDuration={0} onClose={this.showMenu.bind(this,false)}>
                    <MenuItem onClick={this.showText.bind(this,true)}>View</MenuItem>
                    <MenuItem onClick={this.showAddToJournal.bind(this,true)}>Add to Journal</MenuItem>
                    {this.props.onDelete?<MenuItem onClick={this.doDeleteChat.bind(this)}>Delete</MenuItem>:null}
                </Menu>:null}
                <GenericDialog open={this.state.showText} character={character} onClose={this.showText.bind(this,false)} entry={{name:"Handout", entries:[{type:"html", html:"<blockquote>"+handout.html+"</blockquote>"}]}}/>
                <EditJournal character={character} open={this.state.showAddToJournal} onClose={this.showAddToJournal.bind(this,false)} newJournal={this.state.newJournal}/>
            </div>;
        } else if (handout.type == "book") {
            const {SubBookDialog} = require('./book.jsx');

            return<div>
                <div className="tc" onClick={this.showMenu.bind(this,true)}>
                    <a>{handout.description}</a>
                </div>
                {this.state.showMenu?<Menu open disableAutoFocusItem anchorPosition={this.state.anchorPos} anchorReference="anchorPosition" transitionDuration={0} onClose={this.showMenu.bind(this,false)}>
                    <MenuItem onClick={this.showSubBook.bind(this,true)}>View</MenuItem>
                    <MenuItem onClick={this.showAddToJournal.bind(this,true)}>Add to Journal</MenuItem>
                    {this.props.onDelete?<MenuItem onClick={this.doDeleteChat.bind(this)}>Delete</MenuItem>:null}
                </Menu>:null}
                <EditJournal character={character} open={this.state.showAddToJournal} onClose={this.showAddToJournal.bind(this,false)} newJournal={this.state.newJournal}/>
                <SubBookDialog open={this.state.showSubBook} displayName={handout.description} fragment={handout.fragment} bookname={handout.bookname} character={character} onClose={this.showSubBook.bind(this,false)}/>
            </div>;
        } else if (handout.type == "feedback") {
            return <div>
                {campaign.isPlayerMode()?<a onClick={this.showFeedback.bind(this,true)}>Your gamemaster would like some feedback.</a>:<span>Your gamemaster would like some feedback.</span>}
                <span onClick={function (event){ event.preventDefault();event.stopPropagation()}}><FeedbackDialog open={this.state.showFeedback} onClose={this.showFeedback.bind(this,false)}/></span>                
            </div>;
        }
        return null;
    }

    showSubBook(showSubBook, event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({showSubBook,showMenu:false});
    }

    showAddToJournal(showAddToJournal,event) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        let newJournal;
        if (showAddToJournal) {
            const {chat} = this.props;
            const {handout} = chat;
    
            newJournal={
                name:campaign.newUid(),
            };

            if (handout.type == "html") {
                if (handout.html) {
                    newJournal.description="<blockquote>"+handout.html+"</blockquote>";
                }
            } else if (handout.type == "book") {
                newJournal.bookname = handout.bookname,
                newJournal.fragment = handout.fragment,
                newJournal.displayName = handout.description
            } else if (handout.type == "art") {
                newJournal.url = handout.url||null;
                newJournal.imgWidth = handout.imgWidth||10;
                newJournal.imgHeight = handout.imgHeight||10;
            }
        }
        this.setState({showAddToJournal, newJournal,showMenu:false});
    }

    showMenu(showMenu, event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({showMenu, anchorPos:event&&getAnchorPos(event)});
    }

    doDeleteChat(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.props.onDelete();
        this.setState({showMenu:false});
    }

    showImage(showImage, event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({showImage,showMenu:false});
    }

    showText(showText, event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({showText, showMenu:false});
    }

    showFeedback(showFeedback, event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({showFeedback});
    }
}

export {
    PickHandout,
    ShowHandout,
    ChatHandout,
    addToHandoutMRU
}