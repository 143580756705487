const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');

const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {LinkHref} = require('./renderhref.jsx');
const {DeleteWithConfirm, TextVal,CheckVal} = require('./stdedit.jsx');
const {ListFilter} = require('./listfilter.jsx');


class EncountersHistoryList extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {list:campaign.getEncountersHistory()};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:campaign.getEncountersHistory()})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "encountershistory");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "encountershistory");
    }

	render() {
        return <div className="pa1 defaultbackground">
            <ListFilter 
                list={this.state.list}
                showAll
                filters={historyListFilters}
                render={historyListRender}
                onClick={this.doClick.bind(this)}
                extraButtons={<Button className="ma2" onClick={this.onNew.bind(this)} size="small" variant="outlined" color="primary">New</Button>}
                getListRef={this.saveRef.bind(this)}
            />
            <div className="mv1 hk-well">
                Log entries are only visible to the gamemaster.  When finishing encounters, you have the option to record the finished encounter to the log.
            </div>
            <EditHistory open={this.state.showEdit} encounter={this.state.historyName} newHistory={this.state.newHistory} onClose={this.hide.bind(this)} extraButtonsFn={this.getExtraButtons.bind(this)}/>
        </div>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(history) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(history))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.doClick.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.doClick.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    onNew() {
        const newHistory={
            name:campaign.newUid(),
            timeFinished:Date.now()
        }
        this.setState({showEdit:true, historyName:null, newHistory});
    }

    doClick(name) {
        this.setState({showEdit:true, historyName:name, newHistory:null});
    }

    hide(){
        this.setState({showEdit:false});
    }
}

const historyListFilters = [
    {
        filterName:"Keywords",
        fieldName:"keywords",
        convertField: function (v,it) {
            const ret= (v||"none").split(",").map(function (a){return a.trim()});
            return ret;
        }
    },
]

function historyListRender(it) {
    const d = new Date(it.timeFinished);
    const encounter = (it.combatants && it.combatants.length > 0) || (it.encounters && it.encounters.length > 0);
    return <div>
        <div>{encounter?<span className="ph1 fas fa-dragon"/>:null}{it.displayName} <i className="f6">{d.toLocaleString("en",{year:"numeric", month:"short", day:"numeric", weekday:"short", hour:"numeric", minute:"2-digit"})}</i></div>
        {it.log?<div className="stdcontent maxh3 overflow-hidden"><Renderentry className="llb-3" entry={it.log}/></div>:null}
    </div>
}

class EncounterHistoryEntry extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

	render() {
        const e = this.props.encounter;
        let centries = [];
    
        if (e.combatants && e.combatants.length > 0) {
            const ce = [], dce=[];

            if (e.difficulty) {
                centries.push(<p key="difficulty">
                    This encounter had&nbsp;
                    {(e.round > 1)?(e.round+" rounds of combat"):"no rounds of combat"} worth {(e.xpTotal||0).toLocaleString()}XP.
                </p>);
            }

            for (let i in e.combatants) {
                const c = e.combatants[i];
    
                let name;
                switch (c.ctype) {
                    case "object":
                        name=c.name;
                        break;
                    case "cmonster":
                        name=c.displayName;
                        break;
                    case "pc":
                        name = <LinkHref href={"/#players?id="+encodeURIComponent(c.name)}>{c.displayName||c.name}</LinkHref>;
                        break;
                    default:
                        name = <LinkHref href={"/#monster?id="+encodeURIComponent(c.type)}>{c.name}</LinkHref>;
                        break;
    
                }

                const row = <tr key={i}>
                    <td className="w-70">{name}</td>
                    <td className="tr">{c.passive||""}</td>
                    <td className="tr">{c.ac||""}</td>
                    <td className="tr">{c.hp||""}</td>
                    <td className="tr">{c.initiative||""}</td>
                </tr>;

                if (c.ctype == "object") {
                    //oe.push(row);
                } else {
                    switch (c.state) {
                        case "inactive":
                            //ice.push(row);
                            break;
                        case "dead":
                            dce.push(row);
                            break;
                    
                        case "active":
                        default:
                            ce.push(row);
                            break;
                    }
                }    
            }
    
            if (ce.length > 0) {
                centries.push(<table className="w-100 mb1" key="incombat">
                    <tbody>
                        <tr>
                            <td className="b">Name</td>
                            <td className="tr"><i className="fas fa-low-vision"/></td>
                            <td className="tr"><i className="fas fa-shield-alt"/></td>
                            <td className="tr"><i className="fas fa-heart"/></td>
                            <td className="tr"><i className="fas fa-fist-raised"/></td>
                        </tr>
                        {ce}
                    </tbody>
                </table>);
            }
    
            if (dce.length > 0) {
                centries.push(<div className="b" key="hdead">Dead</div>);
                centries.push(<table className="w-100 mb1" key="dead">
                    <tbody>
                        <tr>
                            <td className="b">Name</td>
                            <td className="tr"><i className="fas fa-low-vision"/></td>
                            <td className="tr"><i className="fas fa-shield-alt"/></td>
                            <td className="tr"><i className="fas fa-heart"/></td>
                            <td className="tr"><i className="fas fa-fist-raised"/></td>
                        </tr>
                        {dce}
                    </tbody>
                </table>);
            }
    
        }
    
        if (e.encounters && e.encounters.length > 0) {
            var ee = [];
    
            for (let i in e.encounters) {
                const ei = e.encounters[i];
                const einfo =campaign.getPlannedEncounterInfo(ei);
    
                if (einfo) {
                    if (ee.length) {
                        ee.push(", ");
                    }
                    ee.push(<LinkHref href={"/#plannedencounter?id="+encodeURIComponent(ei)} key={i}>{einfo.displayName}</LinkHref>);
                }
            }
            if (ee.length) {
                centries.push(<div key="ee">
                    Included Encounters {ee}
                </div>)
            }
        }
        if (!centries.length) {
            return null;
        }
        
        return <div className="stdcontent">
            <h3>Combatants {e.xpTotal?<span className="f3 titlecolor">({(e.xpTotal).toLocaleString()+"XP"})</span>:null}</h3>
            {centries}
        </div>;
    }
}

class EditHistory extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {it:props.newHistory||campaign.getEncounterHistoryInfo(props.encounter), dirty:!!props.newHistory, edit:!!props.newHistory};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.encounter != prevProps.encounter)||(this.props.newHistory != prevProps.newHistory)||(this.props.open && this.props.open!= prevProps.open)) {
            this.setState({it:this.props.newHistory||campaign.getEncounterHistoryInfo(this.props.encounter), setTime:false, dirty:!!this.props.newHistory, edit:!!this.props.newHistory});
        }
    }

    render() {
        const {it,dirty, edit} = this.state;
        if (!this.props.open || !it) {
            return null;
        }
        const extraButtonsFn = this.props.extraButtonsFn;

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
            classes={{paper:it.bookname&&!it.fragment?"minvh-90":null}}
        >
            <DialogTitle onClose={this.onClose.bind(this)}>
                {edit?<TextVal    
                    text={it.displayName}
                    fullWidth
                    inputProps={{className:"f1 titletext titlecolor ignoreDrag"}}
                    placeholder="Log name"
                    onChange={this.onChangeField.bind(this,"displayName")}
                />:it.displayName}
            </DialogTitle>
            <DialogContent className={"stdcontent"+(it.bookname&&!it.fragment?" flex-none":"")}>
                {edit?<TextVal text={it.keywords||""} onChange={this.onChangeField.bind(this,"keywords")} fullWidth placeholder="comma separated keywords" helperText="Keywords"/>:null}
                {edit?<EntityEditor onChange={this.onChangeField.bind(this,"log")} entry={it.log} placeholder="Description"/>
                    :
                <Renderentry entry={it.log}/>}
                <EncounterHistoryEntry encounter={it}/>
            </DialogContent>
            <DialogActions>
                {!edit && extraButtonsFn && extraButtonsFn(it.name)}
                {edit&&!this.props.newHistory?<CheckVal label="Set Time" value={!!this.state.setTime} onChange={this.toggleSetTime.bind(this)}/>:null}
                {this.props.encounter?<DeleteWithConfirm 
                    onClick={this.onDelete.bind(this)} 
                    useButton 
                />:null}
                {!edit?<Button disabled={!it.displayName} onClick={this.editData.bind(this)} color="primary">
                    Edit
                </Button>:null}
                {edit?<Button disabled={!it.displayName||!dirty} onClick={this.saveData.bind(this)} color="primary">
                    Save
                </Button>:null}
                <Button onClick={this.onClose.bind(this)} color="primary">
                    {edit?"Cancel":"Close"}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    toggleSetTime() {
        this.setState({setTime:!this.state.setTime, dirty:true});
    }

    editData() {
        this.setState({edit:true});
    }

    onClose() {
        this.props.onClose();
    }

    saveData() {
        let it = this.state.it;
        if (this.state.setTime) {
            it = Object.assign({},it);
            it.timeFinished = Date.now();
        }
        campaign.updateCampaignContent("encountershistory", it);
        this.props.onClose();
    }

    onChangeField(field,val) {
        const it = Object.assign({}, this.state.it);
        if (val == "none") {
            val = null;
        }
        it[field] = val;
        this.setState({it, dirty:true});
    }

    onDelete(e) {
        campaign.deleteCampaignContent("encountershistory", this.props.encounter);
        this.props.onClose();
    }
}

const EncounterHistoryEntryDialog = EditHistory;

export {
    EncountersHistoryList,
    EncounterHistoryEntryDialog
}