const React = require('react');
const {campaign,globalDataListener,sortDisplayName,getExtensionEntryCheckFn} = require('../lib/campaign.js');
const Parser = require("../lib/dutils.js").Parser;
import TextField from '@material-ui/core/TextField';
const {Rendersource} = require("./rendersource.jsx");
import {htmlFromEntry} from "../lib/entryconversion.js";
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
const {DescriptionOptionsListEdit,RenderFeature,FeatureListEdit,renderPrintFeatures,printDescriptionOptions} = require('./features.jsx');
const {sizeSelectList,abilityNames,signedNum,gamesystemRaceOptions} = require('../lib/stdvalues.js');
const {ListFilter} = require('./listfilter.jsx');
const {AddChatEntry,getBasicHref} = require('./renderhref.jsx');
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');
const {ExtraArtList,ArtZoomList, FloatArt,FloatCardArt,printFloatArt} = require('./renderart.jsx');

const {DialogEntry, SelectVal, TextVal, defaultSourceFilter,defaultBookFilter,defaultGamesystemFilter,DeleteEntry} = require('./stdedit.jsx');

class Renderraces extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {list:this.getList()};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:this.getList()})
    }

    getList() {
        const list= campaign.getRaces();
        return list;
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "races");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "races");
    }

	render() {

        return <div>
            <ListFilter 
                list={this.state.list}
                showCards
                showArt
                showAll
                largeCard
                render={this.renderCard.bind(this)}
                filters={raceFilters}
                getListRef={this.saveRef.bind(this)}
                entryCheckFn={getExtensionEntryCheckFn(null,true)}
                collate={function (it,filter){ if (filter.baserace) return false; else return it.baserace;}}
                headerOutline={this.props.headerOutline}
            />
            <RaceDialog open={this.state.showRace} race={this.state.selectedRace} extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.showRace.bind(this, false, null)}/>
        </div>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(race) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(race))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.showRace.bind(this,true,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.showRace.bind(this,true, next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    renderCard(it, width) {
        const {description, keyFeatures,subraces} = getRaceSummary(it);
        const subList = [];
        if (subraces){
            subraces.sort(sortDisplayName);
        }
        for (let i in subraces) {
            const r = subraces[i];
            subList.push(<span key={r.name}>{(i>0)?", ":""}<a onClick={this.showSubrace.bind(this,r.name)} className="nowrap">{r.displayName}</a></span>);
        }

        return <div key={it.name} style={{width:width-10}} className="defaultbackground shadow-3 br3 ma1 stdcontent overflow-hidden" onClick={this.showRace.bind(this,true,it.name)}>
            <div className="h-100 hoverhighlight pa1">
                <div className="f1 titlecolor">{it.displayName}</div>
                <div className="f6 near-black bb titleborder mb1"><Rendersource className=" " entry={it}/></div>
                <FloatCardArt art={it.defaultArt} width={width}/>
                <Renderentry className="ll-8" entry={description}/>
                {keyFeatures.length?<div>
                    <div className="b">Traits</div>
                    {keyFeatures.join(", ")}
                </div>:null}
                {subList.length?<div className="mt1">
                    <div className="b">{it.noBaseRace?"Subraces":"Variants"}</div>
                    {subList}
                </div>:null}
            </div>
        </div>
    }

    showSubrace(race, event) {
        this.setState({showRace:true, selectedRace:race});
        event.stopPropagation();
    }

    showRace(show, race) {
        this.setState({showRace:show, selectedRace:race});
    }
}

const raceFilters=[
    {
        filterName:"Base Race",
        fieldName:"baserace",
        convertField(baserace, race) {
            const base = campaign.getRaceInfo(baserace);
            if (!base) {
                if (!race.noBaseRace) {
                    return race.displayName;
                }
                return "none"
            }
            return base.displayName||"";
        }
    },
    defaultSourceFilter,
    defaultBookFilter,
    defaultGamesystemFilter
]

function getRaceSummary(race) {
    let keyFeatures = [];
    for (let i in race.raceFeatures) {
        const f = race.raceFeatures[i];
        if (f.ability) {
            const ability = f.ability
            const alist=[];
            let all=true;
            if (ability.choose) {
                keyFeatures.push(signedNum(ability.amount||1)+" to "+Parser.numberToString(ability.choose)+((ability.choose>1)?" abilities":" ability"));
            }
            for (let a in abilityNames) {
                if (ability[a]) {
                    alist.push(signedNum(ability[a])+" to "+abilityNames[a]);
                    if (ability[a] != 1) {
                        all=false;
                    }
                } else {
                    all=false;
                }
            }
            if (all) {
                keyFeatures.push("+1 to all abilities");
            } else if (alist.length) {
                keyFeatures = keyFeatures.concat(alist);
            }
        }
        if (f.name && !["skills","tool proficiency","subraces","subrace","ability score increase","abilities", "languages", "alignment", "size", "speed", "age"].includes(f.name.toLowerCase())) {
            keyFeatures.push(f.name);
        }
    }

    return {keyFeatures, description:race.summary||race.description, subraces:race.collateList||[]};
}



class RaceSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            this.setState({selectedRace:null, defaultFilter:null});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges){
            this.props.onClose(this.state.selectedRace);
        } else if (this.state.defaultFilter){
            this.setState({defaultFilter:null});
        }else {
            this.props.onClose(null);
        }
    };

    render() {
        if (!this.props.open) {
            return null;
        }

        const races = campaign.getRaces();
        const character=this.props.character;
        const extensionEntryCheckFn = getExtensionEntryCheckFn(character,true);
        this.entryCheckFn = extensionEntryCheckFn;

        return  <Dialog
            open
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Select {character.raceText}
            </DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={races}
                    showCards
                    showAll
                    largeCard
                    render={this.renderCard.bind(this)}
                    filters={raceFilters}
                    defaultFilter={this.state.defaultFilter}
                    getListRef={this.saveRef.bind(this)}
                    collate={function (it,filter){ if (filter.baserace) return false; else return it.baserace;}}
                    entryCheckFn={extensionEntryCheckFn}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <RaceDialog open={this.state.selectedRace} race={this.state.selectedRace} noSub extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.clickRace.bind(this, null)} onClickSubrace={this.clickRace.bind(this)} disableEdit/>
        </Dialog>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(race) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(race))||{});
        const info = campaign.getRaceInfo(race);
        return <span>
            <Button disabled={!prev} onClick={prev?this.clickRace.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.clickRace.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
            <Button disabled={!info.baserace && !!info.noBaseRace} onClick={this.handleClose.bind(this,true)} color="primary">Select</Button>
        </span>
    }

    renderCard(it, width,bc) {
        const {description, keyFeatures,subraces} = getRaceSummary(it);
        const subList = [];

        if (subraces){
            subraces.sort(sortDisplayName);
        }
        for (let i in subraces) {
            const r = subraces[i];
            const entryScore= this.entryCheckFn?this.entryCheckFn(r):0;
            if (entryScore<2) {
                subList.push(<span key={r.name}>{(i>0)?", ":""}<a onClick={this.showSubrace.bind(this,r.name)} className="nowrap">{r.displayName}</a></span>);
            }
        }

        return <div key={it.name} style={{width:width-10}} className={"shadow-3 br3 ma1 pa1 stdcontent hoverhighlight overflow-hidden "+(bc||"")} onClick={subList.length?this.filterRace.bind(this,it.displayName):this.clickRace.bind(this,it.name)}>
            <div className="f1 titlecolor">{it.displayName}</div>
            <div className="f6 near-black bb titleborder mb1"><Rendersource className=" " entry={it}/></div>
            <FloatCardArt art={it.defaultArt} width={width}/>
            <Renderentry className="ll-8" entry={description}/>
            {keyFeatures.length?<div>
                <div className="b">Traits</div>
                {keyFeatures.join(", ")}
            </div>:null}
            {subList.length?<div className="mt1">
                <div className="b">Subraces</div>
                {subList}
            </div>:null}
        </div>
    }

    filterRace(race) {
        this.setState({defaultFilter:{baserace:race}});
    }

    showSubrace(selectedRace, event) {
        this.setState({selectedRace});
        event.stopPropagation();
    }

    clickRace(selectedRace){
        this.setState({selectedRace});
    }
}

class Race extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({race:campaign.getRaceInfo(this.props.race)})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "races");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "races");
    }

	render() {
        var race =campaign.getRaceInfo(this.props.race);
        var races = campaign.getRaces();
        const raceName = (this.props.race||"").toLowerCase();
        const features = [];
        if (!race){
            return <div>Could not find race {this.props.race}.</div>;
        }

        var subraces=[];
        for (var i in races){
            const r=races[i];
            if (r.baserace && (r.baserace.toLowerCase() == raceName)){
                subraces.push(<div className="mb1" key={r.name}><a onClick={this.setShowSubrace.bind(this,r.name)}>{r.displayName}</a></div>);
            }
        }

        for (let i in race.raceFeatures) {
            features.push(<div key={i} className="mb1"><RenderFeature h3 feature={race.raceFeatures[i]} noDiv/></div>);
        }

        return <div className="stdcontent mb2" key={race.name} >
            {this.props.noTitle?null:<h1>{race.displayName}</h1>}
            <FloatArt art={race.defaultArt} artList={race.artList} defaultArt={race.defaultArt}/>
            {this.props.noDescription?null:<Renderentry entry={race.description}/>}
            {features}
            {(!this.props.noSub && (subraces.length > 0))?<div>
                <h2>{race.noBaseRace?"Subraces":"Variants"}</h2>
                {subraces}
            </div>:null}
            {race.descriptionOptions?<DescriptionOptionsListEdit descriptions={race.descriptionOptions} noBackground/>:null}
            {this.props.noSource?null:<Rendersource entry={race}/>}
            <div className="cb"/>
            <RaceDialog open={this.state.showSubrace} race={this.state.showSubrace} onClose={this.setShowSubrace.bind(this,null)}/>
        </div>;
    }

    setShowSubrace(showSubrace) {
        if (showSubrace && this.props.onClickSubrace) {
            this.props.onClickSubrace(showSubrace);
            return;
        }
        this.setState({showSubrace});
    }
}

function printRace(id,noTitle,header) {
    const list=[];
    const it = campaign.getRaceInfo(id);
    if (!it) {
        return;
    }
    if (!noTitle) {
        list.push(`<h${header}>${it.displayName}</h${header}>`);
    }

    if (campaign.getSourcePreventEmbedding(it.source)) {
        list.push("<p>Not allowed to publish.</p>");
    } else {
        list.push(printFloatArt(it.defaultArt))
        if (it.description) {
            list.push(`<div>${htmlFromEntry(it.description)}</div>`);
        }
        list.push(renderPrintFeatures(it.raceFeatures, noTitle?header:header+1, true));
        list.push(printDescriptionOptions(it.descriptionOptions));
        list.push('<div style="clear: both"></div>');
    }
    return list.join("\n");
}

class RaceDialog extends React.Component {
    constructor(props) {
        super(props);

           this.state= { };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open!= prevProps.open) {
            this.setState({editable:false});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        if (this.state.editable) {
            return <EditRace race={this.props.race} open onClose={this.props.onClose}/>;
        }
        const race =campaign.getRaceInfo(this.props.race);
        const {disableEdit,extraButtonsFn} = this.props;
        const showEdit = !campaign.isSharedCampaign()&&!disableEdit;

        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>{race?.displayName}</DialogTitle>
            <DialogContent key={this.props.race}>
                <Race noTitle race={this.props.race} noSub={this.props.noSub} onClickSubrace={this.props.onClickSubrace}/>
            </DialogContent>
            <DialogActions>
                <AddChatEntry type={race?.gamesystem=="5e24"?"Species":"Race"} displayName={race?.displayName} href={getBasicHref("race",race?.name)}/>
                {extraButtonsFn && extraButtonsFn(this.props.race)}
                {showEdit?<DeleteEntry type="races" entry={race} onClose={this.props.onClose}/>:null}
                {showEdit?<Button onClick={this.clickEdit.bind(this)} color="primary">
                    Edit
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    clickEdit() {
        this.setState({editable:true});
    }
}
    
function getAbilityText(ability) {
    var trait, i;
    if (!ability || (ability.choose && ability.choose[0] && ability.choose[0].textreference))
        return null;

    if (Object.keys(ability).length == 6){
        trait = "Your ability scores each increase by 1."
    } else {
        for (i in ability){
            if (i == 'choose'){
                if (!trait)
                    trait = "Choose ";
                else
                    trait = trait+", and choose ";
                
                if ((ability[i][0].from.length == 6) && !ability[i][0].count){
                    trait = trait + "any trait by "+(ability[i][0].count || ability[i][0].amount);
                } else {
                    var x;
                    trait = trait + (ability[i][0].count || ability[i][0].amount)+" from "
                    for (x in ability[i][0].from){
                        if (x > 0)
                            trait = trait +', ';
                        trait=trait+Parser.ATB_ABV_TO_FULL[ability[i][0].from[x]];

                    }
                    trait = trait+" to increase by 1"
                }
            } else {
                if (!trait){
                    trait = "Your "+Parser.ATB_ABV_TO_FULL[i]+" increases by "+ability[i];
                } else {
                    trait = trait + ", and your "+Parser.ATB_ABV_TO_FULL[i]+" increases by "+ability[i];
                }
            }
        }
        trait=trait+'.';
    }
    return trait;
}

class RaceSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {expanded:{}, open:false};
    }

    componentDidMount() {
        this.setState({open:this.props.open||false})        
    }

	render() {
        const raceList = this.getRaceList();
        const expanded = this.state.expanded;
        const value = this.props.value;
        const options=[];
        const race = campaign.getRaceInfo(value);

        if (this.state.open) {
            for (let i in raceList) {
                const br = raceList[i];
                if ((br.subList.length == 1) && (br.name==br.subList[0].name)) {
                    options.push(<ListItem dense button key={br.name} selected={value==br.name} onClick={this.onChange.bind(this, br.name)}>
                        <span className="mv2 f3">{br.displayName}</span>
                    </ListItem>);
                } else {
                    const subopt = [];
                    const open = expanded[br.name]||false;

                    for (let x in br.subList) {
                        const r = br.subList[x];
                        subopt.push(<ListItem dense button key={r.name} selected={value==r.name} onClick={this.onChange.bind(this, r.name)}>
                            <span className="pl2 mv2 f3">{r.displayName}</span>
                        </ListItem>);
                    }
                    options.push(<ListItem dense button key={br.name} onClick={this.toggelExpand.bind(this, br.name)}>
                        <span className="flex-auto mv2 f3">{br.displayName}</span>
                        {open ? <span className="f3 fas fa-caret-up"/> : <span className="f3 fas fa-caret-down"/>}
                    </ListItem>);
                    options.push(<Collapse in={open} timeout="auto" unmountOnExit key={br.name+"options"}>
                        <List component="div" disablePadding>
                            {subopt}
                        </List>
                    </Collapse>);
                }
            }
        }

        return <span >
                <span className="hoverhighlight" onClick={this.toggelOpen.bind(this)}  ref={this.rememberAnchor.bind(this)}>{race && race.displayName} <span className="br1 fas fa-caret-down"/></span>
                <Popover
                    open={this.state.open}
                    anchorEl={this.anchorEl}
                    onClose={this.toggelOpen.bind(this)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <List
                        component="nav"
                        className="minw55"
                    >
                        {options}
                    </List>
                </Popover>
        </span>;
    }

    rememberAnchor(ref){
        this.anchorEl = ref;
    }
    

    toggelOpen(event) {
        const open = !this.state.open;
        this.setState({open});
    }

    onChange(name) {
        this.setState({open:false});
        this.props.onChange(name);
    }

    toggelExpand(name) {
        const expanded = Object.assign({}, this.state.expanded);
        expanded[name] = !expanded[name];
        this.setState({expanded});
    }

    getRaceList() {
        const races = campaign.getRaces();
        const raceList=[];
       
        for (var i in races){
            const r=races[i];
            if (!r.noBaseRace || r.baserace) {
                const findRace = (r.baserace || r.name).toLowerCase();

                const pos = raceList.findIndex(function (a) {return a.name.toLowerCase() == findRace});

                if (pos >= 0) {
                    raceList[pos].subList.push(r);
                } else {
                    const br = campaign.getRaceInfo(r.baserace || r.name) || r;
                    raceList.push({name:br.name, displayName:br.displayName, subList:[r]});
                }
            }
        }

        raceList.sort(function(a,b) {return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())});
        for (let i in raceList) {
            raceList[i].subList.sort(function(a,b) {return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())});
        }
        return raceList;
    }
}

class EditRace extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {raceInfo:campaign.getRaceInfo(props.race)};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.race != prevProps.race)||(this.props.open && this.props.open!= prevProps.open)) {
            this.setState({raceInfo:campaign.getRaceInfo(this.props.race)});
        }
    }

    render() {
        const race = this.state.raceInfo;
        if (!this.props.open || !race) {
            return null;
        }
        const baseRace = race.baserace?campaign.getRaceInfo(race.baserace):null;

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>
                <TextVal    
                    text={race.displayName}
                    fullWidth
                    inputProps={{className:"f1 titletext titlecolor ignoreDrag"}}
                    onChange={this.onChangeField.bind(this,"displayName")}
                />
            </DialogTitle>
            <DialogContent className="stdcontent overflow-x-hidden">
                {race.baserace?null:<SelectVal className="mb1" value={race.noBaseRace?1:0} isNum values={{0:"Base race is selectable", 1:"Base race is NOT selectable"}} onClick={this.onChangeField.bind(this,"noBaseRace")} fullWidth/>}
                {race.baserace?null:<div className="hk-well mb1">
                    If characters can only select subraces, for example Elf or Dwarf, then you should set the base race to NOT selectable. 
                    If characters can select the race, for example Human, then set the base race as selectable.
                </div>}
                <SelectVal className="mb1" value={race.size} helperText="size" values={sizeSelectList} onClick={this.onChangeField.bind(this,"size")} fullWidth/>
                <EntityEditor onChange={this.onChangeField.bind(this,"description")} entry={race.description} placeholder="Description"/>
                <h3>Summary</h3>
                <EntityEditor onChange={this.onChangeField.bind(this,"summary")} entry={race.summary} placeholder="Summary"/>
                <FeatureListEdit 
                    editable
                    features={race.raceFeatures}
                    onChange={this.onChangeField.bind(this, "raceFeatures")}
                    displayType="Race"
                    type={baseRace?baseRace.displayName:race.displayName}
                    subtype={baseRace?race.displayName:null}
                    name={race.name}
                />
                <h2>Character Description Options</h2>
                <DescriptionOptionsListEdit descriptions={race.descriptionOptions} onChange={this.onChangeField.bind(this, "descriptionOptions")} editable />
                <div className="hoverhighlight" onClick={this.editNames.bind(this,"femaleNames")}>
                    <h2>Female Names</h2>
                    {(race.femaleNames||[]).join(", ")}
                </div>
                <div className="hoverhighlight" onClick={this.editNames.bind(this,"maleNames")}>
                    <h2>Male Names</h2>
                    {(race.maleNames||[]).join(", ")}
                </div>
                <div className="hoverhighlight" onClick={this.editNames.bind(this,"familyNames")}>
                    <h2>Family Names</h2>
                    {(race.familyNames||[]).join(", ")}
                </div>
                <h2>Artwork</h2>
                <ExtraArtList artList={race.artList} onChange={this.onChangeArtwork.bind(this)} pickArt defaultArt={race.defaultArt}/>
                <div className="pt2">
                    <SelectVal value={race.gamesystem||"5e"} values={gamesystemRaceOptions} onClick={this.onChangeField.bind(this,"gamesystem")} helperText="Game System"/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.showExtraArt.bind(this)} color="primary">
                    Select Artwork
                </Button>
                <DeleteEntry type="races" entry={race} onClose={this.props.onClose}/>
                <Button onClick={this.saveData.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <EditNamesList open={this.state.showEditNames} values={this.state.nameValues} onClose={this.doneEditingList.bind(this)}/>
            <ArtZoomList editable open={this.state.showExtraArtDialog} artList={race.artList} onClose={this.onSaveArtwork.bind(this)} pickArt defaultArt={race.defaultArt} defaultSearch={race.displayName}/>
        </Dialog>;
    }

    showExtraArt() {
        this.setState({showExtraArtDialog:true});
    }

    onSaveArtwork(artList, defaultArt) {
        if (artList) {
            this.onChangeArtwork(artList, defaultArt);
        }
        this.setState({showExtraArtDialog:false})
    }

    editNames(nameType) {
        const race = this.state.raceInfo;
        this.setState({showEditNames:true, nameValues:race[nameType]||[], nameType});
    }

    doneEditingList(values) {
        if (values) {
            if (!values.length) {
                values=null;
            }
            this.onChangeField(this.state.nameType, values);
        }
        this.setState({showEditNames:false});
    }

    saveData() {
        campaign.updateCampaignContent("races", this.state.raceInfo);
        this.props.onClose();
    }

    onChangeField(field,val) {
        const raceInfo = Object.assign({}, this.state.raceInfo);
        raceInfo[field] = val;
        this.setState({raceInfo});
    }

    onChangeArtwork(artList, defaultArt) {
        const raceInfo = Object.assign({}, this.state.raceInfo)
        raceInfo.artList = artList;
        if (defaultArt) {
            raceInfo.defaultArt = defaultArt;
        } else {
            delete raceInfo.defaultArt;
        }
        this.setState({raceInfo})
    }
}

class EditNamesList extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({text:(this.props.values||[]).join('\n')});
        }
    }

    handleClose(savechanges) {
        if (savechanges) {
            const values = this.state.text.split('\n');

            for (let i=values.length-1; i>=0; i--) {
                if (values[i]=="") {
                    values.splice(i,1);
                }
            }

            this.props.onClose(values);
        } else {
            this.props.onClose();
        }
    };

    onChange(event) {
        const val = event.target.value;
        this.setState({text:val});
    }

    render() {
        if (!this.props.open)
            return null;
        
        return <Dialog
            open
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Names</DialogTitle>
            <DialogContent>
                <div className="mb2 titletext titlecolor f2">Each line will be a name.</div>
                <TextField
                    multiline
                    className="minvh-60"
                    value={this.state.text||""}
                    onChange={this.onChange.bind(this)}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

const emptyRace =     {
    "size": "M",
    "raceFeatures": [
      {
        "name": "Ability Score Increase",
        auto:true
      },
      {
        "name": "Speed",
        auto:true
      },
      {
        "name": "Age",
        auto:true
      },
      {
        "name": "Alignment",
        auto:true
      },
      {
        "name": "Size",
        auto:true
      },
      {
        "name": "Languages",
        auto:true
      }
    ]
}

class NewRace extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    createBaseRace() {
        const name = this.state.name;
        const selectedRace = this.state.selectedRace;
        let newRace= Object.assign({}, selectedRace?campaign.getRaceInfo(selectedRace):emptyRace);

        newRace.displayName=name;
        newRace.name = campaign.newUid();
        delete newRace.source;
        delete newRace.page;
        delete newRace.base;
        campaign.updateCampaignContent("races", newRace);
        this.props.onClose(newRace.name);
    }

    createSubrace() {
        const name = this.state.name;
        const selectedRace = this.state.selectedSubrace|| this.state.selectedRace;
        let newRace= Object.assign({}, campaign.getRaceInfo(selectedRace));

        newRace.displayName=name;
        newRace.baserace = this.state.selectedRace;
        newRace.name = campaign.newUid();
        delete newRace.source;
        delete newRace.page;
        delete newRace.base;

        campaign.updateCampaignContent("races", newRace);
        this.props.onClose(newRace.name);
    }

    onClose() {
        this.props.onClose();
    }

    onChange(event) {
        if (/[^\n]*/.exec(event.target.value) == event.target.value) {
            this.setState({name:event.target.value});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({name:""});
        }
    }

    render() {
        if (!this.props.open)
            return null;

        const races = campaign.getRaces();
        const raceList=[];
        const subraceList=[];
        const selectedRace = this.state.selectedRace;
        const name=this.state.name||"";
        const race = campaign.getRaceInfo(selectedRace);
        const subrace=campaign.getRaceInfo(this.state.selectedSubrace);

        for (var i in races){
            const r=races[i];
            if (!r.baserace) {
                raceList.push({value:r.name, name:r.displayName});
            }
            if (selectedRace && (r.baserace == selectedRace) || r.name==selectedRace) {
                subraceList.push({value:r.name, name:r.displayName});
            }
        }

        raceList.sort();
        raceList.unshift({value:"Empty Template", name:"Empty Template"})
        if (subraceList.length) {
            subraceList.sort();
            subraceList.unshift({value:race.name, name:race.displayName});
        }
    
        const raceText = campaign.raceText;
        return <Dialog
            open
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle onClose={this.onClose.bind(this)}>
                Create {raceText}
            </DialogTitle>
            <DialogContent>
                <TextField
                    value={name}
                    onChange={this.onChange.bind(this)}
                    margin="normal"
                    label={raceText+" Name"}
                    fullWidth
                />
                <div className="mv1">
                    <SelectVal fullWidth noteText label={"Base "+raceText} value={selectedRace||"Empty Template"} values={raceList} onClick={this.changeBaseRace.bind(this)}/>
                </div>
                <div>
                    <Button disabled={!name || name==""} onClick={this.createBaseRace.bind(this)} color="primary">
                        {race?("Create New "+raceText+" Based on "+race.displayName):"Create from Scratch"}
                    </Button>
                </div>
                {race?<div className="mv1">
                    <SelectVal fullWidth noteText label={(race&&!race.noBaseRace)?"Subrace Template":"Variant Template"} value={this.state.selectedSubrace||selectedRace} values={subraceList} onClick={this.changeSubrace.bind(this)}/>
                    <Button disabled={!name || name==""} onClick={this.createSubrace.bind(this)} color="primary">
                        {(race.noBaseRace?"Create New Subrace Based on ":"Create New Variant Based on ")+((subrace&&subrace.displayName)||race.displayName)}
                    </Button>
                </div>:null}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    changeBaseRace(selectedRace) {
        if (selectedRace=="Empty Template") {
            selectedRace = null;
        }
        this.setState({selectedRace});
    }

    changeSubrace(selectedSubrace) {
        this.setState({selectedSubrace});
    }
}

class RacesHeader extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onNew() {
        this.setState({showNew:true});
    }

    render() {
        return <span>
            {campaign.racesText} 
            {!campaign.isSharedCampaign()?<Button className="ml2 minw2" color="primary" variant="outlined" size="small" onClick={this.onNew.bind(this)}>New</Button>:null}
            <NewRace open={this.state.showNew} onClose={this.closeNew.bind(this)}/>
            <EditRace race={this.state.showRace} open={this.state.showRace} onClose={this.doneEditing.bind(this)}/>
        </span>;
    }

    doneEditing() {
        this.setState({showRace:null});
    }

    closeNew(name) {
        this.setState({showNew:false});
        if (name) {
            this.setState({showRace:name});
        }
    }
}

export {
    Renderraces,
    Race,
    printRace,
    getAbilityText,
    RaceDialog,
    RaceSelect,
    NewRace,
    RacesHeader,
    RaceSelector,
    EditRace
}