const React = require('react');
const ReactDOM = require('react-dom');
import Popper from '@material-ui/core/Popper';


class HoverMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            inPopper:false,
            inMain:false,
            open:false
        };
    }

    componentDidMount() {
    }
  
    componentWillUnmount() {
    }

	render() {
        if (this.props.hidden) {
            return null;
        }
        
        return <div onMouseEnter={this.onEnterMain.bind(this)} onMouseLeave={this.onLeaveMain.bind(this)}>
            <div ref={this.saveRef.bind(this)} onClick={this.mouseDown.bind(this)}>
                {this.props.buttonMenu}
            </div>
            <Popper className="z-9999" open={this.state.open} placement={this.props.placement || "right-start"} anchorEl={this.anchorEl} onClick={this.props.closeOnClick?this.mouseDown.bind(this):null} >
                {this.props.children}
            </Popper>
        </div>;
    }

    mouseDown() {
        this.updateOpenState(!this.state.open);
    }

    onEnterMain() {
        if (this.props.disabled)
            return;
        this.updateOpenState(true);
    }

    onLeaveMain() {
        this.updateOpenState(false);
    }

    updateOpenState(open) {
        this.setState({open:open});
        if (this.props.onShowChange)
            this.props.onShowChange(open);
    }

    saveRef(r) {
        this.anchorEl=r;
    }

    close() {
        this.updateOpenState(false);
    }
}

export {
    HoverMenu
};