const React = require('react');
const {campaign,globalDataListener,getDirectDownloadUrl} = require('../lib/campaign.js');
const {marketplace} = require('../lib/marketplace.js');
const {firebase} = require("./firebase.jsx");
import { getStorage, ref,uploadBytes } from "firebase/storage";
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
const {DeleteWithConfirm, TextVal, SelectVal, CheckVal, getAnchorPos,escapeRegExp, defaultSourceFilter,defaultBookFilter} = require('./stdedit.jsx');
const {displayMessage} = require('./notification.jsx');

class BannerStart extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.state= {startAds:campaign.startAds, current:0};
        this.rotate=false;
    }

    componentDidMount() {
        this.timer = setInterval(this.timer.bind(this), 8*1000);
        globalDataListener.on("startads", this.handleOnDataChange);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
        globalDataListener.removeListener("startads", this.handleOnDataChange);
    }

    onDataChange() {
        this.setState({startAds:campaign.startAds, current:0});
    }

    timer() {
        if (!this.doRotate) {
            return;
        }
        const ads = (this.state.startAds||{}).ads||[];
        let current = this.state.current+1;
        while ((!ads[current]||!ads[current].imageUrl||!ads[current].link) && (current != this.state.current)){
            current++;
            if (current >= ads.length) {
                current=0;
            }
        }
        this.setState({current})
    }

    render() {
        const ads = (this.state.startAds||{}).ads||[];
        const current=this.state.current;
        const width=window.innerWidth;
        const maxH = (width <500)?width/5:100;
        let show1;
        let i=current;
        this.doRotate=false;
        const hasSub = campaign.isOwned("2yarsenaw01v6pj7", "0mgps243ynv32dvk", "1lptzx3bblfjy4bm");

        if (ads.length) {
            do {
                const a = ads[i];
                if (a.imageUrl && a.link && (!a.subscription || (a.subscription=="always") || (hasSub && a.subscription=="sub") || (!hasSub && a.subscription=="nosub"))) {
                    if (!show1) {
                        show1=a;
                    } else {
                        this.doRotate=true;
                    }
                }
                i++;
                if (i>=ads.length) {
                    i=0;
                }
            } while (i!=current);
        }

        if (show1) {
            return <div className="tc">
                <div className="dib banner" style={{maxHeight:maxH}}>
                    <img height={maxH} src={show1.imageUrl} onClick={navTo.bind(null,show1.link)}/>
                </div>
            </div>;
        }

        return null;
    }
}

function navTo(name) {
    window.location.href = name;
}


const maxImages = 15;
class StartAdsDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state={loading:false};
        this.idKey = campaign.newUid();
    }

    componentDidMount() {
        if (this.props.open && this.props.file) {
            this.loadFile(this.props.file);
        }
        if (this.props.open && this.props.webURL) {
            this.selectFromWeb(this.props.webURL);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.open != this.props.open) && this.props.open) {
            this.setState({ads:campaign.startAds.ads||[]});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        const ads = this.state.ads||[];
        const list=[];

        for (let i=0; i<maxImages; i++) {
            const ad = ads[i]||{};
            const id = "ads."+this.idKey+i;

            list.push(<div key={i} className="mb2 titlecolor titleborder ba br1 pa1">
                <div className="f3 titletext">
                    Ad {i+1} 
                    <span className="fas fa-arrow-up pa1" onClick={this.move.bind(this,i,-1)}/>
                    <span className="fas fa-arrow-down pa1" onClick={this.move.bind(this,i,1)}/>
                    <SelectVal values={adSubOpts} value={ad.subscription||"always"} onClick={this.updateAd.bind(this,i,"subscription")} helperText="Show ads"/>
                </div>
                <div><TextVal text={ad.link||""} onChange={this.updateAd.bind(this, i, "link")} fullWidth helperText="Ad link"/></div>
                <div>
                    <input
                        key={id}
                        accept="image/*"
                        className="dn"
                        id={id}
                        type="file"
                        onChange={this.uploadFile.bind(this,i)}
                    />
                    <label htmlFor={id}>
                        {ad.imageUrl?<img height="100px" src={ad.imageUrl}/>:<Button  color="primary" component="span">Upload</Button>}
                    </label>
                    
                </div>      
            </div>);
        }

        return <Dialog 
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>Start Page Ads</DialogTitle>    
            <DialogContent>
                {list}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onSave.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <Dialog open={this.state.loading}>
                <DialogContent>
                    Loading Image...
                </DialogContent>
            </Dialog>
        </Dialog>
    }

    onSave() {
        const startAds = Object.assign({}, campaign.startAds);
        startAds.ads = this.state.ads;
        campaign.updateStartAds(startAds);
        this.props.onClose();
    }

    async uploadFile(i, e) {
        this.idKey = campaign.newUid();

        if (e.target.files.length == 1) {
            try {
                const file = e.target.files[0];
                const storage = getStorage(firebase);
                const name = "ads/adImage."+campaign.newUid();
                const metadata = {contentType:file.type,cacheControl: "public,max-age=4838400"}

                this.setState({loading:true}); 
                const fileRef = ref(storage,name);
                await uploadBytes(fileRef,file,metadata);
                const imageUrl = getDirectDownloadUrl(name);
                this.updateAd(i, "imageUrl", imageUrl);
                this.setState({loading:false});
            } catch (err) {
                console.log("Error uploading image:",err)
                displayMessage("Error uploading image:"+err.message);
                this.setState({loading:false});
            }
        }
    }

    updateAd(i, prop, val) {
        const ads = (this.state.ads||[]).concat([]);
        const ad = Object.assign({}, ads[i]||{});
        ad[prop]=val;
        ads[i]=ad;
        this.setState({ads});
    }

    move(i, direction) {
        const ads = (this.state.ads||[]).concat([]);
        const si = i+direction;
        if ((si < 0) || (si >=maxImages)){
            return;
        }
        const adCur = ads[i]||{};
        ads[i] = ads[si]||{};
        ads[si]=adCur;
        this.setState({ads});
    }
}

const adSubOpts = {
    always:"Always",
    sub:"With Subscription",
    nosub:"Without Subscription"
}

class FeaturedProductSettings extends React.Component {
    constructor(props) {
        super(props);
        const t=this;

        this.state={};
    }

    componentDidMount() {
        if (this.props.open) {
            this.getFeaturedSetting();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.open != this.props.open) && this.props.open) {
            this.getFeaturedSetting();
        }
    }

    getFeaturedSetting() {
        const featured = marketplace.getFeaturedAds()[this.props.product]||{};
        this.setState({featured});
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        const featured = this.state.featured||{};

        return <Dialog 
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>Product Featured Settings</DialogTitle>    
            <DialogContent>
                <TextVal isNum text={featured.weight||0} onChange={this.updateValue.bind(this, "weight")} fullWidth helperText="Featured Weight"/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onSave.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    }

    onSave() {
        const featuredAds = Object.assign({}, marketplace.getFeaturedAds());
        featuredAds[this.props.product] = this.state.featured;
        marketplace.setFeatured(featuredAds);
        this.props.onClose();
    }

    updateValue(prop, val) {
        const featured = Object.assign({}, this.state.featured);
        featured[prop]=val;
        this.setState({featured});
    }
}

/*
class ShardWelcome extends React.Component {
    constructor(props) {
        super(props);
        const t=this;

        this.state={};
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        return <Dialog 
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>Welcome</DialogTitle>    
            <DialogContent>
                <div className="stdcontent">
                    <div className="mb2">Thank you for joining Shard Tabletop. To learn about Shard see our <a href="https://shardtabletop.com/learning">learning page</a>.</div>
                    <div className="mb2">The free forever version provides everything you need to get started as a player or gamemaster including character options, monsters with tokens, and an introductory collection of adventures.</div>
                    <div className="mb2">To get even more out of Shard Tabletop, consider adding a subscription. All of our subscriptions include free products from our marketplace and the core content pack that adds 12 playable races, 48 subclasses, plus numerous backgrounds and feats.</div>
                    <div>Subscription Options:</div>
                    <ul>
                        <li><b><a href="https://play.shardtabletop.com/marketplace#product?id=1lptzx3bblfjy4bm">Adventurer:</a></b> Ideal for players or beginner gamemasters, includes unlimited characters and 2 campaigns.</li>
                        <li><b><a href="https://play.shardtabletop.com/marketplace#product?id=0mgps243ynv32dvk">Gamemaster:</a></b> Ideal for most Gamemasters, includes all the tools you will need to manage up to 6 campaigns.</li>
                        <li><b><a href="https://play.shardtabletop.com/marketplace#product?id=2yarsenaw01v6pj7">Gamemaster Pro:</a></b> The top tier includes everything a serious Gamemaster needs to manage all their campaigns, includes monthly bonus content to inspire your game.</li>
                    </ul>
                    <div>View our <a href="https://play.shardtabletop.com/marketplace#shardsubscriptions">subscription comparison</a> for more details.  See the <a href="https://play.shardtabletop.com/marketplace">marketplace</a> to find options for your next game.</div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    close
                </Button>
            </DialogActions>
        </Dialog>
    }
}
*/

export {
    BannerStart,
    StartAdsDialog,
    FeaturedProductSettings
}