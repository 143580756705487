// ************************************************************************* //
// Strict mode should not be used, as the roll20 script depends on this file //
// Do not use classes                                                        //
// ************************************************************************* //

let STR_CANTRIP = "Cantrip";
let STR_NONE = "None";
let STR_ANY = "Any";

let RNG_SPECIAL = "special";
let RNG_POINT = "point";
let RNG_LINE = "line";
let RNG_CUBE = "cube";
let RNG_CONE = "cone";
let RNG_RADIUS = "radius";
let RNG_SPHERE = "sphere";
let RNG_HEMISPHERE = "hemisphere";
let RNG_SELF = "self";
let RNG_SIGHT = "sight";
let RNG_UNLIMITED = "unlimited";
let RNG_UNLIMITED_SAME_PLANE = "plane";
let RNG_TOUCH = "touch";

let UNT_FEET = "feet";
let UNT_MILES = "miles";

// PARSING =============================================================================================================
let Parser = {};
Parser._parse_aToB = function (abMap, a, fallback) {
	if (a === undefined || a === null) throw new Error("undefined or null object passed to parser");
	if (typeof a === "string") a = a.trim();
	if (abMap[a] !== undefined) return abMap[a];
	return fallback || a;
};

Parser._parse_bToA = function (abMap, b) {
	if (b === undefined || b === null) throw new Error("undefined or null object passed to parser");
	if (typeof b === "string") b = b.trim();
	for (const v in abMap) {
		if (!abMap.hasOwnProperty(v)) continue;
		if (abMap[v] === b) return v;
	}
	return b;
};

Parser.attrChooseToFull = function (attList) {
	if (attList.length === 1) return `${Parser.attAbvToFull(attList[0])} modifier`;
	else {
		const attsTemp = [];
		for (let i = 0; i < attList.length; ++i) {
			attsTemp.push(Parser.attAbvToFull(attList[i]));
		}
		return `${attsTemp.join(" or ")} modifier (your choice)`;
	}
};

Parser.numberToText = function (number) {
	if (Math.abs(number) >= 100) return number;

	function getAsText (num) {
		const abs = Math.abs(num);
		switch (abs) {
			case 0: return "zero";
			case 1: return "one";
			case 2: return "two";
			case 3: return "three";
			case 4: return "four";
			case 5: return "five";
			case 6: return "six";
			case 7: return "seven";
			case 8: return "eight";
			case 9: return "nine";
			case 10: return "ten";
			case 11: return "eleven";
			case 12: return "twelve";
			case 13: return "thirteen";
			case 14: return "fourteen";
			case 15: return "fifteen";
			case 16: return "sixteen";
			case 17: return "seventeen";
			case 18: return "eighteen";
			case 19: return "nineteen";
			case 20: return "twenty";
			case 30: return "thirty";
			case 40: return "forty";
			case 50: return "fiddy"; // :^)
			case 60: return "sixty";
			case 70: return "seventy";
			case 80: return "eighty";
			case 90: return "ninety";
			default: {
				const str = String(abs);
				return `${getAsText(Number(`${str[0]}0`))}-${getAsText(Number(str[1]))}`;
			}
		}
	}
	return `${number < 0 ? "negative " : ""}${getAsText(number)}`;
};

Parser.numberToTextTh = function (number) {
	if (number < 0) return number;
	const numMap = [0, "1st", "2nd", "3rd"];
	if (number > 3) return (number+"th");
	
	return numMap[number];
};

Parser.attAbvToFull = function (abv) {
	return Parser._parse_aToB(Parser.ATB_ABV_TO_FULL, abv);
};

Parser.attFullToAbv = function (full) {
	return Parser._parse_bToA(Parser.ATB_ABV_TO_FULL, full);
};

Parser.sizeAbvToFull = function (abv) {
	if (!abv) return "";
	return Parser._parse_aToB(Parser.SIZE_ABV_TO_FULL, abv);
};

Parser.getAbilityModNumber = function (abilityScore) {
	return Math.floor((abilityScore - 10) / 2);
};

Parser.getAbilityModifier = function (abilityScore) {
	let modifier = Parser.getAbilityModNumber(abilityScore);
	if (modifier >= 0) modifier = "+" + modifier;
	return modifier;
};

/*
Parser.getSpeedString = (it) => {
	function procSpeed (propName) {
		function addSpeed (s) {
			stack.push(`${propName === "walk" ? "" : `${propName} `}${getVal(s)} ft.${getCond(s)}`);
		}

		if (it.speed[propName] || propName === "walk") addSpeed(it.speed[propName] || 0);
		if (it.speed.alternate && it.speed.alternate[propName]) it.speed.alternate[propName].forEach(addSpeed);
	}

	function getVal (speedProp) {
		return (typeof speedProp == "object")?(speedProp.number||0):speedProp;
	}

	function getCond (speedProp) {
		return speedProp.condition ? ` ${speedProp.condition}` : "";
	}

	const stack = [];
	if (typeof it.speed === "object") {
		let joiner = ", ";
		procSpeed("walk");
		procSpeed("burrow");
		procSpeed("climb");
		procSpeed("fly");
		procSpeed("swim");
		if (it.speed.choose) {
			joiner = "; ";
			stack.push(`${it.speed.choose.from.sort().joinConjunct(", ", " or ")} ${it.speed.choose.amount} ft.${it.speed.choose.note ? ` ${it.speed.choose.note}` : ""}`);
		}
		return stack.join(joiner);
	} else {
		return it.speed + (it.speed === "Varies" ? "" : " ft. ");
	}
};
*/

Parser._addCommas = function (intNum) {
	return (intNum + "").replace(/(\d)(?=(\d{3})+$)/g, "$1,");
};

Parser.crToXp = function (cr) {
	if (cr === "Unknown" || cr === undefined) return "Unknown";
	if (cr === "0") return "0 or 10";
	if (cr === "1/8") return "25";
	if (cr === "1/4") return "50";
	if (cr === "1/2") return "100";
	return Parser._addCommas(Parser.XP_CHART[parseInt(cr) - 1]);
};

Parser.crToXpNumber = function (cr) {
	if (cr === "Unknown" || cr === undefined) return null;
	return Parser.XP_CHART_ALT[cr];
};

let LEVEL_TO_XP_EASY = [0, 25, 50, 75, 125, 250, 300, 350, 450, 550, 600, 800, 1000, 1100, 1250, 1400, 1600, 2000, 2100, 2400, 2800];
let LEVEL_TO_XP_MEDIUM = [0, 50, 100, 150, 250, 500, 600, 750, 900, 1100, 1200, 1600, 2000, 2200, 2500, 2800, 3200, 3900, 4100, 4900, 5700];
let LEVEL_TO_XP_HARD = [0, 75, 150, 225, 375, 750, 900, 1100, 1400, 1600, 1900, 2400, 3000, 3400, 3800, 4300, 4800, 5900, 6300, 7300, 8500];
let LEVEL_TO_XP_DEADLY = [0, 100, 200, 400, 500, 1100, 1400, 1700, 2100, 2400, 2800, 3600, 4500, 5100, 5700, 6400, 7200, 8800, 9500, 10900, 12700];

Parser.CRS = ["0", "1/8", "1/4", "1/2", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"];
Parser.crsortVals = [0, .125, .25, .5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];

Parser.levelToXpThreshold = function (level) {
	return [LEVEL_TO_XP_EASY[level], LEVEL_TO_XP_MEDIUM[level], LEVEL_TO_XP_HARD[level], LEVEL_TO_XP_DEADLY[level]];
};

Parser.isValidCr = function (cr) {
	return Parser.CRS.includes(cr);
};

Parser.crToNumber = function (cr) {
	if (cr === "Unknown" || cr == null) return 100;
	if (cr.cr) return Parser.crToNumber(cr.cr);
	const parts = cr.trim().split("/");
	if (parts.length === 1) return Number(parts[0]);
	else if (parts.length === 2) return Number(parts[0]) / Number(parts[1]);
	else return 0;
};

Parser._greatestCommonDivisor = function (a, b) {
	if (b < Number.EPSILON) return a;
	return Parser._greatestCommonDivisor(b, Math.floor(a % b));
};
Parser.numberToCr = function (number) {
	const len = number.toString().length - 2;
	let denominator = Math.pow(10, len);
	let numerator = number * denominator;
	const divisor = Parser._greatestCommonDivisor(numerator, denominator);
	numerator = Math.floor(numerator / divisor);
	denominator = Math.floor(denominator / divisor);

	return denominator === 1 ? String(numerator) : `${Math.floor(numerator)}/${Math.floor(denominator)}`;
};

Parser.crToPb = function (cr) {
	if (cr === "Unknown" || cr == null) return 0;
	cr = cr.cr || cr;
	if (Parser.crToNumber(cr) < 5) return 2;
	return Math.ceil(cr / 4) + 1;
};

Parser.SKILL_TO_ATB_ABV = {
	"athletics": "str",
	"acrobatics": "dex",
	"sleight of hand": "dex",
	"stealth": "dex",
	"arcana": "int",
	"history": "int",
	"investigation": "int",
	"nature": "int",
	"religion": "int",
	"animal handling": "wis",
	"insight": "wis",
	"medicine": "wis",
	"perception": "wis",
	"survival": "wis",
	"deception": "cha",
	"intimidation": "cha",
	"performance": "cha",
	"persuasion": "cha"
};

Parser.skillToAbilityAbv = function (skill) {
	return Parser._parse_aToB(Parser.SKILL_TO_ATB_ABV, skill);
};

Parser.dragonColorToFull = function (c) {
	return Parser._parse_aToB(DRAGON_COLOR_TO_FULL, c);
};

let DRAGON_COLOR_TO_FULL = {
	B: "black",
	U: "blue",
	G: "green",
	R: "red",
	W: "white",
	A: "brass",
	Z: "bronze",
	C: "copper",
	O: "gold",
	S: "silver"
};

Parser.acToFull = function (ac) {
	if ((typeof ac === "string") || (typeof ac === "number")) {
		return ac; // handle classic format
	}

	let stack = "";
	for (let i = 0; i < ac.length; ++i) {
		const cur = ac[i];
		const nxt = ac[i + 1];

		if (cur.ac) {
			stack += cur.ac;
			if (cur.from) {
				const f = cur.from.join(", ");
				if (f.length) {
					if (f.startsWith("(")) {
						stack += (" "+f);
					} else {
						stack += (" ("+f+")");
					}
				}
			} 
			if (cur.condition) stack += ` ${cur.condition}`;
			if (cur.braces) stack += ")";
		} else {
			stack += (((typeof cur=="object")?cur?.from:cur)||"");
		}

		if (nxt) {
			if (nxt.braces) stack += " (";
			else stack += ", ";
		}
	}

	return stack.trim();
};

Parser.acToStruc = function (ac) {
	if (!ac || (typeof ac === "string") || (typeof ac === "number")) return {ac:ac, extra:""}; // handle classic format
	let firstac;

	let stack = "";
	for (let i = 0; i < ac.length; ++i) {
		const cur = ac[i];
		const nxt = ac[i + 1];

		if (typeof cur.ac == "number") {
			let temp="";

			if (!firstac)
				firstac = cur.ac;
			else
				stack += cur.ac+" ";
			if (cur.from) temp += `${cur.from.join(", ")}`;
			if (cur.condition) temp += ` ${cur.condition}`;
			temp = temp.replace(/\|[^{]*}/g, "");
			temp = temp.replace(/}/g, "");
			temp = temp.replace(/{@\w* /g, "");
			stack = stack+temp.trim();
		} else {
			if (!firstac && (typeof cur == "number"))
				firstac = cur;
			else
				stack += cur;
		}

		if (nxt && stack) {
			stack += ", ";
		}
	}

	return {ac:firstac, extra:stack.trim()};
};

Parser.getDice = function (dice) {
	if (!dice)
		return ({number:"", sides:"", bonus:""});
	let pos = dice.indexOf('d');
	let pos2 = dice.indexOf(' ');
	let number = dice.substr(0, pos), sides, bonus="";

	if (pos2 <0) {
		pos2 = dice.indexOf('+');
	}
	if (pos2 <0) {
		pos2 = dice.indexOf('-');
	}
	if (pos2 >=0) {
		sides = dice.substr(pos+1, pos2-pos-1);
		bonus = dice.substr(pos2+1).replace(" ", "");
	} else {
		sides = dice.substr(pos+1);
	}

	return {number:number, sides:sides, bonus:bonus};
}

let MONSTER_COUNT_TO_XP_MULTIPLIER = [1, 1.5, 2, 2, 2, 2, 2.5, 2.5, 2.5, 2.5, 3, 3, 3, 3, 4];
Parser.numMonstersToXpMult = function (num, playerCount = 3) {
	const baseVal = (() => {
		if (num >= MONSTER_COUNT_TO_XP_MULTIPLIER.length) return 4;
		return MONSTER_COUNT_TO_XP_MULTIPLIER[num - 1];
	})();

	if (playerCount < 3) return baseVal >= 3 ? baseVal + 1 : baseVal + 0.5;
	else if (playerCount > 5) {
		return baseVal === 4 ? 3 : baseVal - 0.5;
	} else return baseVal;
};

Parser._decimalSeparator = (0.1).toLocaleString().substring(1, 2);
Parser._numberCleanRegexp = Parser._decimalSeparator === "." ? new RegExp(/[\s,]*/g, "g") : new RegExp(/[\s.]*/g, "g");
Parser._costSplitRegexp = Parser._decimalSeparator === "." ? new RegExp(/(\d+(\.\d+)?)([csegp]p)/) : new RegExp(/(\d+(,\d+)?)([csegp]p)/);

Parser.weightValueToNumber = function (value) {
	if (!value) return 0;
	// handle oddities
	if (/[x×]\s*\d+/i.test(value.trim())) return 0;

	if (Number(value)) return Number(value);
	else throw new Error(`Badly formatted value ${value}`);
};

Parser.dmgTypeToFull = function (dmgType) {
	return Parser._parse_aToB(Parser.DMGTYPE_JSON_TO_FULL, dmgType);
};

Parser.skillToExplanation = function (skillType) {
	return Parser._parse_aToB(Parser.SKILL_JSON_TO_FULL, skillType);
};

Parser.actionToExplanation = function (actionType) {
	return Parser._parse_aToB(Parser.ACTION_JSON_TO_FULL, actionType, ["No explanation available."]);
};

Parser.senseToExplanation = function (senseType) {
	senseType = senseType.toLowerCase();
	return Parser._parse_aToB(Parser.SENSE_JSON_TO_FULL, senseType, ["No explanation available."]);
};

Parser.numberToString = function (num) {
	if (num === 0) return "zero";
	else return parse_hundreds(num);

	function parse_hundreds (num) {
		if (num > 99) {
			return Parser.NUMBERS_ONES[Math.floor(num / 100)] + " hundred " + parse_tens(num % 100);
		} else {
			return parse_tens(num);
		}
	}

	function parse_tens (num) {
		if (num < 10) return Parser.NUMBERS_ONES[num];
		else if (num >= 10 && num < 20) return Parser.NUMBERS_TEENS[num - 10];
		else {
			return Parser.NUMBERS_TENS[Math.floor(num / 10)] + " " + Parser.NUMBERS_ONES[num % 10];
		}
	}
};

// sp-prefix functions are for parsing spell data, and shared with the roll20 script
Parser.spSchoolAbvToFull = function (school) {
	const out = Parser._parse_aToB(Parser.SP_SCHOOL_ABV_TO_FULL, school);
	if (Parser.SP_SCHOOL_ABV_TO_FULL[school]) return out;
	return out;
};

Parser.spSchoolAbvToShort = function (school) {
	const out = Parser._parse_aToB(Parser.SP_SCHOOL_ABV_TO_SHORT, school);
	if (Parser.SP_SCHOOL_ABV_TO_SHORT[school]) return out;
	return out;
};

Parser.spLevelToFull = function (level) {
	switch (level) {
		case 0: return STR_CANTRIP;
		case 1: return `${level}st`;
		case 2: return `${level}nd`;
		case 3: return `${level}rd`;
		default: return `${level}th`;
	}
};

Parser.spellLevelToArticle = function (level) {
	return level === 8 || level === 11 || level === 18 ? "an" : "a";
};


Parser.spMetaToFull = function (meta) {
	// these tags are (so far) mutually independent, so we don't need to combine the text
	if (meta && meta.ritual) return " (ritual)";
	if (meta && meta.technomagic) return " (technomagic)";
	return "";
};

Parser.spTimeListToFull = function (times) {
	return times.map(t => `${Parser.getTimeToFull(t)}${t.condition ? `, ${t.condition}` : ""}`).join(" or ");
};

Parser.getTimeToFull = function (time) {
	return `${time.number} ${time.unit === "bonus" ? "bonus action" : time.unit}${time.number > 1 ? "s" : ""}`;
};

Parser.spRangeToFull = function (range) {
	switch (range.type) {
		case RNG_SPECIAL:
			return "Special";
		case RNG_POINT:
			return renderPoint();
		case RNG_LINE:
		case RNG_CUBE:
		case RNG_CONE:
		case RNG_RADIUS:
		case RNG_SPHERE:
		case RNG_HEMISPHERE:
			return renderArea();
	}

	function renderPoint () {
		const dist = range.distance;
		switch (dist.type) {
			case RNG_SELF:
				return "Self";
			case RNG_SIGHT:
				return "Sight";
			case RNG_UNLIMITED:
				return "Unlimited";
			case RNG_UNLIMITED_SAME_PLANE:
				return "Unlimited on the same plane";
			case RNG_TOUCH:
				return "Touch";
			case UNT_FEET:
			case UNT_MILES:
			default:
				return `${dist.amount} ${dist.amount === 1 ? Parser.getSingletonUnit(dist.type) : dist.type}`;
		}
	}

	function renderArea () {
		const size = range.distance;
		return `Self (${size.amount}-${Parser.getSingletonUnit(size.type)}${getAreaStyleStr()})`;

		function getAreaStyleStr () {
			switch (range.type) {
				case RNG_SPHERE:
					return " radius";
				case RNG_HEMISPHERE:
					return `-radius ${range.type}`;
				default:
					return ` ${range.type}`;
			}
		}
	}
};

Parser.getSingletonUnit = function (unit) {
	switch (unit) {
		case UNT_FEET:
			return "foot";
		case UNT_MILES:
			return "mile";
		default: {
			if (unit.charAt(unit.length - 1) === "s") return unit.slice(0, -1);
			return unit;
		}
	}
};

Parser.spComponentsToFull = function (comp) {
	if (!comp) return "None";
	const out = [];
	if (comp.v) out.push("V");
	if (comp.s) out.push("S");
	if (comp.m) out.push("M" + (comp.m !== true ? ` (${comp.m.text || comp.m})` : ""));
	return out.join(", ");
};

Parser.spMaterialComponents = function (comp) {
	if (comp.m) return (comp.m !== true ? `${comp.m.text || comp.m}` : " ");
	return "";
};

Parser.spDurationToFull = function (dur) {
	return dur.map(d => {
		switch (d.type) {
			case "special":
				return "Special";
			case "instant":
				return `Instantaneous${d.condition ? ` (${d.condition})` : ""}`;
			case "timed":
				return `${d.concentration ? "Concentration, " : ""}${d.concentration ? "u" : d.duration.upTo ? "U" : ""}${d.concentration || d.duration.upTo ? "p to " : ""}${d.duration.amount} ${d.duration.amount === 1 ? d.duration.type : `${d.duration.type}s`}`;
			case "permanent":
				if (d.ends) {
					return `Until ${d.ends.map(m => m === "dispel" ? "dispelled" : m === "trigger" ? "triggered" : m === "discharge" ? "discharged" : undefined).join(" or ")}`;
				} else {
					return "Permanent";
				}
		}
	}).join(" or ") + (dur.length > 1 ? " (see below)" : "");
};

Parser.SPELL_ATTACK_TYPE_TO_FULL = {};
Parser.SPELL_ATTACK_TYPE_TO_FULL["M"] = "Melee";
Parser.SPELL_ATTACK_TYPE_TO_FULL["R"] = "Ranged";
Parser.SPELL_ATTACK_TYPE_TO_FULL["O"] = "Other/Unknown";

Parser.spAttackTypeToFull = function (type) {
	return Parser._parse_aToB(Parser.SPELL_ATTACK_TYPE_TO_FULL, type);
};

// mon-prefix functions are for parsing monster data, and shared with the roll20 script
Parser.monTypeToFullObj = function (type) {
	const out = {type: "", tags: [], asText: ""};

	if (!type) {
		out.type="";
		out.asText="";
		return out;
	}

	if (typeof type === "string") {
		// handles e.g. "fey"
		out.type = type;
		out.asText = type;
		return out;
	}

	const tempTags = [];
	if (type.tags) {
		for (let tag of type.tags) {
			if (typeof tag === "string") {
				// handles e.g. "fiend (devil)"
				if (tag.startsWith("(")) {
					tag = tag.substr(1);
					if (tag.endsWith(")")) {
						tag = tag.substr(0, tag.length-1);
					}
				}
				if (tag.includes(",")) {
					const s = tag.split(",");
					for (let t of s) {
						if (t.length) {
							out.tags.push(t.trim());
						}
					}
				} else {
					out.tags.push(tag);
				}
				tempTags.push(tag);
			} else {
				// handles e.g. "humanoid (Chondathan human)"
				out.tags.push(tag.tag);
				tempTags.push(`${tag.prefix} ${tag.tag}`);
			}
		}
	}
	out.type = type.type;
	if (type.swarmSize) {
		out.type="swarm";
		const tag = `of ${Parser.sizeAbvToFull(type.swarmSize).toLowerCase()} ${Parser.monTypeToPlural(type.type)}`;
		out.tags.push(tag);
		tempTags.push(tag);
		out.asText = "swarm";
	} else {
		out.asText = `${type.type}`;
	}
	if (tempTags.length) out.asText += ` (${tempTags.join(", ")})`;
	return out;
};

Parser.monTypeToPlural = function (type) {
	return Parser._parse_aToB(Parser.MON_TYPE_TO_PLURAL, type);
};

Parser.monCrToFull = function (cr) {
	if (typeof cr === "string" || !cr) return `${cr || "Unknown"} (${Parser.crToXp(cr)} XP)`;
	else {
		const stack = [Parser.monCrToFull(cr.cr)];
		if (cr.lair) stack.push(`${Parser.monCrToFull(cr.lair)} when encountered in lair`);
		if (cr.coven) stack.push(`${Parser.monCrToFull(cr.coven)} when part of a coven`);
		return stack.join(" or ");
	}
};

Parser.monCrOnly = function (cr) {
	if (typeof cr === "string" || !cr) return `${cr || "?"}`;
	else {
		return Parser.monCrOnly(cr.cr);
	}
};


Parser.monImmResToFull = function (toParse) {
	if (!toParse) {
		return "";
	}
	const outerLen = toParse.length;
	let maxDepth = 0;
	if (outerLen === 1 && toParse[0] && (toParse[0].immune || toParse[0].resist)) {
		return toParse.map(it => toString(it, -1)).join(maxDepth ? "; " : ", ");
	}

	function toString (it, depth = 0) {
		maxDepth = Math.max(maxDepth, depth);
		if (typeof it === "string") {
			return it;
		} else if (it && it.special) {
			return it.special;
		} else if (it) {
			let stack = it.preNote ? `${it.preNote} ` : "";
			const prop = it.immune ? "immune" : it.resist ? "resist" : it.vulnerable ? "vulnerable" : null;
			if (prop) {
				const toJoin = it[prop].map(nxt => toString(nxt, depth + 1));
				stack += depth?toJoin.join(maxDepth ? "; " : ", "):toJoin.join(", ");
			}
			if (it.note) stack += ` ${it.note}`;
			return stack;
		}
		return "";
	}

	function serialJoin (arr) {
		if (arr.length <= 1) return arr.join("");

		let out = "";
		for (let i = 0; i < arr.length - 1; ++i) {
			const it = arr[i];
			const nxt = arr[i + 1]
			out += it;
			out += (it.includes(",") || nxt.includes(",")) ? "; " : ", ";
		}
		out += arr[arr.length-1];
		return out;
	}

	return serialJoin(toParse.map(it => toString(it)));
};

Parser.monCondImmToFull = function (condImm) {
	function render (condition) {
		return EntryRenderer.getDefaultRenderer().renderEntry(`{@condition ${condition}}`);
	}
	return condImm.map(it => {
		if (it.special) return it.special;
		if (it.conditionImmune) return `${it.preNote ? `${it.preNote} ` : ""}${it.conditionImmune.map(render).join(", ")}${it.note ? ` ${it.note}` : ""}`;
		return render(it);
	}).join(", ");
};

Parser.MON_SENSE_TAG_TO_FULL = {
	"B": "blindsight",
	"D": "darkvision",
	"SD": "superior darkvision",
	"T": "tremorsense",
	"U": "truesight"
};
Parser.monSenseTagToFull = function (tag) {
	return Parser._parse_aToB(Parser.MON_SENSE_TAG_TO_FULL, tag);
};

// psi-prefix functions are for parsing psionic data, and shared with the roll20 script
Parser.PSI_ABV_TYPE_TALENT = "T";
Parser.PSI_ABV_TYPE_DISCIPLINE = "D";
Parser.PSI_ORDER_NONE = "None";
Parser.psiTypeToFull = (type) => {
	if (type === Parser.PSI_ABV_TYPE_TALENT) return "Talent";
	else if (type === Parser.PSI_ABV_TYPE_DISCIPLINE) return "Discipline";
	else return type;
};

Parser.psiOrderToFull = (order) => {
	return order === undefined ? Parser.PSI_ORDER_NONE : order;
};

Parser.levelToFull = function (level) {
	if (isNaN(level)) return "";
	if (level == "2") return "2nd";
	if (level == "3") return "3rd";
	if (level == "1") return "1st";
	return level + "th";
};

Parser.prereqSpellToFull = function (spell) {
	if (spell === "eldritch blast") return EntryRenderer.getDefaultRenderer().renderEntry(`{@spell ${spell}} cantrip`);
	else if (spell === "hex/curse") return EntryRenderer.getDefaultRenderer().renderEntry("{@spell hex} spell or a warlock feature that curses");
	else if (spell) return EntryRenderer.getDefaultRenderer().renderEntry(`{@spell ${spell}}`);
	return STR_NONE;
};

Parser.prereqPactToFull = function (pact) {
	if (pact === "Chain") return "Pact of the Chain";
	if (pact === "Tome") return "Pact of the Tome";
	if (pact === "Blade") return "Pact of the Blade";
	return pact;
};

Parser.prereqPatronToShort = function (patron) {
	if (patron === STR_ANY) return STR_ANY;
	return /^The (.*?)$/.exec(patron)[1];
};

Parser.alignmentAbvToFull = function (alignment) {
	if (typeof alignment === "object") {
		if (alignment.special) {
			// use in MTF Sacred Statue
			return alignment.special;
		} else {
			if (!alignment.alignment) {
				return "";
			}
			if (Array.isArray(alignment.alignment)) {
				// e.g. `{alignment: ["N", "G"], chance: 50}` or `{alignment: ["N", "G"]}`
				return `${alignment.alignment.map(a => Parser.alignmentAbvToFull(a)).join(" ")}${alignment.chance ? ` (${alignment.chance}%)` : ""}`;
			} else if (typeof alignment.alignment == "string") {
				return alignment.aligment;
			}
			return "unaligned";
		}
	} else {
		alignment = alignment.toUpperCase();
		switch (alignment) {
			case "L":
				return "Lawful";
			case "N":
				return "Neutral";
			case "NX":
				return "Neutral (Law/Chaos axis)";
			case "NY":
				return "Neutral (Good/Evil axis)";
			case "C":
				return "Chaotic";
			case "G":
				return "Good";
			case "E":
				return "Evil";
			// "special" values
			case "U":
				return "Unaligned";
			case "A":
				return "Any alignment";
		}
		return alignment;
	}
};

Parser.alignmentListToFull = function (alignList) {
	// assume all single-length arrays can be simply parsed
	if (alignList.length === 1) return Parser.alignmentAbvToFull(alignList[0]);
	// two-length arrays can be:
	// 1. "[object] or [object]"
	// 2. a pair of abv's, e.g. "L" "G"
	if (alignList.length === 2) {
		if (typeof alignList[0] === "object" && typeof alignList[1] === "object") return `${Parser.alignmentAbvToFull(alignList[0])} or ${Parser.alignmentAbvToFull(alignList[1]).toLowerCase()}`;
		else if (typeof alignList[0] === "string" && typeof alignList[1] === "string") return alignList.map(a => Parser.alignmentAbvToFull(a)).join(" ");
		else throw new Error(`Malformed alignment pair: ${JSON.stringify(alignList)}`);
	}
	// longer arrays should have a custom mapping
	// available options are:
	// "L", "NX", "C" ("NX" = "neutral X" = neutral law/chaos axis)
	// "G", "NY", "E" ("NY" = "neutral Y" = neutral good/evil axis)
	if (alignList.length === 5) {
		if (!alignList.includes("G")) return "any non-good alignment";
		if (!alignList.includes("L")) return "any non-lawful alignment";
	}
	if (alignList.length === 4) {
		if (!alignList.includes("L") && !alignList.includes("NX")) return "any chaotic alignment";
		if (!alignList.includes("G") && !alignList.includes("NY")) return "any evil alignment";
		if (!alignList.includes("C") && !alignList.includes("NX")) return "any lawful alignment";
		if (!alignList.includes("E") && !alignList.includes("NY")) return "any good alignment";
	}
	throw new Error(`Unmapped alignment: ${JSON.stringify(alignList)}`);
};

Parser.attackTypeToFull = function (attackType) {
	return Parser._parse_aToB(Parser.ATK_TYPE_TO_FULL, attackType);
};

Parser.ATK_TYPE_TO_FULL = {};
Parser.ATK_TYPE_TO_FULL["MW"] = "Melee Weapon Attack";
Parser.ATK_TYPE_TO_FULL["RW"] = "Ranged Weapon Attack";

let SKL_ABV_ABJ = "A";
let SKL_ABV_EVO = "V";
let SKL_ABV_ENC = "E";
let SKL_ABV_ILL = "I";
let SKL_ABV_DIV = "D";
let SKL_ABV_NEC = "N";
let SKL_ABV_TRA = "T";
let SKL_ABV_CON = "C";
let SKL_ABV_PSI = "P";

let SKL_ABJ = "Abjuration";
let SKL_EVO = "Evocation";
let SKL_ENC = "Enchantment";
let SKL_ILL = "Illusion";
let SKL_DIV = "Divination";
let SKL_NEC = "Necromancy";
let SKL_TRA = "Transmutation";
let SKL_CON = "Conjuration";
let SKL_PSI = "Psionic";

Parser.SP_SCHOOL_ABV_TO_FULL = {};
Parser.SP_SCHOOL_ABV_TO_FULL[SKL_ABV_ABJ] = SKL_ABJ;
Parser.SP_SCHOOL_ABV_TO_FULL[SKL_ABV_EVO] = SKL_EVO;
Parser.SP_SCHOOL_ABV_TO_FULL[SKL_ABV_ENC] = SKL_ENC;
Parser.SP_SCHOOL_ABV_TO_FULL[SKL_ABV_ILL] = SKL_ILL;
Parser.SP_SCHOOL_ABV_TO_FULL[SKL_ABV_DIV] = SKL_DIV;
Parser.SP_SCHOOL_ABV_TO_FULL[SKL_ABV_NEC] = SKL_NEC;
Parser.SP_SCHOOL_ABV_TO_FULL[SKL_ABV_TRA] = SKL_TRA;
Parser.SP_SCHOOL_ABV_TO_FULL[SKL_ABV_CON] = SKL_CON;
Parser.SP_SCHOOL_ABV_TO_FULL[SKL_ABV_PSI] = SKL_PSI;

Parser.SP_SCHOOL_ABV_TO_SHORT = {};
Parser.SP_SCHOOL_ABV_TO_SHORT[SKL_ABV_ABJ] = "Abj.";
Parser.SP_SCHOOL_ABV_TO_SHORT[SKL_ABV_EVO] = "Evoc.";
Parser.SP_SCHOOL_ABV_TO_SHORT[SKL_ABV_ENC] = "Ench.";
Parser.SP_SCHOOL_ABV_TO_SHORT[SKL_ABV_ILL] = "Illu.";
Parser.SP_SCHOOL_ABV_TO_SHORT[SKL_ABV_DIV] = "Divin.";
Parser.SP_SCHOOL_ABV_TO_SHORT[SKL_ABV_NEC] = "Necro.";
Parser.SP_SCHOOL_ABV_TO_SHORT[SKL_ABV_TRA] = "Trans.";
Parser.SP_SCHOOL_ABV_TO_SHORT[SKL_ABV_CON] = "Conj.";
Parser.SP_SCHOOL_ABV_TO_SHORT[SKL_ABV_PSI] = "Psi.";

Parser.ATB_ABV_TO_FULL = {
	"str": "Strength",
	"dex": "Dexterity",
	"con": "Constitution",
	"int": "Intelligence",
	"wis": "Wisdom",
	"cha": "Charisma"
};

let TP_ABERRATION = "aberration";
let TP_BEAST = "beast";
let TP_CELESTIAL = "celestial";
let TP_CONSTRUCT = "construct";
let TP_DRAGON = "dragon";
let TP_ELEMENTAL = "elemental";
let TP_FEY = "fey";
let TP_FIEND = "fiend";
let TP_GIANT = "giant";
let TP_HUMANOID = "humanoid";
let TP_MONSTROSITY = "monstrosity";
let TP_OOZE = "ooze";
let TP_PLANT = "plant";
let TP_UNDEAD = "undead";
Parser.MON_TYPE_TO_PLURAL = {};
Parser.MON_TYPE_TO_PLURAL[TP_ABERRATION] = "aberrations";
Parser.MON_TYPE_TO_PLURAL[TP_BEAST] = "beasts";
Parser.MON_TYPE_TO_PLURAL[TP_CELESTIAL] = "celestials";
Parser.MON_TYPE_TO_PLURAL[TP_CONSTRUCT] = "constructs";
Parser.MON_TYPE_TO_PLURAL[TP_DRAGON] = "dragons";
Parser.MON_TYPE_TO_PLURAL[TP_ELEMENTAL] = "elementals";
Parser.MON_TYPE_TO_PLURAL[TP_FEY] = "fey";
Parser.MON_TYPE_TO_PLURAL[TP_FIEND] = "fiends";
Parser.MON_TYPE_TO_PLURAL[TP_GIANT] = "giants";
Parser.MON_TYPE_TO_PLURAL[TP_HUMANOID] = "humanoids";
Parser.MON_TYPE_TO_PLURAL[TP_MONSTROSITY] = "monstrosities";
Parser.MON_TYPE_TO_PLURAL[TP_OOZE] = "oozes";
Parser.MON_TYPE_TO_PLURAL[TP_PLANT] = "plants";
Parser.MON_TYPE_TO_PLURAL[TP_UNDEAD] = "undead";

let SZ_FINE = "F";
let SZ_DIMINUTIVE = "D";
let SZ_TINY = "T";
let SZ_SMALL = "S";
let SZ_MEDIUM = "M";
let SZ_LARGE = "L";
let SZ_HUGE = "H";
let SZ_GARGANTUAN = "G";
let SZ_COLOSSAL = "C";
let SZ_VARIES = "V";
Parser.SIZE_ABV_TO_FULL = {};
Parser.SIZE_ABV_TO_FULL[SZ_FINE] = "Fine";
Parser.SIZE_ABV_TO_FULL[SZ_DIMINUTIVE] = "Diminutive";
Parser.SIZE_ABV_TO_FULL[SZ_TINY] = "Tiny";
Parser.SIZE_ABV_TO_FULL[SZ_SMALL] = "Small";
Parser.SIZE_ABV_TO_FULL[SZ_MEDIUM] = "Medium";
Parser.SIZE_ABV_TO_FULL[SZ_LARGE] = "Large";
Parser.SIZE_ABV_TO_FULL[SZ_HUGE] = "Huge";
Parser.SIZE_ABV_TO_FULL[SZ_GARGANTUAN] = "Gargantuan";
Parser.SIZE_ABV_TO_FULL[SZ_COLOSSAL] = "Colossal";
Parser.SIZE_ABV_TO_FULL[SZ_VARIES] = "Varies";

Parser.XP_CHART = [200, 450, 700, 1100, 1800, 2300, 2900, 3900, 5000, 5900, 7200, 8400, 10000, 11500, 13000, 15000, 18000, 20000, 22000, 25000, 30000, 41000, 50000, 62000, 75000, 90000, 105000, 120000, 135000, 155000];

Parser.XP_CHART_ALT = {
	"0": 10,
	"1/8": 25,
	"1/4": 50,
	"1/2": 100,
	"1": 200,
	"2": 450,
	"3": 700,
	"4": 1100,
	"5": 1800,
	"6": 2300,
	"7": 2900,
	"8": 3900,
	"9": 5000,
	"10": 5900,
	"11": 7200,
	"12": 8400,
	"13": 10000,
	"14": 11500,
	"15": 13000,
	"16": 15000,
	"17": 18000,
	"18": 20000,
	"19": 22000,
	"20": 25000,
	"21": 30000,
	"22": 41000,
	"23": 50000,
	"24": 62000,
	"25": 75000,
	"26": 90000,
	"27": 105000,
	"28": 120000,
	"29": 135000,
	"30": 155000
};

Parser.ARMOR_ABV_TO_FULL = {
	"l.": "light",
	"m.": "medium",
	"h.": "heavy"
};

Parser.ITEM_TYPE_JSON_TO_ABV = {
	"$":"Treasure",
	"A": "Ammunition",
	"AF": "Ammunition",
	"AT": "Artisan Tool",
	"coin":"Currency",
	"EM": "Eldritch Machine",
	"EXP": "Explosive",
	"G": "Adventuring Gear",
	"GS": "Gaming Set",
	"HA": "Heavy Armor",
	"INS": "Instrument",
	"LA": "Light Armor",
	"M": "Melee Weapon",
	"MA": "Medium Armor",
	"MNT": "Mount",
	"GV": "Generic Variant",
	"P": "Potion",
	"R": "Ranged Weapon",
	"RD": "Rod",
	"RG": "Ring",
	"S": "Shield",
	"SC": "Scroll",
	"SCF": "Spellcasting Focus",
	"STAFF": "Staff",
	"OTH": "Other",
	"T": "Tool",
	"TAH": "Tack and Harness",
	"TG": "Trade Good",
	"VEH": "Vehicle (land)",
	"SHP": "Vehicle (water)",
	"WON":"Wondrous Item",
	"WD": "Wand"
};

Parser.ITEM_AVB_TO_TYPE = {
	"Treasure":"$",
	"Ammunition":"A",
	"Artisan Tool":"AT",
	"Currency":"coin",
	"Eldritch Machine":"EM",
	"Explosive":"EXP",
	"Adventuring Gear":"G",
	"Gaming Set":"GS",
	"Heavy Armor":"HA",
	"Instrument":"INS",
	"Light Armor":"LA",
	"Melee Weapon":"M",
	"Medium Armor":"MA",
	"Mount":"MNT",
	"Generic Variant":"GV",
	"Potion":"P",
	"Ranged Weapon":"R",
	"Rod":"RD",
	"Ring":"RG",
	"Shield":"S",
	"Scroll":"SC",
	"Spellcasting Focus":"SCF",
	"Staff":"STAFF",
	"Other":"OTH",
	"Tool":"T",
	"Tack and Harness":"TAH",
	"Trade Good":"TG",
	"Vehicle (land)":"VEH",
	"Vehicle (water)":"SHP",
	"Wondrous Item":"WON",
	"Wand":"WD"
};

Parser.BASE_ITEM_TYPES = {
	"Treasure":1,
	"Ammunition":1,
	"Artisan Tool":1,
	"Adventuring Gear":1,
	"Currency":1,
	"Gaming Set":1,
	"Heavy Armor":1,
	"Instrument":1,
	"Light Armor":1,
	"Melee Weapon":1,
	"Medium Armor":1,
	"Potion":1,
	"Ranged Weapon":1,
	"Ring":1,
	"Shield":1,
	"Scroll":1,
	"Spellcasting Focus":1,
	"OTH": "Other",
	"Tool":1,
	"Trade Good":1,
	"Vehicle (land)":1,
	"Vehicle (water)":1,
	"Wondrous Item":1,
	"Wand":1
};

Parser.DMGTYPE_JSON_TO_FULL = {
	default:"default",
	"acid": "acid",
	"B": "bludgeoning",
    "cold":"cold",
    fire:"fire",
    force:"force",
    lightning:"lightning",
	"N": "necrotic",
	"P": "piercing",
    poison:"poison",
    psychic:"psychic",
	"R": "radiant",
	"S": "slashing",
    thunder:"thunder"
};

Parser.damageHealingNames = {
	"acid": "acid",
	"bludgeoning":"B",
    "cold":"cold",
    fire:"fire",
    force:"force",
    lightning:"lightning",
	"necrotic":"N",
	"piercing":"P",
    poison:"poison",
    psychic:"psychic",
	"radiant":"R",
	"slashing":"S",
    thunder:"thunder",
	heal:"heal"
}

Parser.SKILL_JSON_TO_FULL = {
	"Acrobatics": [
		"Your Dexterity (Acrobatics) check covers your attempt to stay on your feet in a tricky situation, such as when you're trying to run across a sheet of ice, balance on a tightrope, or stay upright on a rocking ship's deck. The DM might also call for a Dexterity (Acrobatics) check to see if you can perform acrobatic stunts, including dives, rolls, somersaults, and flips."
	],
	"Animal Handling": [
		"When there is any question whether you can calm down a domesticated animal, keep a mount from getting spooked, or intuit an animal's intentions, the DM might call for a Wisdom (Animal Handling) check. You also make a Wisdom (Animal Handling) check to control your mount when you attempt a risky maneuver."
	],
	"Arcana": [
		"Your Intelligence (Arcana) check measures your ability to recall lore about spells, magic items, eldritch symbols, magical traditions, the planes of existence, and the inhabitants of those planes."
	],
	"Athletics": [
		"Your Strength (Athletics) check covers difficult situations you encounter while climbing, jumping, or swimming. Examples include the following activities:",
		{
			"type": "list",
			"items": [
				"You attempt to climb a sheer or slippery cliff, avoid hazards while scaling a wall, or cling to a surface while something is trying to knock you off.",
				"You try to jump an unusually long distance or pull off a stunt mid jump.",
				"You struggle to swim or stay afloat in treacherous currents, storm-tossed waves, or areas of thick seaweed. Or another creature tries to push or pull you underwater or otherwise interfere with your swimming."
			]
		}
	],
	"Deception": [
		"Your Charisma (Deception) check determines whether you can convincingly hide the truth, either verbally or through your actions. This deception can encompass everything from misleading others through ambiguity to telling outright lies. Typical situations include trying to fast-talk a guard, con a merchant, earn money through gambling, pass yourself off in a disguise, dull someone's suspicions with false assurances, or maintain a straight face while telling a blatant lie."
	],
	"History": [
		"Your Intelligence (History) check measures your ability to recall lore about historical events, legendary people, ancient kingdoms, past disputes, recent wars, and lost civilizations."
	],
	"Insight": [
		"Your Wisdom (Insight) check decides whether you can determine the true intentions of a creature, such as when searching out a lie or predicting someone's next move. Doing so involves gleaning clues from body language, speech habits, and changes in mannerisms."
	],
	"Intimidation": [
		"When you attempt to influence someone through overt threats, hostile actions, and physical violence, the DM might ask you to make a Charisma (Intimidation) check. Examples include trying to pry information out of a prisoner, convincing street thugs to back down from a confrontation, or using the edge of a broken bottle to convince a sneering vizier to reconsider a decision."
	],
	"Investigation": [
		"When you look around for clues and make deductions based on those clues, you make an Intelligence (Investigation) check. You might deduce the location of a hidden object, discern from the appearance of a wound what kind of weapon dealt it, or determine the weakest point in a tunnel that could cause it to collapse. Poring through ancient scrolls in search of a hidden fragment of knowledge might also call for an Intelligence (Investigation) check."
	],
	"Medicine": [
		"A Wisdom (Medicine) check lets you try to stabilize a dying companion or diagnose an illness."
	],
	"Nature": [
		"Your Intelligence (Nature) check measures your ability to recall lore about terrain, plants and animals, the weather, and natural cycles."
	],
	"Perception": [
		"Your Wisdom (Perception) check lets you spot, hear, or otherwise detect the presence of something. It measures your general awareness of your surroundings and the keenness of your senses.", "For example, you might try to hear a conversation through a closed door, eavesdrop under an open window, or hear monsters moving stealthily in the forest. Or you might try to spot things that are obscured or easy to miss, whether they are orcs lying in ambush on a road, thugs hiding in the shadows of an alley, or candlelight under a closed secret door."
	],
	"Performance": [
		"Your Charisma (Performance) check determines how well you can delight an audience with music, dance, acting, storytelling, or some other form of entertainment."
	],
	"Persuasion": [
		"When you attempt to influence someone or a group of people with tact, social graces, or good nature, the DM might ask you to make a Charisma (Persuasion) check. Typically, you use persuasion when acting in good faith, to foster friendships, make cordial requests, or exhibit proper etiquette. Examples of persuading others include convincing a chamberlain to let your party see the king, negotiating peace between warring tribes, or inspiring a crowd of townsfolk."
	],
	"Religion": [
		"Your Intelligence (Religion) check measures your ability to recall lore about deities, rites and prayers, religious hierarchies, holy symbols, and the practices of secret cults."
	],
	"Sleight of Hand": [
		"Whenever you attempt an act of legerdemain or manual trickery, such as planting something on someone else or concealing an object on your person, make a Dexterity (Sleight of Hand) check. The DM might also call for a Dexterity (Sleight of Hand) check to determine whether you can lift a coin purse off another person or slip something out of another person's pocket."
	],
	"Stealth": [
		"Make a Dexterity (Stealth) check when you attempt to conceal yourself from enemies, slink past guards, slip away without being noticed, or sneak up on someone without being seen or heard."
	],
	"Survival": [
		"The DM might ask you to make a Wisdom (Survival) check to follow tracks, hunt wild game, guide your group through frozen wastelands, identify signs that owlbears live nearby, predict the weather, or avoid quicksand and other natural hazards."
	]
};

Parser.ACTION_JSON_TO_FULL = {
	"Attack": [
		"The most common action to take in combat is the Attack action, whether you are swinging a sword, firing an arrow from a bow, or brawling with your fists.",
		"With this action, you make one melee or ranged attack. See the \"{@book Making an Attack|phb|9|making an attack}\" section for the rules that govern attacks.",
		"Certain features, such as the Extra Attack feature of the fighter, allow you to make more than one attack with this action."
	],
	"Dash": [
		"When you take the Dash action, you gain extra movement for the current turn. The increase equals your speed, after applying any modifiers. With a speed of 30 feet, for example, you can move up to 60 feet on your turn if you dash.",
		"Any increase or decrease to your speed changes this additional movement by the same amount. If your speed of 30 feet is reduced to 15 feet, for instance, you can move up to 30 feet this turn if you dash."
	],
	"Disengage": [
		"If you take the Disengage action, your movement doesn't provoke opportunity attacks for the rest of the turn."
	],
	"Dodge": [
		"When you take the Dodge action, you focus entirely on avoiding attacks. Until the start of your next turn, any attack roll made against you has disadvantage if you can see the attacker, and you make Dexterity saving throws with advantage. You lose this benefit if you are incapacitated (as explained in the appendix) or if your speed drops to 0."
	],
	"Help": [
		"You can lend your aid to another creature in the completion of a task. When you take the Help action, the creature you aid gains advantage on the next ability check it makes to perform the task you are helping with, provided that it makes the check before the start of your next turn.",
		"Alternatively, you can aid a friendly creature in attacking a creature within 5 feet of you. You feint, distract the target, or in some other way team up to make your ally's attack more effective. If your ally attacks the target before your next turn, the first attack roll is made with advantage."
	],
	"Hide": [
		"When you take the Hide action, you make a Dexterity (Stealth) check in an attempt to hide, following the rules in chapter 7 for hiding. If you succeed, you gain certain benefits, as described in the \"{@book Unseen Attackers and Targets|PHB|9|unseen attackers and targets}\" section in the Player's Handbook."
	],
	"Ready": [
		"Sometimes you want to get the jump on a foe or wait for a particular circumstance before you act. To do so, you can take the Ready action on your turn so that you can act later in the round using your reaction.",
		"First, you decide what perceivable circumstance will trigger your reaction. Then, you choose the action you will take in response to that trigger, or you choose to move up to your speed in response to it. Examples include \"If the cultist steps on the trapdoor, I'll pull the lever that opens it,\" and \"If the goblin steps next to me, I move away.\"",
		"When the trigger occurs, you can either take your reaction right after the trigger finishes or ignore the trigger. Remember that you can take only one reaction per round.",
		"When you ready a spell, you cast it as normal but hold its energy, which you release with your reaction when the trigger occurs. To be readied, a spell must have a casting time of 1 action, and holding onto the spell's magic requires concentration (explained in chapter 10). If your concentration is broken, the spell dissipates without taking effect. For example, if you are concentrating on the web spell and ready magic missile, your web spell ends, and if you take damage before you release magic missile with your reaction, your concentration might be broken.",
		"You have until the start of your next turn to use a readied action."
	],
	"Search": [
		"When you take the Search action, you devote your attention to finding something. Depending on the nature of your search, the DM might have you make a Wisdom ({@skill Perception}) check or an Intelligence ({@skill Investigation}) check."
	],
	"Use an Object": [
		"You normally interact with an object while doing something else, such as when you draw a sword as part of an attack. When an object requires your action for its use, you take the Use an Object action. This action is also useful when you want to interact with more than one object on your turn."
	]
};

Parser.CONDITIONS_JSON_TO_FULL = {
	"Blinded": [
		{
			"type": "list",
			"items": [
				"A blinded creature can't see and automatically fails any ability check that requires sight.",
				"Attack rolls against the creature have advantage, and the creature's attack rolls have disadvantage."
			]
		}
	],
	"Charmed": [
		{
			"type": "list",
			"items": [
				"A charmed creature can't attack the charmer or target the charmer with harmful abilities or magical effects.",
				"The charmer has advantage on any ability check to interact socially with the creature."
			]
		}
	],
	"Deafened": [
		{
			"type": "list",
			"items": [
				"A deafened creature can't hear and automatically fails any ability check that requires hearing."
			]
		}
	],
	"Exhaustion": [
		"Some special abilities and environmental hazards, such as starvation and the long-term effects of freezing or scorching temperatures, can lead to a special condition called exhaustion. Exhaustion is measured in six levels. An effect can give a creature one or more levels of exhaustion, as specified in the effect's description.",
		{
			"type": "table",
			"colLabels": [
				"Level",
				"Effect"
			],
			"colStyles": [
				"col-1 text-align-center",
				"col-11"
			],
			"rows": [
				[
					"1",
					"Disadvantage on ability checks"
				],
				[
					"2",
					"Speed halved"
				],
				[
					"3",
					"Disadvantage on attack rolls and saving throws"
				],
				[
					"4",
					"Hit point maximum halved"
				],
				[
					"5",
					"Speed reduced to 0"
				],
				[
					"6",
					"Death"
				]
			]
		},
		"If an already exhausted creature suffers another effect that causes exhaustion, its current level of exhaustion increases by the amount specified in the effect's description.",
		"A creature suffers the effect of its current level of exhaustion as well as all lower levels. For example, a creature suffering level 2 exhaustion has its speed halved and has disadvantage on ability checks.",
		"An effect that removes exhaustion reduces its level as specified in the effect's description, with all exhaustion effects ending if a creature's exhaustion level is reduced below 1.",
		"Finishing a long rest reduces a creature's exhaustion level by 1, provided that the creature has also ingested some food and drink. Also, being raised from the dead reduces a creature's exhaustion level by 1."
	],
	"Frightened": [
		{
			"type": "list",
			"items": [
				"A frightened creature has disadvantage on ability checks and attack rolls while the source of its fear is within line of sight.",
				"The creature can't willingly move closer to the source of its fear."
			]
		}
	],
	"Grappled": [
		{
			"type": "list",
			"items": [
				"A grappled creature's speed becomes 0, and it can't benefit from any bonus to its speed.",
				"The condition ends if the grappler is {@condition incapacitated}.",
				"The condition also ends if an effect removes the grappled creature from the reach of the grappler or grappling effect, such as when a creature is hurled away by the {@spell thunderwave|phb} spell."
			]
		}
	],
	"Incapacitated": [
		{
			"type": "list",
			"items": [
				"An incapacitated creature can't take actions or reactions."
			]
		}
	],
	"Invisible": [
		{
			"type": "list",
			"items": [
				"An invisible creature is impossible to see without the aid of magic or a special sense. For the purpose of hiding, the creature is heavily obscured. The creature's location can be detected by any noise it makes or any tracks it leaves.",
				"Attack rolls against the creature have disadvantage, and the creature's attack rolls have advantage."
			]
		}
	],
	"Paralyzed": [
		{
			"type": "list",
			"items": [
				"A paralyzed creature is {@condition incapacitated} and can't move or speak.",
				"The creature automatically fails Strength and Dexterity saving throws. Attack rolls against the creature have advantage.",
				"Any attack that hits the creature is a critical hit if the attacker is within 5 feet of the creature."
			]
		}
	],
	"Petrified": [
		{
			"type": "list",
			"items": [
				"A petrified creature is transformed, along with any nonmagical object it is wearing or carrying, into a solid inanimate substance (usually stone). Its weight increases by a factor of ten, and it ceases aging.",
				"The creature is {@condition incapacitated}, can't move or speak, and is unaware of its surroundings.",
				"Attack rolls against the creature have advantage.",
				"The creature automatically fails Strength and Dexterity saving throws.",
				"The creature has resistance to all damage.",
				"The creature is immune to poison and disease, although a poison or disease already in its system is suspended, not neutralized."
			]
		}
	],
	"Poisoned": [
		{
			"type": "list",
			"items": [
				"A poisoned creature has disadvantage on attack rolls and ability checks."
			]
		}
	],
	"Prone": [
		{
			"type": "list",
			"items": [
				"A prone creature's only movement option is to crawl, unless it stands up and thereby ends the condition.",
				"The creature has disadvantage on attack rolls.",
				"An attack roll against the creature has advantage if the attacker is within 5 feet of the creature. Otherwise, the attack roll has disadvantage."
			]
		}
	],
	"Restrained": [
		{
			"type": "list",
			"items": [
				"A restrained creature's speed becomes 0, and it can't benefit from any bonus to its speed.",
				"Attack rolls against the creature have advantage, and the creature's attack rolls have disadvantage.",
				"The creature has disadvantage on Dexterity saving throws."
			]
		}
	],
	"Stunned": [
		{
			"type": "list",
			"items": [
				"A stunned creature is {@condition incapacitated}, can't move, and can speak only falteringly.",
				"The creature automatically fails Strength and Dexterity saving throws.",
				"Attack rolls against the creature have advantage."
			]
		}
	],
	"Unconscious": [
		{
			"type": "list",
			"items": [
				"An unconscious creature is {@condition incapacitated}, can't move or speak, and is unaware of its surroundings.",
				"The creature drops whatever it's holding and falls {@condition prone}.",
				"The creature automatically fails Strength and Dexterity saving throws.",
				"Attack rolls against the creature have advantage.",
				"Any attack that hits the creature is a critical hit if the attacker is within 5 feet of the creature."
			]
		}
	]
}

Parser.SENSE_JSON_TO_FULL = {
	"blindsight": [
		"A creature with blindsight can perceive its surroundings without relying on sight, within a specific radius. Creatures without eyes, such as oozes, and creatures with echolocation or heightened senses, such as bats and true dragons, have this sense."
	],
	"darkvision": [
		"Many creatures in fantasy gaming worlds, especially those that dwell underground, have darkvision. Within a specified range, a creature with darkvision can see in dim light as if it were bright light and in darkness as if it were dim light, so areas of darkness are only lightly obscured as far as that creature is concerned. However, the creature can't discern color in that darkness, only shades of gray."
	],
	"tremorsense": [
		"A creature with tremorsense can detect and pinpoint the origin of vibrations within a specific radius, provided that the creature and the source of the vibrations are in contact with the same ground or substance. Tremorsense can't be used to detect flying or incorporeal creatures. Many burrowing creatures, such as ankhegs and umber hulks, have this special sense."
	],
	"truesight": [
		"A creature with truesight can, out to a specific range, see in normal and magical darkness, see invisible creatures and objects, automatically detect visual illusions and succeed on saving throws against them, and perceives the original form of a shapechanger or a creature that is transformed by magic. Furthermore, the creature can see into the Ethereal Plane."
	]
};

Parser.COINS_Entries = {
	pp: {
		displayName:"Platinum Piece",
		name:"pp",
		entries:[
			"Unusual coins made of other precious metals sometimes appear in treasure hoards. The electrum piece (ep) and the platinum piece (pp) originate from fallen empires and lost kingdoms, and they sometimes arouse suspicion and skepticism when used in transactions. An electrum piece is worth five silver pieces, and a platinum piece is worth ten gold pieces."
		],
	},
	gp:  {
		displayName:"Gold Piece",
		name:"gp",
		entries:[
			"With one gold piece, a character can buy a bedroll, 50 feet of good rope, or a goat. A skilled (but not exceptional) artisan can earn one gold piece a day. The gold piece is the standard unit of measure for wealth, even if the coin itself is not commonly used. When merchants discuss deals that involve goods or services worth hundreds or thousands of gold pieces, the transactions don't usually involve the exchange of individual coins. Rather, the gold piece is a standard measure of value, and the actual exchange is in gold bars, letters of credit, or valuable goods."
		],
	},
	sp:  {
		displayName:"Silver Piece",
		name:"sp",
		entries:[
			"One gold piece is worth ten silver pieces.  The silver piece is the most prevalent coin among commoners. A silver piece buys a laborer's work for half a day, a flask of lamp oil, or a night's rest in a poor inn."
		],
	},
	ep:  {
		displayName:"Electrum Piece",
		name:"ep",
		entries:[
			"Unusual coins made of other precious metals sometimes appear in treasure hoards. The electrum piece (ep) and the platinum piece (pp) originate from fallen empires and lost kingdoms, and they sometimes arouse suspicion and skepticism when used in transactions. An electrum piece is worth five silver pieces, and a platinum piece is worth ten gold pieces."
		],
	},
	cp:  {
		displayName:"Copper Piece",
		name:"cp",
		entries:[
			"One silver piece is worth ten copper pieces, which are common among laborers and beggars. A single copper piece buys a candle, a torch, or a piece of chalk."
		],
	}
};

Parser.COINS_ALT = {
	"GP":"gp",
	"SP":"sp",
	"PP":"pp",
	"EP":"ep",
	"CP":"cp"
}


Parser.NUMBERS_ONES = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
Parser.NUMBERS_TENS = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
Parser.NUMBERS_TEENS = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];

export {
	Parser
};