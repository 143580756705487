const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
const Parser = require("../lib/dutils.js").Parser;
const {RenderHref, getFirstBook} = require('./renderhref.jsx');
const {getItemType} = require('../lib/character.js');
const {truncateStr} = require('./stdedit.jsx');

const maxResults=10;
let addRowToCombat;

class SearchEverything extends React.Component {
    constructor(props) {
        super(props);
        this.anchorRef = React.createRef();
        this.state={searchVal:"", showSearch:false, selected:null, showResult:false};
        addRowToCombat=require('./encountermonsterlist.jsx').addRowToCombat;
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer=null;
        }
    }

    onFocus() {
        if (this.focusTimer) {
            clearTimeout(this.focusTimer);
        }
        this.setState({hasFocus:true});
    }

    onBlur() {
        const t=this;

        if (this.focusTimer) {
            clearTimeout(this.focusTimer);
        }
        this.focusTimer=setTimeout( function () {
            t.setState({hasFocus:false});
            t.focusTimer=null;
        },200);
    }

	render() {
        const href = this.state.showResult?<RenderHref 
            href={this.state.selected} 
            open={this.state.showResult} 
            onClose={this.closeDetails.bind(this)}
            character={this.props.getCharacter && this.props.getCharacter()}
            addToEncounter={this.props.addToEncounter||(campaign.isGMCampaign()?addRowToCombat:null)}
        />:null;

        if (this.props.editBox) {
            return <div className="flex">
                <div className="flex-auto"/>
                <div className="defaultbackground ma2 mw7 w-100 pa2 br3 shadow-1" ref={this.anchorRef}>
                    <TextField
                        placeholder="search"
                        value={this.state.searchVal}
                        fullWidth
                        onChange={this.onChange.bind(this)}
                        onFocus={this.onFocus.bind(this)}
                        onBlur={this.onBlur.bind(this)}
                        InputProps={{
                            endAdornment:<InputAdornment position="end"><span className="fas fa-search pa1 gray-60"/></InputAdornment>
                        }}
                    />
                    {this.state.hasFocus?<Popper
                        anchorEl={this.anchorRef.current}
                        open
                        placement="bottom-start"
                    >
                        <Paper square className="ml2">
                            {this.getSearchResults(true)}
                        </Paper>
                    </Popper>:null}

                    {href}
                </div>
                <div className="flex-auto"/>
            </div>;
        }

        return <div>
            <span className="fas fa-search pa1 hoverhighlight" onClick={this.clickSearch.bind(this)}/>
            <Popover
                anchorEl={this.state.anchorEl}
                open={this.state.showSearch}
                className="nodrag"
                onClose={this.handleClose.bind(this, false)}
                anchorOrigin={{vertical: 'top',horizontal: 'left'}}
                transformOrigin={{vertical: 'top',horizontal: 'right'}}
                marginThreshold={0}
                PaperProps={{square:true}}
            >
                <div className="w8 maxvw-80 pa1">
                    {this.state.showSearch?<TextField
                        placeholder="search"
                        value={this.state.searchVal}
                        fullWidth
                        autoFocus
                        onChange={this.onChange.bind(this)}
                    />:null}
                    {this.getSearchResults(this.state.showSearch)}
                </div>
            </Popover>
            {href}
        </div>;
    }

    clickSearch(event) {
        this.setState({showSearch:true, anchorEl:event.target});
    }

    handleClose() {
        this.setState({showSearch:false, searchVal:"", searchResults:[], timerRunning:false});
    }

    onChange(event) {
        const t=this;
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer=null;
        }
        this.timer = setTimeout(function () {
            t.updateSearch();
            t.timer=null;
        }, 500);

        this.setState({searchVal:event.target.value, timerRunning:true});
    }

    getSearchResults(show) {
        const ret = [];
        const text = this.state.searchVal;
        let sr = this.state.searchResults;

        if (!show){
            return null;
        } else if (!text || !text.length) {
            // get MRU
            sr = campaign.getMRUList("searchhistory");
            if (!sr.length) {
                return null;
            }
        }

        if ((!sr || !sr.length) && !this.state.timerRunning) {
            return <div className="pa2">no results</div>;
        }

        let lastr;
        for (let i=0;i<(sr?sr.length:0);i++) {
            const r = sr[i];
            
            if (i < maxResults) {
                let showBook;
                if (lastr && (lastr.type==r.type) && (lastr.name==r.name)) {
                    showBook=true;
                } else if (i+1 < sr.length) {
                    const check = sr[i+1];
                    showBook = (check.type==r.type) && (check.name==r.name);
                }
                ret.push(<MenuItem 
                    component="div" 
                    dense 
                    key={i} 
                    onClick={this.onNav.bind(this, r)}
                >
                    <span className="f4"><span className="ttc dib">{r.type}</span>: {showBook?truncateStr(getFirstBook(r.href),30)+":":null} {r.name}</span>
                </MenuItem>);
            } else {
                ret.push(<div key="more" className="pl4 pb2"> ...</div>)
            }
            lastr=r;
        }
        return ret;
    }

    onNav(val) {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer=null;
        }
        val.description = val.name;
        campaign.addMRUList("searchhistory", val)

        this.setState({showSearch:false, searchVal:"", searchResults:[], timerRunning:false, selected:val.href, showResult:true});
    }

    closeDetails(){
        this.setState({selected:null, showResult:false})
    }

    updateSearch() {
        this.setState({searchResults:searchContent(this.state.searchVal), timerRunning:false});
    }
}

function ignoreSpecial(string) {
    return string.replace(/[^a-zA-Z0-9 ]/g, '.');
}

class SearchResults {
    constructor(text){
        this.matches = [];
        this.min = 10000000;
        this.setText(text);
    }

    setText(text) {
        if (text.endsWith(",")) {
            text = text.substr(0,text.length-1);
        }
        this.rawText = text;
        while (text.length && text.charAt(text.length-1).match(/\W/)){
            text = text.substr(0,text.length-1);
        }
        while (text.length && text.charAt(0).match(/\W/)){
            text = text.substr(1,text.length-1);
        }
        const escaped = ignoreSpecial(text).replace(/s/ig, "'*s'*");
        this.f=new RegExp('\\b'+escaped, "i");
    }

    test(val, mult) {
        if (!val) {
            return 0;
        }
        const pos = val.search(this.f);

        if (pos < 0) {
            return 0;
        } else if (pos == 0) {
            if (val.length == this.rawText.length) {
                return 1000 * (mult||1);
            }
            if (val[this.rawText.length]==" ") {
                return 200 * (mult||1);
            }
            return 100 * (mult||1);
        } else {
            if (this.rawText.length+pos == val.length) {
                return 90 * (mult||1);
            }
            if (val[pos+this.rawText.length]==" "){
                return 50 * (mult||1);
            }
            return 10 * (mult||1);
        }
    }

    add(val) {
        for (let i in this.matches) {
            if (this.matches[i].href == val.href){
                return;
            }
        }
        if (this.matches.length > maxResults) {
            // need to kick someone out
            if (val.match <= this.min) {
                // already full
                return;
            }

            this.matches.push(val);
            this.matches.sort(function (a,b) {return b.match-a.match });
            const last = this.matches.pop();
            this.min = last.match;
        } else {
            if (val.match < this.min) {
                this.min = val.match;
            }
            this.matches.push(val);
            this.matches.sort(function (a,b) {return b.match-a.match });
        }
    }
}

function searchContent(text, skip, searchResults) {
    if (!text || !text.length) return [];
    let prefixRestrict;
    let stext = text;
    const colonPos = text.indexOf(":");
    if (colonPos > 0) {
        prefixRestrict = stext.substr(0,colonPos).toLowerCase();
        if (!standardTypes[prefixRestrict]) {
            let found;
            const customTypes = campaign.getCustomTablesList();
            for (let i in customTypes) {
                if (prefixRestrict == customTypes[i].toLowerCase()) {
                    found=true;
                    break;
                }
            }
            if (!found) {
                prefixRestrict=null;
            }
        }
        if (prefixRestrict) {
            stext = stext.substr(colonPos);
        }
    }

    if (!searchResults) {
        searchResults = new SearchResults(stext);
    } else {
        searchResults.setText(stext);
    }
    const playerMode = campaign.getPrefs().playerMode;

    if ((!skip || !skip.mycharacters) && (!prefixRestrict || (prefixRestrict=="my character"))) searchGeneric(searchResults, campaign.getMyCharacters(), "My Character", "mycharacters", 100);
    if ((!skip || !skip.players) && !playerMode && (!prefixRestrict || (prefixRestrict=="campaign character"))) searchGeneric(searchResults, campaign.getPlayers(), "Campaign Character", "players", 100);
    if ((!skip || !skip.monsters) && (!prefixRestrict || (prefixRestrict=="monster"))) searchMonsters(searchResults);
    if ((!skip || !skip.plannedencounter) && (!prefixRestrict || (prefixRestrict=="planned encounter"))) searchGeneric(searchResults, campaign.getPlannedEncounters(), "Planned Encounter", "plannedencounter", 90);

    if ((!skip || !skip.books) && (!prefixRestrict || (prefixRestrict=="book"))) searchBooks(searchResults);
    if ((!skip || !skip.races) && (!prefixRestrict || (prefixRestrict=="race"))) searchGeneric(searchResults, campaign.getRaces(), "race");
    if (!skip || !skip.classes) searchClasses(searchResults, prefixRestrict);
    if ((!skip || !skip.backgrounds) && (!prefixRestrict || (prefixRestrict=="background"))) searchGeneric(searchResults, campaign.getAllBackgrounds(), "background", "backgrounds");
    if ((!skip || !skip.feats) && (!prefixRestrict || (prefixRestrict=="feat"))) searchGeneric(searchResults, campaign.getAllFeats(), "feat", "feats");
    if ((!skip || !skip.spells) && (!prefixRestrict || (prefixRestrict=="spell"))) searchGeneric(searchResults, campaign.getSpellListByName(), "spell");
    if ((!skip || !skip.senses) && (!prefixRestrict || (prefixRestrict=="senses"))) searchBuiltin(searchResults, Parser.SENSE_JSON_TO_FULL, "senses");
    if ((!skip || !skip.coins) && (!prefixRestrict || (prefixRestrict=="coins"))) searchGeneric(searchResults, Parser.COINS_Entries, "coins", null, null, true);
    if ((!skip || !skip.skills) && (!prefixRestrict || (prefixRestrict=="skills"))) {
        searchBuiltin(searchResults, Parser.SKILL_JSON_TO_FULL, "skills");
        searchBuiltin(searchResults, campaign.getExtensionSkillDescriptions(), "skills");
    }
    if ((!skip || !skip.actions) && (!prefixRestrict || (prefixRestrict=="actions"))) searchBuiltin(searchResults, Parser.ACTION_JSON_TO_FULL, "actions");

    if (!skip || !skip.customTables) {
        const customTypes = campaign.getCustomTablesList();
        for (let i in customTypes) {
            const ct = customTypes[i];
            if (!prefixRestrict || (prefixRestrict==ct.toLowerCase())) {
                searchCustomList(searchResults, ct);
            }
        }
    }

    if ((!skip || !skip.items) && (!prefixRestrict || (prefixRestrict=="item"))) searchGeneric(searchResults, campaign.getSortedItemsList(), "item");
    if ((!skip || !skip.audio) && (!prefixRestrict || (prefixRestrict=="audio clip"))) searchGeneric(searchResults, campaign.getAudio(), "audio clip", "audio");

    text =text.trim();
    while (text.length && text.charAt(text.length-1).match(/\W/)){
        text = text.substr(0,text.length-1);
    }
    while (text.length && text.charAt(0).match(/\W/)){
        text = text.substr(1,text.length-1);
    }

    if ((searchResults.matches.length<maxResults) && (text.length>2)) {
        if (text.endsWith("s")) {
            searchContent(text.substr(0, text.length-1), skip, searchResults);
            if (searchResults.matches.length < maxResults) {
                if (text.endsWith("es")) {
                    searchContent(text.substr(0, text.length-2), skip, searchResults);
                }
            }
        }
    }

    return searchResults.matches;
}

const standardTypes={
    "my character":1,
    "campaign character":1,
    "monster":1,
    "planned encounter":1,
    "book":1,
    "race":1,
    "class":1,
    "subclass":1,
    "background":1,
    "feat":1,
    "spell":1,
    "senses":1,
    "coins":1,
    skills:1,
    actions:1,
    item:1,
    "audio clip":1,
}

function searchBooks(sr) {
    const books = campaign.getBookList();

    for (var b in books){
        const book=books[b];
        var baseWeight = 1;

        if (book.name == "Standard Reference Document") {
            baseWeight = 0.1;
        }

        const m = sr.test(book.displayName,baseWeight);
        if (m) {
            sr.add({
                match:m,
                type:"book", 
                href:"#book?id="+encodeURIComponent(book.name),
                name:book.displayName
            });
        } else {
            const chapters = book.chapters || [];

            for (let i in chapters) {
                const chapter = chapters[i];
                const m =sr.test(chapter.name,baseWeight*0.9);

                if (m) {
                    sr.add({
                        match:m,
                        type:"book", 
                        href:"#book?id="+encodeURIComponent(book.name)+"&fragment="+encodeURIComponent(chapter.fragment),
                        name:truncateStr(book.displayName,30)+": "+chapter.name
                    });
                } else {
                    for (let s in chapter.sections) {
                        const sec = chapters[i].sections[s];
                        const m = sr.test(sec.name,baseWeight*0.8);
                        if (m) {
                            sr.add({
                                match:m,
                                type:"book", 
                                href:"#book?id="+encodeURIComponent(book.name)+"&fragment="+encodeURIComponent(sec.fragment),
                                name:truncateStr(book.displayName,30)+": "+sec.name
                            });
                        } else {
                            for (let ss  in sec.subsections) {
                                const subsection = sec.subsections[ss];
                                const m = sr.test(subsection.name, baseWeight*0.7);

                                if (m) {
                                    sr.add({
                                        match:m,
                                        type:"book", 
                                        href:"#book?id="+encodeURIComponent(book.name)+"&fragment="+encodeURIComponent(subsection.fragment),
                                        name:truncateStr(book.displayName,30)+": "+subsection.name
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


function searchMonsters(s) {
    const monsterNames = campaign.getMonsterListByName();
     
    for (var i in monsterNames){
        const m=monsterNames[i];
        const swim = (m.speed||{}).swim;
        const fly = (m.speed||{}).fly;

        const match = s.test(m.displayName) || s.test(Parser.sizeAbvToFull(m.size), 0.01) || s.test(Parser.monTypeToFullObj(m.type).asText, 0.02) || (swim?s.test("swimming", 0.01):0) || (fly?s.test("flying", 0.01):0);
        if (match) {
            s.add({
                match,
                type:"monster", 
                href:"#monster?id="+encodeURIComponent(m.name),
                name:m.displayName
            });
        }
    }
}

function searchClasses(s,prefixRestrict) {
    const classList = campaign.getClassesListByName();
     
    for (var i in classList){
        const c=classList[i];

        if (!prefixRestrict || prefixRestrict=="class") {
            const match = s.test(c.displayName);
            const base ="#class?class="+encodeURIComponent(c.className);
            if (match) {
                s.add({
                    match,
                    type:"class", 
                    href:base,
                    name:c.displayName
                });
            } else {
                searchClassFeatures(s, c, base);
            }
        }

        if (!prefixRestrict || prefixRestrict=="subclass") {
            const subclassList = campaign.getSubclasses(c.className)

            for (var x in subclassList){
                const sc=subclassList[x];
        
                const match = s.test(sc.displayName);
                const scbase = "#class?class="+encodeURIComponent(c.className)+"&subclass="+encodeURIComponent(sc.subclassName);
                if (match) {
                    s.add({
                        match,
                        type:"subclass", 
                        href:scbase,
                        name:sc.displayName
                    });
                } else {
                    searchClassFeatures(s, sc, scbase);
                }
            }
        }
    }
}

function searchClassFeatures(s, cls, base) {
    for (var r=0; r<20; r++){
        for (let f in cls.classFeatures[r]){
            const fn = cls.classFeatures[r][f];
            const m = s.test(fn.name,0.5);
            if (m) {
                s.add({
                    match:m,
                    type:"class", 
                    href:base+"&search="+encodeURIComponent(fn.name),
                    name:cls.displayName+" - "+fn.name
                });
            } else if (fn.options) {
                for (let x in fn.options ){
                    const on = fn.options[x];
                    const sm = s.test(on.name,0.4);
                    if (sm) {
                        s.add({
                            match:sm,
                            type:"class", 
                            href:base+"&search="+encodeURIComponent(fn.name)+"&ss="+encodeURIComponent(on.name),
                            name:cls.displayName+" - "+on.name
                        });
                    }
                }
            }
        }
    }
}

function searchGeneric(s, l, type, hrefType, weight, matchName) {
    for (var i in l){
        const li=l[i];

        const dn = li.displayName||li.name;
        let m = s.test(dn, weight);
        if (!m && matchName) {
            m=s.test(li.name, weight);
        }
        if (m) {
            s.add({
                match:m,
                type:(type=="race"&&li.gamesystem=="5e24")?"species":type, 
                href:"#"+(hrefType||type)+"?id="+encodeURIComponent(li.name),
                name:dn
            });

        }
    }
}

function searchCustomList(s, type, weight) {
    const l = campaign.getAllCustom(type);

    for (var i in l){
        const li=l[i];

        const dn = li.displayName;
        const m = s.test(dn, weight);
        if (m) {
            s.add({
                match:m,
                type:type, 
                href:"#customlist?id="+encodeURIComponent(li.id)+"&type="+encodeURIComponent(type),
                name:dn
            });

        }
    }
}

function searchBuiltin(s, l, type, hrefType, weight,synonyms) {
    for (var i in l){

        const m = s.test(i, weight);
        if (m) {
            s.add({
                match:m,
                type:type, 
                href:"#"+(hrefType||type)+"?id="+encodeURIComponent(i),
                name:i
            });

        }
    }
    for (let i in synonyms){
        const m = s.test(i, weight);
        if (m) {
            s.add({
                match:m,
                type:type, 
                href:"#"+(hrefType||type)+"?id="+encodeURIComponent(synonyms[i]),
                name:i
            });

        }
    }
}

const shortExceptions={"gp":true, "pp":true, "sp":true, "cp":true, "ep":true};

class SearchLinks {
    constructor() {
        const fn = this.resetSearchLinks.bind(this);
        // make sure to keep this list aligned with what is in the search list
        globalDataListener.onChangeCampaignContent(fn, "spells");
        globalDataListener.onChangeCampaignContent(fn, "booktoc"); // special value just for the toc change in a book which is all that is searched
        globalDataListener.onChangeCampaignContent(fn, "customTypes");
        globalDataListener.onChangeCampaignContent(fn, "monsters");
        globalDataListener.onChangeCampaignContent(fn, "items");
        globalDataListener.onChangeCampaignContent(fn, "extensions");
        globalDataListener.onChangeCampaignContent(fn, "audio");
    }
    resetSearchLinks() {
        this.linkMap=null;
    }

    buildMap() {
        this.linkMap = {};

        //this.addGeneric(campaign.getRaces());
        //this.addClasses();
        //this.addGeneric(campaign.getAllBackgrounds());
        //this.addGeneric(campaign.getAllFeats());
        this.addGeneric(campaign.getSpellListByName());
        this.addBuiltin(Parser.SENSE_JSON_TO_FULL);
        this.addGeneric(Parser.COINS_Entries,true);
        
        this.addBuiltin(Parser.SKILL_JSON_TO_FULL);
        this.addBuiltin(campaign.getExtensionSkillDescriptions());
        //this.addBuiltin(Parser.ACTION_JSON_TO_FULL);
        //this.addBuiltin(Parser.CONDITIONS_JSON_TO_FULL);
        this.addBooks();
    
        const customTypes = campaign.getCustomTablesList();
        for (let i in customTypes) {
            this.addCustomList(customTypes[i]);
        }
    
        this.addGeneric(campaign.getMonsterListByName());
        this.addItems();
        this.addGeneric(campaign.getAudio());
        //this.addGeneric(campaign.getSortedItemsList());            
    }

    checkLink(text) {
        if (!this.linkMap) {
            this.buildMap();
        }
        text = text.toLowerCase();
        text = text.replace(/[^\w ]/g,'');
        if ((text.length < 3) && !shortExceptions[text]) {
            return;
        }
        let ret = this.linkMap[text];
        if ((!ret || ret<=1) && text.endsWith("s")){
            let lret = this.linkMap[text.substr(0,text.length-1)];
            if (!ret || lret>ret) {
                ret = lret;
            }

            if ((!ret || ret<=1) && text.endsWith("es")) {
                lret = this.linkMap[text.substr(0,text.length-2)];
                if (!ret || lret>ret) {
                    ret = lret;
                }
            }
        }
        return ret;
    }

    addLink(text, requireMulti) {
        if (!text) {
            return;
        }
        text = text.trim().toLowerCase();
        text = text.replace(/[^\w ]/g,'');
        if (!requireMulti || text.includes(" ")) {
            this.linkMap[text]=10;
        }
        let pos =0;

        while (pos >=0) {
            pos = text.lastIndexOf(" ");
            if (pos >=0) {
                text = text.substring(0,pos);
                if (!this.linkMap[text]) {
                    this.linkMap[text] = 1;
                }
            }
        }
    }

    addGeneric(l,includeName) {
        for (let i in l){
            const li=l[i];
    
            const dn = li.displayName||li.name;
            this.addLink(dn);
            if (includeName){
                this.addLink(li.name);
            }
        }
    }

    addItems() {
        const l = campaign.getSortedItemsList()
        for (let i in l){
            const li=l[i];
    
            const dn = li.displayName||li.name;
            this.addLink(dn, !["Armor","Weapon"].includes(getBasicItemType(li)));
        }
    }

    addClasses() {
        const classList = campaign.getClassesListByName();
         
        for (let i in classList){
            const c=classList[i];
    
            this.addLink(c.className);
   
            const subclassList = campaign.getSubclasses(c.className)
    
            for (let x in subclassList){
                const sc=subclassList[x];
        
                this.addLink(sc.subclassName);
            }
        }
    }    

    addBooks() {
        const books = campaign.getBookList();
         
        for (let i in books){
            const b=books[i];
    
            this.addLink(b.displayName);

            const chapters = b.chapters||[];
            for (let x in chapters ){
                const c = chapters[x];
                this.addLink(c.name);
                for (let y in c.sections) {
                    const s = c.sections[y];
                    this.addLink(s.name);
                    for (let z in s.subsections) {
                        this.addLink(s.subsections[z].name);
                    }
                }
            }
        }
    }    

    addCustomList(type) {
        const l = campaign.getAllCustom(type);
    
        for (let i in l){
            const li=l[i];
            this.addLink(li.displayName);
        }
    }

    addBuiltin(l) {
        for (let i in l){
            this.addLink(i);
        }
    }    
}

const {itemTypeFromAbbreviation} = require("../lib/stdvalues.js");

function getBasicItemType(it) {
    return itemTypeFromAbbreviation[getItemType(it)]||"";
}

const searchLinks = new SearchLinks();


export {
    SearchEverything,
    SearchResults,
    searchContent,
    searchLinks
};