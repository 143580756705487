const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');
const {ListFilter} = require('./listfilter.jsx');
const {Book,SubBook} = require('./book.jsx');
const {Chat} = require('../lib/chat.js');

const {SelectMultiTextVal, TextVal, DeleteWithConfirm} = require('./stdedit.jsx');

class RenderJournal extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {list:campaign.getJournal()};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:campaign.getJournal()})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "journal");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "journal");
    }

	render() {
        return <div className="pa1 defaultbackground tourJournal">
            <ListFilter 
                list={this.state.list}
                showAll
                filters={journalListFilters}
                render={journalListRender}
                onClick={this.doClick.bind(this)}
                extraButtons={<Button className="ma2" onClick={this.onNew.bind(this)} size="small" variant="outlined" color="primary">New</Button>}
                getListRef={this.saveRef.bind(this)}
            />
            <div className="mv1 hk-well">
                Journal entries are visible to both the gamemaster and players to track shared information.  {campaign.isGMCampaign?"Click on sections of the book for option to add book references to journal.":null}
            </div>
            <EditJournal character={this.props.character} open={this.state.showEdit} journal={this.state.journalName} newJournal={this.state.newJournal} onClose={this.hide.bind(this)} extraButtonsFn={this.getExtraButtons.bind(this)}/>
        </div>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(journal) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(journal))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.doClick.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.doClick.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    onNew() {
        const newJournal={
            name:campaign.newUid()
        }
        this.setState({showEdit:true, journalName:null, newJournal});
    }

    doClick(name) {
        this.setState({showEdit:true, journalName:name, newJournal:null});
    }

    hide(){
        this.setState({showEdit:false});
    }
}

const journalListFilters = [
    {
        filterName:"Keywords",
        fieldName:"keywords",
        convertField: function (v,it) {
            const ret= (v||"none").split(",").map(function (a){return a.trim().toLowerCase()});
            return ret;
        }
    },
]

function journalListRender(it) {
    const d = new Date(it.timestamp);
    return <div>
        <div>{it.bookname?<span className="fas fa-book ph1"/>:null}{it.url?<span className="fas fa-image ph1"/>:null}{it.displayName} <i className="f6">{d.toLocaleString("en",{year:"numeric", month:"short", day:"numeric", weekday:"short", hour:"numeric", minute:"2-digit"})}</i></div>
        {it.description?<div className="stdcontent maxh3 overflow-hidden"><Renderentry className="llb-3" entry={it.description}/></div>:null}
    </div>
}

class EditJournal extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {it:props.newJournal||campaign.getJournalInfo(props.journal), dirty:!!props.newJournal, edit:!!props.newJournal};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.journal != prevProps.journal)||(this.props.newJournal != prevProps.newJournal)||(this.props.open && this.props.open!= prevProps.open)) {
            this.setState({it:this.props.newJournal||campaign.getJournalInfo(this.props.journal), dirty:!!this.props.newJournal, edit:!!this.props.newJournal});
        }
    }

    render() {
        const {it,dirty, edit} = this.state;
        if (!this.props.open || !it) {
            return null;
        }

        const width = Math.min(window.innerHeight,window.innerWidth)*0.7;
        const portrait = ((it.imgHeight||0)>(it.imgWidth||0));
        const extraButtonsFn = this.props.extraButtonsFn;

        return  <Dialog
            open
            maxWidth="md"
            fullWidth
            classes={{paper:it.bookname&&!it.fragment?"minvh-90":null}}
        >
            <DialogTitle onClose={this.onClose.bind(this)}>
                {edit?<TextVal    
                    text={it.displayName}
                    fullWidth
                    inputProps={{className:"f1 titletext titlecolor ignoreDrag"}}
                    placeholder="Journal entry title"
                    onChange={this.onChangeField.bind(this,"displayName")}
                />:it.displayName}
            </DialogTitle>
            <DialogContent className={"stdcontent"+(it.bookname&&!it.fragment?" flex-none":"")}>
                {edit?<TextVal text={it.keywords||""} onChange={this.onChangeField.bind(this,"keywords")} fullWidth placeholder="comma separated keywords" helperText="Keywords"/>:null}
                {edit&&(!it.bookname||it.fragment)?
                <EntityEditor onChange={this.onChangeField.bind(this,"description")} entry={it.description} placeholder="Description"/>
                    :
                <Renderentry entry={it.description} character={this.props.character}/>}
                {it.bookname&&it.fragment?<div className="nh1"><SubBook bookname={it.bookname} fragment={it.fragment} character={this.props.character}/></div>:null}
                {it.url?<div>
                    {portrait?<img src={it.url} height={Math.min(it.imgHeight,width)}/>:<img src={it.url} width={Math.min(it.imgWidth,width)}/>}
                </div>:null}
            </DialogContent>
            {it.bookname&&!it.fragment?<div className="flex-auto w-100 h4 overflow-hidden relative ignoreDrag ph2">
                <div className="absolute t00 h-100 w-100 pr4">
                    <Book bookname={it.bookname}/>
                </div>
            </div>:null}
            <DialogActions>
                {!edit && extraButtonsFn && extraButtonsFn(it.name)}
                {this.props.journal?<DeleteWithConfirm 
                    onClick={this.onDelete.bind(this)} 
                    useButton 
                />:null}
                {!edit?<Button disabled={!it.displayName} onClick={this.editData.bind(this)} color="primary">
                    Edit
                </Button>:null}
                {edit?<Button disabled={!it.displayName||!dirty} onClick={this.saveData.bind(this)} color="primary">
                    Save
                </Button>:null}
                <Button onClick={this.onClose.bind(this)} color="primary">
                    {edit?"Cancel":"Close"}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    editData() {
        this.setState({edit:true});
    }

    onClose() {
        this.props.onClose();
    }

    saveData() {
        const {it}=this.state;
        campaign.updateCampaignContent("journal", it);
        this.props.onClose();
        Chat.addJournal(this.props.character, it.name, it.displayName, !!this.props.newJournal);
    }

    onChangeField(field,val) {
        const it = Object.assign({}, this.state.it);
        if (val == "none") {
            val = null;
        }
        it[field] = val;
        this.setState({it, dirty:true});
    }

    onDelete(e) {
        campaign.deleteCampaignContent("journal", this.props.journal);
        this.props.onClose();
    }
}

export {
    RenderJournal,
    EditJournal,
}