const {campaign,globalDataListener, areSameDeep,getDirectDownloadUrl, httpAuthRequestWithRetry,sortDisplayName} = require('../lib/campaign.js');
const {firebase} = require("./firebase.jsx");
const {marketplace} = require('../lib/marketplace.js');
import { getStorage, ref, uploadBytes } from "firebase/storage";
const React = require('react');
const {displayMessage} = require('./notification.jsx');
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
const {escapeRegExp, CheckVal, TextLabelEdit, SelectVal, DeleteWithConfirm,ClipboardCopy,TextVal,TextBasicEdit, SelectMultiTextVal,SelectTextVal} = require('./stdedit.jsx');
const {contentTypeMap,getBookContentTypes} = require('./contentmap.jsx');
const {joinCommaAnd,defaultSettings,defaultStorylines,packageGenres, packageTypes} = require('../lib/stdvalues.js');
const {ListFilter} = require('./listfilter.jsx');

class MyPackagesList extends React.Component {
    constructor(props) {
        super(props);

        this.state= {list:campaign.getSortedMyPackages()};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:campaign.getSortedMyPackages()})
    }

    componentDidMount() {
        const t=this;
        marketplace.load().then(function () {
            t.setState({loadedProducts:true});
        });
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "mypackages");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "mypackages");
    }

	render() {
        const pkgs = campaign.getSortedMyPackages();
        const list = [];

        for (let i in pkgs) {
            const p = pkgs[i];
            list.push({displayName:p.name, name:p.id, type:p.type, genre:p.genre, setting:p.setting, storyline:p.storyline, books:getBooks(p), products:getProducts(p), selectedBooks:p.pkgOptions?.selectedBooks});
        }

        return <div className="ph1 pb1">
            <ListFilter 
                list={list}
                render={this.renderPackage.bind(this)}
                filters={packageListFilters}
                onClick={this.defaultClick.bind(this)}
                select="click"
            />
            <BuildPackageDialog open={this.state.show} id={this.state.id} onClose={this.hide.bind(this)}/>
            <PackageDialog open={!!this.state.showPkgDetails} pkg={this.state.showPkgDetails} onClose={this.onClosePackage.bind(this)} viewOnly/>
        </div>;
    }

    renderPackage(it) {
        const books=[];

        let selected = it?.selectedBooks;
        for (let i in selected) {
            const b=campaign.getBookInfo(selected[i]);
            if (b) {
                if (books.length) {
                    books.push(", ");
                }
                books.push(<a key={b.name} href={"#book?id="+encodeURIComponent(b.name)}>{b.displayName}</a>);
            }
        }
    
        return <div>
            {it.displayName}
            <DeleteWithConfirm className="pa1 hoverhighlight fr" name={it.displayName} onClick={this.deleteMyPackage.bind(this, it.name)} confirm="delete"/>
            {books.length?<span className="i f6 near-black"> {books}</span>:null}
            {it.products.length?<div className="i f6">Products: {it.products.join(", ")}</div>:null}
        </div>
    }

    async deleteMyPackage(id) {
        try {
            await deleteSharedPackage(id, true);
            campaign.deleteMyPackage(id);
        } catch (err) {
            displayMessage("Error deleting the package. "+err.message);
        }
    }

    defaultClick(id) {
        const pkgInfo = campaign.getMyPackage(id);
        if (!pkgInfo.sharePackageUser) {
            this.setState({show:true, id});
        } else {
            this.setState({showPkgDetails:pkgInfo});
        }
    }

    onClosePackage() {
        this.setState({showPkgDetails:null});
    }

    hide() {
        this.setState({show:false});
    }
}

const packageListFilters = [
    {
        filterName:"Book",
        fieldName:"books",
    },
    {
        filterName:"Category",
        fieldName:"type",
        advancedOnly:true,
    },
    {
        filterName:"Theme",
        fieldName:"genre",
        advancedOnly:true,
    },
    {
        filterName:"Setting",
        fieldName:"setting",
        advancedOnly:true,
    },
    {
        filterName:"Storyline",
        fieldName:"storyline",
        advancedOnly:true,
    },
];

function getBooks(pkg) {
    const list =[];
    let selected = pkg?.pkgOptions?.selectedBooks;
    for (let i in selected) {
        const b=campaign.getBookInfo(selected[i]);
        if (b) {
            list.push(b.displayName);
        }
    }
    return list;
}

function getProducts(pkg) {
    const list =[];
    if (marketplace.loaded) {
        const products = (marketplace.products||{});
        for (let i in products) {
            const p = products[i];
            if ((p.includedPackages||[]).includes(pkg.id)) {
                list.push(p.displayName);
            }
        }

    }
    return list;
}


class MyPackagesHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state= {};
    }

    render() {
        return <span>
            My Packages
            <Button className="ml2 minw2" color="primary" variant="outlined" size="small" onClick={this.newMyPackage.bind(this)}>New</Button>
            <BuildPackageDialog open={this.state.show} id={this.state.id} onClose={this.hide.bind(this)}/>
        </span>;
    }

    newMyPackage() {
        const mp = {id:campaign.newUid(), name:"", publisher:campaign.getCurrentUser().displayName};
        campaign.updateMyPackage(mp);
        this.setState({show:true, id:mp.id});
    }

    hide() {
        this.setState({show:false});
    }
}

class PackagePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state={selected:props.selected||{}};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({selected:this.props.selected||{}});
        }
    }    

    render () {
        if (!this.props.open) {
            return null;
        }
        const pkgs = campaign.getAvailablePackageList();
        const list = [];
        const userId = campaign.userId;
        const f = new RegExp(escapeRegExp(campaign.email), "i");
        const adminLevel = campaign.adminStatus.level;

        for (let i in pkgs) {
            const p = Object.assign({},pkgs[i]);
            if (this.props.all || (p.userId == userId) || f.test(p.authorizedPublishers||"") || adminLevel) {
                p.displayName = p.name;
                p.name = p.id;
                p.thumb = p.thumbnail;
                delete p.art;
                list.push(p);
            }
        }
        list.sort(sortDisplayName);

        return <Dialog
            open
            maxWidth="md"
            fullWidth
            classes={{paper:"minvh-80"}}
        >
            <DialogTitle onClose={this.onClose.bind(this)}>{this.props.title|| "Pick Packages"}</DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={list} 
                    select="list"
                    render={packageRender} 
                    filters={packageFilters} 
                    selected={this.state.selected} 
                    onSelectedChange={this.onSelectedChange.bind(this)} 
                    onClick={this.clickPackage.bind(this)} 
                    showThumbnails
                    selectAll
                    border
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose.bind(this,true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.onClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <PackageDialog open={this.state.showPackage} pkg={this.state.showPackagePkg} onClose={this.onClosePackage.bind(this)} viewOnly/>
        </Dialog>;
    }

    onClosePackage() {
        this.setState({showPackage:false});
    }

    clickPackage(id) {
        const showPackagePkg = campaign.getPackageInfo(id);
        this.setState({showPackage:true, showPackagePkg})
    }

    onSelectedChange(selected) {
        this.setState({selected});
    }

    onClose(save) {
        if (save) {
            // cleanup packages that aren't known
            const selected = Object.assign({}, this.state.selected||{});
            const pkgs = campaign.getAvailablePackageList()||[];

            for (let i in selected) {
                if (pkgs.findIndex(function (a){return a.id==i})<0) {
                    console.log("remove missing package", i);
                    delete selected[i];
                }
            }
            this.props.onClose(selected);
        }else {
            this.props.onClose();
        }
    }
}

const thumbnailSize = 512;
class BuildPackageDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
        this.idKey = campaign.newUid();
    }

    componentDidMount() {
        const t=this;
        marketplace.load().then(function (){t.setState({loadedinfo:true})});
        if (this.props.open) {
            this.setPkgState();
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setPkgState();
        }
    }

    setPkgState() {
        const pkg =campaign.getMyPackage(this.props.id);
        this.setState({loading:false, publishPackage:pkg, descriptionEntry:{type:"html", html:pkg.description||""}});
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const pkg = this.state.publishPackage||{};
        const summary = pkg.summary||{};
        const summaryList = [];
        const dependList = [];
        const {types,settings, storylines,rulesets} = marketplace.getAllVals();

        for (let i in summary) {
            summaryList.push(<p key={i}>{summary[i]} {i}</p>);
        }

        for (let i in pkg.dependencies) {
            dependList.push(<span key={i}>{dependList.length?", ":""}{pkg.dependencies[i]}</span>);
        }

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Package Details</DialogTitle>
            <DialogContent>
                <div className="flex items-start flex-wrap">
                    <div className="flex-auto stdcontent f4 w-50">
                        <TextVal 
                            InputProps={{className:"f2 titletext titlecolor"}}
                            text={pkg.name||""} 
                            variant="standard" 
                            helperText="Package Name"
                            fullWidth 
                            onChange={this.onChangeVal.bind(this, "name")}
                            placeholder="package name"
                        />
                        <div className="mb1">
                            <TextVal text={pkg.publisher||""} fullWidth helperText="Publisher" onChange={this.onChangeVal.bind(this, "publisher")}/>
                        </div>
                        <TextLabelEdit label="Last Updated" text={pkg.lastPublished && (new Date(pkg.lastPublished)).toLocaleDateString(undefined, dateOptions)}/>      
                        <SelectMultiTextVal freeSolo csv value={pkg.type} values={types} onChange={this.onChangeVal.bind(this,"type")} fullWidth helperText="Category"/>
                        <SelectMultiTextVal csv value={pkg.genre} values={packageGenres} onChange={this.onChangeVal.bind(this,"genre")} fullWidth helperText="Theme"/>
                        <SelectMultiTextVal freeSolo csv value={pkg.setting} values={settings} onChange={this.onChangeVal.bind(this,"setting")} fullWidth helperText="Setting"/>
                        <SelectMultiTextVal freeSolo csv value={pkg.storyline} values={storylines} onChange={this.onChangeVal.bind(this,"storyline")} fullWidth helperText="Storyline"/>
                        <SelectTextVal text={pkg.ruleset||""} values={rulesets} onChange={this.onChangeVal.bind(this,"ruleset")} fullWidth helperText="Ruleset"/>
                        <div>
                            <TextLabelEdit label="Adventure level " text={pkg.startlevel} editable onChange={this.onChangeVal.bind(this,"startlevel")} values={levelVals} noDiv/>      
                            {pkg.startlevel?
                                <TextLabelEdit label=" - " text={pkg.endlevel} editable onChange={this.onChangeVal.bind(this,"endlevel")} values={levelVals} noDiv/>
                            :null
                            }
                        </div>
                        <div className="notetext f3 bb">Description</div>
                        <EntityEditor onChange={this.changeDescription.bind(this)} entry={this.state.descriptionEntry}/>
                        {dependList.length?<div className="mt2">
                            <b>Dependencies: </b>
                            {dependList}
                        </div>:null}
                    </div>
                    <div className="w6 ml2">
                        <input
                            accept="image/*"
                            className="dn"
                            id={"image-file-upload"+this.idKey}
                            type="file"
                            onChange={this.selectFile.bind(this)}
                        />
                        <label htmlFor={"image-file-upload"+this.idKey}>
                            {pkg.thumbnail?
                                <img className="w6" src={pkg.thumbnail}/>
                            :
                                <Button variant="outlined" component="span">
                                    upload thumbnail
                                </Button>
                            }
                        </label>
                        {summaryList.length?<div className="stdcontent">
                            <h3>Contents</h3>
                            {summaryList}
                        </div>:null}
                    </div>
                </div>
                <div className="hk-well mt2">
                    <div>
                        <CheckVal value={pkg.preventEmbedding} label="Prevent Embedding" onChange={this.onChangeVal.bind(this, "preventEmbedding")}/>
                    </div>
                    <div>
                        Prevent embedding will not allow users to build packages embedding any content from this package, including copies. Only references will be allowed.
                    </div>
                </div>
                <div className="hk-well mt2">
                    <div className="b">
                        Authorized Publishing Accounts
                    </div>
                    <div>
                        The comma separated list of accounts will be allowed to build a product for the marketplace that includes this package.  To authorize someone to publish add the email address of their account and share the package with them.
                    </div>
                    <TextVal text={pkg.authorizedPublishers} fullWidth onChange={this.onChangeVal.bind(this,"authorizedPublishers")} multiline/>
                </div>
                <div className="hk-well mt2">
                    <div>
                        <CheckVal value={pkg.noPictures} label="Exclude Pictures" onChange={this.onChangeVal.bind(this, "noPictures")}/>
                    </div>
                    <div>
                        Do not include non-token artwork from entries. This is useful when building reference documents that should not include the full artwork.
                    </div>
                </div>

            </DialogContent>
            <DialogActions>
                {pkg.uploaded?<Button onClick={this.onShare.bind(this)} color="primary">
                    Share
                </Button>:null}

                <Button disabled={!pkg.name} onClick={this.showBuild.bind(this)} color="primary">
                    Build
                </Button>
                <Button onClick={this.save.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <Dialog open={this.state.loading}>
                <DialogContent>
                    Loading...
                </DialogContent>
            </Dialog>
            <MyPackageInvitation open={this.state.sharePkg} name={pkg.name} id={pkg.id} onClose={this.closeShare.bind(this)}/>
            <PackageBookDialog open={this.state.showBuild} source={this.props.id} bookname={pkg.defaultBook} noPictures={pkg.noPictures} pkgOptions={pkg.pkgOptions} onClose={this.closeBuild.bind(this)}/>
        </Dialog>;
    }

    onShare(){
        if (!campaign.sharePackages) {
            displayMessage(<span>You don't have package sharing enabled.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to enable.</span>);
            return;
        }

        this.setState({sharePkg:true});
    }

    closeShare(){
        this.setState({sharePkg:false});
    }

    showBuild(){
        this.setState({showBuild:true});
    }

    changeDescription(nd) {
        const pkg = Object.assign({}, this.state.publishPackage);
        pkg.description = nd && nd.html;
        this.setState({publishPackage:pkg, descriptionEntry:nd})
    }

    onChangeField(field, event) {
        const val = event.target.value;
        this.onChangeVal(field, val);
    }

    onChangeVal(field, val) {
        const pkg = Object.assign({}, this.state.publishPackage);
        pkg[field] = val;
        this.setState({publishPackage:pkg})
    }

    async selectFile(e) {
        this.idKey = campaign.newUid();
        if (e.target.files.length == 1) {
            // upload file
            try {
                const userId = campaign.currentUser.uid;
                const storage = getStorage(firebase);
                const file = e.target.files[0];

                this.setState({loading:true, 
                    loadingMessage:"Loading Image "+file.name, 
                    image:null
                });

                const imageInfo = await resizeAndCropImage(file, thumbnailSize,thumbnailSize);
                const blob=imageInfo.blob;
                const path = "users/"+userId+"/MyPackages/"+this.idKey+".thumb.png";
                const fileRefThumb = ref(storage,path);

                await uploadBytes(fileRefThumb, blob, {contentType:"image/png",cacheControl: "public,max-age=4838400"});
                const downloadThumbURL = getDirectDownloadUrl(path);
                const pkg = Object.assign({}, this.state.publishPackage);
                pkg.thumbnail = downloadThumbURL;
                this.setState({loading:false, publishPackage:pkg});
            } catch (err) {
                this.errorSelectingFile("Error uploading thumbnail file", err);
            }
       }
    }

    errorSelectingFile(msg, err) {
        displayMessage(msg+"\nError:"+err);
        console.log(msg, err);
        this.setState({loading:false});
    }

    save() {
        campaign.updateMyPackage(this.state.publishPackage);
        this.props.onClose();
    }

    closeBuild(bookname, builtPkg, pkgOptions, pkgOriginal) {
        if (bookname && builtPkg) {
            // save pkg to service
            const t=this;
            const pkg = Object.assign({}, this.state.publishPackage);
            const currentUser = campaign.getCurrentUser();

            pkg.summary = getPkgSummary(builtPkg);
            pkg.defaultBook = bookname;
            pkg.userId = currentUser.uid;
            if (!pkg.publisher) {
                pkg.publisher = currentUser.displayName;
            }
            pkg.uploaded = true;
            pkg.pkgOptions = pkgOptions;
            pkg.dependencies = builtPkg.dependencies||null;
            pkg.dependenciesDetails = builtPkg.dependenciesDetails || null;
            delete pkg.dependDetails;
    
            pkg.majorVersion = 2;
            
            setTimeout(function () {
                restoreOrphans(builtPkg, pkg.id, pkgOriginal).then(function () {
                    campaign.publishPackage(pkg, builtPkg, pkgOriginal).then(function() {
                        let xhttp = new XMLHttpRequest();
                        const url = location.origin+"/search?cmd=addmy&uid="+currentUser.uid+"&pkg="+pkg.id;
            
                        xhttp.onreadystatechange = function() {
                            if (xhttp.readyState == 4) {
                                if (xhttp.status == 200) {
                                    displayMessage("Package built and saved.", function (){
                                        t.setPkgState();
                                    });
                                    //console.log("added package", url)
                                } else {
                                    displayMessage("Error adding package "+xhttp.status, function (){
                                        t.setPkgState();
                                    });
                                    console.log("error", xhttp)
                                }
                            }
                        }
                        xhttp.open("POST", url, true);
                        xhttp.send();
                    }, function (err) {
                        displayMessage("Error building package "+err.message);
                        console.log("Error building package", err);
                        t.setState({loading:false});
                    });
                }, function (err) {
                    displayMessage("Error building package "+err.message);
                    console.log("Error building package", err);
                    t.setState({loading:false});
                });
            }, 10);

            this.setState({showBuild:false, publishPackage:pkg, loading:true});
        } else {
            this.setState({showBuild:false});
        }
    }
}

const pkgReferenceOptions = ["reference", "embed"];
class PackageBookDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            const bookname = this.props.bookname;
            const pkgOptions = this.props.pkgOptions||{};
            const pkgOriginal = campaign.getPackageInfo(this.props.source);
            let selected = pkgOptions.selectedBooks;
            if (!Array.isArray(selected)) {
                selected = bookname?[bookname]:[];
            }
            this.setState({bookname, pkgOptions, selected, loading:!pkgOriginal, pkgOriginal});
            if (!pkgOriginal) {
                const t=this;
                campaign.fetchPackage(this.props.source).then(function(pkgOriginal) {
                    t.setState({pkgOriginal, loading:false})
                }, function() {
                    t.setState({loading:false})
                });
            }
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        const {BooksPicker, BookDialog,getBookDescription} = require('./book.jsx');
        const source = this.props.source;
        const expandedType = this.state.expandedType || {};
        const excludeList = this.state.excludeList||{};
        const selected = this.state.selected||[];
        selected.sort(sortBookNames);

        const bookPkg = this.state.bookname?(new PackageBuilder(selected,this.state.pkgOptions||{}, source, excludeList, this.props.noPictures)):null;
        const pkg = bookPkg && bookPkg.pkg;
        const unresolved = bookPkg && bookPkg.unresolved;
        const protectErrors = bookPkg && bookPkg.protectErrors;
        const pkgOptions = this.state.pkgOptions||{};
        const books = {};
        const bookList = [];
        const missing = [];
        const refs = [];

        if (pkg) {
            for (let i in unresolved){
                const u = unresolved[i];
                const il = [];

                for (let x in u) {
                    const selList = u[x];
                    for (let y in selList) {
                        const sel = selList[y];
                        il.push(<li key={x+y}><a onClick={this.showBook.bind(this, sel.book, sel.chapter, sel.section, sel.subsection)}>{getBookDescription(sel.book, sel.chapter, sel.section, sel.subsection)}</a></li>);
                    }
                }

                missing.push(<div key={i}>
                    <h3>{Object.keys(u).length} {i}</h3>
                    <ul>{il}</ul>
                </div>);
            }

            const srcSum = getPkgSourceSummary(bookPkg.fullPkg, source, excludeList, this.state.pkgOriginal);
            for (let s in srcSum) {
                const sl = srcSum[s];
                const sum = [];
                const isPackageSource = (s==source);
                const preventEmbedding = campaign.getSourcePreventEmbedding(s);

                for (let i in sl) {
                    const k = sl[i];
                    const expanded = expandedType[s+"."+i];
                    const detailsList = [];
        
                    if (expanded) {
                        k.list.sort(sortDisplayName);
                        for (let x in k.list) {
                            const it = k.list[x];
                            let include = true;
                            const altIt = isPackageSource?resolveContent(k.type, it.name, [source]):null;
                            if (altIt) {
                                include = !(excludeList[k.type] && excludeList[k.type][it.name.toLowerCase()]);
                            }
                            detailsList.push(<div key={x} className="ml4"><span className={altIt?(include?"hoverhighlight pa1 far fa-check-square":"hoverhighlight pa1 far fa-square"):"hoverhighlight near-black pa1 far fa-check-square"} onClick={altIt?this.toggleInclude.bind(this, k.type, it):null}/>{it.displayName||it.name}</div>);
                        }
                    }
                    sum.push(<div key={i}>
                        <div onClick={this.toggleType.bind(this,s+"."+i)}>
                            <span className={expanded?"hoverhighlight pa1 far fa-minus-square":"hoverhighlight pa1 far fa-plus-square"}/>
                            {i} {k.count}
                            {k.changed&& ((s=="My Creations") || (pkgOptions[s]=="embed") || (s==source))?(" ("+k.changed+" update)"):null}
                            {k.deleted?(" ("+k.deleted+" orphaned)"):null}
                        </div>
                        {detailsList}
                    </div>);
        
                }
                refs.push(<div key={s}>
                    <div className="f2 titletext titlecolor mt1">
                        {campaign.getSourceName(s)||s}&nbsp;
                {((s!="My Creations")&&(s!=source))?<span>({preventEmbedding?"reference":<SelectVal selectClass="titletext titlecolor f2 pt0" value={pkgOptions[s]||"reference"} values={pkgReferenceOptions} onClick={this.changeInclude.bind(this, s)}/>})</span>:null}
                    </div>
                    <div className="ml2">
                        {sum}
                    </div>
                </div>);
            }
        }

        let hasBooks = false;
        for (let i in selected) {
            const b=campaign.getBookInfo(selected[i]);
            if (b) {
                bookList.push(b.displayName);
                books[b.name] = b.displayName;
                hasBooks=true;
            }
        }

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.build.bind(this,false)}>Build Package</DialogTitle>
            <DialogContent>
                <div className="stdcontent">
                    <div  className="mb2">
                        Select books to build package.  The books and all related content will be included in the package.
                    </div>
                    <div className="tc mb1">
                        <Button onClick={this.onShowPickBooks.bind(this)} variant="outlined" color="primary">
                            Pick Books
                        </Button>
                    </div>
                    {hasBooks?<div className="mb2">Included Books: {joinCommaAnd(bookList)}</div>:null}
                    {hasBooks?<div className="mb2">
                        <SelectVal value={this.state.bookname||""} values={books} label="Default Book" fullWidth onClick={this.changeBook.bind(this)}/>
                    </div>:null}
                    {protectErrors?<div className="mv2">
                        <div className="f2 red b tc">**** The following list of creations are edited versions of protected content that cannot be republished:</div>
                        {protectErrors.join(", ")}
                    </div>:null}
                    {(missing.length)?<div className="mv2">
                        <div className="f2 red tc">**** Some references could not be resolved!</div>
                        {missing}
                    </div>:null}
                    {pkg?<div>
                        <h2>Contents</h2>
                        {refs}
                    </div>:null}
                </div>
            </DialogContent>
            <DialogActions>
                {pkg?<Button disabled={!!protectErrors} onClick={this.build.bind(this,true)} color="primary">
                    build
                </Button>:null}
                <Button onClick={this.build.bind(this,false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <Dialog open={this.state.loading}>
                <DialogContent>
                    Loading...
                </DialogContent>
            </Dialog>
            <BooksPicker open={this.state.pickBooks} onClose={this.onPickBooks.bind(this)} selected={this.state.selectedBooks}/>
            <BookDialog editable open={this.state.showBook} bookname={this.state.selectedBook} chapter={this.state.selectedChapter} section={this.state.selectedSection} subsection={this.state.selectedSubsection} onClose={this.hideBook.bind(this)}/>
        </Dialog>;
    }

    showBook(selectedBook, selectedChapter, selectedSection, selectedSubsection) {
        this.setState({showBook:true, selectedBook, selectedChapter:Number(selectedChapter), selectedSection:Number(selectedSection), selectedSubsection:Number(selectedSubsection)});
    }

    hideBook() {
        this.setState({showBook:false}); 
    }

    onShowPickBooks() {
        const selectedBooks = {};
        const selected = this.state.selected;
        for (let i in selected) {
            selectedBooks[selected[i]]=true;
        }
        this.setState({pickBooks:true, selectedBooks});
    }

    onPickBooks(s) {
        if (s) {
            const selected = [];
            let bookname = this.state.bookname;
            for (let i in s) {
                if (s[i]) {
                    if (campaign.getBookInfo(i)) {
                        selected.push(i);
                    }
                }
            }
            if (!selected.includes(bookname) && selected.length) {
                bookname = selected[0];
            }

            this.setState({pickBooks:false, selected,bookname});
        } else {
            this.setState({pickBooks:false});
        }
    }

    changeInclude(source, val) {
        const pkgOptions = Object.assign({}, this.state.pkgOptions||{});
        pkgOptions[source]=val;
        this.setState({pkgOptions});
    }

    changeBook(bookname) {
        this.setState({bookname});
    }

    build(doBuild) {
        if (!doBuild || !(this.state.selected||[]).length) {
            this.props.onClose();
        } else {
            const pkgOptions = Object.assign({}, this.state.pkgOptions||{});
            pkgOptions.selectedBooks = this.state.selected;
            const bookPkg = new PackageBuilder(this.state.selected,pkgOptions||{}, this.props.source, this.state.excludeList||{}, this.props.noPictures);

            this.props.onClose(this.state.bookname, bookPkg.pkg, pkgOptions||{}, this.state.pkgOriginal);
        }

    }

    toggleType(t) {
        const expandedType = Object.assign({}, this.state.expandedType||{});
        if (expandedType[t]) {
            delete expandedType[t];
        } else {
            expandedType[t]=true;
        }
        this.setState({expandedType})
    }

    toggleInclude(type, it) {
        const excludeList = Object.assign({}, this.state.excludeList||{});
        const name = it.name.toLowerCase();
        if (excludeList[type]) {
            if (excludeList[type][name]) {
                delete excludeList[type][name];
            } else {
                excludeList[type][name] = it;
            }
        } else {
            excludeList[type] = {};
            excludeList[type][name] = it;
        }
        this.setState({excludeList})
    }
}

function sortBookNames(a,b) {
    const ba=campaign.getBookInfo(a); 
    const bb=campaign.getBookInfo(b)

    return (ba?ba.displayName:"").toLowerCase().localeCompare((bb?bb.displayName:"").toLowerCase());
}

function resolveContent(type, name, ignorePackages) {
    const pkgList = campaign.getCurrentPackageList();
    name = name.toLowerCase();

    for (let i =pkgList.length-1; i>=0; i--) {
        const pkg = pkgList[i].pkg;
        if (!ignorePackages.includes(pkg.id)) {
            const it = (pkg[type]||[]).find(function (f){ return name==f.name.toLowerCase()});
            if (it) {
                return it;
            }
        }
    }
    return null;
}

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

class PackageDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({loading:false});
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        const pkg = this.props.pkg||{};

        const summary = pkg.summary||{};
        const summaryList = [];
        const dependList = [];
        const bookList = [];
        const canDelete = (this.props.type == "owned") && campaign.isSharedPackage(pkg.id);
        const mine = (pkg.userId == campaign.userId);

        for (let i in summary) {
            summaryList.push(<span key={i}>{summaryList.length?", ":""}{summary[i]} {i}</span>);
        }

        for (let i in pkg.dependencies) {
            dependList.push(<span key={i}>{dependList.length?", ":""}{pkg.dependencies[i]}</span>);
        }
        if (this.props.showBooks) {
            const books = pkg.books;
            for (let i in books) {
                const b = books[i];
                if ((b.type=="book") && campaign.getBookInfo(b.name)) {
                    bookList.push(<span key={b.name}>{bookList.length?", ":""}<a href={"/#book?id="+b.name}>{b.displayName}</a></span>)
                }
            }
        }

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>{pkg.name}</DialogTitle>
            <DialogContent>
                <div className="v-top f2 titletext titlecolor">
                    <img className="w6 fr ma2" src={pkg.thumbnail||"/noimage.png"}/>
                    {pkg.publisher?<div className="ml2 mb1 stdcontent">
                        by {pkg.publisher}
                    </div>:null}
                    {this.props.admin?<div className="ml2 mb1 stdcontent">
                        id:{pkg.id} user:{pkg.userId}
                    </div>:null}
                    <div className="ml2 mv1 stdcontent">
                        <TextLabelEdit label="Last Updated" text={pkg.lastPublished && (new Date(pkg.lastPublished)).toLocaleDateString(undefined, dateOptions)}/>      
                    </div>
                </div>
                <div className="notetext f4 pt3">
                    <TextLabelEdit fullWidth label="Category" text={pkg.type}/>                
                    <TextLabelEdit fullWidth label="Theme" text={pkg.genre}/>                
                    <TextLabelEdit fullWidth label="Setting" text={pkg.setting}/>                
                    <TextLabelEdit fullWidth label="Storyline" text={pkg.storyline}/>    
                    <TextLabelEdit fullWidth label="Ruleset" text={pkg.ruleset}/>    
                    <div>
                        <TextLabelEdit label="Adventure level " text={pkg.startlevel} noDiv/>      
                        {pkg.startlevel?
                            <TextLabelEdit label=" - " text={pkg.endlevel}noDiv/>
                        :null
                        }
                    </div>            
                </div>
                <div className="stdcontent">
                    {pkg.description?<Renderentry entry={{type:"html", html:pkg.description}}/>:null}
                    {summaryList.length?<div className="mv1">
                        <b>Includes:</b> {summaryList}
                    </div>:null}
                    {dependList.length?<div className="mv1">
                        <b>Dependencies:</b> {dependList}
                    </div>:null}
                    {bookList.length?<div className="mv1">
                        <b>Included Books:</b> {bookList}
                    </div>:null}
                </div>
            </DialogContent>
            <DialogActions>
                {this.props.admin?<Button className="mr1" color="primary" size="small" onClick={this.showAssignPackage.bind(this)}>Assign</Button>:null}
                {canDelete?<DeleteWithConfirm 
                    useButton 
                    name={pkg.name} 
                    onClick={this.deleteSharedPackage.bind(this)} 
                    confirm={pkg.name} 
                    extraDescription={
                        mine?"Content that is only in this package will be added back to your my creations."
                        :"If you delete this package, all of its content will no longer be available. To recover the content, ask owner to share it with you again."
                    }/>:null}
                {(this.props.type=="my"&&pkg.uploaded&&!this.props.viewOnly)?<Button onClick={this.onShare.bind(this)} color="primary">
                    Share
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <MyPackageInvitation open={this.state.sharePkg} name={pkg.name} id={pkg.id} onClose={this.closeShare.bind(this)}/>
            {this.props.admin?<TextBasicEdit show={this.state.showAssignPackage} onChange={this.doAssignPackage.bind(this)} label="Assign to Email" info="Enter the email address of the account to assign the package"/>:null}
            <Dialog open={this.state.loading}>
                <DialogContent>
                    Removing shared package...
                </DialogContent>
            </Dialog>
        </Dialog>;
    }

    showAssignPackage() {
        this.setState({showAssignPackage:true});
    }

    async doAssignPackage(email) {
        this.setState({showAssignPackage:false});
        if (email) {
            this.setState({loading:true});
            try {
                await httpAuthRequestWithRetry("POST", "/search?cmd=assignpackage", JSON.stringify({packageId:this.props.pkg.id,email}));
                displayMessage("Package assigned");
            } catch (error) {
                displayMessage("Could not assign the package: "+error.message);
            }
    
            this.setState({loading:false});
        }
    }

    onShare(){
        if (!campaign.sharePackages) {
            displayMessage(<span>You don't have package sharing enabled.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to enable.</span>);
            return;
        }

        this.setState({sharePkg:true});
    }

    closeShare(){
        this.setState({sharePkg:false});
    }

    async deleteSharedPackage() {
        const pkg = this.props.pkg||{};
        const mine = (pkg.userId == campaign.userId);

        this.setState({loading:true});
        try {
            await deleteSharedPackage(pkg.id, mine);
    
            this.setState({loading:false});
            this.props.onClose();
        } catch (err) {
            displayMessage("Could not remove shared package. "+err.message);
            this.setState({loading:false});
        };
    }
}

async function deleteSharedPackage(pkgid, mine) {
    if (mine) {
        let pkgData = campaign.getPackageInfo(pkgid);

        if (!pkgData) {
            await campaign.loadPackage(pkgid);
            pkgData = campaign.getPackageInfo(pkgid);
        }
        if (pkgData) {
            await restoreOrphans({}, pkgid, pkgData);
        }
    }
    await httpAuthRequestWithRetry("POST", "/search?cmd=removeshared", JSON.stringify({pkg:pkgid}));

    const globalDisabled = Object.assign({}, campaign.getUserSettings().disabledPackages||{});
    globalDisabled.owned = Object.assign({}, globalDisabled.owned || {});

    delete globalDisabled.owned[pkgid]
    campaign.setGlobalDisabledPackages(globalDisabled);
}

class GlobalEnablePackageDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {loading:false};
        this.loadFn = this.loadPackages.bind(this);
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignSettings(this.loadFn);
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignSettingsListener(this.loadFn);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.loadPackages();
        }
    }

    loadPackages() {
        const t= this;
        this.setState({globalDisabled:campaign.getUserSettings().disabledPackages||{}, loading:true,list:[]});
        campaign.getAllPackages().then(function (list){
            t.setState({loading:false, list});
        }, function (err){
            displayMessage("Error loading packages: "+err.toString());
            console.log("error loading packages", err);
            t.props.onClose();
        });
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        return <Dialog
            scroll="paper"
            maxWidth="md"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>Select Enabled Packages</DialogTitle>
            <DialogContent>
                {this.getList()}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <Dialog open={this.state.loading}>
                <DialogContent>
                    Loading...
                </DialogContent>
            </Dialog>
            <PackageDialog open={this.state.showPackage} pkg={this.state.showPackagePkg} onClose={this.onClosePackage.bind(this)} type="owned" viewOnly/>
        </Dialog>;
    }

    onClosePackage() {
        this.setState({showPackage:false});
    }

    clickPackage(id) {
        const list = this.state.list||[];
        const showPackagePkg = list.find(function (p) {return p.id==id});
        if (showPackagePkg) {
            this.setState({showPackage:true, showPackagePkg})
        }
    }

    getList() {
        const globalDisabled = campaign.getUserSettings().disabledPackages||{};
        const list = this.state.list||[];
        const fl=[];
        
        for (let i in list){
            const pkg = list[i];
            if (pkg) {
                const it = Object.assign({},pkg);
                it.displayName = pkg.name;
                it.name = pkg.id;
                it.thumb = pkg.thumbnail;
                delete it.art;
                fl.push(it);
            }
        }

        fl.sort(sortDisplayName);

        return <ListFilter 
            list={fl} 
            select="list"
            render={packageRender} 
            filters={packageFilters} 
            selected={globalDisabled.owned} 
            onSelectedChange={this.onChangeDisabled.bind(this)} 
            onClick={this.clickPackage.bind(this)} 
            showThumbnails
            inverted
            selectAll
            border
        />;
    }

    onChangeDisabled(owned) {
        const globalDisabled = Object.assign({}, campaign.getUserSettings().disabledPackages||{});
        globalDisabled.owned = owned;

        campaign.setGlobalDisabledPackages(globalDisabled);
        this.setState({globalDisabled, loading:false});
    }
}

class AdminPackageDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }


    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.loadPackages();
        }
    }

    loadPackages() {
        const t= this;
        this.setState({loading:true});
        campaign.getFullPackageList().then(function (list){
            t.setState({loading:false, list});
        }, function (err){
            displayMessage("Error loading packages: "+err.toString());
            console.log("error loading packages", err);
            t.props.onClose();
        });
    }

    doClose(save) {
        const {onClose} = this.props;
        if (save) {
            onClose(this.state.selected||{});
        } else {
            onClose();
        }
    }

	render() {
        const {open, select} = this.props;
        if (!open) {
            return null;
        }
        const {selected} = this.state;

        const list = this.state.list||[];
        const fl=[];
        
        for (let i in list){
            const pkg = list[i];
            if (pkg) {
                const it = Object.assign({},pkg);
                it.displayName = pkg.name;
                it.name = pkg.id;
                it.thumb = pkg.thumbnail;
                delete it.art;
                fl.push(it);
            }
        }

        fl.sort(sortDisplayName);

        return <Dialog
            scroll="paper"
            maxWidth="md"
            fullWidth
            open
        >
            <DialogTitle onClose={this.doClose.bind(this,false)}>Admin All Packages {fl.length}</DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={fl} 
                    render={packageRender} 
                    filters={packageFilters} 
                    onClick={this.clickPackage.bind(this)} 
                    showThumbnails
                    select={select?"list":null}
                    selectAll={!!select}
                    selected={select?this.state.selected:null} 
                    onSelectedChange={select?this.onSelectedChange.bind(this):null} 
                    border
                />
            </DialogContent>
            <DialogActions>
                {select?<Button onClick={this.doClose.bind(this,true)} color="primary">
                    Pick
                </Button>:null}
                <Button onClick={this.doClose.bind(this,false)} color="primary">
                    Close
                </Button>
            </DialogActions>
            <Dialog open={this.state.loading}>
                <DialogContent>
                    Loading...
                </DialogContent>
            </Dialog>
            <PackageDialog admin open={this.state.showPackage} pkg={this.state.showPackagePkg} onClose={this.onClosePackage.bind(this)} type="owned" viewOnly/>
        </Dialog>;
    }

    onSelectedChange(selected) {
        this.setState({selected});
    }

    onClosePackage() {
        this.setState({showPackage:false});
    }

    clickPackage(id) {
        const list = this.state.list||[];
        const showPackagePkg = list.find(function (p) {return p.id==id});
        if (showPackagePkg) {
            this.setState({showPackage:true, showPackagePkg})
        }
    }
}


function checkDisabledChange(olddisabled, newdisabled, pkgs) {
    console.log("disabled change",olddisabled, newdisabled)
    for (let i in newdisabled) {
        if (newdisabled[i] && !olddisabled[i]) {
            //package was disabled see what packages depend on this package
            console.log("check disable", i)
            const list = checkForDependencies(i, pkgs, olddisabled);
            if (list) {
                return {action:"disable", list};
            }
        }
    }

    for (let i in olddisabled) {
        if (olddisabled[i] && !newdisabled[i]) {
            // package was enabled see what packages it needs
            const list = checkDependentPackages(i, pkgs, olddisabled);
            console.log("check enable", i)
            if (list) {
                return {action:"enable", list};
            }
        }
    }
    return null;
}

function checkDependentPackages(pkgid, pkgs, disabled) {
    const pkg = pkgs.find(function (p) {return p.id==pkgid});
    if (!pkg) {
        console.log("could not find package in list?", pkgid, pkgs);
        return null;
    }
    const list = [];
    if (pkg.dependencies) {
        for (let i in pkg.dependencies) {
            if (disabled[i]) {
                list.push({pkgid:i, displayName:pkg.dependencies[i]});
            }
        }
    }
    return list.length?list:null;
}

function checkForDependencies(pkgid, pkgs, disabled) {
    const list = [];
    for (let x in pkgs) {
        const pkg = pkgs[x];
        let depend;

        if (!disabled[pkg.id] && pkg.dependencies && pkg.dependencies[pkgid]) {
            depend=true;
        }
        if (depend) {
            list.push({pkgid:pkg.id, displayName:pkg.name});
        }
    }
    return list.length?list:null;
}

class MyPackageInvitation extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {key:null };
    }

    loadKey(reset) {
        const t=this;
        this.setState({key:null});
        campaign.getPackageShareKey(this.props.id,reset).then(function (key) {
            t.setState({key});
        }, function (err) {
            displayMessage("Could not get a sharing key:"+err.toString());
            console.log("error getting key", err);
        })
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({key:null});
            this.loadKey();
        }
    }

    getMailToURL() {
        const subject="Try my Shard Tabletop package "+this.props.name;
        const body = 'To add the package to your account click this link: '+
            this.getJoinURL()+ '\n\nThis game is hosted on Shard Tabletop, the best way to play the world’s greatest role-playing game.\n\nThanks';
        
        return "mailto:?subject="+encodeURIComponent(subject)+"&body="+encodeURIComponent(body);
    }

    getJoinURL() {
        return window.location.origin+"/#home?sharekey="+encodeURIComponent(this.state.key)+"&sharepackage="+encodeURIComponent(this.props.id);
    }


    handleClose(event) {
        this.props.onClose();
        event.stopPropagation();
    };

    render() {
        if (!this.props.open)
            return null;
        
        return <Dialog
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this)}>Share Package {this.props.name}</DialogTitle>
            <DialogContent>
                {this.state.key?<div className="titlecolor f3">
                    Share link for {this.props.name} 
                    <ClipboardCopy text={this.getJoinURL()}>{this.getJoinURL()}</ClipboardCopy>
                    Send url to players to allow them to use the package.  <a href={this.getMailToURL()}>(Send Email)</a>
                </div>:"Loading share key..."}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.loadKey.bind(this,true)} color="primary">
                    Reset
                </Button>
                <Button onClick={this.handleClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

class AddSharedPackage extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {loading:true };
    }

    componentDidMount() {
        if (this.props.open) {
            this.startLoading();
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.startLoading();
        }
    }

    startLoading() {
        const t=this;
        const existingPkgs = (campaign.getOwnedPackages()||{}).packageList||[];

        campaign.fetchPackage(this.props.sharepackage).then(
            function (pkg) {
                t.setState({pkg, loading:false, alreadyLoaded:existingPkgs.includes(t.props.sharepackage)});
            },
            function (err) {
                displayMessage("Could not get shared package. Error:"+err.message, function () {
                    window.location.href = "/#home";
                });
            }
        );
	    this.state= {loading:true };
    }

    handleClose() {
        window.location.href = "/#home";
    };

    render() {
        if (!this.props.open)
            return null;

        const pkg=this.state.pkg;
        if (this.state.loading || !pkg) {
            return <Dialog open>
                <DialogContent>
                    Loading...
                </DialogContent>
            </Dialog>
        }

        return <Dialog
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this)}>Add Shared Package</DialogTitle>
            <DialogContent>
                <div className="stdcontent">
                    <p>{pkg.publisher} has shared a package, {pkg.name}, with you.</p>
                    {this.state.alreadyLoaded?
                        <p>You already have this package.</p>:
                        <p>If you add this package to your account, using the Add button, then you will see the books and all of the content published in the package.</p>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.showPackageDetails.bind(this)} color="primary">
                    Package Details
                </Button>
                <Button disabled={this.state.alreadyLoaded} onClick={this.addPackage.bind(this)} color="primary">
                    Add
                </Button>
                <Button onClick={this.handleClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <PackageDialog pkg={pkg} onClose={this.closePackageDetails.bind(this)} viewOnly open={this.state.showDetails}/>
        </Dialog>;
    }

    showPackageDetails() {
        this.setState({showDetails:true})
    }

    closePackageDetails() {
        this.setState({showDetails:false})
    }

    async addPackage() {
        // save pkg to service
        const pkg = this.state.pkg;

        this.setState({loading:true});
        try {
            await httpAuthRequestWithRetry("POST", "/search?cmd=addsharedpackage", JSON.stringify({pkg:pkg.id,key:this.props.sharekey}));
        } catch (err) {
            displayMessage("Error adding package: "+err.message);
            console.log("error adding package", err)
            this.setState({loading:false});
            return;
        }

        const mine = campaign.getMyPackages()[pkg.id];
        if (mine && mine.sharePackageUser) {
            campaign.deleteMyPackage(pkg.id)
        }
        gtag('config', 'UA-159662255-1', {
            'page_title' : "Add Shared Package",
            'page_path' : "/addpackage",
            'page_location' : "https://"+location.hostname+"/addpackage",
            'user_id': campaign.userId
        });
        displayMessage("Package added", function() {
            if (pkg.defaultBook) {
                window.location.href = "/#book?chapter=0&section=-1&subsection=-1&id="+encodeURIComponent(pkg.defaultBook);
            } else {
                window.location.href = "/#home";
            }
        });
    }

}

function resizeAndCropImage(file, w, h, mime) {

    return new Promise((resolve, reject) => {
        // Create file reader
        const reader = new FileReader();
        reader.onload = readerEvent => {
            // Create image object
            const image = new Image();
            image.onerror = function (err) {
                reject("Error loading file.  Format may not be supported.");
                console.log("error setting image src", err);
            }
            image.onload = imageEvent => {
                // Create canvas or use provided canvas
                const canvas = document.createElement('canvas');
                canvas.width = w;
                canvas.height = h;
                // Calculate scaling
                const horizontalScale = w / image.width;
                const verticalScale = h / image.height;
                const scale = Math.min(horizontalScale, verticalScale);
                // Calculate cropping
                const [width, height] = [scale * image.width, scale * image.height];
                const verticalOffset = (h-height)/2;
                const horizontalOffset = (w-width)/2;
                // Obtain the context for a 2d drawing
                const context = canvas.getContext('2d');
                if (!context) {
                    return reject('Could not get the context of the canvas element');
                }
                // Draw the resized and cropped image
                if (mime == "image/jpeg") {
                    context.fillStyle="#eee7cd";
                    context.fillRect(0,0,w,h)
                }
                context.drawImage(
                    image,
                    horizontalOffset,
                    verticalOffset,
                    width,
                    height
                );
                canvas.toBlob(blob => {
                    resolve({blob:blob, width:image.naturalWidth, height:image.naturalHeight});
                }, mime||"image/png");
            };
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
}

function getPkgSummary(pkg) {
    const summary = {};

    for (let t in pkg) {
        switch (t) {
            case "monsters":
                summary.Monsters = pkg.monsters.length;
                break;
            
            case "mycharacters":
                summary["Pregenerated Characters"] = pkg.mycharacters.length;
                break;

            case "maps":
                summary.Maps = pkg.maps.length;
                break;

            case "plannedencounters":
                summary.Encounters = pkg.plannedencounters.length;
                break;
            
            case "books":
                let count =0;
                for (let i in pkg.books) {
                    if (pkg.books[i].type=="book") {
                        count++;
                    }
                }
                summary.Books=count;
                break;

            case "mapextra":
            case "pins":
                break;

            case "art":
                summary.Art = pkg.art?.length;
                break;

            case "audio":
                summary["Audio Clips"] = pkg.audio?.length;
                break;

            case "randomtables":
                summary["Random Encounter Tables"] = pkg.randomtables.length;
                break;

            case "items":
                summary.Items = pkg.items.length;
                break;

            case "classes": {
                for (let i in pkg.classes) {
                    const c = pkg.classes[i];
                    if (c.subclassName) {
                        summary.Subclasses =  (summary.Subclasses||0)+1;
                    } else {
                        summary.Classes =  (summary.Classes||0)+1;
                    }
                }
                break;
            }

            case "races":
                summary.Races = pkg.races.length;
                break;
            
            case "customTypes": {
                for (let i in pkg.customTypes) {
                    const c = pkg.customTypes[i];

                    summary[c.type] = (summary[c.type]||0)+1;
                }
                break;
            }

            case "feats":
                summary.Feats = pkg.feats.length;
                break;

            case "extensions":
                summary.Extension = pkg.extensions.length;
                break;

            case "spells":
                summary.Spells = pkg.spells.length;
                break;
            
            case "backgrounds":
                summary.Backgrounds = pkg.backgrounds.length;
                break;

            case "unresolved":
            case "dependencies":
            case "dependenciesDetails":
                break;

            default:
                console.log("unknown package data", t);
                break;
        }
    }
    return summary;
}

function getPkgSourceSummary(pkg, pkgSource, excludeList,pkgOriginal) {
    const sources = {};

    for (let t in pkg) {
        const list = pkg[t];
        const olist = (pkgOriginal && pkgOriginal[t])||[];
        if (Array.isArray(list)) {
            for (let i in list) {
                const it = list[i];
                const source = it.source||"My Creations";
                let pval = getDetailedType(t,it);

                if (pval) {
                    let countIt = true;
                    let changed = false;
                    if (pkgOriginal) {
                        const ot = olist.find(function(ot) {
                            return ot.name == it.name;
                        });
                        if (ot) {
                            const itm = Object.assign({}, it);
                            delete itm.edited;
                            delete itm.version;
                            itm.source=ot.source;// more efficient than deleting source on it
                            changed = !areSameDeep(itm, ot);
                        } else {
                            changed = true;
                        }
                    }
                    if ((source==pkgSource) && excludeList[t] && excludeList[t][it.name.toLowerCase()]) {
                        const altIt = resolveContent(t, it.name, [source]);
                        if (altIt) {
                            const source = altIt.source;
                            if (!sources[source]) {
                                sources[source] = {};
                            }
                            const summary = sources[source];
                            
                            if (!summary[pval]){
                                summary[pval] = {count:0, changed:0, type:t, list:[]};
                            }
                            summary[pval].count++;
                            if (changed) {
                                summary[pval].changed++;
                            }
                            summary[pval].list.push(altIt);
                            countIt = false;
                        }
                    }

                    if (!sources[source]) {
                        sources[source] = {};
                    }
                    const summary = sources[source];
                    
                    if (!summary[pval]){
                        summary[pval] = {count:0, changed:0, type:t, list:[]};
                    }
                    if (countIt) {
                        summary[pval].count++;
                        if (changed) {
                            summary[pval].changed++;
                        }
                    }
                    summary[pval].list.push(it);
                }
            }
        }
    }

    if (pkgOriginal) {
        for (let t in pkgOriginal) {
            const list = pkg[t]||[];
            const olist = pkgOriginal[t];
            if (Array.isArray(olist)) {
                for (let i in olist) {
                    const ot = olist[i];
                    let pval = getDetailedType(t, ot);

                    if (pval) {
                        const it = list.find(function(it) {
                            return ot.name == it.name;
                        });
                        if (!it) {
        
                            const rc = resolveContent(t, ot.name, [pkgSource]);
                            if (!rc && !((campaign.userEditedContent()[t]||{})[ot.name])) {
                                if (!sources[pkgSource]) {
                                    sources[pkgSource] = {};
                                }
                                const summary = sources[pkgSource];
                                if (!summary[pval]) {
                                    summary[pval] = {count:0, changed:0, type:t, list:[]};
                                }
                                summary[pval].deleted = (summary[pval].deleted||0)+1;
                            }
                        }
                    }
                }
            }
        }
    }
    return sources;
}


function getSpecificType(t) {
    switch (t) {
        case "Feats":
            return "feats";
        case "Extensions":
            return "extensions";
        case "Spells":
            return "spells";
        case "Backgrounds":
            return "backgrounds";
        case "Races":
            return "races";
        case "Random Encounter Tables":
            return "randomtables";
        case "Subclasses":
        case "Classes": 
            return "classes";
        case "Pins":
            return "pins";
        case "Art":
            return "art";
        case "Audio Clips":
            return "audio";
        case "Items":
            return "items";
        case "Encounters":
            return "plannedencounters";
        case "Maps":
            return "maps";
        case "Monsters":
            return "monsters";
        case "Books":
            return "books";
        case "Pregenerated Characters":
            return "mycharacters";
    }
    return "customTypes";
}

function getDetailedType(t, it) {
    switch (t) {
        case "feats":
            return "Feats";
        case "extensions":
            return "Extensions";
        case "spells":
            return "Spells";
        case "backgrounds":
            return "Backgrounds";
        case "races":
            return "Races";
        case "randomtables":
            return "Random Encounter Tables";
        case "classes": 
            return it.subclassName?"Subclasses":"Classes";
        case "pins":
            return "Pins";
        case "art":
            return "Art";
        case "audio":
            return "Audio Clips";
        case "items":
            return "Items";
        case "plannedencounters":
            return "Encounters";
        case "maps":
            return "Maps";
        case "monsters":
            return "Monsters";
        case "books":
            if (it.type=="book"){
                return "Books";
            }
            break;
        case "mycharacters":
            return "Pregenerated Characters";
        case "customTypes": 
            return it.type;

        case "mapextra":
        case "unresolved":
        case "dependencies":
        case "dependenciesDetails":
            break;

        default:
            console.log("unknown package data", t);
            break;
    }

    return null;
}

function getPkgEntries(pkg, included) {
    for (let t in pkg) {
        const list = pkg[t];
        if (Array.isArray(list)) {
            for (let i in list) {
                const it = list[i];
                let pval = getDetailedType(t, it);

                if (pval) {
                    if (!included[pval]) {
                        included[pval] = {};
                    }
                    included[pval][it.name.toLowerCase()] = it.displayName;
                }
            }
        }
    }
}

function restoreOrphans(pkg, pkgSource, pkgOriginal) {
    const promises = [];
    promises.push(new Promise(function(resolve, reject) {
        setTimeout(function() {
          resolve();
        }, 1);
    }));
    if (pkgOriginal) {
        for (let t in pkgOriginal) {
            const list = pkg[t]||[];
            const olist = pkgOriginal[t];
            if (Array.isArray(olist)) {
                for (let i in olist) {
                    const ot = olist[i];
                    let pval;

                    switch (t) {
                        case "books":
                            if (ot.type=="book"){
                                pval=true;
                            }
                        break;

                        case "monsters":
                        case "mycharacters":
                        case "maps":
                        case "plannedencounters":
                        case "pins":
                        case "art":
                        case "audio":
                        case "items":
                        case "classes": 
                        case "races":
                        case "randomtables":
                        case "customTypes": 
                        case "extensions":
                        case "feats":
                        case "spells":
                        case "backgrounds":
                            pval = true;
                            break;

                        case "mapextra":
                        case "unresolved":
                        case "dependencies":
                            break;

                        default:
                            console.log("unknown package data", t);
                            break;
                    }
                    if (pval) {
                        const it = list.find(function(it) {
                            return ot.name == it.name;
                        });
                        if (!it) {
                            const rc = resolveContent(t, ot.name, [pkgSource]);
                            if (!rc && !((campaign.userEditedContent()[t]||{})[ot.name])) {
                                const nt = Object.assign({}, ot);
                                delete nt.source;
                                promises.push(campaign.updateCampaignContent(t,nt));

                                if (t=="books") {
                                    const book = nt;
                                    for (let c in book.chapters) {
                                        const chapter = book.chapters[c];
                                        const ofrag = campaign.getBookFragment(chapter.fragment);
                                        if (ofrag) {
                                            promises.push(campaign.updateCampaignContent(t,ofrag));
                                        }
                                        for (let s in chapter.sections) {
                                            const section = chapter.sections[s];
                                            const ofrag = campaign.getBookFragment(section.fragment);
                                            if (ofrag) {
                                                promises.push(campaign.updateCampaignContent(t,ofrag));
                                            }
                        
                                            for (let ss in section.subsections) {
                                                const subsection = section.subsections[ss];
                                                const ofrag = campaign.getBookFragment(subsection.fragment);
                                                if (ofrag) {
                                                    promises.push(campaign.updateCampaignContent(t,ofrag));
                                                }
                                            }    
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return Promise.all(promises);
}

class PackageBuilder {
    constructor(booklist, pkgOptions, source, excludeList, noPictures) {
        this.noPictures = noPictures;
        this.pkg = {};
        this.source = source;
        this.unresolved={};
        this.protectErrors=null;
        for (let i in booklist) {
            const bookname = booklist[i]
            this.getBookPackageInfo(bookname);
        }
        this.filterSources(pkgOptions, source, excludeList);
        this.createMissingArtwork();
    }

    filterSources(pkgOptions,source, excludeList) {
        const fPkg = {dependencies:{}};
        const pkg = this.pkg;
        this.fullPkg = this.pkg;

        for (let t in pkg) {
            const list = pkg[t];
            const nl = [];
            for (let i in list){
                let it = list[i];

                if ((source==it.source) && excludeList[t] && excludeList[t][it.name.toLowerCase()]) {
                    it = resolveContent(t, it.name, [source]);
                }

                if (it.edited || !it.source || (it.source==source) || (t=="books") || ("embed" == pkgOptions[it.source])) {
                    nl.push(it);
                } else {
                    fPkg.dependencies[it.source] = campaign.getSourceName(it.source);
                    if (!fPkg.dependenciesDetails) {
                        fPkg.dependenciesDetails = {};
                    }
                    if (!fPkg.dependenciesDetails[it.source]) {
                        fPkg.dependenciesDetails[it.source] = {};
                    }
                    const dt = getDetailedType(t,it);
                    if (!fPkg.dependenciesDetails[it.source][dt]) {
                        fPkg.dependenciesDetails[it.source][dt]={};
                    }
                    fPkg.dependenciesDetails[it.source][dt][it.name]= {displayName:it.displayName};
                }
            }

            if (nl.length) {
                fPkg[t]=nl;
            }
        }
        this.pkg = fPkg;
    }

    getBookPackageInfo(bookname) {
        const book = campaign.getBookInfo(bookname);
        if (!book) {
            return;
        }
        const {getChapterInfoFromFragment} = require('./book.jsx');

        this.addItemToPkg("books", book);
        getBookContentTypes(); // need this to make sure custom tables are included

        const chapters = book.chapters||[];
        for (let c in chapters) {
            const chapter = chapters[c];
            const sections = chapter.sections||[];

            this.addFragmentToPkg(chapter, bookname, c,-1,-1);

            for (let s in sections) {
                const section = sections[s];
                const subsections = section.subsections||[];

                this.addFragmentToPkg(section,bookname, c,s,-1);

                for (let ss in subsections) {
                    this.addFragmentToPkg(subsections[ss], bookname, c,s,ss);
                }
            }
        }


        const pins = campaign.getPins();
            
        for (let i in pins) {
            const p = pins[i];

            for (let l in p.links) {
                const pl =p.links[l];

                if ((pl.type == "book") && pl.book && (pl.book.toLowerCase() == bookname.toLowerCase())) {
                    let chapter,section,subsection;
                    if (pl.fragment) {
                        const chaptInfo = getChapterInfoFromFragment(bookname, pl.fragment);
        
                        chapter=chaptInfo.chapter;
                        section=chaptInfo.section;
                        subsection=chaptInfo.subsection;
                    } else {
                        chapter=pl.chapter;
                        section=pl.section;
                        subsection=pl.subsection;
                    }
    
                    this.addItemToPkg("pins", p, bookname, chapter, section, subsection );
                }
            }
        }

        const encounters = campaign.getPlannedEncounters();

        for (let i in encounters) {
            const e = encounters[i];
            const br = e.bookReference;

            if (br && br.book && (br.book.toLowerCase() == bookname.toLowerCase())) {
                let chapter,section,subsection;
                if (br) {
                    if (br.fragment) {
                        const chaptInfo = getChapterInfoFromFragment(br.book, br.fragment);
        
                        chapter=chaptInfo.chapter;
                        section=chaptInfo.section;
                        subsection=chaptInfo.subsection;
                    } else {
                        chapter=br.chapter;
                        section=br.section;
                        subsection=br.subsection;
                    }
                }
        
                this.addItemToPkg("plannedencounters", e, bookname, chapter, section, subsection);
            }
        }
    }

    getPkg() {
        return this.pkg;
    }

    addFragmentToPkg(data, book, chapter, section, subsection) {
        const fragment = campaign.getBookFragment(data.fragment);

        this.addItemToPkg("books", fragment, book, chapter,section,subsection);
        const contentList = data.contentType?[{contentId:data.contentId, contentType:data.contentType}]:data.contentList||[];

        for (let i in contentList) {
            const ci = contentList[i];
            const cm = contentTypeMap[ci.contentType];

            if (cm) {
                const gd = cm.get(ci.contentId);
                if (gd.value) {
                    this.addItemToPkg(gd.type, gd.value,book, chapter, section,subsection, true);
                } else {
                    this.addUnresolved(gd.type, ci.contentId, {book, chapter, section,subsection});
                }
            } else {
                console.log("ignore unknown type", ci.contentType);
            }

        }
    }

    addItemToPkg(type, value,book, chapter, section,subsection,force) {
        const pkg=this.pkg;
        if (!value) {
            return;
        }

        if (!force && this.noPictures && (type=="art") && !(value.type||"").includes("Token")) {
            //console.log("skipping", force, this.noPictures, type,value);
            return;
        }

        if (!pkg[type]) {
            pkg[type] = [];
        }

        value = Object.assign({}, value);
        if ((value.edited||(type=="books")) && (value.source != this.source) && value.source) {
            if (((type!="books") || (value.type=="book")) && campaign.getSourcePreventEmbedding(value.source)) {
                if (!this.protectErrors) {
                    this.protectErrors=[];
                }
                const bk = campaign.getBookInfo(book);
                const dn = value.displayName || (bk && bk.displayName) || "unknown";
                if (!this.protectErrors.includes(dn)) {
                    this.protectErrors.push(dn);
                }
                return;
            }
            delete value.source;
            delete value.page;
        }
        if (!value.timestamp) {
            value.timestamp=Date.now();
        }
        if (value.source && (value.source != this.source)) {
            value.timestamp--;
        }

        if (!pkg[type].find(function (f){ return value.name.toLowerCase()==f.name.toLowerCase()})) {
            pkg[type].push(value);

            if (value.defaultArt) {
                const art = campaign.getArtInfo(value.defaultArt);
                if (art) {
                    this.addItemToPkg("art", art,book, chapter, section,subsection);
                } else {
                    this.addUnresolved("art", value.defaultArt, {type, value, book, chapter, section,subsection});
                }
            }

            if (value.defaultToken) {
                const art = campaign.getArtInfo(value.defaultToken);
                if (art) {
                    this.addItemToPkg("art", art,book, chapter, section,subsection);
                } else {
                    this.addUnresolved("art", value.defaultToken, {type, value, book, chapter, section,subsection});
                }
            }

            if (value.artList) {
                for (let a of value.artList) {
                    const art = campaign.getArtInfo(a);
                    if (art) {
                        this.addItemToPkg("art", art,book, chapter, section,subsection);
                    } else {
                        this.addUnresolved("art", a, {type, value, book, chapter, section,subsection});
                    }
                }
            }

            switch (type) {
                case "monsters": {
                    // need to add artwork
                    if (value.tokenArt) {
                        const art = campaign.getArtInfo(value.tokenArt);
                        if (art) {
                            this.addItemToPkg("art", art,book, chapter, section,subsection);
                        } else {
                            this.addUnresolved("art", value.tokenArt, {type, value, book, chapter, section,subsection});
                        }
                    }
                    break;
                }

                case "mycharacters": {
                    // need to add artwork
                    if (value.tokenArt) {
                        const art = campaign.getArtInfo(value.tokenArt);
                        if (art) {
                            this.addItemToPkg("art", art,book, chapter, section,subsection);
                        } else {
                            this.addUnresolved("art", value.tokenArt, {type, value, book, chapter, section,subsection});
                        }
                    }
                    break;
                }

                case "audio": {
                    // need to add artwork
                    if (value.art) {
                        const art = campaign.getArtInfo(value.art);
                        if (art) {
                            this.addItemToPkg("art", art,book, chapter, section,subsection);
                        } else {
                            this.addUnresolved("art", value.art, {type, value, book, chapter, section,subsection});
                        }
                    }
                    if (value.clips) {
                        for (let c in value.clips) {
                            const audio = campaign.getAudioInfo(c);
                            if (audio) {
                                this.addItemToPkg("audio", audio,book, chapter, section,subsection);
                            } else {
                                this.addUnresolved("audio", c, {type, value, book, chapter, section,subsection});
                            }
                        }
                    }
                    break;
                }

                case "randomtables": {
                    if (value.rows) {
                        for (let r in value.rows) {
                            const monsters = value.rows[r].monsters;
                            if (monsters) {
                                for (let i in monsters) {
                                    const mon = campaign.getMonsterInfo(i);
                                    if (mon) {
                                        this.addItemToPkg("monsters", mon,book, chapter, section,subsection);
                                    } else {
                                        this.addUnresolved("monsters", i, {type, value, book, chapter, section,subsection});
                                    }

                                }
                            }
                        }
                    }
                    break;
                }

                case "maps": {
                    if (value.art) {
                        const art = campaign.getArtInfo(value.art);
                        if (art) {
                            this.addItemToPkg("art", art,book, chapter, section,subsection);
                        } else {
                            this.addUnresolved("art", value.art, {type, value, book, chapter, section,subsection});
                        }
                    }
                    
                    if (value.artList){
                        for (let i in value.artList) {
                            const art = campaign.getArtInfo(value.artList[i]);
                            if (art) {
                                this.addItemToPkg("art", art,book, chapter, section,subsection);
                            } else {
                                this.addUnresolved("art", value.art, {type, value, book, chapter, section,subsection});
                            }
                        }
                    }
                    const pins = campaign.getPins();
            
                    for (let i in pins) {
                        const p = pins[i];
            
                        if (p.mapName==value.name) {
                            this.addItemToPkg("pins", p, book, chapter, section, subsection );
                        }

                        for (let l in p.links) {
                            const pl =p.links[l];
            
                            if ((pl.type == "map") && (pl.name==value.name)) {
                                this.addItemToPkg("pins", p, book, chapter, section, subsection );
                            }
                        }
                    }
            
                    break;
                }

                case "pins": {
                    /* Don't include base map to prevent over expansion
                    if (value.mapPos && value.mapPos.mapName) {
                        const map = campaign.getMapInfo(value.mapPos.mapName);
                        if (map) {
                            this.addItemToPkg("maps", map,book, chapter, section,subsection);
                        } else {
                            this.addUnresolved("maps", value.mapPos.mapName, {type, value, book, chapter, section,subsection});
                        }
                    }*/
                    break;
                }

                case "plannedencounters": {
                    const p = campaign.findEncounterPin(value.name);
                    if (p) {
                        this.addItemToPkg("pins", p,book, chapter, section,subsection);
                    }
                    const combatants = value.combatants;
                    for (let i in combatants) {
                        const c = combatants[i];
                        switch (c.ctype) {
                            default:
                            case "monster":{
                                const mon = campaign.getMonsterInfo(c.type);
                                if (mon) {
                                    this.addItemToPkg("monsters", mon,book, chapter, section,subsection);
                                } else {
                                    this.addUnresolved("monsters", c.type, {type, value, book, chapter, section,subsection});
                                }
                                break;
                            }

                            case "object":{
                                break;
                            }

                            case "pc":{
                                console.log("unhandled pc");
                                break;
                            }
                        }
                        if (c.tokenArt) {
                            const art = campaign.getArtInfo(c.tokenArt);
                            if (art) {
                                this.addItemToPkg("art", art,book, chapter, section,subsection);
                            } else {
                                this.addUnresolved("art", c.tokenArt, {type, value, book, chapter, section,subsection});
                            }
                        }
                    }
                    
                    break;
                }
            }
        }
    }

    addUnresolved(type, id, ref) {
        if (!this.unresolved[type]){
            this.unresolved[type]={};
        }
        if (type=="classes"){
            id = id.className+(id.subclassName?("."+id.subclassName):"");
        }
        if (!this.unresolved[type][id]){
            this.unresolved[type][id]=[ref];
        } else {
            this.unresolved[type][id].push(ref);
        }
    }

    createMissingArtwork() {
        const artMap={};
        const pkg = this.pkg;
        const monsters = pkg.monsters;
        const maps = pkg.maps;

        for (let i in monsters) {
            let mon = monsters[i];

            if (mon.imageURL) {
                let art = artMap[mon.imageURL];

                if (!art) {
                    art = {
                        name:campaign.newUid(),
                        displayName:mon.name,
                        type:"Monster Token",
                        url:mon.imageURL,
                        imgHeight:560,
                        imgWidth:560,
                        mapWidth:5,
                        opacity:1,
                        timestamp:Date.now(),
                        source:mon.source||null
                    }
                    artMap[mon.imageURL] = art;
                }
                mon = Object.assign({},mon);
                mon.tokenArt = art.name;
                delete mon.imageURL;
                monsters[i]=mon;
            }
        }

        for (let i in maps) {
            let m = maps[i];

            if (m.url) {
                let art = artMap[m.url];

                if (!art) {
                    art = {
                        name:campaign.newUid(),
                        displayName:m.displayName,
                        type:"Map",
                        url:m.url,
                        thumb:m.thumb||null,
                        imgHeight:m.imgHeight,
                        imgWidth:m.imgWidth,
                        mapWidth:10,
                        opacity:1,
                        timestamp:Date.now(),
                        source:m.source||null
                    }
                    artMap[m.url] = art;
                }
                m = Object.assign({},m);
                m.art = art.name;
                delete m.url;
                delete m.thumb;
                delete m.imgWidth;
                delete m.imgHeight;
                maps[i]=m;
            }
        }
        const newArt = [];
        for (let i in artMap) {
            newArt.push(artMap[i]);
        }
        if (newArt.length) {
            pkg.art = (pkg.art||[]).concat(newArt);
        }
    }
}

const levelVals = [
    {name:"none", value:0},
    {name:"1", value:1},
    {name:"2", value:2},
    {name:"3", value:3},
    {name:"4", value:4},
    {name:"5", value:5},
    {name:"6", value:6},
    {name:"7", value:7},
    {name:"8", value:8},
    {name:"9", value:9},
    {name:"10", value:10},
    {name:"11", value:11},
    {name:"12", value:12},
    {name:"13", value:13},
    {name:"14", value:14},
    {name:"15", value:15},
    {name:"16", value:16},
    {name:"17", value:17},
    {name:"18", value:18},
    {name:"19", value:19},
    {name:"20", value:20},
];

function packageRender(pkg) {
    return <div>
        <div className="notetext titlecolor f3 truncate tc">{pkg.displayName}</div>
        {pkg.publisher?<div className="notetext titlecolor f5 truncate tc">by {pkg.publisher}</div>:null}
    </div>;
}


const packageFilters = [
    {
        filterName:"Publisher",
        fieldName:"publisher"
    },
    {
        filterName:"Category",
        fieldName:"type",
        convertField:convertToArray
    },
    {
        filterName:"Theme",
        fieldName:"genre",
        convertField:convertToArray
    },
    {
        filterName:"Setting",
        fieldName:"setting",
        convertField:convertToArray
    },
    {
        filterName:"Storeline",
        fieldName:"storyline",
        convertField:convertToArray
    },
];

function convertToArray(v) {
    if (!v) {
        return "None"
    }
    const vals = v.split(",").map(function (a){return a.trim()});
    if (!vals.length) {
        return "None";
    }
    return vals;
}

const excludeTypes = {"Audio Clips":true, "Art":true, "Books":true, "Random Encounter Tables":true, "Encounters":true, "Pins":true, "Pregenerated Characters":true};

export {
    GlobalEnablePackageDialog,
    BuildPackageDialog,
    MyPackagesList,
    MyPackagesHeader,
    AddSharedPackage,
    PackagePicker,
    resizeAndCropImage,
    thumbnailSize,
    PackageDialog,
    getPkgEntries,
    getSpecificType,
    getDetailedType,
    excludeTypes,
    AdminPackageDialog
}