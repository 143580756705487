const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
const {displayMessage} = require('./notification.jsx');
const {extensionsFromSaved} = require('../lib/character.js');
import TextField from '@material-ui/core/TextField';
const {Rendersource} = require("./rendersource.jsx");
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');
const {RenderFeature,FeatureListEdit, FeatureConfigure, hasFeatureConfig} = require('./features.jsx');
const {ListFilter} = require('./listfilter.jsx');
const {CustomPicker, CustomDialog} = require('./customtable.jsx');
const {LinkHref}=require('./renderhref.jsx');
import {htmlFromEntry} from "../lib/entryconversion.js";
const {abilityNames,skillsList,defaultSettings,defaultStorylines,packageGenres, packageTypes,defaultRulesets,gamesystemOptions} = require('../lib/stdvalues.js');

const {SelectVal, TextVal, CheckVal, defaultSourceFilter,defaultBookFilter, DeleteWithConfirm, DialogSelectVal,SelectMultiTextVal} = require('./stdedit.jsx');
const {ArtPicker} = require('./renderart.jsx');

class RenderExtensions extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {list:campaign.getSortedExtensionsList()};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:campaign.getSortedExtensionsList()})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "extensions");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "extensions");
    }

	render() {
        return <div>
            <ListFilter 
                list={this.state.list}
                onClick={this.doClick.bind(this)}
                filters={[defaultSourceFilter,defaultBookFilter]}
            />
            <ExtensionDialog open={this.state.showExtension} extension={this.state.showExtensionName} onClose={this.hideExtension.bind(this)}/>
        </div>;
    }

    doClick(name) {
        this.setState({showExtension:true, showExtensionName:name});
    }

    hideExtension(){
        this.setState({showExtension:false});
    }
}

class Extension extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({extension:campaign.getExtensionInfo(this.props.extension)})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "extension");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "extension");
    }

	render() {
        var extension = campaign.getExtensionInfo(this.props.extension);
        if (!extension){
            return <div>Could not find extension {this.props.extension}.</div>;
        }

        return <div className="stdcontent">
            {this.props.noTitle?null:<h3>{extension.displayName}</h3>}
            <Renderentry entry={extension.description} noDice/>
            {this.props.noSource?null:<Rendersource entry={extension}/>}
        </div>;
    }
}

function printExtension(id,noTitle,header) {
    const list=[];
    const it = campaign.getExtensionInfo(id);
    if (!it) {
        return;
    }
    if (!noTitle) {
        list.push(`<h${header}>${it.displayName}</h${header}>`);
    }

    if (it.description) {
        list.push(`<div>${htmlFromEntry(it.description)}</div>`);
    }
    return list.join("\n");
}

class ExtensionDialog extends React.Component {
    constructor(props) {
        super(props);


        this.state= {extension:campaign.getExtensionInfo(props.extension)};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open!= prevProps.open) {
            this.setState({extension:campaign.getExtensionInfo(this.props.extension)});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const {PackagePicker} = require('./packages.jsx');

        const extension=this.state.extension||{};
        const preferred = Object.keys(extension.preferred||{}).length;
        const disallowed = Object.keys(extension.disallowed||{}).length;
        const cSelected = Object.keys(extension.packagesSelected||{}).length;
        const art = campaign.getArtInfo(extension.wallpaper);

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>
                <TextVal 
                    text={extension.displayName||""}
                    fullWidth
                    inputProps={{className:"f1 titletext titlecolor ignoreDrag"}}
                    onChange={this.onChangeField.bind(this,"displayName")}
                />
            </DialogTitle>
            <DialogContent>
                {this.state.extension?<div className="stdcontent">
                    <TextVal className="mb2" text={extension.onSheetName||""} fullWidth onChange={this.onChangeField.bind(this,"onSheetName")} helperText="Character Sheet Display Name (optional)"/>
                    <EntityEditor onChange={this.onChangeField.bind(this,"description")} entry={extension.description} placeholder="Description"/>
                    <h2>Skills</h2>
                    {this.getSkills()}
                    <h2>Languages</h2>
                    <TextVal className="mb2" fullWidth text={extension.languages||""} onChange={this.onChangeField.bind(this, "languages")} placeholder="comma separated languages" helperText="additional languages"/>
                    <SelectMultiTextVal freeSolo className="mb2" value={extension.excludeLanguages} values={campaign.getAllLanguages()} onChange={this.onChangeField.bind(this,"excludeLanguages")} fullWidth helperText="exclude languages"/>
                    <h2>Character Options</h2>
                    {this.getCharacterOptions()}
                    <div className="mv1 hk-well">
                        Preferred content will show above other content when users make selections while disallowed content will not show as an option.
                        <div>
                            {preferred?<span className="nowrap">{preferred} entries preferred </span>:null}<Button onClick={this.clickPreferred.bind(this,true)} color="primary" variant="outlined" size="small">Preferred Content</Button>{" "}
                            {disallowed?<span className="nowrap">{disallowed} entries disallowed </span>:null}<Button onClick={this.clickPreferred.bind(this,false)} color="primary" variant="outlined" size="small">Disallowed Content</Button>
                        </div>
                    </div>
                    <div className="mv1 hk-well">
                        <CheckVal value={extension.template} onChange={this.onChangeField.bind(this,"template",!extension.template)} label="Ruleset Template"/>                        
                        <div>
                            Ruleset templates can be used to directly create rulesets and campaigns. Matching packages will be included when the extension is applied.  Ruleset must match if specified.
                        </div>
                        {extension.template?<div>
                            <div className="pt2">
                                <SelectVal value={extension.gamesystem||"any"} includeVal="any" values={gamesystemOptions} onClick={this.onChangeField.bind(this,"gamesystem")} helperText="Game System"/>
                            </div>

                            <div className="pv2">
                                {cSelected?cSelected+" selected":null} <Button className="mr2" onClick={this.showPackagePicker.bind(this)} variant="outlined" size="small">Selected Packages</Button>
                            </div>

                            <SelectMultiTextVal freeSolo value={extension.packageType} values={packageTypes} onChange={this.onChangeField.bind(this,"packageType")} fullWidth helperText="Category"/>
                            <SelectMultiTextVal value={extension.packageGenre} values={packageGenres} onChange={this.onChangeField.bind(this,"packageGenre")} fullWidth helperText="Theme"/>
                            <SelectMultiTextVal freeSolo value={extension.packageSetting} values={defaultSettings} onChange={this.onChangeField.bind(this,"packageSetting")} fullWidth helperText="Setting"/>
                            <SelectMultiTextVal freeSolo value={extension.packageStoryline} values={defaultStorylines} onChange={this.onChangeField.bind(this,"packageStoryline")} fullWidth helperText="Storyline"/>
                            <SelectMultiTextVal freeSolo value={extension.packageRulesets} values={defaultRulesets} onChange={this.onChangeField.bind(this,"packageRulesets")} fullWidth helperText="Rulesets"/>
                            <div className="mv1">
                                <b>Wallpaper Image:</b> {art?.displayName}
                                <Button className="mh2" onClick={this.onPickWallpaper.bind(this)} color="primary" size="small" variant="outlined">Pick</Button>
                                <Button onClick={this.onChangeField.bind(this,"wallpaper", null)} color="primary" size="small" variant="outlined">Clear</Button>
                            </div>
                        </div>:null}
                    </div>
                    {this.props.noSource?null:<Rendersource entry={extension}/>}
                </div>:<div>Could not find extension {this.props.extension}.</div>}
            </DialogContent>
            <DialogActions>
                <Button disabled={!extension.displayName} onClick={this.handleClose.bind(this,true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <ContentSelector open={this.state.showContentSelector} preferred={this.state.preferred} label={this.state.preferred?"Pick Preferred":"Pick Disallowed"} selected={this.state.preferred?extension.preferred:extension.disallowed} onClose={this.closeContentSelector.bind(this)}/>
            <DialogSelectVal open={this.state.showSelectRemove} values={skillsList} selected={extension.removeSkills} label="Remove Skills" onClose={this.closeSelectRemove.bind(this)}/>
            <PackagePicker all title="Selected Packages" open={this.state.showPackagePicker} selected={extension.packagesSelected} onClose={this.onClosePicker.bind(this)}/>
            <ArtPicker defaultType="Wallpaper" open={this.state.showPickWallpaper} onClose={this.pickWallpaper.bind(this)}/>
        </Dialog>;
    }

    onPickWallpaper() {
        this.setState({showPickWallpaper:true})
    }

    pickWallpaper(art) {
        if (art) {
            this.onChangeField("wallpaper", art.name);
        }
        this.setState({showPickWallpaper:false})
    }

    onClosePicker(selected) {
        if (selected) {
            if (Object.keys(selected).length ==0) {
                selected = null;
            }
            this.onChangeField("packagesSelected", selected);
        }
        this.setState({showPackagePicker:false});
    }

    showPackagePicker() {
        this.setState({showPackagePicker:true});
    }

    showSelectRemove() {
        this.setState({showSelectRemove:true})
    }

    closeSelectRemove(removeSkills) {
        if (removeSkills != null) {
            const extension=Object.assign({},this.state.extension||{});
            extension.removeSkills = removeSkills;
            this.setState({extension});
        }
        this.setState({showSelectRemove:false})
    }

    clickPreferred(preferred) {
        this.setState({showContentSelector:true, preferred});
    }

    closeContentSelector(selected) {
        if (selected) {
            const extension = Object.assign({}, this.state.extension);
            let preferred;
            let disallowed;
            let a;
            let b;

            if (this.state.preferred) {
                preferred = selected;
                if (extension.disallowed) {
                    disallowed=Object.assign({}, extension.disallowed);
                }
                a=preferred;
                b=disallowed;
            } else {
                disallowed = selected;
                if (extension.preferred) {
                    preferred=Object.assign({}, extension.preferred);
                }
                b=preferred;
                a=disallowed;
            }
            if (a && b) {
                for (let i in a) {
                    delete b[i];
                }
            }
            if (preferred) {
                extension.preferred = preferred;
            }
            if (disallowed) {
                extension.disallowed = disallowed;
            }

            this.setState({extension});
        }
        this.setState({showContentSelector:false});
    }

    getSkills() {
        const extension=this.state.extension||{};
        const skills = extension.skills||[];
        const list = [];

        for (let i in skills) {
            const s = skills[i];
            list.push(<tr key={i} className="hoverhighlight">
                <td className="w-100" onClick={this.editSkill.bind(this,i)}>
                    {s.name}: {s.ability}
                </td>
                <td>
                    <DeleteWithConfirm name={s.name} onClick={this.deleteSkill.bind(this,i)}/>
                </td>
            </tr>);
        }

        return <div className="mb2">
            <table>
                <tbody>
                    {list}
                </tbody>
            </table>
            {extension.removeSkills?<div className="mv1"><b>Remove Skills: </b>{extension.removeSkills.join(", ")}</div>:null}
            <div>
                <Button onClick={this.addSkill.bind(this)} color="primary" variant="outlined" size="small">Add Skill</Button>
                <Button className="ml2" onClick={this.showSelectRemove.bind(this)} color="primary" variant="outlined" size="small">Remove Skills</Button>
            </div>
            <EditSkill skill={this.state.selectedSkill} open={this.state.selectedSkill} onClose={this.setSkill.bind(this,this.state.selectedSkillIndex)}/>
        </div>;
    }

    editSkill(i) {
        const extension=this.state.extension||{};
        const skills = (extension.skills||[]);
        this.setState({selectedSkill:skills[i], selectedSkillIndex:i});
    }
    
    addSkill() {
        const extension=this.state.extension||{};
        const skills = (extension.skills||[]);
        this.setState({selectedSkill:{name:"", ability:"str"}, selectedSkillIndex:skills.length});
    }

    deleteSkill(i) {
        const extension=this.state.extension||{};
        const skills = (extension.skills||[]).concat([]);
        extension.skills = skills;
        skills.splice(i,1);
        this.setState({extension});
    }

    setSkill(i,val){
        const extension=Object.assign({}, this.state.extension||{});
        if (val) {
            const skills = (extension.skills||[]).concat([]);
            extension.skills = skills;
            skills[i]=val;
        }
        this.setState({extension, selectedSkill:null});
    }

    getCharacterOptions() {
        const extension=this.state.extension||{};
        const options = extension.options||[];
        const list = [];

        for (let i in options) {
            const s = options[i];
            let val;
            switch (s.type) {
                case "counter":
                    val=<TextVal className="minw35" isNum text={s.max||0} onChange={this.setOptionVal.bind(this, i, "max")} helperText="max value"/>;
                    break;
                case "check":
                case "uses":
                    val = <SelectVal className="minw35" value={s.max||1} values={[1,2,3,4,5,6]} onClick={this.setOptionVal.bind(this, i, "max")} helperText="max"/>;
                    break;
                case "select":
                    val =<TextVal className="minw35" text={s.customType||""} onChange={this.setOptionVal.bind(this, i, "customType")} helperText="type name"/>;
                    break;
                case "features":
                    break;
                default:
                    console.log("unknown char opt", s.type);
                    break;
            }
            list.push(<div className="titleborder ba br2 pa1 mv1" key={i}>
                <div className="flex items-center">
                    <TextVal fullWidth text={s.name||""} onChange={this.setOptionVal.bind(this, i, "name")} helperText="name"/>
                    <div className="nowrap">
                        {i>0?<span className="hoverhighlight fas fa-arrow-up pa1 ml--2" onClick={this.moveOption.bind(this,i,-1)}/>:null}
                        {(i<(options.length-1))?<span className="hoverhighlight fas fa-arrow-down pa1 ml--2" onClick={this.moveOption.bind(this,i,1)}/>:null}
                        <DeleteWithConfirm name={s.name} onClick={this.deleteOption.bind(this,i)} className="pa1"/>
                    </div>
                </div>
                <div className="titleborder bb mb1 pb1 flex items-end">
                    <SelectVal className="minw35 mr1" value={s.type} values={{counter:"counter", uses:"uses", check:"check boxes", select:"pick", features:"features only"}} onClick={this.setOptionVal.bind(this, i, "type")} helperText="type"/>
                    {val}
                    <SelectVal className="minw4 ml1" isNum value={s.required?1:0} values={{0:"optional",1:"required"}} onClick={this.setOptionVal.bind(this, i, "required")} helperText="config"/>
                </div>
                <div className="mr3">
                    <FeatureListEdit 
                        editable 
                        features={s.features}
                        displayType="Extension" type={extension.name} 
                        onChange={this.setOptionVal.bind(this, i,"features")}
                    />
                </div>
            </div>);
        }
        
        return <div className="mb2">
            {list}
            <div>
                <Button onClick={this.addOption.bind(this)} color="primary" variant="outlined" size="small">Add Option</Button>
            </div>
        </div>;
    }

    moveOption(i,direction) {
        const extension=this.state.extension||{};
        const options = (extension.options||[]).concat([]);
        extension.options = options;
        
        const out = options.splice(i,1);
        console.log("out with", out);
        options.splice(i+direction,0,out[0]);
        this.setState({extension});
    }

    addOption() {
        const extension=this.state.extension||{};
        const options = (extension.options||[]).concat([{name:"", type:"counter", max:3}]);
        extension.options = options;
        this.setState({extension});
    }

    deleteOption(i) {
        const extension=this.state.extension||{};
        const options = (extension.options||[]).concat([]);
        extension.options = options;
        options.splice(i,1);
        this.setState({extension});
    }

    setOptionVal(i, prop, val) {
        const extension=this.state.extension||{};
        const options = (extension.options||[]).concat([]);
        extension.options = options;
        const s = Object.assign({},options[i]);
        options[i]=s;
        s[prop]=val;
        this.setState({extension});
    }

    handleClose(save) {
        if (save) {
            const extension=this.state.extension||{};
            const options = extension.options||[];
            for (let i=options.length-1; i>=0; i--) {
                if (!options[i].name) {
                    displayMessage("Cannot save extension with an option that doesn't have a name.")
                    return;
                }
            }
            const skills = extension.skills||[];
            for (let i=skills.length-1; i>=0; i--) {
                if (!skills[i].name) {
                    skills.splice(i,1);
                }
            }
            campaign.updateCampaignContent("extensions", this.state.extension);
        }
        this.props.onClose();
    }

    onChangeField(field,val) {
        const extension = Object.assign({}, this.state.extension);
        if (["none","any"].includes(val)) {
            val = null;
        } else if (val == "true") {
            val=true;
        } else if (val == "false") {
            val=false;
        }
        extension[field] = val;
        this.setState({extension});
    }
}

class EditSkill extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {skill:props.skill||{}};
    }

    onClose(save) {
        this.props.onClose(save?this.state.skill:null);
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({skill:this.props.skill||{}});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const s = this.state.skill;
        const alts = s.alts||{};
        const list=[];

        for (let i in skillsList) {
            const a = skillsList[i];
            list.push(<div className="w-50 minw45" key={a}>
                <CheckVal value={alts[a]} onChange={this.onToggleAlt.bind(this,a)} label={a}/>
            </div>)
        }

        return <Dialog
            open
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle onClose={this.onClose.bind(this)}>
                Edit Skill
            </DialogTitle>
            <DialogContent>
                <TextVal fullWidth text={s.name||""} onChange={this.setSkillVal.bind(this, "name")} helperText="skill name"/>
                <SelectVal fullWidth value={s.ability} values={abilityNames} onClick={this.setSkillVal.bind(this, "ability")} helperText="ability"/>
                <EntityEditor onChange={this.setSkillVal.bind(this,"description")} entry={s.description} placeholder="Description"/>
                <div className="stdcontent mt2">
                    <div className="hk-well">Show this skill as an option if the user is allowed pick any of these selected skills.</div>
                    <div className="flex flex-wrap">
                        {list}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose.bind(this,true)} color="primary">
                    save
                </Button>
                <Button onClick={this.onClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    setSkillVal(prop, val) {
        const skill = Object.assign({},this.state.skill);
        skill[prop]=val;
        this.setState({skill});
    }

    onToggleAlt(alt) {
        const alts = Object.assign({},this.state.skill.alts||{});
        if (alts[alt]) {
            delete alts[alt];
        } else {
            alts[alt]=true;
        }
        this.setSkillVal("alts", alts);
    }

}

class ContentSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            this.setState({list:this.getContentList(), selected:this.props.selected});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges){
            this.props.onClose(this.state.selected);
        } else {
            this.props.onClose(null);
        }
    };

    getContentList() {
        const list = [];

        const classes = campaign.getClassesListByName();
        for (let i in classes) {
            const c = classes[i];
            list.push({type:"Classes", name:c.name, id:{className:c.className, subclassName:null}, displayName:c.displayName||"", source:c.source, className:c.className, subclassName:c.subclassName, edited:c.edited});
            const subclasses = campaign.getSubclasses(c.className);
            for (let x in subclasses) {
                const sc = subclasses[x];
                list.push({type:"Classes", name:sc.name, id:{className:sc.className, subclassName:sc.subclassName}, displayName:(c.displayName||"")+": "+(sc.displayName||""), source:sc.source, className:sc.className, subclassName:sc.subclassName, edited:sc.edited});
            }
        }

        const races = campaign.getRaces();
        for (let i in races) {
            const it = races[i];
            list.push({type:"Races", name:it.name, displayName:it.displayName||"", source:it.source, edited:it.edited});
        }

        const backgrounds = campaign.getSortedBackgroundsList();
        for (let i in backgrounds) {
            const it = backgrounds[i];
            list.push({type:"Backgrounds", name:it.name, displayName:it.displayName||"", source:it.source, edited:it.edited});
        }

        const feats = campaign.getSortedFeatsList();
        for (let i in feats) {
            const it = feats[i];
            list.push({type:"Feats", name:it.name, displayName:it.displayName||"", source:it.source, edited:it.edited});
        }

        if (!this.props.preferred) {
            const cts = campaign.getCustomTablesList();
            for (let x in cts) {
                const ct = cts[x];
                const clist = campaign.getSortedCustomList(ct);
                for (let i in clist) {
                    const it = clist[i];
                    list.push({type:ct, name:it.id, displayName:it.displayName||"", source:it.source, edited:it.edited});
                }
    
            }

            const spells = campaign.getSpellListByName();
            for (let i in spells) {
                const it = spells[i];
                list.push({type:"Spells", name:it.name, displayName:it.displayName||"", source:it.source, edited:it.edited});
            }
    
            const items = campaign.getSortedItemsList();
            for (let i in items) {
                const it = items[i];
                list.push({type:"Items", name:it.name, displayName:it.displayName||"", source:it.source, edited:it.edited});
            }
        }

        list.sort(function (a,b) {
            const c = a.type.localeCompare(b.type);
            if (c != 0) {
                return c;
            }
            return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
        });
        return list;
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        return  <Dialog
            open
            maxWidth="md"
            fullWidth
            classes={{paper:"minvh-80"}}
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                {this.props.label}
            </DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={this.state.list||[]}
                    groupBy="type"
                    defaultCollapsed
                    select="list"
                    selectAll
                    selected={this.state.selected}
                    onSelectedChange={this.onSelectedChange.bind(this)}
                    filters={selectFilters}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onSelectedChange(selected) {
        this.setState({selected});
    }
}

const selectFilters=[
    {
        filterName:"Type",
        fieldName:"type",
    },
    defaultSourceFilter,
    defaultBookFilter
];

class NewExtension extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    createExtension() {
        const name = this.state.name;
        const selectedExtension = this.state.selectedExtension;
        let newExtension = Object.assign({}, selectedExtension?campaign.getExtensionInfo(selectedExtension):{});

        newExtension.displayName=name;
        newExtension.name=campaign.newUid();
        campaign.updateCampaignContent("extensions", newExtension);
        this.props.onClose(newExtension.name);
    }

    onClose() {
        this.props.onClose();
    }

    onChange(event) {
        if (/[^\n]*/.exec(event.target.value) == event.target.value) {
            this.setState({name:event.target.value});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({name:""});
        }
    }

    render() {
        if (!this.props.open)
            return null;

        const extensions = campaign.getSortedExtensionsList();
        const extensionsList=[];
        const selectedExtension = this.state.selectedExtension;
        const name=this.state.name||"";

        extensionsList.push({name:"Empty Template",value:"Empty Template"});
        for (var i in extensions){
            extensionsList.push({name:extensions[i].displayName, value:extensions[i].name});
        }
    
        return <Dialog
            open={this.props.open||false}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle onClose={this.onClose.bind(this)}>
                Create Extension
            </DialogTitle>
            <DialogContent>
                <TextField
                    value={name}
                    onChange={this.onChange.bind(this)}
                    margin="normal"
                    helperText="Extension Name"
                    fullWidth
                />
                <div className="mv1">
                    <SelectVal fullWidth noteText helperText="Base Extension" value={selectedExtension||"Empty Template"} values={extensionsList} onClick={this.changeExtension.bind(this)}/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={!name || name==""} onClick={this.createExtension.bind(this)} color="primary">
                    New
                </Button>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    changeExtension(selectedExtension) {
        if (selectedExtension=="Empty Template") {
            selectedExtension = null;
        }
        this.setState({selectedExtension});
    }
}

class ExtensionsPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state= {selected:{}};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {

            this.setState({selected:extensionsSelectedFromArray(extensionsFromSaved(this.props.selected))});
        }
    }

    handleClose(save) {
        if (save) {
            this.props.onClose(extensionsArrayFromSelected(this.props.selected||[],this.state.selected));
        } else {
            this.props.onClose();
        }
        this.setState({selected:null});
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const list = campaign.getSortedExtensionsList(this.props.type);

        return <Dialog
            scroll="paper"
            maxWidth="md"
            fullWidth={true}
            open
            classes={{paper:"minvh-80"}}
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Pick Extensions
            </DialogTitle>
            <DialogContent>
                <div className="stdcontent" key={this.props.id}>
                    <ListFilter 
                        list={list}
                        select={"list"}
                        selected={this.state.selected}
                        render={extensionListRender}
                        onSelectedChange={this.onSelectedChange.bind(this)}
                        filters={[defaultSourceFilter,defaultBookFilter]}
                    >
                    </ListFilter>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }    

    onSelectedChange(selected) {
        this.setState({selected});
    }
}

function extensionListRender(s) {
    return <Extension extension={s.name}/>;
}

class CharacterOptionDialog extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        const character = this.props.character;
        const extension = campaign.getExtensionInfo(this.props.extension);
        if (!extension) {
            return null;
        }
        const index = character.findCharacterOptionIndex(extension, this.props.name)
        const charOpt = extension.options[index];
        const editable = this.props.editable;
        const list = [];

        for (let i in charOpt.features) {
            list.push(<div key={i} className="mb1"><RenderFeature h3 feature={charOpt.features[i]} noDiv/></div>);
        }

        return  <Dialog
            open={this.props.open||false}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>
               {this.props.name}
            </DialogTitle>
            <DialogContent>
                <div className="stdcontent">
                    {editable?this.getOptions(character, charOpt):null}
                    {list}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <CustomPicker 
                open={this.state.charOptShowCustomPicker}
                type={this.state.charOptCustomType} 
                selected={this.state.charOptPickselected} 
                known={1}
                onClose={this.onCloseCharOptCustomPicker.bind(this)}
            />
        </Dialog>;
    }

    getOptions(character, charOpt) {
        const options = character.extensionOptions;
        let opts = [];

        const baseName ="charopt.c."+this.props.extension+"."+charOpt.name;

        if (charOpt.type == "select") {
            const selected = character.getExtensionCharacterOption(baseName);
            const selectedVals = Object.keys(selected||{});
            const ct = campaign.getCustom(charOpt.customType, selectedVals[0]);
            opts.push(<div className="mb1" key="sel">
                {ct?<LinkHref href={"#customlist?type="+encodeURIComponent(charOpt.customType)+"&id="+selectedVals[0]}>{ct.displayName}</LinkHref>:null} <Button onClick={this.pickCharacterOpt.bind(this, charOpt.customType, baseName, selected)} variant="outlined" size="small">Pick {charOpt.customType}</Button>
            </div>)

            if (ct) {
                for (let x in ct.features) {
                    const f = ct.features[x];
                    const custBaseName = baseName+".customtable."+(f.id||f.name||"");

                    if (hasFeatureConfig(f) && character.checkRestriction(f.restriction)) {
                        opts.push(<FeatureConfigure
                            key={baseName+".customtable."+x}
                            character={character}
                            feature={f}
                            baseName={custBaseName}
                            options={options}
                            onChange={this.changeOptionVal.bind(this)}
                        />);
                    }
                }
            }
        }

        const r = charOpt.features;
        for (let i in r) {
            if (hasFeatureConfig(r[i]) && character.checkRestriction(r[i].restriction)) {
                const featureBaseName = baseName+"."+(r[i].id||r[i].name||"");
                opts.push(<FeatureConfigure key={featureBaseName+i}
                    character={character}
                    feature={r[i]}
                    baseName={featureBaseName}
                    options={options}
                    onChange={this.changeOptionVal.bind(this)}
                />);
            }
        }

        return opts;
    }

    changeOptionVal(option, value) {
        this.props.character.setExtensionCharacterOption(option,value);
    }

    pickCharacterOpt(customType, key, selected) {
        this.setState({charOptShowCustomPicker:true, charOptCustomType:customType, charOptKey:key, charOptPickselected:selected});
    }

    onCloseCharOptCustomPicker(selected) {
        if (selected) {
            this.props.character.setExtensionCharacterOption(this.state.charOptKey, selected);
        }
        this.setState({charOptShowCustomPicker:false});
    }

}

class ExtensionsHeader extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onNew() {
        this.setState({showNew:true});
    }

    render() {
        return <span>
            Extensions
            {!campaign.isSharedCampaign()?<Button className="ml2 minw2" color="primary" variant="outlined" size="small" onClick={this.onNew.bind(this)}>New</Button>:null}
            <NewExtension open={this.state.showNew} onClose={this.closeNew.bind(this)}/>
            <ExtensionDialog open={this.state.showExtension} extension={this.state.showExtension} onClose={this.setExtension.bind(this,null)}/>
        </span>;
    }

    setExtension(showExtension) {
        this.setState({showExtension});
    }

    closeNew(name) {
        this.setState({showNew:false, showExtension:name});
    }
}

function extensionsArrayFromSelected(extensions, selected) {
    const newext = [];
    for (let i in extensions) {
        if (selected[extensions[i]]) {
            newext.push(extensions[i]);
        }
    }
    for (let i in selected) {
        if (!newext.includes(i)) {
            newext.push(i);
        }
    }
    return newext;
}

function extensionsSelectedFromArray(extensions) {
    const selected={};
    if (extensions) {
        for (let i of extensions) {
            selected[i]=true;
        }
    }
    return selected;
}

export {
    RenderExtensions,
    printExtension,
    Extension,
    ExtensionDialog,
    NewExtension,
    CharacterOptionDialog,
    ExtensionsHeader,
    ExtensionsPicker,
    ContentSelector,
}