import React from 'react';
import { createRoot } from 'react-dom/client';

const DEFAULT_OPTIONS = {
    toolbar: 'no',
    location: 'no',
    directories: 'no',
    status: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'yes',
    width: 500,
    height: 400,
    top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
    left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
};

const ABOUT_BLANK = 'about:blank';

/**
 * @class PopoutWindow
 */
export default class PopoutWindow extends React.Component {
    /**
     * @constructs PopoutWindow
     * @param props
     */
    constructor(props) {
        super(props);

        this.mainWindowClosedFn = this.mainWindowClosed.bind(this);
        this.popoutWindowUnloading = this.popoutWindowUnloading.bind(this);
        this.popoutWindowLoaded = this.popoutWindowLoaded.bind(this);

        this.state = {
            openedWindowComponent: null,
            popoutWindow: null,
            container: null
        };
    }

    createOptions(ownerWindow) {
        const mergedOptions = Object.assign({}, DEFAULT_OPTIONS, this.props.options);

        return Object.keys(mergedOptions)
        .map(
            key =>
                key +
                '=' +
                (typeof mergedOptions[key] === 'function'
                    ? mergedOptions[key].call(this, mergedOptions, ownerWindow)
                    : mergedOptions[key])
        )
        .join(',');
    }

    componentDidMount() {
        const ownerWindow = this.props.window || window;

        // May not exist if server-side rendering
        if (ownerWindow) {
            this.openPopoutWindow(ownerWindow);

            // Close any open popouts when page unloads/refreshes
            ownerWindow.addEventListener('unload', this.mainWindowClosedFn);
        }
    }

    componentDidUpdate() {
        this.renderToContainer(this.state.container, this.state.popoutWindow, this.props.children);
    }

    componentWillUnmount() {
        this.mainWindowClosed();
        let root = this.root;
        if (root){
            setTimeout(function(){
                root.unmount();
            },100);
        }
    }

    popoutWindowLoaded(popoutWindow) {
        if (!this.state.container) {
            // Popout window is passed from openPopoutWindow if no url is specified.
            // In this case this.state.popoutWindow will not yet be set, so use the argument.
            popoutWindow = this.state.popoutWindow || popoutWindow;
            popoutWindow.document.title = this.props.title;
            let container = popoutWindow.document.getElementById(this.props.containerId);

            if (container){
                popoutWindow.document.body.removeChild(container);
            }

            container=popoutWindow.document.createElement('div');
            container.id = this.props.containerId;
            popoutWindow.document.body.appendChild(container);

            this.setState({ container });
            this.renderToContainer(container, popoutWindow, this.props.children);
        }
    }

    openPopoutWindow(ownerWindow) {
        const popoutWindow = ownerWindow.open(this.props.url, this.props.name || this.props.title, this.createOptions(ownerWindow));
        this.setState({ popoutWindow });

        popoutWindow.addEventListener('beforeunload', this.popoutWindowUnloading);
        this.popoutWindowLoaded(popoutWindow);
    }

    /**
     * API method to close the window.
     */
    closeWindow() {
        this.mainWindowClosed();
    }

    mainWindowClosed() {
        //this.state.popoutWindow && this.state.popoutWindow.close();
        (this.props.window || window).removeEventListener('unload', this.mainWindowClosedFn);
    }

    popoutWindowUnloading() {
        if (this.root) {
            this.root.unmount();
            this.root=null;
        }
        this.props.onClosing && this.props.onClosing();
    }

    renderToContainer(container, popoutWindow, children) {
        // For SSR we might get updated but there will be no container.
        if (container) {
            let renderedComponent = children;
            if (typeof children === 'function') {
                renderedComponent = children(popoutWindow);
            }
            if (!this.root) {
                this.root = createRoot(container);
            }
            this.root.render(renderedComponent);
        }
    }

    render() {
        return null;
    }
}

PopoutWindow.defaultProps = {
    url: ABOUT_BLANK,
    containerId: 'popout-content-container'
};

