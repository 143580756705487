const React = require('react');
const {campaign,globalDataListener,isNativeUrl,getDirectDownloadUrl,httpHeadRequest,areSameDeep,sortDisplayName} = require('../lib/campaign.js');
const {firebase} = require("./firebase.jsx");
import { getStorage, ref,uploadBytes,deleteObject } from "firebase/storage";
import { Stage, Layer, Circle, Image as KImage } from 'react-konva';

const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
const {Rendersource} = require("./rendersource.jsx");
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
const {DeleteWithConfirm, TextVal, SelectVal, CheckVal, getAnchorPos,escapeRegExp, defaultSourceFilter,defaultBookFilter,windowSize} = require('./stdedit.jsx');
const {nameEncode,environmentTypeList} = require('../lib/stdvalues.js');
import sizeMe from 'react-sizeme';
const {ListFilter} = require('./listfilter.jsx');
const {displayMessage} = require('./notification.jsx');

class RenderArtList extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnDataChange = this.onDataChange.bind(this);
    
	    this.state= {};
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "art");
    }
  
    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "art");
    }

    onDataChange() {
        this.setState({art:campaign.getArt()});
    }

	render() {
        return <div className="h-100 w-100">
            <ListFilter 
                list={campaign.getArt()}
                border
                showThumbnails
                defaultFilter={{type:this.props.defaultType||null}}
                filters={artListFilters}
                onClick={this.clickArt.bind(this)}
                select="click"
                render={renderArtwork}
                getListRef={this.saveRef.bind(this)}
                headerOutline={this.props.headerOutline}
                open
            />
            <ArtDialog open={this.state.showToken} name={this.state.pickToken} extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.onCloseToken.bind(this)}/>
        </div>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(cname) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(cname))||{});

        return <span>
            <Button disabled={!prev} onClick={prev?this.clickArt.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.clickArt.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    clickArt(name){
        if (this.props.onClick) {
            this.props.onClick(campaign.getArtInfo(name));
        } else {
            this.setState({showToken:true, pickToken:name});
        }
    }

    onCloseToken() {
        this.setState({showToken:false});
    }
}

function renderArtwork(art) {
    return <div>
        <div className="truncate pv1 tc f3">{art.displayName}</div>
        {art.imgWidth && art.imgHeight?<div className="tc f5 i">{art.imgWidth.toLocaleString()} X {art.imgHeight.toLocaleString()}</div>:null}
    </div>
}

const artListFilters = [
    {
        filterName:"Type",
        fieldName:"type",
        convertField:function (v) {
            return v||"Picture";
        }
    },
    {
        filterName:"Keywords",
        fieldName:"keywords"
    },
    defaultSourceFilter,
    defaultBookFilter
];


const tokenSize = 280;
const artTypeList = ["Character Token", "Monster Token", "Map", "Map Token","Picture","Spell Token", "Treasure Token", "Audio Token","Wallpaper"];
const specialArtTypeList = ["Dice", "Dice Tray", "Token Border", "Token Background"];
const fullBorderOptions = [
    {description:"b1",url:"/border1.png"},
    {description:"b2",url:"/borderblue.png"},
    {description:"b3",url:"/borderblue2.png"},
    {description:"b4",url:"/borderblue3.png"},
    {description:"b5",url:"/bordergreen.png"},
    {description:"b6",url:"/borderred2.png"},
    {description:"b7",url:"/borderred3.png"},
    {description:"b8",url:"/bordergray.png"},
    {description:"b9",url:"/bordergray2.png"},
    {description:"b10",url:"/bordergray3.png"},
    {description:"b111",url:"/defaultborder.png"}
];
const basicBorderOptions = [
    {description:"b1",url:"/border1.png"},
    {description:"b4",url:"/borderblue3.png"}
];
const ginnyDiBorderOptions = [
    {description:"gb1",url:"/gdiborder1.png"},
    {description:"gb2",url:"/gdiborder2.png"}
];

const fullBackgroundOptions = [
    {description:"b1",url:"/backgrounddark.png"}, 
    {description:"b2",url:"/backgroundblue.png"},
    {description:"b3",url:"/backgroundyellow.png"},
    {description:"b4",url:"/backgroundgray.png"},
    {description:"b5",url:"/backgroundgreen.png"},
    {description:"b6",url:"/backgroundpurple.png"},
    {description:"b7",url:"/backgrounddark2.png"},
    {description:"b8",url:"/backgroundblue2.png"},
    {description:"b9",url:"/backgroundyellow2.png"},
    {description:"b10",url:"/backgroundgray2.png"},
    {description:"b11",url:"/backgroundgreen2.png"},
    {description:"b12",url:"/backgroundpurple2.png"},
    {description:"b13",url:"/defaultbackground.png"}
];
const basicBackgroundOptions = [
    {description:"b1",url:"/backgrounddark.png"},
    {description:"b3",url:"/backgroundyellow.png"}
];
const ginnyDiBackgroundOptions = [
    {description:"gb1",url:"/gdibackground1.png"},
    {description:"gb2",url:"/gdibackground2.png"}
];

class ArtDialog extends React.Component {
    constructor(props) {
        super(props);
        const t=this;
        let dirty = false;

        let token;
        if (props.name) {
            token =campaign.getArtInfo(props.name);
        } else {
            token={};
            if (props.defaultType) {
                token.type = props.defaultType;
            }
            if (!token.type) {
                token.type="Picture"
            }
            token.displayName=props.defaultName||"";
            token.name=campaign.newUid();
            dirty = true;
        }

        this.state={
            token,
            keywords:((token && token.keywords)||[]).join(", "),
            uploadFile:null,
            image:null,
            newImageURL:null,
            newWebURL:null,
            loading:false,
            newWidth:0,
            newHeight:0,
            imageSize:0,
            switchToJpeg:false,
            imageContentType:null,
            quickBuild:props.quickBuild,
            addBorder:["Monster Token", "Character Token"].includes(token && token.type),
            dirty
        };
        this.idKey = campaign.newUid();
        this.loadBorderImages();
    }

    componentDidMount() {
        if (this.state.token && this.state.token.url) {
            this.loadImageInformation(this.state.token);
        }
        if (this.props.open && this.props.file) {
            this.loadFile(this.props.file);
        }
        if (this.props.open && this.props.webURL) {
            this.selectFromWeb(this.props.webURL);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (((prevProps.open != this.props.open) || (prevProps.name!=this.props.name)) && this.props.open) {
            this.setDefaultState();
        }
    }

    setDefaultState() {
        let token;
        if (this.props.name) {
            token =campaign.getArtInfo(this.props.name);
        } else {
            token={};
            if (this.props.defaultType) {
                token.type = this.props.defaultType;
            }
            token.displayName=this.props.defaultName||"";
            token.name=campaign.newUid();
        }
        this.setState({
            token,
            keywords:token && (token.keywords||[]).join(", "),
            uploadFile:null,
            image:null,
            newImageURL:null,
            newWebURL:null,
            loading:false,
            newWidth:0,
            newHeight:0,
            imageSize:0,
            switchToJpeg:false,
            imageContentType:null,
            quickBuild:this.props.quickBuild,
            addBorder:["Monster Token", "Character Token"].includes(token&&token.type),
            dirty:false
        });
        if (token.url) {
            this.loadImageInformation(token);
        }
        if (this.props.file) {
            this.loadFile(this.props.file);
        }
        if (this.props.webURL) {
            this.selectFromWeb(this.props.webURL);
        }
    }

    async loadImageInformation(token) {
        if (token && token.url && isNativeUrl(token.url)) {
            const res = await httpHeadRequest(token.url);
            this.setState({imageSize:res.size||0, imageContentType:res.contentType||null});
        }
    }

    quickBuild() {
        if (this.state.quickBuild) {
            const t=this;
            setTimeout(function() {
                if (t.props.webURL) {
                    t.onSave();
                    return;
                }
                if (t.props.file) {
                    const token=t.state.token;
                    const limits = imageSizeLimits[(token&&token.type)||"Picture"] || defaultLimits;

                    const tooLarge = limitCheck(token.imgWidth, token.imgHeight, t.state.imageSize, limits.recommend);
                    const exceedLimit = limitCheck(token.imgWidth, token.imgHeight, t.state.imageSize, limits.limit);

                    if (!tooLarge && !exceedLimit) {
                        t.onSave();
                        return;
                    }
                } else if (t.props.webURL) {
                    t.onSave();
                    return;
                }
                t.setState({quickBuild:false});
            },10);
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const t=this;
        const token=this.state.token;
        if (!token) {
            return <Dialog 
                open
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    Artwork not found
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose.bind(this)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>;
        }

        const {addBorder,newWebURL} = this.state;
        const url = this.state.newImageURL || newWebURL || (token && token.url);
        const showToken = this.state.image&&["Monster Token", "Character Token","Audio Token"].includes(token && token.type);
        const limits = imageSizeLimits[(token&&token.type)||"Picture"] || defaultLimits;
        const suggestJpeg = limits&&limits.suggestJpeg&&(this.state.imageContentType!="image/jpeg");
        const options = [];
        const extraButtonsFn = this.props.extraButtonsFn;
        let artTypeOptions = artTypeList;
        let createMap;
        let tooLarge;
        let exceedLimit;
        let exceedCorrected;

        if (campaign.allowSpecialArtTypes) {
            artTypeOptions=artTypeOptions.concat(specialArtTypeList)
        }
        if (token.type && !artTypeOptions.includes(token.type)) {
            artTypeOptions = artTypeOptions.concat(token.type);
        }

        if (!this.props.dirty && token && (token.type=="Map")) {
            const {MapDialog} = require('./rendermaps.jsx');
            createMap=<div className="mb2 tc">
                <Button color="primary" variant="outlined" onClick={this.createMap.bind(this,true)}>Create Map from Artwork</Button>
                <MapDialog open={this.state.showCreateMap} onClose={this.createMap.bind(this,false)} defaultName={token.displayName} mapArt={token.name}/>
            </div>;
        }

        if (limits && token && (!addBorder || !showToken)&& !newWebURL) {
            if (token.imgWidth && token.imgHeight) {
                tooLarge = limitCheck(token.imgWidth, token.imgHeight, this.state.imageSize, limits.recommend);
                exceedLimit = limitCheck(token.imgWidth, token.imgHeight, this.state.imageSize, limits.limit);

                exceedCorrected = limitCheck(this.state.newWidth||token.imgWidth, this.state.newHeight||token.imgHeight, estimateSize(this.state.newWidth||token.imgWidth, token.imgWidth, this.state.imageSize, this.state.switchToJpeg), limits.limit);
                if (tooLarge || exceedLimit) {
                    const optionList = generateSizeSuggestions(token.imgWidth, token.imgHeight, this.state.imageSize, limits, tooLarge, exceedLimit, suggestJpeg, token.type=="Map");
                    for (let i in optionList) {
                        const l = optionList[i];
                        options.push(<tr className="mb1" key={i}>
                            <td>{(l.width*100/token.imgWidth).toFixed(1)}% </td>
                            <td>{l.width.toLocaleString()} X {l.height.toLocaleString()}</td>
                            <td className="tr">~{displaySize(l.estimatedSize)} bytes</td>
                            <td className="tr">{l.jpeg?"jpeg":null}</td>
                            <td><Button color="primary" variant="outlined" onClick={this.setDimensions.bind(this, l.width, l.height, l.jpeg)}>Select</Button></td>
                            <td><Button color="primary" variant="outlined" onClick={this.viewImage.bind(this, l.width, l.height, l.jpeg)}>View</Button></td>
                        </tr>);
                    }
                }
            }
        }

        return <span>
            <Dialog 
                open={!this.state.quickBuild}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <div>
                        <TextVal fullWidth inputProps={{className:"f1 titletext titlecolor"}} className="mb2" text={token.displayName} onChange={this.onChangeField.bind(this,"displayName")} label="Name"/>
                        <SelectVal fullWidth className="mb2 titletext titlecolor" value={token.type||"Picture"} values={artTypeOptions} onClick={this.onChangeField.bind(this, "type")} label="Type"/>
                        <TextVal fullWidth multiline rowsMax={3} className="mb2" text={this.state.keywords} onChange={this.onChangeKeywords.bind(this)} helperText="Keywords (comma separated)"/>
                        {url&&["Map Token", "Treasure Token", "Spell Token"].includes(token.type)?<div className="flex items-end">
                            <TextVal
                                className="flex-auto"
                                text={Math.trunc(token.mapWidth)}
                                onChange={this.onChangeWidth.bind(this)}
                                isNum
                                label="Width (ft)"
                            />
                            <TextVal
                                className="flex-auto"
                                text={Math.trunc(token.mapWidth*token.imgHeight/token.imgWidth)}
                                onChange={this.onChangeHeight.bind(this)}
                                isNum
                                label="Height (ft)"
                            />
                            <SelectVal
                                className="flex-auto"
                                value={token.opacity||1}
                                values={opacityValues}
                                onClick={this.onChangeField.bind(this, "opacity")}
                                label="Opacity"
                            />
                        </div>:null}
                        <div className="tc mt2">
                            Image attributes:&nbsp;
                            {token.imgHeight && token.imgWidth?<b>
                                {token.imgWidth.toLocaleString()} X {token.imgHeight.toLocaleString()}&nbsp;
                            </b>:null}
                            {this.state.imageSize && this.state.imageContentType?<i>
                                {this.state.imageContentType} {displaySize(this.state.imageSize)}
                            </i>:null}
                        </div>
                        {(tooLarge||exceedLimit)?<div className="mv1 hk-well">
                            {exceedLimit?<div>
                                <div className="mb1">Image too large. Resize or pick another image.</div>
                            </div>:<div className="mb1">
                                <div className="mb1">Image exceeds recommended limits. You may want to resize or pick another image.</div>
                            </div>}
                            {limits.recommend&&tooLarge?<div className="mb1">Recommend a maximum dimension of {limits.recommend.maxDimension.toLocaleString()} and a size of {displaySize(limits.recommend.maxSize)}.</div>:null}
                            {limits.limit&&exceedLimit?<div className="mb1">Maximum limits of a dimension of {limits.limit.maxDimension.toLocaleString()} and a size of {displaySize(limits.limit.maxSize)}.</div>:null}
                            <div className="mb1 flex items-center">
                                <div className="flex-auto"/>
                                <div className="mr2">{((this.state.newWidth||token.imgWidth)*100/token.imgWidth).toFixed(1)}% </div>
                                <TextVal isNum text={this.state.newWidth||token.imgWidth} onChange={this.setNewDimension.bind(this,"w")} helperText="width" className="w5 mr2"/>X
                                <TextVal isNum text={this.state.newHeight||token.imgHeight} onChange={this.setNewDimension.bind(this,"h")} helperText="height" className="w5 mh2"/>
                                {suggestJpeg?<CheckVal value={this.state.switchToJpeg||false} onChange={this.setSwitchToJpeg.bind(this)} label="Save as jpeg"/>:null}
                                <div className="mh1"><Button color="primary" variant="outlined" onClick={this.viewImage.bind(this, this.state.newWidth||token.imgWidth, this.state.newHeight||token.imgHeight, this.state.switchToJpeg||false)}>View</Button></div>
                                <div className="flex-auto"/>
                            </div>
                            <div className="mb1 flex">
                                <div className="flex-auto"/>
                                <table>
                                    <tbody>
                                        {options}
                                    </tbody>
                                </table>
                                <div className="flex-auto"/>
                            </div>
                            {this.state.viewedSize?<div className="mb1">Last viewed image size: <b>{this.state.viewedWidth.toLocaleString()} X {this.state.viewedHeight.toLocaleString()}</b> {this.state.viewedJpeg?"image/jpeg ":null}{this.state.viewedSize.toLocaleString()}</div>:null}
                        </div>:null}
                        {showToken?<div className="mv2">
                            <CheckVal value={addBorder||false} onChange={this.toggleBorder.bind(this)} label="Add Border"/>
                            {addBorder?<PickImage variant="outlined" artType="Token Border" label="Pick Border" onClick={this.pickBorder.bind(this)}/>:null}
                            {addBorder?<PickImage variant="outlined" artType="Token Background" label="Pick Background" onClick={this.pickBackground.bind(this)}/>:null}
                        </div>:null}
                    </div>
                    {url?<div className="tc mt2">
                        {addBorder&&showToken?<div>
                            <div className="flex items-center titlecolor f2 notetext pa2">
                                <div className="flex-auto"/>
                                <div className="far fa-minus-square hoverhighlight pa1" onClick={this.onZoom.bind(this, false)}></div>
                                <div>Zoom</div>
                                <div className="far fa-plus-square hoverhighlight pa1" onClick={this.onZoom.bind(this, true)}></div>
                                <div className="flex-auto"/>
                            </div>
                            <div className="hk-well mb1">
                                Click and drag image to center.
                            </div>
                            <div className="flex">
                                <div className="flex-auto"/>
                                <Stage ref={function(r) {t.canvas=r}} height={tokenSize} width={tokenSize} x={0} y={0} onWheel={this.onWheel.bind(this)}>
                                    <Layer listening={false}>
                                        <Circle x={tokenSize/2} y={tokenSize/2} radius={tokenSize/2-10} fill="green"/>
                                        <KImage image={this.state.imageBackground} globalCompositeOperation="source-in" height={tokenSize} width={tokenSize}/>
                                    </Layer>
                                    <Layer>
                                        <Circle x={tokenSize/2} y={tokenSize/2} radius={tokenSize/2-10} fill="green"/>
                                        <KImage draggable image={this.state.image} globalCompositeOperation="source-in" scaleX={this.state.zoom} scaleY={this.state.zoom}/>
                                    </Layer>
                                    <Layer listening={false}>
                                        <KImage image={this.state.imageBorder} height={tokenSize} width={tokenSize}/>
                                    </Layer>
                                </Stage>
                                <div className="flex-auto"/>
                            </div>
                        </div>:
                        (token.imgHeight/(token.imgWidth+1) >0.8 )?<img className="ba titleborder" height="400px" src={url} alt="Loading..."/>:<img className="ba titleborder" width="100%" src={url} alt="Loading..."/>}
                    </div>:null}
                    {createMap}
                    <div className={url?"hk-well mt2":"mt2 pv4 tc minh5 ba titleborder w-100"}>
                        {url?<div className="mb1">Change Artwork Image</div>:null}
                        <Button className="mr2" color="primary" variant="outlined" onClick={this.showSearchWeb.bind(this)}>
                            Pick Image From Web
                        </Button>
                        <input
                            key={this.idKey}
                            accept="image/*"
                            className="dn"
                            id={"image-file-upload2"+this.idKey}
                            type="file"
                            onChange={this.selectFile.bind(this)}
                        />
                        <label htmlFor={"image-file-upload2"+this.idKey}>
                            <Button color="primary" component="span" variant="outlined">
                                Upload Image
                            </Button>
                        </label>
                    </div>
                    <Rendersource entry={token}/>
                </DialogContent>
                <DialogActions>
                    {!this.state.dirty && extraButtonsFn && extraButtonsFn(token.name)}
                    <Button disabled={exceedCorrected||!this.state.dirty} onClick={this.onSave.bind(this)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.onClose.bind(this)} color="primary">
                        {this.state.dirty?"Cancel":"Close"}
                    </Button>
                </DialogActions>
            </Dialog>
            {this.state.loading?<Dialog
                open={this.state.loading}
            >
                <DialogContent>
                    {this.state.loadingMessage}
                </DialogContent>
            </Dialog>:null}
            <SearchWebArtPicker open={this.state.showSearchWeb} onClose={this.selectFromWeb.bind(this)} type={token.type}/>
        </span>
    }

    createMap(showCreateMap) {
        this.setState({showCreateMap});
    }

    setDimensions(width, height, jpeg) {
        this.setState({newWidth:width, newHeight:height, switchToJpeg:jpeg||false, dirty:true});
    }

    setNewDimension(dimension, val) {
        const token=this.state.token;
        let width = this.state.newWidth||token.imgWidth;
        let height = this.state.newHeight||token.imgHeight;
        if (dimension == "w") {
            width = val;
            height = Math.round(val*token.imgHeight/token.imgWidth);
        } else {
            height = val;
            width = Math.round(val*token.imgWidth/token.imgHeight);
        }
        this.setState({newWidth:width, newHeight:height, dirty:true});
    }

    setSwitchToJpeg(switchToJpeg){
        this.setState({switchToJpeg, dirty:true});
    }

    onChangeKeywords(keywords) {
        const newKeywords = keywords.split(",");
        this.onChangeField("keywords", newKeywords.map(function (s) {return s.trim()}));
        this.setState({keywords, dirty:true});
    }

    selectFromWeb(url, search) {
        if (url) {
            const t=this;
            const canvasURL = getCanvasUrl(url);

            const image = new Image();
            image.onload = imageEvent => {
                const token=Object.assign({}, t.state.token);
        
                token.displayName = token.displayName || search || null;
                token.imgWidth = image.naturalWidth;
                token.imgHeight = image.naturalHeight;
                delete token.originalWidth;
                delete token.originalHeight;
                token.url = url;
                token.thumb = null;
                token.mapWidth = 10;
                token.artVersionId = campaign.newUid();
                t.quickBuild();
                t.setState({loading:false, image, token, zoom:tokenSize/token.imgHeight, dirty:true});
            };
            image.onerror = function (err) { 
                displayMessage("Error loading image");
                console.log("error loading", err, canvasURL);
                t.setState({newImageURL:null, image:null, loading:false, quickBuild:false});
            };
            image.src = canvasURL;
            this.setState({showSearchWeb:false, uploadFile:null, newWebURL:url, image:null, newImageURL:null, loading:true,loadingMessage:"Loading Image from Web", zoom:1, imageSize:0, imageContentType:null,
                newWidth:0,newHeight:0,imageSize:0,switchToJpeg:false,imageContentType:null, dirty:true
            });
        } else {
            this.setState({showSearchWeb:false});
        }
    }

    showSearchWeb() {
        this.setState({showSearchWeb:true});
    }

    pickBorder(url) {
        campaign.setPrefs({borderUrl:url, dirty:true});
        this.loadBorderImages();
    }

    pickBackground(url) {
        campaign.setPrefs({backgroundUrl:url, dirty:true});
        this.loadBorderImages();
    }

    loadBorderImages() {
        const t=this;
        const prefs = campaign.getPrefs();
        const backgroundUrl = prefs.backgroundUrl;
        const borderUrl = prefs.borderUrl;

        const imageBackground = new Image();
        imageBackground.onload = imageEvent => {
            t.setState({imageBackground});
        };
        imageBackground.src = getCanvasUrl(backgroundUrl || "/backgroundblue.png");

        const imageBorder = new Image();
        imageBorder.onload = imageEvent => {
            t.setState({imageBorder})
        };
        imageBorder.src = getCanvasUrl(borderUrl || "/border1.png");
    }

    onWheel(e) {
        e.evt.preventDefault();
        e.evt.stopPropagation();
        var zoom = this.state.zoom;
        if (e.evt.deltaY < 0) {
            zoom = zoom * 1.1;
        } else {
            zoom = zoom / 1.1;
        }
        this.setState({zoom:zoom, dirty:true});
    }

    onZoom(bigger) {
        var zoom = this.state.zoom;
        if (bigger) {
            zoom = zoom * 1.1;
        } else {
            zoom = zoom / 1.1;
        }
        this.setState({zoom:zoom, dirty:true});
    }

    onClose() {
        this.props.onClose();
    }

    onDoSave(name) {
        if (this.props.name && this.props.extraButtonsFn) {
            this.setDefaultState();
        } else {
            this.props.onClose(name);
        }
    }

    onChangeWidth(width) {
        const token=Object.assign({}, this.state.token);

        token.mapWidth = width;
        this.setState({token, dirty:true});
    }

    onChangeHeight(height) {
        const token=Object.assign({}, this.state.token);

        token.mapWidth = height*token.imgWidth/token.imgHeight;
        this.setState({token, dirty:true});
    }

    onChangeField(prop, val) {
        const token=Object.assign({}, this.state.token);

        token[prop] = val;
        this.setState({token, addBorder:(prop=="type")?["Monster Token", "Character Token"].includes(token.type):this.state.addBorder, dirty:true});
        if (prop=="type") {
            this.setState({newWidth:0,newHeight:0,switchToJpeg:false, dirty:true});
        }
    }

    toggleBorder() {
        this.setState({addBorder:!this.state.addBorder, dirty:true});
    }

    onSave() {
        const token=Object.assign({}, this.state.token);
        const newWidth = this.state.newWidth;

        if (((newWidth && (newWidth!=token.imageWidth)) || this.state.switchToJpeg) && !(this.state.newWebURL || this.state.addBorder)) {
            this.resizeUpload(token);
        } else if (!this.state.uploadFile && !(this.state.addBorder&&this.state.image)) {
            campaign.updateCampaignContent("art", token);
            this.onDoSave(token.name);
        } else {
            if (this.state.addBorder) {
                this.uploadWithBorder()
            } else {
                this.uploadFile(token, this.state.uploadFile, this.state.uploadFile.type, this.state.uploadFile);
            }
        }
    }

    selectFile(e) {
        this.idKey = campaign.newUid();

        if (e.target.files.length == 1) {
            this.loadFile(e.target.files[0]);
        }
    }

    async viewImage(width, height, jpeg) {
        this.setState({
            loading:true, 
            loadingMessage:"Preparing Image"
        });
        try {
            const token=this.state.token;
            let url;
            if (this.state.uploadFile) {
                url = await getFileAsUrl(this.state.uploadFile);
            } else {
                url = token.url;
            }
            const contentType = jpeg?"image/jpeg":this.state.imageContentType;
            const blob = await resizeImage(url, width, contentType);
            this.setState({viewedWidth:width, viewedHeight:height, viewedJpeg:jpeg, viewedSize:blob.size});
            const newurl = await getFileAsUrl(blob);

            let image = new Image();
            image.src = newurl;

            let w = window.open("");
            w.document.write(image.outerHTML);
        } catch (err){
            displayMessage("Unable to display");
        }
        this.setState({
            loading:false, 
            loadingMessage:null
        });
    }

    async resizeUpload(token) {
        let url;
        try {
            if (this.state.uploadFile) {
                this.setState({
                    loading:true, 
                    loadingMessage:"Reading Image"
                });
                url = await getFileAsUrl(this.state.uploadFile);
            } else {
                url = token.url;
            }
            const contentType = this.state.switchToJpeg?"image/jpeg":this.state.imageContentType;
            const blob = await resizeImage(url, this.state.newWidth||token.imgWidth, contentType);
            token.originalWidth = token.originalWidth||token.imgWidth;
            token.originalHeight = token.originalHeight||token.imgHeight;
            token.imgWidth = this.state.newWidth || token.imgWidth;
            token.imgHeight = this.state.newHeight || token.imgHeight;
            this.uploadFile(token, blob, contentType, this.state.uploadFile);
        } catch (err) {
            console.log("error resizing image", err);
        }
    }

    loadFile(file) {
        const t=this;

        t.setState({
            loading:true, 
            loadingMessage:"Loading Image "+file.name, 
            zoom:1,
            uploadFile:null,
            image:null,
            newWebURL:null,
            newImageURL:null,
            imageSize:file.size,
            imageContentType:file.type,
            newWidth:0,newHeight:0,switchToJpeg:false
        });

        const reader = new FileReader();
        reader.onload = readerEvent => {
            // Create image object
            const image = new Image();
            image.onload = imageEvent => {
                const token=Object.assign({}, t.state.token);
        
                token.imgWidth = image.naturalWidth;
                token.imgHeight = image.naturalHeight;
                delete token.originalWidth;
                delete token.originalHeight;
                if (!token.displayName) {
                    token.displayName=cleanFilename(file.name);
                }
                token.url = null;
                token.thumb = null;
                token.mapWidth = 10;
                token.artVersionId = campaign.newUid();
                t.quickBuild();
                t.setState({loading:false, uploadFile:file, newImageURL:readerEvent.target.result, image, token, zoom:tokenSize/token.imgHeight, dirty:true});
            };
            image.onerror = function (err) {
                displayMessage("Error loading file.  Format may not be supported.");
                t.setState({loading:false, quickBuild:false});
                console.log("error setting image src", err);
            }
            image.src = readerEvent.target.result;
            image.setAttribute('crossorigin', 'anonymous');

        };
        reader.readAsDataURL(file);
    }

    uploadFile(token, blob, contentType, file) {
        const t=this;

        t.setState({loading:true, 
            loadingMessage:"Loading Image"
        });
        token.artVersionId = campaign.newUid();
        uploadArtworkWithThumbnail(token, blob, contentType, file).then(function (){
            t.onDoSave(token.name);
        }, function (err){
            t.errorSelectingFile("Error uploading image", err);
        })
    }

    uploadWithBorder() {
        const t=this;
        const ratio=2;
        var tokenCanvas = this.canvas.toCanvas({pixelRatio:ratio});
        tokenCanvas.toBlob(function(blob) {
            const token = Object.assign({}, t.state.token);
            delete token.thumb;
            token.imgWidth = tokenSize*ratio;
            token.imgHeight = tokenSize*ratio;
            token.artVersionId = campaign.newUid();
            delete token.originalWidth;
            delete token.originalHeight;

            uploadArtworkWithThumbnail(token, blob, "image/png", null).then(function (){
                t.onDoSave(token.name);
            }, function (err){
                t.errorSelectingFile("Error uploading image", err);
            })
        }, "image/png");
        t.setState({loading:true, 
            loadingMessage:"Loading Image"
        });
    }

    errorSelectingFile(msg, err) {
        displayMessage(msg+(err?("\nError:"+err.message):""));
        console.log(msg, err);
        this.setState({loading:false});
    }

}

class RenderArt extends React.Component {
    constructor(props) {
        super(props);

        this.state={ };
    }

    render() {
        const art = campaign.getArtInfo(this.props.name);
        if (!art) {
            return <div>artwork not found</div>;
        }

        const frameSize = this.props.frameSize;
        let style = {paddingTop:Math.trunc(100*art.imgHeight/art.imgWidth)+"%", height:0, width:"100%", position:"relative", overflow:"hidden"};
        if (frameSize && frameSize.width && frameSize.height) {
            const maxH = Math.min(art.imgHeight,frameSize.height*0.8);
            const height = frameSize.width*art.imgHeight/art.imgWidth;
            if (height > maxH) {
                return <div onClick={this.showArtList.bind(this, true)} className="flex">
                    <div className="flex-auto"/>
                    <img height={maxH} src={art.url}/>
                    <div className="flex-auto"/>
                    <ArtZoomList defaultArt={this.props.name} open={this.state.show} onClose={this.showArtList.bind(this, false)} pageSync={this.props.pageSync}/>
                </div>
            }
        }

        return <div style={style} onClick={this.showArtList.bind(this, true)}>
            <div style={{position:"absolute", width:"100%", height:"100%", top:0, bottom:0}}>
                <img width="100%" src={art.url}/>
            </div>
            <ArtZoomList defaultArt={this.props.name} open={this.state.show} onClose={this.showArtList.bind(this, false)} pageSync={this.props.pageSync}/>
        </div>
    }

    showArtList(show, evt) {
        if (!this.props.noClick) {
            if (evt){
                evt.stopPropagation();
                evt.preventDefault();
            }
            this.setState({show});
        }
    }

}

function printArt(id) {
    const art = campaign.getArtInfo(id);
    if (!art) {
        return;
    }

    if (campaign.getSourcePreventEmbedding(art.source)) {
        return "<p>Not allowed to publish art.</p>";
    }

    let height=art.imgHeight, width=art.imgWidth;

    if (height > width) {
        if (height > 720) {
            width = width/height*720;
            height = 720;
        }
    } else if (width > 720) {
        height = height/width*720;
        width=720;
    }

    return `<div style="text-align:center"><img width="${width}" height="${height}" src="${art.url}"/></div>`;

}

class RenderImage extends React.Component {
    constructor(props) {
        super(props);

        this.state={ };
    }

    render() {
        const {imgWidth, imgHeight, url, open, onClose} = this.props;
        if (!open) {
            return null;
        }


        const width = Math.min(windowSize.innerHeight,windowSize.innerWidth)*0.7;
        const portrait = ((imgHeight||0)>(imgWidth||0));

        return <Dialog 
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={onClose}>Handout</DialogTitle>
            <DialogContent>
                {portrait?<img src={url} height={Math.min(imgHeight,width)}/>:<img src={url} width={Math.min(imgWidth,width)}/>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

function resizeAndCropImage(file, l) {

    return new Promise((resolve, reject) => {
        // Create file reader
        const reader = new FileReader();
        reader.onload = readerEvent => {
            // Create image object
            const image = new Image();
            image.onload = imageEvent => {
                // Create canvas or use provided canvas
                const canvas = document.createElement('canvas');
                // Calculate scaling
                const horizontalScale = l / image.width;
                const verticalScale = l / image.height;
                const scale = Math.min(horizontalScale, verticalScale);
                // Calculate cropping
                const [width, height] = [scale * image.width, scale * image.height];
                canvas.width = width;
                canvas.height = height;
                // Obtain the context for a 2d drawing
                const context = canvas.getContext('2d');
                if (!context) {
                    return reject('Could not get the context of the canvas element');
                }
                // Draw the resized and cropped image
                context.drawImage(
                    image,
                    0,
                    0,
                    width,
                    height
                );
                canvas.toBlob(blob => {
                    resolve({blob:blob, width:image.naturalWidth, height:image.naturalHeight});
                }, "image/png");
            };
            image.onerror = function (err) {
                console.log("error converting image src", err, err.message);
                return reject("Error loading file.  Format may not be supported.");
            }
            image.src = readerEvent.target.result;
        };

        reader.readAsDataURL(file);
    });
}

function resizeImage(url, newWidth, contentType) {

    return new Promise((resolve, reject) => {
        // Create image object
        const image = new Image();
        image.onload = imageEvent => {
            resizeRawImage(image, newWidth, contentType).then(function(blob){
                resolve(blob);
            }, function (err){
                reject(err)
            });
        };
        image.onerror = function (err) {
            console.log("error resizing image", err, err.message);
            return reject("Error resizing image.  Format may not be supported.");
        }
        image.setAttribute('crossorigin', 'anonymous');
        image.src = url;
    });
}

function resizeRawImage(image, newWidth, contentType) {

    return new Promise((resolve, reject) => {
        // Create canvas or use provided canvas
        const canvas = document.createElement('canvas');
        // Calculate scaling
        const scale = newWidth / image.width;
        const [width, height] = [scale * image.width, scale * image.height];
        canvas.width = width;
        canvas.height = height;
        // Obtain the context for a 2d drawing
        const context = canvas.getContext('2d');
        if (!context) {
            return reject('Could not get the context of the canvas element');
        }
        // Draw the resized and cropped image
        context.drawImage(
            image,
            0,
            0,
            width,
            height
        );
        canvas.toBlob(blob => {
            resolve(blob);
        }, contentType);
    });
}

function getFileAsUrl(file) {
    return new Promise((resolve, reject) => {
        // Create file reader
        const reader = new FileReader();
        reader.onload = readerEvent => {
            resolve(readerEvent.target.result);
        };

        reader.readAsDataURL(file);
    });
}



const opacityValues=[
    {name:"100%", value:1},
    {name:"75%", value:0.75},
    {name:"50%", value:0.5},
    {name:"25%", value:0.25},
];

class ArtHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
        this.idKey = campaign.newUid();
    }

    render() {
        return <span>
            Artwork
            <input
                key={this.idKey}
                accept="image/*"
                className="dn"
                id={"image-file-upload2"+this.idKey}
                type="file"
                onChange={this.selectFile.bind(this)}
                multiple
            />
            <label htmlFor={"image-file-upload2"+this.idKey}>
                <Button className="ml2 minw2" color="primary" variant="outlined" size="small"  component="span">
                    Upload Images
                </Button>
            </label>
            <Button  className="ml2 minw2" color="primary" variant="outlined" size="small" onClick={this.clickSearchWeb.bind(this)}>Search Web for Image</Button>
            <BulkArtCreator open={this.state.bulkFiles} files={this.state.bulkFiles} onClose={this.setState.bind(this, {bulkFiles:null}, null)}/>
            <ArtDialog open={this.state.showNewArt} onClose={this.onCloseNew.bind(this)} defaultName={this.state.defaultName} file={this.state.file} webURL={this.state.webURL}/>
            <SearchWebArtPicker open={this.state.showSearchWeb} onClose={this.selectFromWeb.bind(this)}/>
        </span>;
    }

    onCloseNew(name) {
        this.setState({showNewArt:false})
    }

    selectFile(e) {
        this.idKey = campaign.newUid();
        const length = e.target.files.length;

        if (length == 1) {
            const file = e.target.files[0];
            this.setState({showNewArt:true, file, webURL:null, defaultName:cleanFilename(file.name)});
        } else if (length > 1) {
            this.setState({bulkFiles:e.target.files});
        }
    }

    clickSearchWeb() {
        this.setState({showSearchWeb:true});
    }

    selectFromWeb(url,search) {
        if (url) {
            this.setState({showNewArt:true, file:null, webURL:url, defaultName:search});
        }
        this.setState({showSearchWeb:false});
    }

}

class BulkArtCreator extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnDataChange = this.onDataChange.bind(this);
        this.state={newList:null,type:"Picture"};
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "art");
    }
  
    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "art");
    }

    onDataChange() {
        const oldList = this.state.newList;

        if (oldList) {
            const newList = [];
            for (let it of oldList) {
                const newIt = campaign.getArtInfo(it.name);
                if (newIt){
                    newList.push(newIt);
                }
            }
            newList.sort(sortDisplayName)
            this.setState({newList});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open && (prevProps.open != this.props.open)) {
            this.setState({newList:null, loading:false, uploadName:null,type:"Picture"});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        const {newList, uploadName, type} = this.state;
        let artTypeOptions = artTypeList;
        if (campaign.allowSpecialArtTypes) {
            artTypeOptions=artTypeOptions.concat(specialArtTypeList)
        }

        return <Dialog
            open
            scroll="paper"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={this.onDone.bind(this)}>Bulk Create Artwork</DialogTitle>    
            <DialogContent>
                {newList?<ListFilter 
                    list={newList}
                    border
                    showThumbnails
                    filters={artListFilters}
                    onClick={this.onClickArt.bind(this)}
                    select="click"
                    render={renderArtwork}
                    getListRef={this.saveRef.bind(this)}
                />:<div className="tc">
                    <p>Upload {this.props.files.length} images</p>
                    <div><SelectVal className="w5 mv2 titletext titlecolor" value={type} values={artTypeOptions} onClick={this.setType.bind(this)} label="Artwork Type"/></div>
                    <Button color="primary" variant="outlined" onClick={this.bulkCreateArtwork.bind(this)}>
                        Start Bulk Upload
                    </Button>
                </div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onDone.bind(this)} color="primary">
                   Close
                </Button>
            </DialogActions>
            <ArtDialog open={this.state.showArt} name={this.state.showArt} extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.onClickArt.bind(this,null)}/>
            {this.state.loading?<Dialog
                open
            >
                <DialogContent>
                    Uploading artwork {uploadName}...
                </DialogContent>
            </Dialog>:null}
        </Dialog>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(cname) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(cname))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.onClickArt.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.onClickArt.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    setType(type) {
        this.setState({type});
    }

    onClickArt(name) {
        this.setState({showArt:name});
    }

    onDone() {
        this.props.onClose();
    }

    async bulkCreateArtwork() {
        const files = this.props.files;
        const {type}=this.state;
        const results = [];
        const limits = imageSizeLimits[type] || defaultLimits;
    
        this.setState({loading:true});
        try {
            for (let file of files) {
                const image = await loadFileImage(file);
                const art = {
                    name:campaign.newUid(),
                    displayName:cleanFilename(file.name),
                    type,
                    artVersionId:campaign.newUid(),
                    originalWidth:image.naturalWidth,
                    originalHeight:image.naturalHeight
                };
                let imgWidth=image.naturalWidth;
                let imgHeight=image.naturalHeight;
                const imageSize=file.size;
                this.setState({uploadName:file.name});
        
                const tooLarge = limitCheck(imgWidth, imgHeight, imageSize, limits && limits.recommend);
                const exceedLimit = limitCheck(imgWidth, imgHeight, imageSize, limits && limits.limit);
        
                if (tooLarge || exceedLimit) {
                    const optionList = generateSizeSuggestions(imgWidth, imgHeight, imageSize, limits, tooLarge, exceedLimit, false, false);
                    if (optionList.length > 0) {
                        const {width,height} = optionList[0];
                        imgWidth=width;
                        imgHeight=height;
                    }
                }
                const blob = await resizeRawImage(image, imgWidth, file.type);
        
                art.imgWidth = imgWidth;
                art.imgHeight = imgHeight;
                await uploadArtworkWithThumbnail(art, blob, file.type, file);
                results.push(art);
            }
        } catch (err) {
            console.log("error loading artwork", err);
            displayMessage("Error loading artwork: "+err.message);
            this.props.onClose();
        }
        this.setState({loading:false, newList:results});
    }
}


function loadFileImage(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = readerEvent => {
            // Create image object
            const image = new Image();
            image.onload = imageEvent => {
                resolve(image);
            };
            image.onerror = function (err) {
                reject(err);
            }
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
}

class ExtraArtListBase extends React.Component {
    constructor(props) {
        super(props);

        this.idKey = campaign.newUid();
        this.state={};
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.size && this.props.size && 
            ((Math.max(nextProps.size.width,this.props.size.width)*0.3) < Math.abs(nextProps.size.width-this.props.size.width))
        ) {
            return true;
        }
    
        return (nextProps.artList != this.props.artList) ||
        (nextProps.defaultArt != this.props.defaultArt) ||
        (nextProps.defaultToken != this.props.defaultToken) ||
        (nextProps.pickArt != this.props.pickArt) ||
        (nextProps.pickToken != this.props.pickToken) ||
        (nextProps.noBorder != this.props.noBorder) ||
        (nextProps.widthCalc != this.props.widthCalc) ||
        (nextProps.usePicturePlus != this.props.usePicturePlus) ||
        (nextProps.showPlus != this.props.showPlus) ||
        (nextProps.widthCalc != this.props.widthCalc) ||
         !areSameDeep(nextState,this.state);
    }


    render() {
        let artList = this.props.artList||[];
        const defaultArt = this.props.defaultArt;
        const defaultToken = this.props.defaultToken;
        var ret=[];

        if (this.props.pickArt && defaultArt) {
            if (!artList.includes(defaultArt)) {
                artList = [defaultArt].concat(artList);
            }
        }

        if (this.props.pickToken && defaultToken) {
            if (!artList.includes(defaultToken)) {
                artList = [defaultToken].concat(artList);
            }
        }
        if (this.props.hideShort && (artList.length<2)){
            return null;
        }

        const widthCalc = Number(this.props.widthCalc||160);
        const sizeWidth = this.props.size.width-14; // leave room for scrollbar
        const cols = Math.max(1, Math.trunc(sizeWidth/widthCalc));
        const width = Math.trunc(sizeWidth/cols)-(this.props.noBorder?10:20);

        for (let i in artList) {
            const img = artList[i];
            const art = campaign.getArtInfo(img);
            const isDefaultArt = (this.props.pickArt&&(img==defaultArt));
            const isDefaultToken = (this.props.pickToken&&(img==defaultToken));

            ret.push(<div key={i} className={this.props.noBorder?"dib relative pa1 hoverhighlight overflow-hidden":"ma1 dib relative shadow-3 br3 pa1 hoverhighlight overflow-hidden"}  style={{width:width+10}} onClick={this.onClick.bind(this, img)}>
                <div className="tc">
                    {art?art.imgHeight>art.imgWidth?<img src={art.thumb || art.url} height={width} alt="Loading..."/>:<img src={art.thumb || art.url} width={width} alt="Loading..."/>:<span>Image Missing</span>}
                </div>
                {this.props.noBorder?null:<div className="notetext f4 pv1 pl1 truncate tc">
                    {art && art.displayName}
                    {isDefaultArt?<div className="green">Default Artwork</div>:null}
                    {isDefaultToken?<div className="green">Default Token</div>:null}
                </div>}
            </div>);
        }

        if (this.props.showPlus) {
            ret.push(<div key="plus" className={this.props.noBorder?"dib relative pa1 overflow-hidden":"ma1 dib relative shadow-3 br3 pa1 overflow-hidden"} style={{width:width+10}} onClick={this.onClick.bind(this, "plus")}>
                <div className="flex items-center hoverhighlight overflow-hidden" style={{width:width, height:width}}>
                    <div className={this.props.usePicturePlus?"tc f1 far fa-user-circle titlecolor w-100":"tc f1 fas fa-plus titlecolor w-100"}/>
                </div>
            </div>);
        }

        return <div>
            <div className="flex flex-wrap items-stretch">
                {ret}
            </div>
            <Menu open={this.state.showMenu||false} 
                disableAutoFocusItem 
                anchorPosition={this.state.anchorPos} 
                anchorReference="anchorPosition"
                transformOrigin={{vertical: 'top',horizontal: 'center',}}
                onClose={this.closeMenu.bind(this)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right',}}
            >
                {this.props.pickArt?<MenuItem onClick={this.onDefault.bind(this)}>{(this.props.defaultArt==this.state.selectedArt)?"Remove as default artwork":"Set as default artwork"}</MenuItem>:null}
                {this.props.pickToken?<MenuItem onClick={this.onDefaultToken.bind(this)}>{(this.props.defaultToken==this.state.selectedArt)?"Remove as default token":"Set as default token"}</MenuItem>:null}
                <MenuItem onClick={this.onDelete.bind(this)}>Delete</MenuItem>
            </Menu>

        </div>;
    }

    onClick(img, e){
        if (this.props.onClick) {
            this.props.onClick(img,e);
        } else {
            this.setState({showMenu:true, selectedArt:img, anchorPos:getAnchorPos(e)})
        }
    }

    closeMenu() {
        this.setState({showMenu:false})
    }

    onDelete() {
        this.deleteArtwork(this.state.selectedArt);
        this.closeMenu();
    }

    onDefault() {
        this.props.onChange(this.props.artList, (this.props.defaultArt==this.state.selectedArt)?null:this.state.selectedArt||null, this.props.defaultToken||null);
        this.closeMenu();
    }

    onDefaultToken() {
        this.props.onChange(this.props.artList, this.props.defaultArt||null, (this.props.defaultToken==this.state.selectedArt)?null:this.state.selectedArt||null);
        this.closeMenu();
    }

    deleteArtwork(name) {
        let artList = (this.props.artList||[]).concat([]);
        let defaultArt = this.props.defaultArt;
        let defaultToken = this.props.defaultToken;

        const pos = artList.indexOf(name);
        if (pos >=0) {
            artList.splice(pos,1);
        }

        if (this.props.pickArt && (defaultArt==name)) {
            defaultArt = null;
        }

        if (this.props.pickToken && (defaultToken==name)) {
            defaultToken=null;
        }
        this.props.onChange(artList, defaultArt||null, defaultToken||null);
    }

    addArtwork(name) {
        let artList = (this.props.artList||[]).concat([]);
        let defaultArt = this.props.defaultArt;
        let defaultToken = this.props.defaultToken;
        const art = campaign.getArtInfo(name);
        if (!artList.includes(name)) {
            artList.push(name);
        }

        if (this.props.pickArt && !defaultArt) {
            defaultArt = name;
        }

        if (this.props.pickToken && !defaultToken && (art.type||"").includes("Token")) {
            defaultToken=name;
        }
        this.props.onChange(artList, defaultArt||null, defaultToken||null);
    }
}

class FillArtBase extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.size && this.props.size && 
            ((Math.max(nextProps.size.width,this.props.size.width)*0.3) < Math.abs(nextProps.size.width-this.props.size.width))
        ) {
            return true;
        }
    
        return (nextProps.art != this.props.art);
    }



    render() {
        const art = campaign.getArtInfo(this.props.art)||{};
        const width = Math.min(this.props.size.width, windowSize.innerHeight*0.5);
        const portrait = ((art.imgHeight||0)>(art.imgWidth||0));

        return <div style={{height:width}} className="flex items-center" onClick={this.props.onClick}>
            <div className="flex-auto"/>
            {art.url?(portrait?<img src={art.url} height={width}/>:<img src={art.url} width={width}/>):null}
            <div className="flex-auto"/>
        </div>;
    }
}

class ArtZoomList extends React.Component {
    constructor(props) {
        super(props);
        this.idKey = campaign.newUid();
        this.state=this.getState(props);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open && (prevProps.open != this.props.open)) {
            let artList = this.props.artList||[];
            let art = null;
            const defaultArt = this.props.defaultArt;
            const defaultToken = this.props.defaultToken;
    
            if (defaultArt && !artList.includes(defaultArt)) {
                artList = [defaultArt].concat(artList);
            }
    
            if (defaultToken && !artList.includes(defaultToken)) {
                artList = [defaultToken].concat(artList);
            }
            
            if (!art && (artList.length==1) && !this.props.editable) {
                art = artList[0];
            }
            this.setState(this.getState(this.props));
        }
    }

    getState(props) {
        if (!props.open) {
            return {};
        }
        let artList = props.artList||[];
        let art = null;
        const defaultArt = props.defaultArt;
        const defaultToken = props.defaultToken;

        if (defaultArt && !artList.includes(defaultArt)) {
            artList = [defaultArt].concat(artList);
        }

        if (defaultToken && !artList.includes(defaultToken)) {
            artList = [defaultToken].concat(artList);
        }
        
        if (!art && (artList.length==1) && !props.editable) {
            art = artList[0];
        }
        return ({art, artList,defaultArt:props.defaultArt,defaultToken:props.defaultToken});

    }

    onClose(save) {
        if (save) {
            this.props.onClose(this.state.artList, this.state.defaultArt, this.state.defaultToken);
        } else {
            this.props.onClose();
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        const artList = this.state.artList||[];
        const art = this.state.art;
        const defaultArt = this.state.defaultArt;
        const defaultToken = this.state.defaultToken;
        const editable = this.props.editable;
        const artInfo = campaign.getArtInfo(art);
        const canSetWallpaper = artInfo && !campaign.isCampaignGame() && (artInfo.type=="Wallpaper");

        return <Dialog 
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.onClose.bind(this,false)}>Artwork {this.props.pickToken?"and Tokens":null}</DialogTitle>    
            <DialogContent>
                {art?<FillArt art={art} onClick={this.onClickArt.bind(this,art)}/>:null}
                <ExtraArtList 
                    artList={artList} 
                    defaultArt={defaultArt} 
                    defaultToken={defaultToken} 
                    widthCalc={art&&!editable?80:null} 
                    showPlus={editable||this.props.onSelectToken}
                    usePicturePlus={!this.props.editable && this.props.onSelectToken}
                    noBorder={!editable}
                    hideShort={!editable&&!this.props.onSelectToken&&art} 
                    pickArt={this.props.pickArt} 
                    pickToken={this.props.pickToken} 
                    onClick={this.onClickArt.bind(this)}/>
            </DialogContent>
            <DialogActions>
                {this.props.onSelectToken&&art?<Button onClick={this.changeToken.bind(this, art)} color="primary">
                    Change Token
                </Button>:null}
                {this.props.pageSync&&art&&!campaign.isPlayerMode()?<Button onClick={this.handout.bind(this,art)} color="primary">
                    Handout
                </Button>:null}
                {canSetWallpaper?<Button onClick={this.setWallpaper.bind(this,art)} color="primary">
                    Set Wallpaper
                </Button>:null}
                {editable?<Button onClick={this.onClose.bind(this,true)} color="primary">
                    Save
                </Button>:null}
                <Button onClick={this.onClose.bind(this,false)} color="primary">
                    {editable?"Cancel":"Close"}
                </Button>
            </DialogActions>
            {this.state.showMenu?<Menu open 
                disableAutoFocusItem 
                anchorPosition={this.state.anchorPos} 
                anchorReference="anchorPosition"
                transformOrigin={{vertical: 'top',horizontal: 'center',}}
                onClose={this.closeMenu.bind(this)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right',}}
            >
                {art!=this.state.selectedArt?<MenuItem onClick={this.onShowArt.bind(this, this.state.selectedArt)}>View</MenuItem>:null}
                {this.props.onSelectToken?<MenuItem onClick={this.changeToken.bind(this, this.state.selectedArt)} color="primary">Change Token</MenuItem>:null}
                {this.props.pageSync&&!campaign.isPlayerMode()?<MenuItem onClick={this.handout.bind(this,this.state.selectedArt)} color="primary">Handout</MenuItem>:null}
                {this.props.pickArt&&editable&&(defaultArt!=this.state.selectedArt)?<MenuItem onClick={this.onDefault.bind(this)}>Set Default Artwork</MenuItem>:null}
                {this.props.pickToken&&editable&&(defaultToken!=this.state.selectedArt)?<MenuItem onClick={this.onDefaultToken.bind(this)}>Set Default Token</MenuItem>:null}
                {editable?<MenuItem onClick={this.onDelete.bind(this)}>Remove</MenuItem>:null}
            </Menu>:null}
            <PickArtMenu open={this.state.showAddMenu} onClose={this.closeMenu.bind(this)} onAddArtwork={this.addArtwork.bind(this)} anchorPos={this.state.anchorPos} defaultType={this.props.defaultType} defaultSearch={this.props.defaultSearch}/>
            <ArtDialog open={this.state.showNewArt} onClose={this.onCloseNew.bind(this)} defaultName={this.state.newDefaultName} webURL={this.state.newBasebURL} defaultType={this.state.newDefaultType}/>
        </Dialog>;
    }

    handout(artName) {
        const art=campaign.getArtInfo(artName);
        if (art) {

            this.props.pageSync.emit("showImageHandout", {
                type:"art",
                art:art.name,
                url:art.url,
                description:this.props.description||art.displayName||null,
                imgHeight:art.imgHeight,
                imgWidth:art.imgWidth
            });
            this.props.onClose();
        }
        this.closeMenu();
    }

    changeToken(art) {
        this.props.onSelectToken(art);
        this.closeMenu();
    }

    onShowArt(art) {
        if (this.state.art == art) {
            art=null;
        }
        this.setState({art,showMenu:false});
        this.closeMenu();
    }

    onClickArt(art, event) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        if (art=="plus") {
            this.setState({showAddMenu:true, anchorPos:getAnchorPos(event)})
        } else {
            this.setState({showMenu:true, selectedArt:art, anchorPos:getAnchorPos(event)})
        }
    }

    onChange(artList, defaultArt, defaultToken) {
        this.setState({artList, defaultArt, defaultToken});
    }

    closeMenu() {
        this.setState({showMenu:false,showAddMenu:false})
    }

    onDelete() {
        this.deleteArtwork(this.state.selectedArt);
        this.closeMenu();
    }

    onDefault() {
        this.onChange(this.state.artList, (this.state.defaultArt==this.state.selectedArt)?null:this.state.selectedArt||null, this.state.defaultToken||null);
        this.closeMenu();
    }

    onDefaultToken() {
        const art=campaign.getArtInfo(this.state.selectedArt);
        if (art && (!art.type || !art.type.includes("Token"))) {
            this.setState({showNewArt:true, newDefaultName:art.displayName, newBasebURL:art.url, newDefaultType:this.props.defaultType.includes("Token")?this.props.defaultType:"Monster Token"});
        } else {
            this.onChange(this.state.artList, this.state.defaultArt||null, (this.state.defaultToken==this.state.selectedArt)?null:this.state.selectedArt||null);
        }
        this.closeMenu();
    }

    onCloseNew(name) {
        this.setState({showNewArt:false});
        if (name) {
            const artList = (this.state.artList||[]).concat([]);
            artList.push(name);
    
            this.onChange(artList, this.state.defaultArt||null, name);
        }
    }

    deleteArtwork(name) {
        let artList = (this.state.artList||[]).concat([]);
        let defaultArt = this.state.defaultArt;
        let defaultToken = this.state.defaultToken;

        const pos = artList.indexOf(name);
        if (pos >=0) {
            artList.splice(pos,1);
        }

        if (this.props.pickArt && (defaultArt==name)) {
            defaultArt = null;
        }

        if (this.props.pickToken && (defaultToken==name)) {
            defaultToken=null;
        }
        this.onChange(artList, defaultArt||null, defaultToken||null);
        if (this.state.art == name) {
            this.setState({art:null});
        }
    }

    addArtwork(name) {
        if (!this.props.editable && this.props.onSelectToken) {
            this.changeToken(name);
            return;
        }
        let artList = (this.state.artList||[]).concat([]);
        let defaultArt = this.state.defaultArt;
        let defaultToken = this.state.defaultToken;
        const art = campaign.getArtInfo(name);
        if (!artList.includes(name)) {
            artList.push(name);
        }

        if (this.props.pickArt && !defaultArt) {
            defaultArt = name;
        }

        if (this.props.pickToken && !defaultToken && (art.type||"").includes("Token")) {
            defaultToken=name;
        }
        this.onChange(artList, defaultArt||null, defaultToken||null);
    }

    setWallpaper(wallpaper) {
        if (campaign.isDefaultCampaign()) {
            campaign.updateUserSettings({wallpaper})
        } else {
            campaign.setPrefs({wallpaper});
        }
    }
}

class PickArtMenu extends React.Component {
    constructor(props) {
        super(props);
        this.idKey = campaign.newUid();
        this.state={};
    }

    render() {
        return <span>
            <input
                key={this.idKey}
                accept="image/*"
                className="dn"
                id={this.idKey}
                type="file"
                onChange={this.selectFile.bind(this)}
            />
            <Menu 
                open={!!this.props.open}
                disableAutoFocusItem 
                anchorPosition={this.props.anchorPos} 
                anchorReference="anchorPosition"
                transformOrigin={{vertical: 'top',horizontal: 'center',}}
                onClose={this.closeMenu.bind(this)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right',}}
            >
                <label htmlFor={this.idKey}>
                    <MenuItem>Upload Image</MenuItem>
                </label>
                <MenuItem onClick={this.clickSearchWeb.bind(this)}>Search Web for Image</MenuItem>
                <MenuItem onClick={this.showPickImage.bind(this)}>Pick Image</MenuItem>
            </Menu>
            <ArtDialog open={this.state.showNewArt} onClose={this.onCloseNew.bind(this)} defaultName={this.state.defaultName} file={this.state.file} webURL={this.state.webURL} defaultType={this.props.defaultType}/>
            <ArtPicker defaultType={this.props.defaultType} open={this.state.showPickArt} onClose={this.onClosePick.bind(this)}/>
            <SearchWebArtPicker open={this.state.showSearchWeb} onClose={this.selectFromWeb.bind(this)} type={this.props.defaultType} defaultSearch={this.props.defaultSearch}/>
        </span>;
    }

    clickSearchWeb() {
        this.closeMenu();
        this.setState({showSearchWeb:true});
    }

    selectFromWeb(url,search) {
        if (url) {
            this.setState({showNewArt:true, file:null, webURL:url, defaultName:search});
        }
        this.setState({showSearchWeb:false});
    }

    showPickImage(){
        this.closeMenu();
        this.setState({showPickArt:true});
    }

    onClosePick(art) {
        this.setState({showPickArt:false});
        if (art) {
            this.addArtwork(art.name);
        }
    }

    onCloseNew(name) {
        this.setState({showNewArt:false});
        if (name) {
            this.addArtwork(name);
        }
    }

    addArtwork(name) {
        this.props.onAddArtwork(name);
    }

    closeMenu() {
        this.props.onClose();
    }

    selectFile(e) {
        this.idKey = campaign.newUid();

        if (e.target.files.length == 1) {
            const file = e.target.files[0];
            this.setState({showNewArt:true, file, webURL:null, defaultName:cleanFilename(file.name)});
        }
        this.closeMenu();
    }
}

class ArtImageListBase extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
    }

    render() {
        const imageList = this.props.imageList;
        const widthCalc = Number(this.props.widthCalc||180);
        const cols = Math.max(1, Math.trunc(this.props.size.width/widthCalc));
        var ret=[];
        const width = Math.trunc(this.props.size.width/cols)-20;

        for (let i in imageList) {
            const img = imageList[i];
            const art = img.art?campaign.getArtInfo(img.art):img;

            ret.push(<div key={img.name} className="ma1 dib relative overflow-hidden shadow-3 br3 pa1" style={{width:width}}>
                <div className="tc hoverhighlight" onClick={this.onClick.bind(this, img)}>
                    {art?art.imgHeight>art.imgWidth?<img src={art.thumb || art.url} height={width} alt="Loading..."/>:<img src={art.thumb || art.url} width={width} alt="Loading..."/>:<span>Image Missing</span>}
                </div>
                <div className="flex">
                    <div className="flex-auto truncate pv1 truncate f3 tc hoverhighlight" onClick={this.onClick.bind(this, img)}>{img.displayName}</div>
                    {this.props.onDelete?<DeleteWithConfirm className="pa1" name={img.displayName} onClick={this.onDelete.bind(this,img)}/>:null}
                    {this.props.selected?<span className={this.props.selected[img.name.toLowerCase()]?"pa1 hoverhighlight far fa-check-square":"pa1 hoverhighlight far fa-square"} onClick={this.onToggle.bind(this,img)}/>:null}
                </div>
            </div>);
        }
        return <div className="flex flex-wrap justify-center w-100">
            {ret}
        </div>;
    }

    onClick(img, e){
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.props.onClick(img);
    }

    onDelete(img) {
        this.props.onDelete(img);
    }

    onToggle(img,e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.props.onToggle(img);
    }
}

class FloatArt extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
    }

    render() {
        const art = campaign.getArtInfo(this.props.art);
        if (!art){
            return null;
        }
        const ratio=art.imgHeight/art.imgWidth;
        const extra = <ArtZoomList defaultArt={this.props.art} artList={this.props.artList} defaultToken={this.props.defaultToken} open={this.state.show} onClose={this.showArtList.bind(this, false)} pageSync={this.props.pageSync}/>;

        if (ratio < 0.2) {
            return <div className="mv1">
                <img width="100%" src={art.url} onClick={this.showArtList.bind(this, true)}/>
                {extra}
            </div>
        }

        let maxWidth = 300;
        if (ratio > 3) {
            maxWidth = 300 * 3 * art.imgWidth/art.imgHeight;
        }
        return <div>
            <img className="fr pa1 defaultbackground" width="40%" style={{maxWidth}} src={art.url} onClick={this.showArtList.bind(this, true)}/>
            {extra}
        </div>
    }

    showArtList(show, e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({show});
    }
}

function printFloatArt(artName) {
    const art = campaign.getArtInfo(artName);
    if (!art){
        return "";
    }
    const ratio=art.imgHeight/art.imgWidth;

    let width,height;
    if (ratio > 1) {
        height=Math.min(art.imgHeight,360);
        width = art.imgWidth/art.imgHeight*height;
    } else {
        width=Math.min(art.imgWidth,360);
        height = art.imgHeight/art.imgWidth*width;
    }

    return `<img width="${width}" height="${height}" ${ratio>0.2?'style="float:right;padding: 5px"':""} src="${art.url}" />`;
}

class FloatCardArt extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
    }

    render() {
        const art = campaign.getArtInfo(this.props.art);
        if (!art){
            return null;
        }
        const width=this.props.width;

        return<div style={{width:width*0.33, maxHeight:width*0.8}} className="fr overflow-hidden">
            <img src={art.url} className="relative dib" width={width*0.33*1.1}/>
        </div>
    }

    showArtList(show, e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({show});
    }
}

class ArtPicker extends React.Component {
    constructor(props) {
        super(props);

        this.idKey = campaign.newUid();
        this.state={showNew:props.open?!props.noCreate&&isNoArt(props.defaultType):false};
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open && (prevProps.open != this.props.open)) {
            this.setState({showNew:this.props.noCreate&&isNoArt(this.props.defaultType)});
        }
    }

    render() {
        const {open,defaultType,restrictType} = this.props;
        if (!open) {
            return null;
        }
        let list = campaign.getArt();
        if (restrictType) {
            const plist = list;
            list = [];
            for (let i in plist) {
                if (plist[i].type==restrictType) {
                    list.push(plist[i]);
                }
            }
        }

        return <Dialog
            open
            scroll="paper"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={this.onDone.bind(this)}>Pick Artwork</DialogTitle>    
            <DialogContent>
                <ListFilter 
                    list={list}
                    border
                    showThumbnails
                    defaultFilter={{type:defaultType||null}}
                    filters={artListFilters}
                    onClick={this.onClickArt.bind(this)}
                    select="click"
                    render={renderArtwork}
                />
            </DialogContent>
            <DialogActions>
                <input
                    key={this.idKey}
                    accept="image/*"
                    className="dn"
                    id={this.idKey}
                    type="file"
                    onChange={this.selectFile.bind(this)}
                />
                {this.props.noCreate?null:<label htmlFor={this.idKey}>
                    <Button color="primary" component="span">
                        Upload Image
                    </Button>
                </label>}
                {this.props.noCreate?null:<Button color="primary" onClick={this.clickSearchWeb.bind(this)}>
                    Search Web for Image
                </Button>}
                <Button onClick={this.onDone.bind(this)} color="primary">
                   Cancel
                </Button>
            </DialogActions>
            <ArtDialog open={this.state.showNewArt} onClose={this.onCloseNew.bind(this)} defaultName={this.state.defaultName} file={this.state.file} webURL={this.state.webURL} defaultType={defaultType}/>
            <SearchWebArtPicker open={this.state.showSearchWeb} onClose={this.selectFromWeb.bind(this)} type={defaultType} defaultSearch={this.state.defaultName}/>
        </Dialog>;
    }

    onClickArt(name) {
        this.props.onClose(campaign.getArtInfo(name));
    }

    onCloseNew(name) {
        this.setState({showNewArt:false});
        if (name) {
            const art = campaign.getArtInfo(name);
            this.props.onClose(art);
        }
    }

    selectFile(e) {
        this.idKey = campaign.newUid();

        if (e.target.files.length == 1) {
            const file = e.target.files[0];
            this.setState({showNewArt:true, file, webURL:null, defaultName:cleanFilename(file.name)});
        }
    }

    clickSearchWeb() {
        this.setState({showSearchWeb:true});
    }

    selectFromWeb(url,search) {
        if (url) {
            this.setState({showNewArt:true, file:null, webURL:url, defaultName:search});
        }
        this.setState({showSearchWeb:false});
    }

    onDone() {
        this.props.onClose();
    }
}

class PickImage extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.showMenu && (prevState.showMenu != this.state.showMenu)) {
            this.setState({});
        }
    }

    render() {
        const list = this.getPickList();
        const menues=[];

        for (let i=0; i<list.length; i+=3) {
            const m=list[i];
            const m2=list[i+1];
            const m3=list[i+2];
            menues.push(<MenuItem key={i}>
                <img src={m.url} height={50} width={50} onClick={this.onClickImage.bind(this, m)}/>
                {m2?<img className="ml1" src={m2.url} height={50} width={50} onClick={this.onClickImage.bind(this, m2)}/>:null}
                {m3?<img className="ml1" src={m3.url} height={50} width={50} onClick={this.onClickImage.bind(this, m3)}/>:null}
            </MenuItem>);
        }
        
        return  <span>
            {this.props.useMenu?
                <MenuItem onClick={this.showMenu.bind(this)}>{this.props.label}</MenuItem>
                :<Button className="ml1" color={this.props.color||"primary"} size="small" variant={this.props.variant} onClick={this.showMenu.bind(this)}>{this.props.label}</Button>
            }
            <span onClick={function (e){e.preventDefault(); e.stopPropagation()}}>
                <Menu open={this.state.showMenu||false} 
                    disableAutoFocusItem 
                    anchorEl={this.state.anchorEl} 
                    transitionDuration={0} 
                    onClose={this.closeMenu.bind(this)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right',}}
                >
                    {menues}
                    {this.props.artType&&!isNoArt(this.props.artType)?<MenuItem onClick={this.showPick.bind(this)}>More...</MenuItem>:null}
                </Menu>
                <ArtPicker noCreate={!campaign.allowSpecialArtTypes} restrictType={this.props.artType} defaultType={this.props.artType} open={this.state.showPick} onClose={this.pickArt.bind(this)}/>
            </span>
        </span>
    }

    getPickList() {
        const showAll = (campaign.tokenBorders=="all");
        const list = [];

        const fav = campaign.getUserMRUList("favorites"+this.props.artType);
        for (let i in fav) {
            list.push(fav[i]);
        }

        if (this.props.artType == "Token Border") {
            addToList(showAll?fullBorderOptions:basicBorderOptions);
            if (campaign.ginnyDiDice) {
                addToList(ginnyDiBorderOptions);
            }
        } else {
            addToList(showAll?fullBackgroundOptions:basicBackgroundOptions);
            if (campaign.ginnyDiDice) {
                addToList(ginnyDiBackgroundOptions);
            }
        }

        return list;

        function addToList(opts) {
            for (let o of opts) {
                if (!list.find(function (a) {return (a.description==o.description) || (a.url==o.url);})) {
                    list.push(o);
                }
            }
        }
    }

    showPick(){
        this.setState({showPick:true});
    }
    
    pickArt(artwork) {
        if (artwork) {
            this.onClickImage({description:artwork.name, url:artwork.url});
        }
        this.setState({showPick:false});
    }

    showMenu(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({showMenu:true, anchorEl:e.target});
    }

    closeMenu() {
        this.setState({showMenu:false});
    }

    onClickImage(m) {
        this.props.onClick(m.url);
        this.setState({showMenu:false});
        campaign.addUserMRUList("favorites"+this.props.artType,m);
    }
}

class CreatureArt extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
        this.idKey = campaign.newUid();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open && (prevProps.open != this.props.open)) {
            this.setState({});
        }
    }

    render() {
        let inside;
        if (this.props.children) {
            inside= <span onClick={this.props.onClick||(this.props.onPickToken?this.clickChildren.bind(this):null)}>
                {this.props.children}
            </span>;
        } else {
            inside= <div className="tc">
                <div className="mb1">
                    <input
                        key={this.idKey}
                        accept="image/*"
                        className="dn"
                        id={"image-file-upload2"+this.idKey}
                        type="file"
                        onChange={this.selectFile.bind(this)}
                    />
                    <label htmlFor={"image-file-upload2"+this.idKey}>
                        <Button  size="small" variant="outlined" color="primary" component="span">
                            Upload Token
                        </Button>
                    </label>
                </div>
                <div className="mb1"><Button size="small" variant="outlined" color="primary" onClick={this.clickSearchWeb.bind(this)}>Search Web for Token</Button></div>
                <Button size="small" variant="outlined" color="primary" onClick={this.clickLoadToken.bind(this)}>Pick Token</Button>
            </div>;
        }
        let search = this.props.defaultSearch||"";
        if (this.state.showSearchWeb) {
            search = search + " " + (this.props.character?.getCharacterWebSearchDefault()||"");
        }

        return <span>
            {inside}
            {this.getMenu()}
            {this.state.showPickToken?<ArtPicker defaultType={isArtwork(this.props.defaultType)?this.props.defaultType:null} defaultName={this.props.defaultName} open onClose={this.pickToken.bind(this)}/>:null}
            {this.state.showNewArt?<ArtDialog open onClose={this.onCloseNew.bind(this)} defaultType={this.props.defaultType} defaultName={this.props.defaultName} file={this.state.file} webURL={this.state.webURL}/>:null}
            {this.state.showSearchWeb?<SearchWebArtPicker open onClose={this.selectFromWeb.bind(this)} type={this.props.defaultType} defaultSearch={search}/>:null}
        </span>
    }

    clickChildren(e) {
        this.setState({anchorPos:getAnchorPos(e, true), showOptionsMenu:true});
    }

    getMenu() {
        if (!this.state.showOptionsMenu) {
            return;
        }
        return <Menu open disableAutoFocusItem anchorPosition={this.state.anchorPos} anchorReference="anchorPosition" onClose={this.closeMenu.bind(this)}>
            <input
                key={this.idKey}
                accept="image/*"
                className="dn"
                id={"image-file-upload2"+this.idKey}
                type="file"
                onChange={this.selectFile.bind(this)}
            />
            <label htmlFor={"image-file-upload2"+this.idKey}>
                <MenuItem>Upload Token</MenuItem>
            </label>
            <MenuItem onClick={this.clickSearchWeb.bind(this)}>Search Web for Token</MenuItem>
            {isArtwork(this.props.defaultType)?<MenuItem onClick={this.clickLoadToken.bind(this)}>Pick Token</MenuItem>:null}
        </Menu>;
    }

    closeMenu() {
        this.setState({showOptionsMenu:false});
    }

    clickLoadToken(){
        this.setState({showPickToken:true,showOptionsMenu:false});
    }

    pickToken(token) {
        if (token){
            if (!["Monster Token", "Character Token"].includes(token.type)) {
                // not a token create a new one
                this.setState({showNewArt:true, file:null, webURL:token.url});
            } else {
                this.props.onPickToken(token);
            }
        } 
        this.setState({showPickToken:false});
    }

    onCloseNew(name) {
        this.setState({showNewArt:false})
        if (name) {
            const token = campaign.getArtInfo(name);
            this.props.onPickToken(token);
        }
    }

    selectFile(e) {
        this.idKey = campaign.newUid();

        if (e.target.files.length == 1) {
            const file = e.target.files[0];
            this.setState({showNewArt:true, file, webURL:null});
        }
        this.setState({showOptionsMenu:false});
    }

    clickSearchWeb() {
        this.setState({showOptionsMenu:false, showSearchWeb:true});
    }

    selectFromWeb(url) {
        if (url) {
            this.setState({showNewArt:true, file:null, webURL:url});
        }
        this.setState({showSearchWeb:false});
    }
}


class SearchWebArtPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state={search:this.defaultSearch(), imageURL:null, results:null, size:"all", transparent:false};
        if (props.open) {
            this.search();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open && (prevProps.open != this.props.open)) {
            this.setState({search:this.defaultSearch(), imageURL:null, results:null, size:"all", transparent:false});
            this.search();
        }
    }

    defaultSearch() {
        const suggest = typeSuggestions[this.props.type]||typeSuggestions.default;
        return this.props.defaultSearch || (suggest && suggest.default) || "";
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const results = this.state.results||[];
        const list = [];
        let search = this.state.search;

        for (let i in results) {
            const it = results[i];
            if (it.thumbnailUrl) {
                const maxW = Math.max(100, Math.trunc(150*(it.width||1)/(it.height||1)))
                list.push(<div key={it.thumbnailUrl} className="dib pa--2 tc">
                    <img src={it.thumbnailUrl} onClick={this.showResult.bind(this, it.contentUrl)} height="150px"/>
                    <div className="f7 mv--2">
                        {it.width}x{it.height} {it.encodingFormat}
                        
                    </div>
                    <div className="f7"><a style={{maxWidth:maxW}} className="truncate dib" href={it.hostPageUrl} target="_blank">{it.name}</a></div>
                </div>);
            }
        }

        const suggest = typeSuggestions[this.props.type]||typeSuggestions.default;
        let checkvals;
        const optionvals=[];
        const transparent = this.state.transparent;
        if (suggest) {
            if (suggest.checkTerms) {
                checkvals = <div>
                    {this.props.type != "Map"?<span key="T"><span className="nowrap" onClick={this.toggleTransparent.bind(this)}><span className={transparent?"far fa-check-square pa1 hoverhighlight":"far fa-square pa1 hoverhighlight"}/> transparent</span> </span>:null}
                    {this.getCheckVals(suggest.checkTerms)}
                </div>;
            }
            if (suggest.optionTerms) {
                for (let i in suggest.optionTerms) {
                    const ot=suggest.optionTerms[i];
                    optionvals.push(<span className="mr1" key={i}>{this.getOptionVals(ot.name, ot.list)}</span>);
                }
            }
        }

        return <Dialog
            open
            scroll="paper"
            maxWidth="lg"
            fullWidth
            classes={{paper:"minvh-90"}}
        >
            <DialogTitle onClose={this.onDone.bind(this,false)}>Search Web for Artwork</DialogTitle>    
            <DialogContent className="flex-auto h1 relative ma0 pa0">
                <div className="flex flex-column absolute t00 h-100 w-100 ph2">
                    <div className="mb2">
                        <div>
                            <form onSubmit={this.doSearch.bind(this)} className="w-100 flex items-end">
                                <TextVal fullWidth className="mw6 mr2" text={search||""} onChange={this.setSearch.bind(this)} InputProps={{endAdornment:<span className="pa1 fas fa-times hoverhighlight" onClick={this.setSearch.bind(this, "")}/>}}/>
                                <SelectVal className="minw3" label="Size" value={this.state.size||"all"} values={["all", "medium", "large"]} onClick={this.changeSize.bind(this)}/>
                                <Button color="primary" type="submit">
                                    Search
                                </Button>
                            </form>
                        </div>
                        <div>
                            {checkvals}
                            {optionvals}
                        </div>
                    </div>
                    {this.state.loadingMessage?<div className="ma2">{this.state.loadingMessage}</div>:null}
                    {this.state.imageURL?
                        <div className="flex-auto">
                            <span className="ma1 absolute v-top hoverhighlight pa1 defaultbackground titlecolor fas fa-times" onClick={this.showResult.bind(this,null)}/>
                            <img src={this.state.imageURL} height="100%" onLoad={this.setLoadingMessage.bind(this,null)}/>
                        </div>
                    :
                        <div className="flex flex-wrap justify-around overflow-auto">
                            {list}
                        </div>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                {this.state.imageURL?<Button onClick={this.onDone.bind(this, this.state.imageURL)} color="primary">
                   Pick
                </Button>:null}
                <Button onClick={this.onDone.bind(this, false)} color="primary">
                   Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    getCheckVals(list) {
        const res=[];
        const search = this.state.search||"";

        for (let i in list) {
            const v = list[i];
            const f=new RegExp("\\b"+escapeRegExp(v)+"\\b", "i");

            res.push(<span key={i}><span className="nowrap" onClick={this.toggleSearchTerm.bind(this, v)}><span className={f.test(search)?"far fa-check-square pa1 hoverhighlight":"far fa-square pa1 hoverhighlight"}/> {v}</span> </span>);
        }
        return res;
    }

    getOptionVals(name, list) {
        const search = this.state.search||"";
        let value = "";

        for (let i in list) {
            const v = list[i];
            const f=new RegExp("\\b"+escapeRegExp(v)+"\\b", "i");
            if (f.test(search) && (v.length > value.length)) {
                value = v;
            }
        }
        return <SelectVal className="minw4" label={name} value={value} values={["none"].concat(list)} onClick={this.setOptionTerm.bind(this, value)}/>
    }

    toggleTransparent() {
        this.search();
        this.setState({transparent:!this.state.transparent});
    }

    toggleSearchTerm(term) {
        let search=this.state.search||"";
        const f=new RegExp("\\b"+escapeRegExp(term)+"\\b", "i");

        if (f.test(search)) {
            search = search.replace(f,"");
            search = search.replace(/\s+/," ");
        } else {
            search = term+" "+search;
        }
        search=search.trim();
        this.setState({search});
        this.search();
    }

    setOptionTerm(value, term) {
        let search=this.state.search||"";
        const f=new RegExp("\\b"+escapeRegExp(value)+"\\b", "i");

        search = search.replace(f,"");
        if (term!="none") {
            search = term+" "+search;
        }
        search = search.replace(/\s+/," ").trim();
        this.setState({search});
        this.search();
    }

    setSearch(search) {
        this.setState({search});
    }

    changeSize(size) {
        this.search();
        this.setState({size});
    }
    
    showResult(imageURL) {
        this.setState({imageURL, loadingMessage:imageURL?"Loading image...":null});
    }

    setLoadingMessage(loadingMessage) {
        this.setState({loadingMessage});
    }

    doSearch(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.search();
    }
    
    search() {
        const t=this;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        const suggest = typeSuggestions[this.props.type]||typeSuggestions.default;

        this.timeout = setTimeout(function () {
            const search = (t.state.search||"").replace(/\s+/g, " ").trim();
            const width = widthFromSize[t.state.size];
            const sizeVal = width?("&minWidth="+width):"";
            const transparent = t.state.transparent;

            if (search.length) {
                t.setState({results:null, loadingMessage:"Searching web...", imageURL:null})
                var xhttp = new XMLHttpRequest();
        //        const url = "https://shardtabletop.cognitiveservices.azure.com/bing/v7.0/images/search?count=150&safesearch=Moderate&setlang=en&q="+encodeURIComponent(t.state.search)+defaultSearchAddition;
                const url = location.origin+"/search?count=150&safesearch=Strict&setlang=en"+sizeVal+(transparent?"&imagetype=transparent":"")+"&q="+encodeURIComponent(search)+(suggest.searchAddition||defaultSearchAddition);

                //doGoogleSearch(search, sizeVal, transparent);
                xhttp.onreadystatechange = function() {
                    if (xhttp.readyState == 4) {
                        if (xhttp.status == 200) {
                            const data= JSON.parse(xhttp.responseText);
                            //console.log("downloaded", data);
                            t.setState({results:data.value, loadingMessage:null});
                            gtag("event", "search", {
                                "event_category" : "ImageSearch",
                                "event_label" : search,
                                "search_term":search
                            });
                        } else {
                            console.log("Error loading search results", xhttp.status);
                        }
                    }
                }
                xhttp.open("GET", url, true);
                //xhttp.setRequestHeader("Ocp-Apim-Subscription-Key", "56883b280dad49aca2766d1b33f6945e");
                xhttp.send();
            }
            this.timeout=null;
        },50);
    }

    onDone(imageURL) {
        this.props.onClose(imageURL, imageURL?this.state.search:null);
    }
}

/*
function doGoogleSearch(search, sizeVal, transparent) {
    var xhttp = new XMLHttpRequest();
    const url = "https://customsearch.googleapis.com/customsearch/v1?num=2&cx=c734a6cad754748c4&searchType=image&key=AIzaSyBKGldtgwFiR4MR5zGqPVTk3i-GdHb3nHY&q="+encodeURIComponent(search);

    console.log("about to search google")
    xhttp.onreadystatechange = function() {
        if (xhttp.readyState == 4) {
            if (xhttp.status == 200) {
                const data= JSON.parse(xhttp.responseText);
                console.log("google downloaded", data);
            } else {
                console.log("Error loading google search results", xhttp.status);
            }
        }
    }
    xhttp.open("GET", url, true);
    xhttp.send();

}
*/

const widthFromSize = {
    small:500,
    large:1000,
}

const defaultSearchAddition=encodeURIComponent(' -"video game" -"video games"'); 

const typeSuggestions = {
    Map:{
        default:"battle map",
        checkTerms:["battle","map", "dungeon", "cavern", "road", "river", "tavern", "grove", "clearing", "lake", "pass", "building", "city"],
        optionTerms:[
            {name:"Environment", list:environmentTypeList},
        ],
        searchAddition:encodeURIComponent(' ("d d" OR dnd OR fantasy OR rpg OR pathfinder OR tabletop OR battle OR dungeon OR dungeons OR dragons) -"video game" -"video games"')   
    },
    "Character Token":{
        default:"fighter",
        checkTerms:["token"],
        optionTerms:[
            {name:"Race", list:["aarakocra", "dragonborn", "dwarf", "elf", "genasi", "gnome", "half-elf", "half-orc", "halfling", "human", "tiefling"]},
            {name:"Class", list:["barbarian", "bard", "cleric", "druid", "fighter", "monk", "paladin", "ranger", "rogue", "sorcerer", "warlock", "wizard"]},
            {name:"Gender", list:["male","female"]},
        ]
    },
    "Monster Token":{
        default:"beast",
        checkTerms:["token"],
        optionTerms:[
            {name:"Gender", list:["male","female"]},
        ]
    },
    default:{
        default:"",
        checkTerms:["token"],
    },
}

function isArtwork(type) {
    const list = campaign.getArt();
    for (let i in list) {
        if (list[i].type == type) {
            return true;
        }
    }
    return false;
}

function deleteArtwork(name) {
    const storage = getStorage(firebase);
    const userId = campaign.currentUser.uid;
    const art = campaign.getArtInfo(name)||{};

    const fileRef = ref(storage,"users/"+userId+"/"+nameEncode(campaign.getCurrentCampaign())+"/"+(art.artVersionId||name));
    const fileRefTh = ref(storage, "users/"+userId+"/"+nameEncode(campaign.getCurrentCampaign())+"/"+(art.artVersionId||name)+"th");
    
    deleteObject(fileRef).then(function () {
        //console.log("successfully deleted obj", name);
    }).catch(function (err) {
        console.log("error deleting art", name);
    });

    deleteObject(fileRefTh).then(function () {
        //console.log("successfully deleted thumb obj", name);
    }).catch(function (err) {
        console.log("error deleting thumb art", name);
    });

    campaign.deleteCampaignContent("art", name);
}

const defaultSuggestSizes = [0.8, 0.75, 0.6666, 0.5, 0.3333, 0.25,  0.125, 0.1, 0.05];
function generateSizeSuggestions(width, height, size, limits, overRecommended, overRestricted, jpegOption, isMap) {
    const maxDimension = Math.max(width, height);
    let list = [];
    let check = defaultSuggestSizes;

    if (isMap) {
        if (jpegOption) {
            if (overRecommended) {
                let rec = getRecommended(maxDimension, true);
                if (checkLimit(rec, limits.recommend)){
                    overRecommended=false;
                    addRecommended(rec);
                }
            }
            if (overRestricted) {
                let rec = getRecommended(maxDimension, true);
                if (checkLimit(rec, limits.limit)){
                    overRestricted=false;
                    addRecommended(rec);
                }
            }
        }
    } else {
        if (overRecommended && ((maxDimension*0.8) > limits.recommend.maxDimension)) {
            let rec = getRecommended(limits.recommend.maxDimension, jpegOption);
            if (checkLimit(rec, limits.recommend)){
                addRecommended(rec);
            }
        }
        if (overRestricted && ((maxDimension*0.8) > limits.limit.maxDimension)) {
            let rec = getRecommended(limits.limit.maxDimension, jpegOption);
            if (checkLimit(rec, limits.limit)){
                addRecommended(rec);
            }
        }
    }
    //console.log("check", check, overRecommended, overRestricted, jpegOption);
    for (let i in check) {
        const c = check[i];
        let rec = getRecommended(c*maxDimension, jpegOption);
        if (overRecommended) {
            if (checkLimit(rec, limits.recommend)){
                overRecommended = false;
                addRecommended(rec);
            }
        }
        if (overRestricted) {
            if (checkLimit(rec, limits.limit)){
                overRestricted = false;
                addRecommended(rec);
            }
        }
    }

    list.sort(function (a,b){return b.estimatedSize-a.estimatedSize})
    //console.log("recommend", list);
    return list;

    function checkLimit(rec,limit) {
        const ret = ((rec.height <= limit.maxDimension) && (rec.width <= limit.maxDimension) && (rec.estimatedSize <= limit.maxSize));
        return ret;
    }

    function getRecommended(newSize, jpeg) {
        const ret = {estimatedSize:estimateSize(newSize,maxDimension, size, jpeg)};
        ret.height = Math.round(height*newSize/maxDimension);
        ret.width = Math.round(width*newSize/maxDimension);
        if (jpeg) {
            ret.jpeg = true;
        }
        return ret;
    }

    function addRecommended(newval) {
        for (let i in list) {
            const l = list[i];
            if (Math.abs(l.width-newval.width) < (l.width*0.05)) {
                return;
            }
        }
        list.push(newval);
    }
}

function estimateSize(newWidth, oldWidth, size, jpeg) {
    const ratio = newWidth/oldWidth;
    let ns = (size||100000)*(ratio*ratio);
    if (jpeg) {
        ns = ns/2;
    }
    return ns;
}

function limitCheck(width, height, size, limit) {
    return (limit && width && height && ((width > limit.maxDimension) || (height > limit.maxDimension) || (size&&(size>limit.maxSize))));
}

function cleanFilename(name) {
    const pos = name.indexOf(".");
    if (pos > 1) {
        name = name.substr(0,pos);
    }
    return name.replace(/[_\-\.]/g, " ");
}

const sizeMB = 1024*1024;
const sizeKB = 1024;

function displaySize(size) {
    if (size > sizeMB) {
        return (size/sizeMB).toFixed(1)+"MB";
    }
    return (size/sizeKB).toFixed(1)+"KB";
}

const defaultLimits = {
    recommend:{maxDimension:800,maxSize:1*1024*1024},
    limit:{maxDimension:2000,maxSize:4*1024*1024}
};

const imageSizeLimits = {
    Map:{
        recommend:{maxDimension:10000, maxSize:3*1024*1024},
        limit:{maxDimension:20000, maxSize:10*1024*1024},
        suggestJpeg:true
    },
    Picture:{
        recommend:{ maxDimension:1200, maxSize:2*1024*1024},
        limit:{maxDimension:2000, maxSize:4*1024*1024}
    },
    "Character Token":{
        recommend:{maxDimension:560,maxSize:1*1024*1024},
        limit:{maxDimension:1000,maxSize:4*1024*1024}
    },
    "Monster Token":{
        recommend:{maxDimension:560,maxSize:1*1024*1024},
        limit:{maxDimension:1000,maxSize:4*1024*1024}
    },
    "Audio Token":{
        recommend:{maxDimension:280,maxSize:0.5*1024*1024},
        limit:{maxDimension:560,maxSize:1*1024*1024}
    },
    "Map Token":{
        recommend:{maxDimension:800,maxSize:1*1024*1024},
        limit:{maxDimension:2000,maxSize:4*1024*1024}
    },
    "Spell Token":{
        recommend:{maxDimension:800,maxSize:1*1024*1024},
        limit:{maxDimension:2000,maxSize:4*1024*1024}
    },
    "Treasure Token":{
        recommend:{maxDimension:800,maxSize:1*1024*1024},
        limit:{maxDimension:2000,maxSize:4*1024*1024}
    }
}

async function uploadArtworkWithThumbnail(token, blob, contentType, file) {
    try {
        const rand = token.artVersionId;
        token.url = await uploadUserBlob(blob, contentType, "/"+nameEncode(campaign.getCurrentCampaign())+"/"+rand);

        if (file && file.size > 600000) {
            const imageInfo = await resizeAndCropImage(file, 300);

            token.thumb = await uploadUserBlob(imageInfo.blob, "image/png", "/"+nameEncode(campaign.getCurrentCampaign())+"/"+rand+"th");
        }

        campaign.updateCampaignContent("art", token);
    } catch (err) {
        console.log("Error uploading file", err);
    }
}

let useSiteUpload = false;
async function uploadUserBlob(blob, contentType, path) {
    if (useSiteUpload) {
        return await uploadUserBlobThroughSite(blob, contentType, path);
    }

    try {
        const storage = getStorage(firebase);
        const userId = campaign.userId;
        const relUrl = "users/"+userId+path;
        const fileRef = ref(storage,relUrl);

        await uploadBytes(fileRef,blob,{contentType:contentType,cacheControl: "public,max-age=4838400"});
        return getDirectDownloadUrl(relUrl);
    } catch (err) {
        console.log("failed direct upload retry through site", err);
        useSiteUpload=true;
        return await uploadUserBlobThroughSite(blob, contentType, path);
    }
}

async function uploadUserBlobThroughSite(blob, contentType, path) {
    const currentUser = campaign.currentUser;
    const idToken = await currentUser.getIdToken(/* forceRefresh */ false);
    const userId = campaign.userId;
    const relUrl = "users/"+userId+path;
    
    console.log("use site upload")
    await fetch("/search?cmd=publishuserblob&path="+encodeURIComponent(path),{
        method:"POST",
        headers:{auth:idToken,contentType},
        body:blob
    })
    return getDirectDownloadUrl(relUrl);
}

function getCanvasUrl(url) {
    if (url.startsWith("/")) {
        return url;
    }
    const canvasURL = (location.origin+"/search?forward="+encodeURIComponent(url));
    return canvasURL;
}

function isNoArt(type) {
    const art = campaign.getArt();

    for (let i in art) {
        if (!type || art[i].type==type) {
            return false;
        }
    }
    return true;
}


const ArtImageList = sizeMe({monitorHeight:false, monitorWidth:true})(ArtImageListBase);
const ExtraArtList = sizeMe({monitorHeight:false, monitorWidth:true})(ExtraArtListBase);
//const FloatArt = sizeMe({monitorHeight:false, monitorWidth:true})(FloatArtBase);
const FillArt = sizeMe({monitorHeight:false, monitorWidth:true})(FillArtBase);

export {
    RenderArtList,
    ArtHeader,
    ArtDialog,
    ArtImageList,
    ArtPicker,
    SearchWebArtPicker,
    deleteArtwork,
    CreatureArt,
    resizeAndCropImage,
    cleanFilename,
    RenderArt,
    ExtraArtList,
    FloatArt,
    ArtZoomList,
    FloatCardArt,
    PickArtMenu,
    uploadArtworkWithThumbnail,
    getFileAsUrl,
    resizeImage,
    RenderImage,
    isNoArt,
    uploadUserBlob,
    artListFilters,
    printFloatArt,
    printArt
}