const React = require('react');
import TextField from '@material-ui/core/TextField';
const {displayMessage} = require('./notification.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {campaign} = require('../lib/campaign.js');
const {firebase} = require("./firebase.jsx");
import { getAuth,signInWithEmailAndPassword,updateProfile, GoogleAuthProvider, 
    signInWithPopup, sendEmailVerification, sendPasswordResetEmail,
    createUserWithEmailAndPassword, EmailAuthProvider,reauthenticateWithCredential,
    updatePassword
} from "firebase/auth";

class Authenticate extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {doCreate:props.doCreate};
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.doCreate != prevProps.doCreate) {
            this.setState({doCreate:this.props.doCreate});
        }
    }

    render() {
        const doCreate = this.state.doCreate;
        const resetPassword = this.state.resetPassword;
        const onClose = this.props.onClose;
        
        return <Dialog
            maxWidth="sm"
            fullWidth={true}
            open
        >
            <DialogTitle onClose={onClose}>{doCreate?"Create Free Account":resetPassword?"Reset Password":"Sign In"}</DialogTitle>
            <DialogContent>
                {doCreate?<div className="hk-well titletext f3 ph1">
                    Free to join and will always have a free version
                </div>:null}
                <form>
                    <TextField 
                        className="mv2" 
                        fullWidth
                        autoFocus 
                        type="email"
                        autoComplete="username"
                        value={this.state.email||""}
                        onChange={this.onChangeEmail.bind(this)}
                        label="Email"
                        error={!!this.state.verifyError}
                        helperText={this.state.verifyError}
                    />
                    {doCreate?<TextField 
                        className="mv2" 
                        fullWidth
                        type="email"
                        autoComplete="verifyusername"
                        value={this.state.verifyEmail||""}
                        onChange={this.onChangeVerifyEmail.bind(this)}
                        label="Verify Email"
                    />:null}
                    {doCreate?<TextField 
                        className="mv2" 
                        fullWidth 
                        type="text" 
                        autoComplete="name" 
                        value={this.state.name||""} 
                        onChange={this.onChangeName.bind(this)} 
                        label="First & last name"
                    />:null}
                    {!resetPassword?<TextField 
                        className="mv2" 
                        fullWidth 
                        type="password" 
                        autoComplete="current-password" 
                        value={this.state.password||""} 
                        onChange={this.onChangePassword.bind(this)} 
                        label="Password"
                        error={!!this.state.error}
                        helperText={this.state.error}
                    />:null}
                    {(!resetPassword&&!doCreate)?<div>
                        <a className="f5" onClick={this.setResetPassword.bind(this)}>Reset password</a>
                    </div>:null}
                </form>
                <div className="mv2 stdcontent">
                    Using the site implies that you agree to our <a href="https://shardtabletop.com/terms">Terms of Service</a> and <a href="https://shardtabletop.com/privacy">Privacy Statement</a>.
                </div>
            </DialogContent>
            <DialogActions>
                {resetPassword?null:<Button className="mh1 white-90 googleButton" onClick={this.signInGoogle.bind(this)} variant="outlined" color="primary">
                    Sign In With Google
                </Button>}
                {resetPassword?<Button className="mh1" onClick={this.sendResetPassword.bind(this)} variant="outlined" color="secondary">
                    Send Reset Password
                </Button>:null}
                {(doCreate||resetPassword)?null:<Button className="mh1" onClick={this.switchToCreate.bind(this,true)} variant="outlined" color="secondary">
                    Create Account
                </Button>}
                {(doCreate||resetPassword)?null:<Button className="mh1" onClick={this.signIn.bind(this)} variant="contained" color="primary">
                    Sign In
                </Button>}
                {doCreate?<Button className="mh1" onClick={this.createAccount.bind(this)} variant="contained" color="primary">
                    Create Account
                </Button>:null}
                {doCreate?<Button className="mh1" onClick={this.switchToCreate.bind(this,false)}  variant="outlined" color="secondary">
                    Cancel
                </Button>:null}
            </DialogActions>
            <Dialog open={this.state.loading||false}>
                <DialogContent>
                    {resetPassword?"Sending password reset email...":"Signing in..."}
                </DialogContent>
            </Dialog>
            <Dialog open={!!this.state.dialogError} onClose={this.closeDialogError.bind(this)}>
                <DialogContent>
                    {this.state.provider?<div>Error signing into {this.state.provider}</div>:<div>Error signing in</div>}
                    <div>{this.state.dialogError}</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeDialogError.bind(this)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>;
    }

    close() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    setResetPassword() {
        this.setState({resetPassword:true});
    }

    switchToCreate(doCreate) {
        this.setState({doCreate});
    }

    onChangeName(event) {
        const name = event.target.value;
        this.setState({name, error:null});
    }

    onChangeEmail(event) {
        const email = event.target.value;
        this.setState({email, error:null, verifyError:null});
    }

    onChangeVerifyEmail(event) {
        const email = event.target.value;
        this.setState({verifyEmail:email, error:null, verifyError:null});
    }

    onChangePassword(event) {
        const password = event.target.value;
        this.setState({password, error:null});
    }

    onChangeMergePassword(event) {
        const mergePassword = event.target.value;
        this.setState({mergePassword, error:null});
    }

    closeDialogError() {
        this.setState({dialogError:null});
    }

    signIn() {
        const t=this;
        signInWithEmailAndPassword(getAuth(firebase), this.state.email||"", this.state.password||"").then(function () {
            t.close();
        }, function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
            console.log("signin error", errorCode, errorMessage);
            t.setState({error:error.message, loading:false});
        });
        this.setState({loading:true});
    }

    sendResetPassword() {
        const t=this;
        var actionCodeSettings = {
            url: window.location.href,
            handleCodeInApp: false
        };

        sendPasswordResetEmail(getAuth(firebase), this.state.email||"", actionCodeSettings).then(function() {
            t.setState({error:"Reset email sent.  Click link to set password.", loading:false, resetPassword:false});
        }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
            console.log("send reset password error", errorCode, errorMessage);
            t.setState({error:error.message, loading:false, resetPassword:false});
        });
        this.setState({loading:true});
    }

    createAccount() {
        const t=this;
        if (this.state.email != this.state.verifyEmail) {
            this.setState({verifyError:"Email addresses don't match.  Please verify and correct."});
            return;
        }
        createUserWithEmailAndPassword(getAuth(firebase), this.state.email||"", this.state.password||"").then(async function(userCredentialPromise) {
            const userCredential = await userCredentialPromise;
            const user = userCredential.user;
            updateProfile(user, {displayName:t.state.name}).then(function() {
                console.log("set display name")
                t.close();
            }).catch(function(err) {
                console.log("error setting displayName", err);
            });

            var actionCodeSettings = {
                url: window.location.href,
                handleCodeInApp: false
            };
            return sendEmailVerification(user, actionCodeSettings)
            .then(function() {
                // Verification email sent.
                console.log("verify email sent");
            })
            .catch(function(error) {
                // Error occurred. Inspect error.code.
                console.log("email send error", error);
            });

        }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
            console.log("createaccount error", errorCode, errorMessage);
            t.setState({error:error.message, loading:false});
        });
        this.setState({loading:true});
    }

    signInGoogle() {
        const provider = new GoogleAuthProvider();
        const t= this;
        signInWithPopup(getAuth(firebase),provider).then(function(result) {
            t.close();
        }).catch(function(error) {
            // Handle Errors here.
            t.setState({loading:false, dialogError:(error.code=='auth/popup-closed-by-user')?null:error.message, provider:"Google"});
        });
        this.setState({loading:true});
    }
}

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {loading:false};
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open != prevProps.open) {
            this.setState({loading:false, password:"", newPassword:"", verifyNewPassword:""});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const password = this.state.password||"";
        const newPassword = this.state.newPassword||"";
        const verifyNewPassword = this.state.verifyNewPassword||"";

        return <Dialog
            maxWidth="xs"
            fullWidth={true}
            open
        >
            <DialogTitle onClose={this.props.onClose}>Change Password</DialogTitle>
            <DialogContent>
                <form>
                    <TextField 
                        className="mv2" 
                        fullWidth 
                        type="password" 
                        autoComplete="current-password" 
                        value={password} 
                        onChange={this.onChangePassword.bind(this)} 
                        label="Current Password"
                    />
                    <TextField 
                        className="mv2" 
                        fullWidth 
                        type="password" 
                        value={newPassword} 
                        onChange={this.onChangeNewPassword.bind(this)} 
                        label="New Password"
                    />
                    <TextField 
                        className="mv2" 
                        fullWidth 
                        type="password" 
                        value={verifyNewPassword} 
                        onChange={this.onChangeVerifyNewPassword.bind(this)} 
                        label="Verify New Password"
                        error={!!(newPassword&&(newPassword!=verifyNewPassword))}
                        helperText={newPassword&&(newPassword!=verifyNewPassword)?"Passwords don't match":null}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button disabled={!password||!newPassword||newPassword!=verifyNewPassword} onClick={this.changePassword.bind(this)} color="primary">
                    Change Password
                </Button>
                <Button onClick={this.props.onClose}  color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <Dialog open={this.state.loading||false}>
                <DialogContent>
                    Changing password...
                </DialogContent>
            </Dialog>
        </Dialog>;
    }

    onChangePassword(event) {
        this.setState({password:event.target.value});
    }

    onChangeNewPassword(event) {
        this.setState({newPassword:event.target.value});
    }

    onChangeVerifyNewPassword(event) {
        this.setState({verifyNewPassword:event.target.value});
    }

    changePassword() {
        const t=this;
        const password = this.state.password||"";
        const newPassword = this.state.newPassword||"";
        const user = campaign.currentUser;
        const credential = EmailAuthProvider.credential(user.email, password);

        this.setState({loading:true});
        reauthenticateWithCredential(user, credential).then(function () {
            updatePassword(user, newPassword).then(function() {
                displayMessage("Password Changed");
                t.props.onClose();
            }, function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // ...
                console.log("change password error", errorCode, errorMessage);
                displayMessage("Error Changing Password: "+error.message);
                t.setState({loading:false});
            })
        }, function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
            console.log("change password signin error", errorCode, errorMessage);
            displayMessage("Error Verifying Current Password: "+error.message);
            t.setState({loading:false});
        });
    }
}

export {
    Authenticate, 
    ChangePassword
};