const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';

const {CheckVal, TextVal, DeleteWithConfirm,} = require('./stdedit.jsx');

class FeedbackDialog extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {feedback:(props.open && campaign.getFeedbackInfo(props.feedback))||{name:campaign.newUid(), userName:campaign.displayName||null}};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && this.props.open!= prevProps.open) {
            this.setState({feedback:(this.props.open && campaign.getFeedbackInfo(this.props.feedback))||{name:campaign.newUid(), userName:campaign.displayName||null}});
        }
    }

    render() {
        const feedback = this.state.feedback;
        if (!this.props.open) {
            return null;
        }
        const {BuyButton,tipProduct} = require('./purchase.jsx');
        const tipUser = campaign.getPrefs().shareUser;

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>Gamemaster Feedback</DialogTitle>
            <DialogContent className="stdcontent">
                <TextVal fullWidth text={feedback.userName||""} onChange={this.onChangeField.bind(this,"userName")} helperText="User Name"/>
                <div><CheckVal label="Anonymous" value={!feedback.userName} onChange={this.onChangeField.bind(this, "userName", null)}/></div>
                <div className="mv2">Rating: <Rating value={feedback.rating||0} onChange={this.onChangeField.bind(this,"rating")} range={5}/></div>
                <div>Feedback</div>
                <TextVal fullWidth multiline text={feedback.notes} onChange={this.onChangeField.bind(this,"notes")} placeholder="Feedback text"/>
                <div className="hk-well mt2">
                    Send a tip to the Gamemaster of your campaign.
                    {feedback.tipAmount?<div className="mv2">Includes a tip of ${feedback.tipAmount}.</div>:<div className="mv2"><BuyButton label="tip" product={{name:tipProduct}} tipUser={tipUser} onComplete={this.buyComplete.bind(this)} directPurchase defaultDollarValue={5}/></div>}
                    <i>Tips accrue as credits which can be used to purchase items in the Shard Marketplace.  Additionally, tips can be payed out via PayPal with a fee withheld to cover processing fees.</i>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.saveData.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    buyComplete(dollarValue) {
        if (dollarValue) {
            this.onChangeField("tipAmount", dollarValue);
        }
    }

    saveData() {
        this.state.feedback.time = Date.now()
        campaign.updateCampaignContent("feedback", this.state.feedback);
        this.props.onClose();
    }

    onChangeField(field,val) {
        const feedback = Object.assign({}, this.state.feedback);
        if (val == "none") {
            val = null;
        } else if (val == "true") {
            val=true;
        } else if (val == "false") {
            val=false;
        }
        feedback[field] = val;
        this.setState({feedback});
    }
}

class Rating extends React.Component {

    render() {
        const {range,value,readonly} = this.props;
        const list=[];

        if (!readonly) {
            list.push(<span key="none" className="notetext ph1 hoverhighlight f7" onClick={this.clickValue.bind(this,0)}>NONE</span>)
        }
        for (let i=0; i< range; i++) {
            list.push(<span key={i} className={((i < value)?"pa--2 fas fa-star ":"pa--2 far fa-star ")+(readonly?"":"hoverhighlight")} onClick={readonly?null:this.clickValue.bind(this,i+1)}/>)
        }
        return  <span>{list}</span>;
    }

    clickValue(value) {
        this.props.onChange(value);
    }

}

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
class FeedbackListDialog extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnDataChange = this.onDataChange.bind(this);
	    this.state= {};
    }

    componentDidMount() {
        this.onDataChange();
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "feedback");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "feedback");
    }

    onDataChange() {
        this.setState({feedback:campaign.getFeedback()})
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const feedback = this.state.feedback||[];
        const list = [];

        for (let i in feedback) {
            const f = feedback[i];

            list.push(<div key={i}>
                {(i>0)?<div className="mv2 bb b--gray"/>:null}
                <div>
                    <div className="h0 tr"><DeleteWithConfirm className="pa1 titlecolor z-1 relative" name="feedback" onClick={this.onDelete.bind(this,f.name)}/></div>
                    <span className="f2">{f.userName||"Anonymous"}</span>
                    <span className="pl3 f7">{(new Date(f.time)).toLocaleDateString(undefined,dateOptions)}</span>
                </div>
                {f.rating||f.tipAmount?<div>
                    {f.rating?<span>Rating: <Rating value={f.rating} readonly range={5}/>&nbsp;</span>:null}
                    {f.tipAmount?<span>Tip: ${f.tipAmount.toFixed(2)}</span>:null}
                </div>:null}
                {f.notes?<div className="mv2">{f.notes}</div>:null}
            </div>)
        }

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>Gamemaster Feedback</DialogTitle>
            <DialogContent className="stdcontent">
                {list}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.showFeedbackRequest.bind(this)} color="primary">
                    Request Feedback
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    showFeedbackRequest() {
        const {addToHandoutMRU} = require('./handouts.jsx');

        const ho = {
            type:"feedback",
            description:"Feedback",
        };
        addToHandoutMRU(ho, true);
        this.props.onClose();
    }

    onDelete(name) {
        campaign.deleteCampaignContent("feedback", name);
    }
}


export {
    FeedbackDialog,
    FeedbackListDialog
}