const React = require('react');
const {campaign,globalDataListener,addCampaignToPath} = require('../lib/campaign.js');
const {firebase} = require("./firebase.jsx");

import Button from '@material-ui/core/Button';
const {TextBasicEdit, windowSize, getAnchorPos,DeleteWithConfirm} = require('./stdedit.jsx');
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {MapPane} = require('./map.jsx');
const {EncounterDialog, getDefaultBookName} = require('./encounterview.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
const {PinDialog} = require('./pins.jsx');
const {getLocationInfo} = require("./renderhref.jsx");
const {Book,getChapterInfoFromFragment,getFragmentFromChapterSection} = require('./book.jsx');

class RenderBuild extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.clickContentFn = this.clickContent.bind(this);

        const book = campaign.getBookInfo(props.bookname);
        this.state= {
            selectedMap:this.selectedMap(), 
            mapPos:null,
            chapter:props.chapter||0, 
            section:props.section||-1, 
            subsection:props.subsection||-1
        };
        if (props.fragment) {
            const chaptInfo = getChapterInfoFromFragment(props.bookname, props.fragment);
            Object.assign(this.state, chaptInfo);
        }
    }

    componentDidMount() {
        if (this.props.pageSync){
            this.props.pageSync.on("clickcontent", this.clickContentFn);
        }
        globalDataListener.onChangeUserSettings(this.handleOnDataChange);
    }
  
    componentWillUnmount() {
        if (this.props.pageSync){
            this.props.pageSync.removeListener("clickcontent", this.clickContentFn);
        }
        globalDataListener.removeUserSettingsListener(this.handleOnDataChange);
    }

    selectedMap() {
        const {map, bookname} = this.props;
        if (map) {
            return map;
        }
        const book = campaign.getBookInfo(bookname);

        return (campaign.getPrefs().bookMaps||{})[bookname] || (book && book.buildMap);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.map != this.props.map) {
            this.setState({selectedMap:this.props.map, mapPos:null});
        }
        if ((prevProps.bookname != this.props.bookname) ||
            (prevProps.chapter != this.props.chapter) ||
            (prevProps.section != this.props.section) ||
            (prevProps.subsection != this.props.subsection) ||
            (prevProps.fragment != this.props.fragment)) {

            const book = campaign.getBookInfo(this.props.bookname);

            const newState = {
                selectedMap:this.selectedMap(), 
                mapPos:null,
                chapter:this.props.chapter||0, 
                section:this.props.section||-1, 
                subsection:this.props.subsection||-1
            };
            if (this.props.fragment) {
                const chaptInfo = getChapterInfoFromFragment(this.props.bookname, this.props.fragment);
                Object.assign(newState, chaptInfo);
            }
            this.setState(newState);
    
        }
    }

    onDataChange() {
        this.setState({usersettings:campaign.getUserSettings()});
    }

    clickContent(data) {
        if (data.contentType=="Map") {
            this.onGoToMap({mapName:data.id});
        }
    }

	render() {
        const pinRules ={location:true};
        const showMap = (this.props.mapOnly || campaign.getUserSettings().showMapInBookEdit);
        const wideEnough = (windowSize.innerWidth > 800);
        const {selectedMap} = this.state;
        let {mapPos}= this.state;

        if (!mapPos && selectedMap) {
            mapPos={mapName:selectedMap}
        }

        if (this.state.confirmDelete) {
            const mi = campaign.getMapInfo(this.state.deleteName);
            if (mi) {
                deleteDisplay=mi.displayName;
            }
        }

        this.canShowMap = (showMap&&(wideEnough || this.props.mapOnly));

        return <div className="h-100 w-100">
            <div className="flex h-100 w-100 overflow-hidden items-stretch">
                
                {!this.props.mapOnly?<div className="flex-2 h-100 mw-100">
                    {this.getBookPannel()}
                </div>:null}
                {this.canShowMap?<div className="flex-2 h-100 mw-100 defaultbackground">
                    <MapPane 
                        mapRef={this.saveRef.bind(this)} 
                        useMapName 
                        showImagePick={!this.props.mapOnly}
                        nosometimesCover
                        noPopup 
                        mapIndex={0} 
                        numPanes={1} 
                        pinRules={pinRules}
                        mapPos={mapPos}
                        grid={this.state.grid}
                        onToggleGrid={this.onToggleGrid.bind(this)}
                        onGoToMap={this.onGoToMap.bind(this)}
                        onGoToBook={this.onGoToBook.bind(this)}
                        onSetEncounterBook={this.props.bookname?this.onSetEncounterBook.bind(this):null }
                        bookname={this.props.bookname}
                        chapter={this.state.chapter} 
                        section={this.state.section} 
                        subsection={this.state.subsection}
                        onTapMap={this.onTapMap.bind(this)}
                        className="h-100 w-100"
                        onGoToEncounter={this.onGoToEncounter.bind(this)}
                    />
                    {this.getMapContextMenu()}
                    <PinDialog 
                        open={this.state.showNewPin} 
                        bookname={this.props.bookname}
                        chapter={this.state.chapter} 
                        section={this.state.section} 
                        subsection={this.state.subsection}
                        pin={this.state.newPin}
                        onClose={this.setNewPin.bind(this,false)}
                    />
                </div>:null}
            </div>
            <EncounterDialog 
                encounter={this.state.encounter} 
                open={this.state.showEncounter} 
                createNew={this.state.createNew} 
                onClose={this.onCloseEncounter.bind(this)} 
                onGoToEncounter={this.onGoToEncounter.bind(this)}
                mapPos={this.state.emapPos}
                startingBook={this.props.bookname||null}
                startingChapter={this.state.chapter}
                startingSection={this.state.section}
                startingSubsection={this.state.subsection}
            />
            <RenderMapDialog onGoToMap={this.onGoToMap.bind(this)} onGoToBook={this.onGoToBook.bind(this)} open={this.state.showMapDialog} mapName={this.state.selectedMap} onClose={this.hideMapDialog.bind(this)}/>
        </div>;
    }

    hideMapDialog() {
        this.setState({showMapDialog:false});
    }

    onToggleGrid(){
        this.setState({grid:!this.state.grid});
    }

    getDefaultBookName() {
        return getDefaultBookName(this.props.bookname, this.state.chapter, this.state.section, this.state.subsection);
    }

    getBookPannel() {
 
        return <Book 
            showHero
            bookname={this.props.bookname} 
            onClick={this.changeBookSelection.bind(this)} 
            chapter={this.state.chapter} 
            section={this.state.section} 
            subsection={this.state.subsection}
            onClickEncounter={this.onGoToEncounter.bind(this)}
            onClickLink={this.onGoToMap.bind(this)}
            pageSync={this.props.pageSync}
            editable={this.props.editable}
            onGoToBook={this.onGoToBook.bind(this)}
            handleHref={this.checkHandleHref.bind(this)}
        />;
    }

    onGoToBook(book, chapter, section, subsection) {
        if (this.props.onGoToBook) {
            this.props.onGoToBook(book, chapter, section, subsection);
        } else {
            this.setState({showMapDialog:false});
            window.location.href = addCampaignToPath("/#book?id="+encodeURIComponent(book)+(chapter?("&chapter="+chapter):"")+(section?("&section="+section):"")+(subsection?("&subsection="+subsection):"")+(this.props.editable?"&mode=edit":""),true);
        }
    }

    checkHandleHref(href) {
        const page = getLocationInfo(href||"");
        if (page.page == "book") {
            if (page.fragment) {
                const ci = getChapterInfoFromFragment(page.id, page.fragment);
                this.onGoToBook(page.id, ci.chapter, ci.section, ci.subsection);
            } else {
                this.onGoToBook(page.id, page.chapter, page.section,page.subsection);
            }
            return true;
        }
        return false;
    }

    changeBookSelection(chapter, section, subsection) {
        this.setState({chapter, section, subsection});
    }

    onGoToEncounter(encounter, clickedMapPos) {
        this.setState({showEncounter:true, encounter, createNew:false, emapPos:null, showMapContextMenu:false});
    }

    onNewEncounter() {
        var mapPos = null;
        mapPos = Object.assign({}, this.state.actionPos);
        mapPos.mapName = this.state.actionMap.state.imageName;
        mapPos.diameter = 80;

        let newEncounterName = this.getDefaultBookName();
        if (!newEncounterName && this.state.selectedMap) {
            const mi = campaign.getMapInfo(this.state.selectedMap);
            newEncounterName = mi && mi.displayName;
        }

        this.setState({showEncounter:true, encounter:newEncounterName, createNew:true, emapPos:mapPos, showMapContextMenu:false});
    }

    onCloseEncounter() {
        this.setState({showEncounter:false});
    }

    onGoToMap(mapPos) {
        if (this.props.mapOnly) {
            this.setState({showMapContextMenu:false});

            if (this.props.onGoToMap) {
                this.props.onGoToMap(mapPos);
            } else if (this.props.map != mapPos.mapName) {
                window.location.href = addCampaignToPath("#maps?id="+encodeURIComponent(mapPos.mapName),true);
            }
        } else {
            var ref = Object.assign({}, mapPos);

            if (this.props.bookname && mapPos.mapName) {
                const bookMaps = Object.assign({}, campaign.getPrefs().bookMaps||{});
                bookMaps[this.props.bookname] = mapPos.mapName;
                campaign.setPrefs({bookMaps});
            }

            this.setState({selectedMap:mapPos.mapName, mapPos:ref,showMapContextMenu:false, showMapDialog:!this.canShowMap});
        }
    }

    onTapMap(pos, e, mapRef, coverRegion) {
        this.setState({showMapContextMenu:true, actionPos:pos, actionMap:mapRef, anchorPos:getAnchorPos(e.evt, true), coverRegion});
    }

    getMapContextMenu() {
        const t=this;
        if (!this.state.showMapContextMenu)
            return null;

        return <Menu open={true} disableAutoFocusItem anchorPosition={this.state.anchorPos} anchorReference="anchorPosition" transitionDuration={0} onClose={function(){t.setState({showMapContextMenu:false})}}>
            {this.state.coverRegion?<MenuItem onClick={this.onToggleCoverRegion.bind(this)}>{this.state.coverRegion.uncovered?"Restore fog":"Unfog"}</MenuItem>:null}
            {this.state.coverRegion?<DeleteWithConfirm useMenu onClick={this.onDeleteCoverRegion.bind(this)} altText="Delete Fog Region" name="fog region" extraDescription="Use Unfog to reveal the map."/>:null}            <MenuItem onClick={this.onNewEncounter.bind(this)}>New Encounter</MenuItem>
            <MenuItem onClick={this.onCenterMap.bind(this)}>Center</MenuItem>
            <MenuItem onClick={this.setNewPin.bind(this,true)}>New Pin</MenuItem>
        </Menu>;
    }

    setNewPin(showNewPin) {
        let newPin=null;
        if (showNewPin) {
            const mapPos = Object.assign({}, this.state.actionPos||this.mapRef.state.mapPos);
            mapPos.mapName = this.mapRef.state.imageName;
            if (this.mapRef) {
                mapPos.diameter = this.mapRef.state.mapPos.diameter;
            }
            newPin = {
                name:campaign.newUid(),
                type:"location", 
                mapPos: mapPos,
            }

            if (this.props.bookname) {
                const {getFragmentFromChapterSection} = require('./book.jsx');
                const fragment = getFragmentFromChapterSection(this.props.bookname,this.state.chapter,this.state.section,this.state.subsection);
        
                newPin.links=[{
                    name:this.props.bookname, 
                    type:"book", 
                    book:this.props.bookname, 
                    fragment
                }];
                newPin.displayName = this.getDefaultBookName();
            }
        }
        this.setState({showNewPin,newPin,showMapContextMenu:false});
    }

    onToggleCoverRegion() {
        this.state.actionMap.toggleCover(this.state.coverRegion);
        this.setState({showMapContextMenu:false});
    }

    onDeleteCoverRegion() {
        this.state.actionMap.deleteCover(this.state.coverRegion);
        this.setState({showMapContextMenu:false});
    }

    onCenterMap() {
        const newPos = Object.assign({}, this.state.actionMap.state.mapPos);
        newPos.x = this.state.actionPos.x;
        newPos.y = this.state.actionPos.y;
        this.setState({showMapContextMenu:false, mapPos:newPos});
    }

    onSetEncounterBook(ename) {
        const encounter = campaign.getPlannedEncounterInfo(ename);
        if (!this.props.bookname || !encounter) {
            return;
        }

        const newEncounter = Object.assign({},encounter);
        const fragment = getFragmentFromChapterSection(this.props.bookname, this.state.chapter, this.state.section, this.state.subsection);

        newEncounter.bookReference = {book:this.props.bookname, fragment};

        campaign.updateCampaignContent("plannedencounters", newEncounter);
    }

    saveRef(r) {
        this.mapRef = r;
    }
}


class RenderMapDialog extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const mapInfo = campaign.getMapInfo(this.props.mapName)||{};

        return <Dialog
            classes={{paper:"minvh-90"}}
            open
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle onClose={this.props.onClose}>{mapInfo.displayName||"Map"}</DialogTitle>
            <div className="flex-auto w-100 h4 overflow-hidden pb2 pt0 ph0 bt titleborder mt1 relative ignoreDrag">
                <div className="absolute t00 h-100 w-100">
                    <RenderBuild mapOnly editable map={this.props.mapName} pageSync={this.props.pageSync} onGoToMap={this.props.onGoToMap} onGoToBook={this.props.onGoToBook}/>
                </div>
            </div>
        </Dialog>;
    }
}

export {
    RenderBuild,
    RenderMapDialog,
}