const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
const {Character} = require('../lib/character.js');
const Parser = require("../lib/dutils.js").Parser;
const {displayMessage} = require('./notification.jsx');
import sizeMe from 'react-sizeme';
const {MapSharedView} = require("./map.jsx");
const {RenderChat} = require('./renderchat.jsx');
const {getAnchorPos} = require('./stdedit.jsx');
const {ConditionList} = require('./conditions.jsx');
const {DialogPlayer} = require('./renderplayers.jsx');
const {MonsterDetails} = require('./rendermonster.jsx');
const {Combatant} = require('./encountermonsterlist.jsx');

const checkSize = 900;

class WatchCampaign extends React.Component {
    constructor(props) {
        super(props);

        this.updateFn = this.update.bind(this);

	    this.state= {campaign:null, error:null};
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.updateFn,"players");
        globalDataListener.onChangeCampaignContent(this.updateFn,"monsters");
        globalDataListener.onChangeCampaignContent(this.updateFn,"adventure");
    }

    update() {
        this.setState({players:campaign.getPlayers()})
    }

    componentWillUnmount() {
        this.closed = true;
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        globalDataListener.removeCampaignContentListener(this.updateFn, "players");
        globalDataListener.removeCampaignContentListener(this.updateFn, "monsters");
        globalDataListener.removeCampaignContentListener(this.updateFn, "adventure");
    }

    doScroll(scrollToTop) {
        if (!this.timer) {
            const t=this;

            this.timer = setTimeout(function (){
                t.timer = null;
                if (scrollToTop) {
                    if (t.scrollArea && t.scrollArea.current) {
                        t.scrollArea.current.scrollTop=0;
                    }
                } else if (t.lastRef && t.lastRef.current) {
                    t.lastRef.current.scrollIntoView(false);
                }
            },50);
        }
    }

	render() {
        const t=this;
        const showVert = this.props.size.height > (this.props.size.width*1.2);
        const adventure = campaign.getAdventure();
        const combatants = adventure.combatants||[];
        const list = [];
        let lastTurn = false;
        let num=1;
        let skipped=false;
        let skiplist=[];
        this.lastRef = React.createRef();
        this.scrollArea = React.createRef();

        for (let i in combatants ){
            const c = combatants[i];
            if ((c.ctype != "object") && (!c.state || c.state=="active") && !c.hidden) {
                lastTurn = !!c.currentTurn;
            } else if (!lastTurn) {
                lastTurn = !!c.currentTurn;
            }
        }

        for (let i in combatants ){
            const c = combatants[i];
            if ((c.ctype != "object") && (!c.state || c.state=="active") && !c.hidden) {
                if (skipped && (c.ctype!="monster" || (c.currentTurn||lastTurn))) {
                    addSkipped("sk"+i);
                }
                switch (c.ctype) {
                    case "pc":
                        list.push(<PlayerCard saveRef={lastTurn?this.lastRef:null} key={c.name} cInfo={c} num={num} type="players" className="cursor-context-menu pv--2 w-50 minw6 flex-auto overflow-hidden hoverhighlight titleborder bb" onClick={this.showCharacter.bind(this, c.name, "players")}/>);
                        break;
                    case "monster":
                        if (c.currentTurn || lastTurn) {
                            const mon = campaign.getMonster(c.type);
                            if (mon.npc) {
                                list.push(<PlayerCard saveRef={lastTurn?this.lastRef:null} key={c.name} cInfo={c} num={num} type="monsters" className="cursor-context-menu pv--2 w-50 minw6 flex-auto overflow-hidden hoverhighlight titleborder bb" onClick={this.showCharacter.bind(this, c.type, "monsters")}/>);
                            } else {
                                list.push(<MonsterCard saveRef={lastTurn?this.lastRef:null} key={i} cInfo={c} num={num} className="cursor-context-menu pv--2 w-50 minw6 flex-auto overflow-hidden hoverhighlight titleborder bb" onClick={this.showMonster.bind(this, c.type)}/>);
                            }
                        } else {
                            const crow = new Combatant(c);
                            skipped = true;
                            skiplist[c.type] = (skiplist[c.type]||0)+1;
                            if (skiplist.length) {
                                skiplist.push(<span key={i+"c"}>, </span>);
                            }
                            skiplist.push(<a key={i} onClick={this.showMonster.bind(this, c.type)} className="nowrap f4"><span className="yellow bg-gray-50 notetext br1 ph1">{num}</span>{c.name}<span className="notetext titleborder ml1 f5 defaultcolor">(HP {crow.hp})</span></a>);
                        }
                        break;
                    default:
                        //console.log("other ctype", c.ctype, c);
                        break;
                }
                num++;
                lastTurn = !!c.currentTurn;
            } else {
                if (c.currentTurn) {
                    if (skipped) {
                        addSkipped("sk"+i);
                    }
                    list.push(<tr key="curturn">
                        <td className="titleborder bb tc turncolor pa--2">
                            <div className="fas fa-arrow-right titlecolorcontrast"/>
                        </td>
                        <td className="f3 titletext titlecolor titleborder bb">Unknown</td>
                        <td className="titleborder bb"/>
                    </tr>);
                }
                if (!lastTurn) {
                    lastTurn = !!c.currentTurn;
                }
            }
        }
        if (lastTurn) {
            const t = list.pop();
            list.unshift(t);
        }
        addSkipped("skend");

        this.doScroll(lastTurn);

        if (showVert) {
            return <div className="w-100 h-100 flex flex-column">
                <div className="defaultbackground titlecolor titletext bb f2 titleanchor pv1 tc">
                    <a href="/#home" className="flex items-center justify-center"><img src="/logo.svg" height="25px"/><div>ShardTabletop.com</div></a>
                </div>
                <div className="flex-auto overflow-x-hidden titleanchor bb" ref={this.scrollArea}>
                    <table className="w-100 collapse">
                        <tbody>
                            {list}
                        </tbody>
                    </table>
                </div>
                <div className="w-100 flex-auto vh-60"><MapSharedView readonly matchView/></div>
                <div className="hdicetrayMin h-1 flex-auto relative">
                    <RenderChat readonly/>
                </div>

                <DialogPlayer name={this.state.selCharacter} type="players" show={this.state.showCharacter} hideDice onClose={this.onClose.bind(this)} readonly/>
                <MonsterDetails monster={this.state.selMonster} open={this.state.showMonster} onClose={this.onClose.bind(this)}/>
            </div>;
        } else {
            return <div className="w-100 h-100 flex">
                <div className="h-100 minw55 w-33 mw6 overflow-hidden br">
                    <div className="flex flex-column h-100">
                        <div className="defaultbackground titlecolor titletext bb f2 titleanchor pv1 tc">
                            <a href="/#home" className="flex items-center justify-center"><img src="/logo.svg" height="25px"/><div>ShardTabletop.com</div></a>
                        </div>
                        <div className="flex-auto overflow-x-hidden titleanchor" ref={this.scrollArea}>
                            <table className="w-100 collapse">
                                <tbody>
                                    {list}
                                </tbody>
                            </table>
                        </div>
                        <div className="hdicetrayMin h-33 relative">
                            <RenderChat readonly/>
                        </div>
                    </div>
                </div>
                <div className="h-100 flex-auto"><MapSharedView readonly matchView/></div>
                <DialogPlayer name={this.state.selCharacter} show={this.state.showCharacter} type={this.state.characterType} hideDice onClose={this.onClose.bind(this)} readonly/>
                <MonsterDetails monster={this.state.selMonster} open={this.state.showMonster} onClose={this.onClose.bind(this)}/>
            </div>;
        }

        function addSkipped(key) {
            if (skipped) {
                list.push(<tr key={key}>
                    <td className="titleborder bb tl pa1 f3 titlecolor titletext" colSpan="3">
                        {skiplist}
                    </td>
                </tr>);
                skipped=false;
                skiplist=[];
            }
        }
    }

    showMonster(name) {
        this.setState({selMonster:name, showMonster:true});
    }

    showCharacter(name,characterType) {
        this.setState({selCharacter:name, showCharacter:true, characterType});
    }

    onClose() {
        this.setState({showCharacter:false, showMonster:false});
    }
}


class PlayerCard extends React.Component {
    constructor(props) {
        super(props);

        this.updateFn = this.update.bind(this);
	    this.state= {};
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.updateFn,"monsters");
        globalDataListener.onChangeCampaignContent(this.updateFn,"players");
        this.update();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.cInfo != this.props.cInfo) {
            this.update();
        }
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.updateFn, "monsters");
        globalDataListener.removeCampaignContentListener(this.updateFn, "players");
    }

    update() {
        const c = this.props.cInfo;
        let character;
        if (this.props.type=="players") {
            const player = campaign.getPlayerInfo(c.name);
            if (player) {
                character = new Character(player, "players", true);
            }
        } else {
            const monster = campaign.getMonster(c.type);
            if (monster) {
                character = new Character(monster, "monsters", true);
            }
        }

        this.setState({character})
    }

    render() {
        const character = this.state.character;
        if (!character) {
            return null;
        }
        const c = this.props.cInfo;

        return <tr key="hdr" ref={this.props.saveRef} className="hoverhighlight" onClick={this.props.onClick}>
            <td className={c.currentTurn?"titleborder bb tc turncolor pa--2":"titleborder bb tc pa--2"}>
                <div className="relative">
                    <img height="70" width="70" src={character.imageURL || "/blankplayer.png"}/>
                    <div className="tokennum"><span className="pv--2 ph1 f3 yellow bg-gray-50 br1">{this.props.num}</span></div>
                </div>
                {c.currentTurn?<div className="fas fa-arrow-right titlecolorcontrast"/>:null}
            </td>
            <td className="w-100 v-top titleborder bb">
                <div className="f2 titletext titlecolor" >{character.displayName}</div>
                <div className="f6 near-black i">
                    {character.playerName?<div className="ml2" >Played by {character.playerName}</div>:null}
                    <div>{character.getOriginDisplayName()} {character.backgroundDisplayName}</div>
                    <div>Level {character.level} {character.state.classDisplayNames}</div>
                </div>
                <div className="pt--2">
                    <ConditionList conditions={character.state.conditions} readonly/>
                </div>
                {c.currentTurn?<div className="hk-well pa1 ma1">
                    <table className="w-100 mt1 tc collapse f5">
                        <tbody>
                            <tr className="titlecolor ">
                                <td>STR</td>
                                <td>DEX</td>
                                <td>CON</td>
                                <td>INT</td>
                                <td>WIS</td>
                                <td>CHA</td>
                            </tr>
                            <tr>
                                <td width="14.4%">{character.str}</td>
                                <td width="14.4%">{character.dex}</td>
                                <td width="14.4%">{character.con}</td>
                                <td width="14.4%">{character.int}</td>
                                <td width="14.4%">{character.wis}</td>
                                <td width="14.4%">{character.cha}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>:null}
            </td>
            <td className="f2 titlecolor tc titleborder bb v-top ph1">
                <div className="titleborder ba br1 mv1 pa--2 nowrap">HP <span className="defaultcolor">{character.hp}/{character.maxhp}</span></div>
                <div className="titleborder ba br1 pa--2 nowrap">AC <span className="defaultcolor">{character.ac}</span></div>
            </td>
        </tr>;
    }
}

class MonsterCard extends React.Component {
    constructor(props) {
        super(props);

        this.updateFn = this.update.bind(this);
	    this.state= {};
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.updateFn,"monsters");
        this.update();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.cInfo != this.props.cInfo) {
            this.update();
        }
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.updateFn, "monsters");
    }

    update() {
        const c = this.props.cInfo;
        const monster = campaign.getMonster(c.type);

        this.setState({monster})
    }

    render() {
        const mon = this.state.monster;
        const c = this.props.cInfo;
        if (!mon) {
            return null;
        }

        let tokenURL;
        if (mon.imageURL) {
            tokenURL=mon.imageURL;
        } else if (mon.tokenArt) {
            const art = campaign.getArtInfo(mon.tokenArt);
            if (art) {
                tokenURL = art.url;
            }
        }

        return <tr key="hdr"  ref={this.props.saveRef} className="hoverhighlight" onClick={this.props.onClick}>
            <td className={c.currentTurn?"titleborder bb tc turncolor pa--2":"titleborder bb tc pa--2"}>
                <div className="relative">
                    <img height="70" width="70" src={tokenURL||"/blankmonster.png"}/>
                    <div className="tokennum"><span className="pv--2 ph1 f3 yellow bg-gray-50 br1">{this.props.num}</span></div>
                </div>
                {c.currentTurn?<div className="fas fa-arrow-right titlecolorcontrast"/>:null}
            </td>
            <td className="w-100 v-top titleborder bb">
                <div className="f2 titletext titlecolor" >{c.name} {mon.displayName!=c.name?("("+mon.displayName+")"):null}</div>
                <div className="f6 near-black i">
                    <div>{Parser.sizeAbvToFull(mon.size)} {Parser.monTypeToFullObj(mon.type).asText}, {Parser.alignmentAbvToFull(mon)}</div>
                    <div>Challenge Rating {Parser.monCrToFull(mon.cr)}</div>
                </div>
                <div className="pt--2">
                    <ConditionList conditions={c.conditions} readonly/>
                </div>
                {c.currentTurn?<div className="hk-well pa1 ma1">
                    <table className="w-100 mt1 tc collapse f5">
                        <tbody>
                            <tr className="titlecolor">
                                <td className="titleborder bt">STR</td>
                                <td className="titleborder bt">DEX</td>
                                <td className="titleborder bt">CON</td>
                                <td className="titleborder bt">INT</td>
                                <td className="titleborder bt">WIS</td>
                                <td className="titleborder bt">CHA</td>
                            </tr>
                            <tr>
                                <td width="14.4%">{mon.str}</td>
                                <td width="14.4%">{mon.dex}</td>
                                <td width="14.4%">{mon.con}</td>
                                <td width="14.4%">{mon.int}</td>
                                <td width="14.4%">{mon.wis}</td>
                                <td width="14.4%">{mon.cha}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>:null}
            </td>
            <td className="f2 titlecolor tc titleborder bb v-top ph1">
                {mon.unique?<div className="titleborder ba br1 mv1 pa--2 nowrap">HP <span className="defaultcolor">{mon.hp.curHP||0}/{mon.hp.maxHP||1}</span></div>
                :<div className="titleborder ba br1 mv1 pa--2 nowrap">HP <span className="defaultcolor">{c.hp||mon.hp.average||0}/{mon.hp.average||1}</span></div>}
                <div className="titleborder ba br1 pa--2 nowrap">AC <span className="defaultcolor">{Parser.acToStruc(mon.ac).ac}</span></div>
            </td>
        </tr>;
    }
}

const WatchCampaignSize = sizeMe({monitorHeight:true, monitorWidth:true})(WatchCampaign);

export {
    WatchCampaignSize as WatchCampaign,
}