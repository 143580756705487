const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
const {MonsterBlock, MonsterDetails} = require("./rendermonster.jsx");
const {Player} = require("./renderplayers.jsx");
const {RenderCompanion} = require('./rendercompanions.jsx');
const {CustomItem} = require('./customtable.jsx');
import Button from '@material-ui/core/Button';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
const {doRoll,getDiceFromString} = require('../src/diceroller.jsx');
const {Chat} = require('../lib/chat.js');
const {ChatButton} = require('./renderchat.jsx');

class MonsterPCView extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.state= {
        };
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "monsters");
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "npcs");

    }
  
    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "monsters");
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "npcs");
    }

    onDataChange() {
        this.setState({uid:campaign.newUid()});
    }


    render() {
        let {cid} = this.props;
        let type;
        let id;
        let companionId;
        let customType;
        let crow;
        let inside;
        let rollName;

        const members= this.props.members;
        for (let i in members) {
            const m=members[i];

            if ((m.id==cid) || (m.currentTurn || (!crow && (!m.state || m.state == "active")))) {
                crow = m;
                if (m.type) {
                    type="monster";
                    id = m.type;
                } else if (m.ctype=="pc") {
                    type="player";
                    id = m.name;
                } else if (m.ctype=="cmonster") {
                    type="cmonster";
                    id = m.name;
                    companionId=m.companionId;
                } else if (m.ctype=="object" && m.custom && m.customId) {
                    type="custom";
                    id = m.customId;
                    customType=m.custom;
                    rollName=m.name;
                } else {
                    type=null;
                }
            }
            if (m.id==cid) {
                break;
            }
        }
        this.characterId=id;

        if (!crow) {
            return this.props.showMessage||null;
        }

        const addSpellToken = this.props.addSpellToken?this.addSpellToken.bind(this, cid):null;

        switch (type) {
            case "monster":
                const monster = campaign.getMonster(id);
                if (monster) {
                    inside = <MonsterBlock monster={monster} crow={crow} inlineEdit eventSync={this.props.eventSync} rollable={this.props.rollable} addSpellToken={addSpellToken} onChangeCombatant={this.onChangeRow.bind(this)}/>;
                }
                break;
            case "player":
                inside = <Player name={id} type="players" baseOnly useCampaign eventSync={this.props.eventSync} addSpellToken={addSpellToken}/>;
                break;
            case "cmonster":
                inside = <RenderCompanion name={id} companionId={companionId} addSpellToken={addSpellToken} eventSync={this.props.eventSync}/>;
                break;
            case "custom":
                inside = <CustomItem id={id} type={customType} extraName={rollName} doRoll={this.props.rollable?this.doTextRoll.bind(this,rollName):null} doSubRoll={this.props.rollable?this.doSubRoll.bind(this,rollName):null} getDiceRoller={this.getDiceRoller.bind(this)} addSpellToken={addSpellToken} pageSync={this.props.eventSync}/>;
                break;
        }

        return (
            <div className={(campaign.isDarkMode?"darkT ":"lightT ")+(type!="player"?"w-100 mw8 pa1 defaultbackground":"w-100 mw8")}>
                {inside}
            </div>
        );
    }

    onChangeRow(crow) {
        let members= this.props.members;
        let f=-1;
        for (let i in members) {
            const m=members[i];

            if (m.id==crow.id) {
                f=i;
            }
        }
        if (f>=0) {
            members = members.concat([]);
            members[f]=crow;
            this.props.onChange(members);
        }
    }

    addSpellToken(name, ao) {
        this.props.addSpellToken(name, ao);
    }

    doSubRoll(rollName, name, text){
        const dice = getDiceFromString(text);
        const {rolls} = doRoll(dice);
        let attack = (dice.D20==1);
        for (let i in dice) {
            if ((i!="D20") && (i!="bonus") && (i!="extraBonus")) {
                attack = false;
            }
        }

        const newRoll = {dice, rolls, source:rollName+(name?(": "+name):""), action:attack?"to hit":null};

        return Chat.addGMRoll(newRoll);
    }

    doTextRoll(rollName, text){
        return this.doSubRoll(rollName, null, text);
    }

    getDiceRoller() {
        return <ChatButton character={getCharacterFromId(this.characterId)}/>;
    }
}

class MonsterPCDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.open && (prevProps.open != this.props.open)) {
            this.setState({showEdit:false});
        }
    }

    render () {
        const {cid, open} = this.props;
        if (!open || !cid) {
            return null;
        }


        let showEdit=false;
        let crow;

        const members= this.props.members;
        for (let i in members) {
            const m=members[i];

            if (m.id==cid) {
                crow = m;
                if (crow.type) {
                    const monster = campaign.getMonster(crow.type);
                    if (monster && monster.unique) {
                        showEdit=true;
                        if (this.state.showEdit) {
                            return <MonsterDetails open monster={crow.type} editable onClose={this.props.onClose}/>;
                        }
                    }
                }
                break;
            }
        }

        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}></DialogTitle>
            <DialogContent>
                <MonsterPCView 
                    cid={cid}
                    rollable={!campaign.isDefaultCampaign()}
                    eventSync={this.props.eventSync}
                    members={this.props.members}
                    onChange={this.props.onChange}
                    showMessage={this.props.showMessage}
                    addSpellToken={this.props.addSpellToken}
                />
            </DialogContent>
            <DialogActions>
                {showEdit?<Button onClick={this.doEdit.bind(this)} color="primary">
                    Edit
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    doEdit() {
        this.setState({showEdit:true});
    }
}


export {
    MonsterPCView,
    MonsterPCDialog
};