const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
const {ListFilter} = require('./listfilter.jsx');
const {RenderRandomTables} = require('./renderrandomtables.jsx');
const {MonsterPicker} = require("./rendermonster.jsx");

class EncounterPicker extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const mode=this.state.mode||"random"
    
        return <Dialog
            open
            scroll="paper"
            maxWidth="sm"
            fullWidth
            classes={{paper:"minvh-80"}}
        >
            <DialogTitle onClose={this.onClose.bind(this)}>Pick Encounter</DialogTitle>
            <DialogContent>
                <div className="defaultbackground sticktop pb1 z-5 tc">
                    <ButtonGroup size="small" color="primary">
                        <Button variant={(mode=="random")?"contained":null} onClick={this.clickMode.bind(this, "random")}>Random</Button>
                        <Button variant={(mode=="planned")?"contained":null} onClick={this.clickMode.bind(this, "planned")}>Planned</Button>
                    </ButtonGroup>
                </div>
                {(mode=="planned")?<ListFilter 
                    list={campaign.getPlannedEncounters()}
                    render={plannedEncounterListRender}
                    filters={plannedEncounterListFilters}
                    onClick={this.props.onClose}
                    select="click"
                />:<RenderRandomTables addRandomEncounter={this.addRandomEncounter.bind(this)}
                />}
                <div className="stdcontent mv2">
                    <p>You can start an encounter by selecting a random encounter, or just picking monsters.  Planned encounters can be started here, from pins or from a book.</p>
                    <p>Click Finish button in adventuring mode when encounter completes to clear monsters for next encounter.</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.showMonsterPicker.bind(this)} color="primary">
                    Pick Monsters
                </Button>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <MonsterPicker includeCount onClose={this.hideMonsterPicker.bind(this)} open={this.state.showMonsterPicker}/>
        </Dialog>;
    }

    showMonsterPicker() {
        this.setState({showMonsterPicker:true});
    }

    clickMode(mode) {
        this.setState({mode});
    }

    addRandomEncounter(row) {
        this.props.onClose(null,row);
    }

    onClose(e) {
        e.stopPropagation();
        e.preventDefault();
        this.props.onClose();
    }

    hideMonsterPicker(selList) {
        this.setState({showMonsterPicker:false});
        if (selList) {
            this.props.onClose(null, null, selList);
        }
    }
}

function plannedEncounterListRender(e) {
    const book = campaign.getBookInfo(e.bookReference && e.bookReference.book);
    return <div>
        {e.displayName} 
        {book?<div className="mt--2 i f6">{book.displayName}</div>:null}
    </div>;
}



const plannedEncounterListFilters = [
    {
        filterName:"Book",
        fieldName:"bookReference",
        convertField(br, it) {
            const book = campaign.getBookInfo(br&&br.book);
            if (book) {
                return book.displayName;
            }
            return null;
        }
    },
    {
        filterName:"Source",
        fieldName:"source",
        convertField(source, it) {
            const src = campaign.getSourceName(source);
            if (it.edited) {
                if (src) {
                    return ["My Creations",src];
                } else {
                    return "My Creations";
                }
            }
            return src;
        }
    },
];



export {
    EncounterPicker,
}