const React = require('react');
const ReactDOM = require('react-dom');
const {campaign,globalDataListener} = require('../lib/campaign.js');
const {RenderHtml} = require('./entityeditor.jsx');
const {displayMessage} = require('./notification.jsx');
const {AskYesNo} = require('./stdedit.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
const {ListFilter} = require('./listfilter.jsx');

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

let allTours = require('./tourdef.json');

class ShowTour extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
        this.baseRef = React.createRef();
        this.tourEventFn = this.tourEvent.bind(this);
    }

    componentDidMount() {
        const {pageSync} = this.props;
        pageSync && pageSync.on("tour", this.tourEventFn);
        this.checkAutoRun();
    }

    componentWillUnmount() {
        const {pageSync} = this.props;
        pageSync && pageSync.removeListener("tour", this.tourEventFn);

        if (this.checkAuto) {
            clearTimeout(this.checkAuto);
            this.checkAuto=null;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tourSet != this.props.tourSet) {
            this.endTour(false);
            this.checkAutoRun();
        }
    }

    checkAutoRun() {
        if (!this.checkAuto) {
            const t=this;
            this.checkAuto = setTimeout(function (){
                t.checkAuto=null;
                t.doCheckAutoRun();
            },50);
        }
    }

    doCheckAutoRun() {
        const {tourSet} = this.props;
        const availableTours = allTours.tourLists[tourSet] || (tourSet && [tourSet]);

        for (let i in availableTours) {
            const defaultTourName = availableTours[i];
            const defaultTour = allTours.tourDefs[defaultTourName];
            if (defaultTour && defaultTour.autorun && (getTourStep(defaultTourName)==null) && this.checkRequire(defaultTour.require)) {
                const tour = Object.assign({name:defaultTourName}, defaultTour);
                this.pickTour(tour,0);
                break;
            }
        }
    }

    checkRequire(require) {
        if (require){
            const tourState = this.getTourState();
            return tourState[require];
        }
        return true;
    }

    getTourState() {
        const {pageSync} = this.props;
        const tourState = {};
        pageSync && pageSync.emit("tour", "tourState", tourState);
        return tourState;
    }
  
	render() {
        const {tourSet,defaultDialog} = this.props;
        const availableTours = allTours.tourLists[tourSet];
        if (!availableTours && !allTours.tourDefs[tourSet]) {
            return null;
        }

        const {currentTour, step,pickTour, nextTour, finishedTour} = this.state;
        let popup;

        if (currentTour) {
            const curStep = currentTour.tourSteps[step];
            const tourLength = currentTour.tourSteps.length;
            const lastStep = (step+1)==tourLength;
            let anchorReference="anchorPosition", 
            anchorEl,
            anchorOrigin={vertical:"center",horizontal:"center"},
            transformOrigin={vertical:"center",horizontal:"center"},
            backdropProps={};
            const el = findElement(curStep, defaultDialog, this.baseRef.current);
            if (el) {
                backdropProps.anchorEl=el;
                anchorEl=el;
                anchorReference="anchorEl"
                el.scrollIntoView({behavior:"instant", block:el.offsetHeight<100?"center":"nearest"});
                switch (curStep.horizontal) {
                    case "left":
                        anchorOrigin.horizontal="left";
                        transformOrigin.horizontal="right";
                        break;
                    case "right":
                        anchorOrigin.horizontal="right";
                        transformOrigin.horizontal="left";
                        break;
                }
                switch (curStep.vertical) {
                    case "top":
                        anchorOrigin.vertical="top";
                        transformOrigin.vertical="bottom";
                        break;
                    case "bottom":
                        anchorOrigin.vertical="bottom";
                        transformOrigin.vertical="top";
                        break;
                }
            }

            popup = <Popover
                open
                anchorReference={anchorReference}
                anchorEl={anchorEl}
                anchorPosition={{left:window.innerWidth/2, top:window.innerHeight/2}}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                BackdropComponent={TourBackdrop}
                BackdropProps={backdropProps}
                classes={{paper:"b--light-orange"}}
            >
                <div className="pl1 pv1 bg-light-orange darkText w7 flex items-start">
                    <div className="flex-auto tc f3">{curStep.title}</div>
                    <span className="pa1 f8 hoverhighlight fas fa-times" onClick={this.endTour.bind(this,false)}/>
                </div>
                <div className="f4 tc maxh55 w7 darkBackground lightAltText overflow-auto pa1"><RenderHtml html={curStep.description}/></div>
                <div className="flex items-end w7 darkBackground lightText">
                    {step>0?<div className="fas fa-step-backward f8 pa1 hoverhighlight" onClick={this.setStep.bind(this,step-1)}/>:
                        <div className="fas fa-step-backward f8 pa1 clearText"/>
                    }
                    <div className="flex-auto f8 tc pa1">
                        Step {step+1} of {tourLength}
                    </div>
                    {lastStep?<div className="fas fa-check f8 pa1 hoverhighlight" onClick={this.endTour.bind(this,true)}/>:
                        <div className="fas fa-step-forward f8 pa1 hoverhighlight" onClick={this.setStep.bind(this,step+1)}/>
                    }
                </div>

            </Popover>;
        }
        return <span ref={this.baseRef}>
            <span className="fas fa-question pa1 hoverhighlight tourHelp" onClick={this.clickStart.bind(this)}/>
            {popup}
            {pickTour?<TourPicker open tours={availableTours} onClose={this.pickTour.bind(this)}/>:null}
            {nextTour?<AskYesNo open title="Suggested Next Tour" onClose={this.doNextTour.bind(this)}>
                <span className="f4 tc">
                    <p>You have completed the tour</p>
                    <p><b>{finishedTour.displayName}</b></p>
                    <p>Would you like to go to the next tour?</p>
                    <p><b>{nextTour.displayName}</b></p>
                </span>
            </AskYesNo>:null}
        </span>;
    }

    tourEvent(action, val) {
        switch (action) {
            case "checkauto":
                this.checkAutoRun();
                break;
        }
    }

    clickStart() {
        const {tourSet} = this.props;
        let tourName;
        if (tourSet) {
            const availableTours = allTours.tourLists[tourSet];
            if (availableTours) {
                this.setState({pickTour:true})
            } else if (allTours.tourDefs[tourSet]){
                tourName = tourSet;
            }
        } 
        if (tourName) {
            const tour = Object.assign({name:tourName}, allTours.tourDefs[tourName]);
            this.pickTour(tour,getTourStep(tourName));
        }
    }

    endTour(finish) {
        const {tourSet,defaultDialog,pageSync} = this.props;
        const {currentTour} = this.state;
        if (currentTour) {
            if (pageSync && currentTour.event) {
                pageSync.emit("tour","setevent",currentTour.event, 0);
            }
            if (finish) {
                const availableTours = allTours.tourLists[tourSet];
                setTourStep(currentTour.name, -1);

                if (availableTours) {
                    const pos = availableTours.indexOf(currentTour.name);
                    const nextTourName = availableTours[pos+1];
                    let nextTour = allTours.tourDefs[nextTourName];
                    if (pos>=0 && nextTour && ((getTourStep(nextTourName)||0)>=0)) {
                        if (this.checkRequire(nextTour.require)) {
                            nextTour = Object.assign({name:nextTourName}, nextTour);
                            this.setState({finishedTour:currentTour, nextTour});
                        }
                    }
                }
            }
            this.setState({currentTour:null});
        }
    }

    setStep(step) {
        const {currentTour} = this.state;
        this.gotoTourStep(currentTour, step);

        this.setState({step});
    }

    gotoTourStep(currentTour,step) {
        if (step <0) {
            step=0;
        }
        if (step >= currentTour.tourSteps.length) {
            step = currentTour.tourSteps.length-1;
        }
        setTourStep(currentTour.name, step);
        this.setState({step:step||0});
        const {pageSync}=this.props;
        if (pageSync) {
            const curStep = currentTour.tourSteps[step];
            const setTab = curStep?.setTab;
            if (setTab) {
                pageSync.emit("tour", "tab",setTab);
            }
        }
    }

    pickTour(tour,step) {
        if (tour) {
            this.setState({currentTour:tour})
            this.gotoTourStep(tour, step);
            const {pageSync}=this.props;
            if (pageSync && tour.event) {
                pageSync.emit("tour","setevent",tour.event, 1);
            }
        }
        this.setState({pickTour:false});
    }

    doNextTour(cont) {
        if (cont) {
            const {nextTour} = this.state;
            this.pickTour(nextTour,  getTourStep(nextTour.name));
        }
        this.setState({nextTour:null});
    }
}

function findElement(step, defaultDialog, baseEl) {
    let el;
    if (step.id) {
       el = document.getElementById(step.id);
    }
    if (!el && step.class) {
        const elements = document.getElementsByClassName(step.class);
        if (elements && elements.length) {
            el = elements[0];
        }
    }
    if (!el && defaultDialog) {
        let startEl = baseEl;
        while (startEl && !el) {
            if (startEl.getAttribute("role")=="dialog") {
                el= startEl;
            }
            startEl = startEl.parentNode;
        }
    }

    if (step.button) {
        const buttons = (el||document).querySelectorAll(".MuiButton-root")
        let found;
        if (buttons) {
            for (let b=0; b<buttons.length && !found; b++) {
                const button = buttons[b];
                if (button.innerText.toLowerCase() == step.button.toLowerCase()) {
                    found = button;
                }
            }
            if (found) {
                el=found;
            }
        }
    }

    return el;
}

class TourPicker extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

	render() {
        const {open,tours} = this.props;
        if (!this.props.open) {
            return null;
        }
    
        const adminLevel = campaign.adminStatus.level;
        const tourList=[];
        for (let t of tours) {
            const tv = Object.assign({name:t}, allTours.tourDefs[t])
            tourList.push(tv);
        }

        return <Dialog
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle onClose={this.onClose.bind(this)}>Guided Tours</DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={tourList}
                    render={this.tourRender.bind(this)}
                    hideSearch
                />
            </DialogContent>
            <DialogActions>
                {adminLevel?<span>
                    <input
                        accept="application/json"
                        className="dn"
                        id="tour-file-upload"
                        type="file"
                        onChange={this.selectFile.bind(this)}
                    />
                    <label htmlFor="tour-file-upload">
                        <Button color="primary" component="span">
                            upload tours
                        </Button>
                    </label>
                </span>:null}
                <Button color="primary" onClick={this.resetTours.bind(this)}>
                    Reset Tours
                </Button>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    resetTours() {
        displayMessage("Tour run state has been reset to not run")
        campaign.updateUserSettings({tourState:{}});
        this.onClose();
    }

    tourRender(tour) {
        let step = getTourStep(tour.name);

        return <div>
            <div className="fr f6">
                {(step||0)>=0?<span className="fas fa-play pa1 hoverhighlight" onClick={this.playTour.bind(this, tour,step)}/>
                  :
                <span className="fas fa-check pa1"/>}
                {(step||0)!=0?<span className="fas fa-undo pa1 hoverhighlight" onClick={this.resetTour.bind(this, tour)}/>:null}
            </div>
            <div className="f4 b">
                <span className={step<0?"lightcolor":null}>{tour.displayName}</span> <i className="lightcolor f8">
                    ({step == null?"not started":step<0?"finished":"step "+(step+1)+" of "+(tour.tourSteps.length)})
                </i>
            </div>
            <div className="mt--2 f6 defaultcolor">{tour.description}</div>
        </div>;
    }

    playTour(tour,step) {
        this.props.onClose(tour,step||0)
    }

    resetTour(tour) {
        setTourStep(tour.name,null);
        //force dialog refresh
        this.setState({update:campaign.newUid()});
    }

    onClose() {
        this.props.onClose();
    }

    async selectFile(e) {
        if (e.target.files.length == 1) {
            // upload file
            try {
                const file = e.target.files[0];
                const data = await file.text()
                const tours = JSON.parse(data);
                allTours = tours;
                displayMessage("Tour data updated")
                this.setState({update:campaign.newUid()});
            } catch (err) {
                displayMessage("Cannot read file"+" Error:"+err);
            }
       }
    }
}

function getTourStep(name) {
    const tourState = campaign.getUserSettings().tourState||{};
    return tourState[name];
}

function setTourStep(name,step) {
    const tourState = Object.assign({}, campaign.getUserSettings().tourState||{});
    tourState[name] = step;
    campaign.updateUserSettings({tourState});
}


class TourBackdrop extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    componentDidMount() {
    }
  
    componentWillUnmount() {
    }

	render() {
        const {anchorEl} = this.props;
        if (!anchorEl) {
            return <div className="h-100 w-100 fadedBackground"/>
        }
        const blocks=[];
        const width=window.innerWidth, height=window.innerHeight;
        const {left,right, top, bottom}=anchorEl.getBoundingClientRect();

        if (left > 0) {
            blocks.push(<div key="left" className="fadedBackground fixed" style={{top, left:0, width:left, height:bottom-top}}/>)
        }
        if (right <width) {
            blocks.push(<div key="right" className="fadedBackground fixed" style={{top, left:right, width:width-right, height:bottom-top}}/>)
        }
        if (top >=0) {
            blocks.push(<div key="top" className="fadedBackground fixed" style={{top:0, left:0, width, height:top}}/>)
        }
        if (bottom <height) {
            blocks.push(<div key="bottom" className="fadedBackground fixed" style={{top:bottom, left:0, width, height:height-bottom}}/>)
        }
        return <div>{blocks}</div>;
    }
}

export {
    ShowTour
};