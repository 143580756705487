const Parser = require("../lib/dutils.js").Parser;
const createDomPurify = require('dompurify');
const DOMPurify = createDomPurify();

function htmlFromEntry(entry, depth, inset, root, spanFirst) {
    var ret = [];
    var depth;
    var nextdepth;

    if (!entry) {
        return "";
    }
    if (typeof entry === "object") {
        if (Array.isArray(entry)){
            entry = {entries:entry};
        }
        // the root entry (e.g. "Rage" in barbarian "classFeatures") is assumed to be of type "entries"
        const type = entry.type === undefined || entry.type === "section" ? "entries" : entry.type;

        if (root || entry.root){
            root= true;
            depth=-1;
            nextdepth=0;
        } else {
            depth=entry.depth||depth||0;
            nextdepth=depth+1;
        }

        switch (type) {
            default:
                console.log("unknown entry type "+type);
            case "patron":
            case "options":
            case "variantSub":
            case "entries":
            case "optfeature":
                if (type=='options'){
                    if (entry.entries){
                        
                        entry.entries.sort(function(a, b){

                            if (!a || !a.name)
                                a="";
                            else
                                a=a.name;
                            if (!b || !b.name)
                                b="";
                            else b=b.name;

                            return a.localeCompare(b);
                        });
                    }
                }  

                var merge="";

                if (entry.name && ((typeof entry.name) == 'string')) {
                    if (type=='variantSub') {
                        merge=`<i>${htmlFromString(entry.name)}. </i>`;
                    } else if (inset || (depth > 1)) {
                        spanFirst=true;
                        merge=`<b><i>${htmlFromString(entry.name)}. </i></b>`;
                    } else {
                        if (depth < 0) {
                            ret.push(`<h1>${htmlFromString(entry.name)}</h1>`);
                        } else if (depth==0) {
                            ret.push(`<h2>${htmlFromString(entry.name)}</h2>`);
                        } else {
                            ret.push(`<h3>${htmlFromString(entry.name)}</h3>`);
                        }
                    }
                } else {
                    nextdepth=depth;
                }

                if (entry.prerequisite){
                    ret.push(`<h5>Prerequisite: ${htmlFromString(entry.prerequisite)}</h5>`);
                }

                if (entry.entries) {
                    for (let i = 0; i < entry.entries.length; i++) {
                        if (merge && spanFirst) {
                            ret.push(`<p>${merge+htmlFromEntry(entry.entries[i],nextdepth, inset, false, spanFirst)}</p>`);
                        } else if (spanFirst) {
                            ret.push(`${htmlFromEntry(entry.entries[i],nextdepth, inset, false, spanFirst)}`);
                        } else {
                            ret.push(`${merge+htmlFromEntry(entry.entries[i],nextdepth, inset)}`);
                        }
                        spanFirst=false;
                        merge="";
                    }
                }

                if (merge){
                    ret.push(`<p>${merge}</p>`);
                }

                return ret.join('');
                break;

            case "list":
                if (entry.name){
                    ret.push(`<p><b>${htmlFromString(entry.name)}</b></p>`);
                }
                var l=[];
                if (entry.items) {
                    for (let i = 0; i < entry.items.length; i++) {
                        if (entry.items[i].type == 'list')
                            l.push(htmlFromEntry(entry.items[i],nextdepth, inset));
                        else
                            l.push(`<li>${htmlFromEntry(entry.items[i],nextdepth,inset)}</li>`);
                    }
                }
    
                return `${ret.join('')}<ul>${l.join('')}</ul>`;
                break;
                
            case "variant":
            case "insetReadaloud":
            case "inset":
                if (entry.name && ((typeof entry.name) == 'string')) {
                    ret.push(`<h3>${(type=='variant')?"Variant: ":""}${htmlFromString(entry.name)}</h3>`);
                }
                if (entry.entries) {
                    for (let i = 0; i < entry.entries.length; i++) {
                        ret.push(`${htmlFromEntry(entry.entries[i],nextdepth, true)}`);
                    }
                }
                return `<blockquote>${ret.join('')}</blockquote>`;
                break;

            case "quote":
                if (entry.entries) {
                    for (let i = 0; i < entry.entries.length; i++) {
                        ret.push(`<i>${htmlFromEntry(entry.entries[i],nextdepth, inset)}</i>`);
                    }
                }
                ret.push(`<p style="text-align:right;">-${entry.by||""}, <i>${entry.from||""}</i></p>`);
                return ret.join('');
                break;
            
            // inline
            case "inline":
                if (entry.entries) {
                    for (let i = 0; i < entry.entries.length; i++) {
                        ret.push(`${htmlFromEntry(entry.entries[i],nextdepth, inset, false, true)} `);
                    }
                }
                return ret.join('');
                break;
                
            case "inlineBlock":
                if (entry.entries) {
                    for (let i = 0; i < entry.entries.length; i++) {
                        ret.push(`${htmlFromEntry(entry.entries[i],nextdepth, inset)} `);
                    }
                }
                return `${ret.join('')}`;
                break;

            case "bonus":
                return `${(entry.value < 0 ? "" : "+")}${entry.value}`;
                break;

            case "bonusSpeed":
                return `${(entry.value < 0 ? "" : "+")}${entry.value} ft.`;
                break;

            case "dice":
                const diceinfo = getDiceInfo(null,null,entry);
                return (`<b>${diceinfo.toDisplay}</b>`);
                break;

            case "link":
                const linkinfo = getLinkInfo(null,null, entry);
//                if (!linkinfo.href) {
                    return `<span>${linkinfo.hText}</span>`;
//                }
//                return `<a href=${linkinfo.href}/>${linkinfo.hText}</a>`;
                break;

            case "actions":
                if (entry.name)
                    ret.push(`<b>${entry.name}. </b>`);

                if (entry.entries) {
                    for (let i = 0; i < entry.entries.length; i++) {
                        ret.push(htmlFromEntry(entry.entries[i],nextdepth, inset));
                    }
                }
                return `${ret.join('')}`;
                break;

            case "attack":
                ret.push(`<i>${Parser.attackTypeToFull(entry.attackType)}:</i>`);
                for (let i = 0; i < entry.attackEntries.length; i++) {
                    ret.push(htmlFromString(entry.attackEntries[i]));
                }
                ret.push('<i>Hit:</i>');
                for (let i = 0; i < entry.hitEntries.length; i++) {
                    ret.push(htmlFromString(entry.hitEntries[i]));
                }
                return ret.join('');
                break;
            case "cell":
                if (!entry.roll)
                    return null;
                if (entry.roll.exact || (entry.roll.exact == 0))
                    return entry.roll.exact;
                return `${entry.roll.min||""}-${entry.roll.max||""}`;
                break;


            // block
            case "abilityDc":
                return `<p style="text-align:center;"><b><i>${entry.name} save DC</i></b> = 8 + your proficiency bonus + your ${Parser.attrChooseToFull(entry.attributes)}</p>`;
                break;
            case "abilityAttackMod":
                return  `<p style="text-align:center;"><b><i>${entry.name} attack modifier</i></b> = your proficiency bonus + your ${Parser.attrChooseToFull(entry.attributes)}</p>`;
                break;
            case "abilityGeneric":
                return `<p style="text-align:center;">${entry.name?(`<b><i>${entry.name}</i></b> = `): ""}${entry.text} ${entry.attributes ? Parser.attrChooseToFull(entry.attributes): ""}</p>`;
                break;

            case "table":
                if (entry.intro) {
                    for (let i = 0; i < entry.intro.length; i++) {
                        ret.push(`<p>${htmlFromString(entry.intro[i])}</p>`);
                    }
                }
                if (entry.caption){
                    ret.push(`<h4>${htmlFromString(entry.caption)}</h4>`);
                }
                var head=[], rows=[];
                var labels=entry.colLabels||[];
                var styles=entry.colStyles||[];
                const cols=Math.max(labels.length, styles.length);
                for (let i = 0; i < cols; i++) {
                    head.push(`<td class="${styles[i]||""}"><b>${htmlFromString(labels[i]||"")}</b></td>`);
                }
                for (let r=0; r<entry.rows.length; r++){
                    const cr = entry.rows[r];
                    var row=[];
                    var rd = (cr.type === "row") ? cr.row : cr;
                    for (let c=0; c<cols; c++) {
                        var toRender;
                        const cd = rd[c];
                        if (cd) {
                            if (cd.type === "cell"){
                                if (cd.entry) {
                                    toRender = cd.entry;
                                } else if (cd.roll) {
                                    if (cd.roll.entry) {
                                        toRender = cd.roll.entry;
                                    } else if (cd.roll.exact !== undefined) {
                                        toRender = cd.roll.exact;
                                    } else {
                                        toRender = `${cd.roll.min}-${cd.roll.max}`;
                                    }
                                }
                            } else {
                                toRender = cd;
                            }
                        } else {
                            toRender=cd;
                        }
                        row.push(`<td class="${styles[c]||""}">${htmlFromEntry(toRender||"", nextdepth, inset)}</td>`);
                    }
                    rows.push(`<tr>${row.join('')}</tr>`);
                }

                ret.push(`<table><tr>${head.join('')}</tr>${rows.join('')}</table>`);

                if (entry.footnotes) {
                    for (let i = 0; i < entry.footnotes.length; i++) {
                        ret.push(`<p>${htmlFromString(entry.footnotes[i])}</p>`);
                    }
                }
                if (entry.outro) {
                    for (let i = 0; i < entry.outro.length; i++) {
                        ret.push(`<p>${htmlFromString(entry.outro[i])}</p>`);
                    }
                }
                return ret.join('');
                break;

            case "tableGroup":
                if (entry.tables) {
                    for (let i = 0; i < entry.tables.length; i++) {
                        ret.push(`${htmlFromEntry(entry.tables[i], nextdepth, inset)}`);
                    }
                }
                return ret.join('');
                break;

            case "itemSpell":
            case "item":
                if (entry.name) {
                    ret.push(`<b>${entry.name} </b>`);
                }
                if (entry.entry) {
                    ret.push(`${htmlFromEntry(entry.entry, nextdepth, inset)} `);
                }

                if (entry.entries) {
                    for (let i = 0; i < entry.entries.length; i++) {
                        if (i==0)
                            ret.push(`${htmlFromEntry(entry.entries[i], nextdepth, inset)} `);
                        else
                            ret.push(`${htmlFromEntry(entry.entries[i], nextdepth, inset)}`);
                    }
                }
                return `${ret.join('')}`;
                break;

            case "itemSub":
                if (entry.name) {
                    ret.push(`<i>${entry.name} </i>`);
                }
                if (entry.entry) {
                    ret.push(`${htmlFromEntry(entry.entry,nextdepth, inset)} `);
                }
                return `${ret.join('')}`;
                break;

            case "image":
                let href;
                if (entry.href.type === "internal") {
                    href = "/img/"+encodeURI(entry.href.path)
                    return "";
                } else if (entry.href.type === "external") {
                    href = entry.href.url;
                    return `<p style="text-align:center;"><a href=${href} target="_blank" title=${entry.title||""}><img src=${href} alt=${entry.altText||""}/></a><p>${entry.title?('<p style="text-align:center;">'+entry.title+"</p>"):""}`;
                }
                break;

            case "html":
                return DOMPurify.sanitize(entry.html, { USE_PROFILES: { html: true }, FORBID_TAGS: ["form", "input", "label", "button", "canvas", "applet", "area","audio","dialog","embed","img","link","object","select","option","svg","video","track","style"] });
                break;

            case "gallery":
                // don't render gallery
                return "";

/*
            // entire data records
            case "dataCreature":
                renderPrefix();
                textStack[0] += `<table class="statsDataInset">`;
                textStack[0] += `<thead><tr><th class="dataCreature__header" colspan="6" onclick="((ele) => {
                    $(ele).find('.dataCreature__name').toggle(); 
                    $(ele).find('.dataCreature__showHide').text($(ele).text().includes('+') ? '[\u2013]' : '[+]'); 
                    $(ele).closest('table').find('tbody').toggle()
                })(this)">
                    <span style="display: none;" class="dataCreature__name">${entry.dataCreature.name}</span>
                    <span class="dataCreature__showHide">[\u2013]</span>
                </th></tr></thead><tbody>`;
                textStack[0] += EntryRenderer.monster.getCompactRenderedString(entry.dataCreature, this);
                textStack[0] += `</tbody></table>`;
                renderSuffix();
                break;

            // images
            case "gallery": {
                textStack[0] += `<div class="img__gallery">`;
                entry.images.forEach(img => {
                    img = MiscUtil.copy(img);
                    delete img.imageType;
                    this._recursiveEntryRender(img, textStack, depth, {}); // no prefix/suffix
                });
                textStack[0] += `</div>`;
                break;
            }

            // homebrew changes
            case "homebrew": {
                renderPrefix();
                textStack[0] += `<div class="homebrew-section">`;
                if (entry.oldEntries) {
                    const mouseOver = EntryRenderer.hover.createOnMouseHover(entry.oldEntries);
                    let markerText;
                    if (entry.movedTo) {
                        markerText = "(See moved content)";
                    } else if (entry.entries) {
                        markerText = "(See replaced content)";
                    } else {
                        markerText = "(See removed content)";
                    }
                    textStack[0] += `<span class="homebrew-old-content" href="#${window.location.hash}" ${mouseOver}>
                            ${markerText}
                        </span>`;
                }

                textStack[0] += `<span class="homebrew-notice"></span>`;

                if (entry.entries) {
                    entry.entries.forEach(nxt => this._recursiveEntryRender(nxt, textStack, depth));
                } else if (entry.movedTo) {
                    textStack[0] += `<i>This content has been moved to ${entry.movedTo}.</i>`;
                } else {
                    textStack[0] += "<i>This content has been deleted.</i>";
                }

                textStack[0] += `</div>`;
                renderSuffix();
                break;
            }

            case "code": {
                textStack[0] += `<pre>${entry.preformatted}</pre>`;
            }*/
        }
    } else {//if (typeof entry === "string") { // block
        if (spanFirst) {
            return htmlFromString(entry);
        } else {
            return '<p>'+htmlFromString(entry)+'</p>';
        }
    }
}

function htmlFromString(fmttext) {
    var ret = [];
    if (fmttext != null) {
        fmttext = fmttext.toString();
    }
    if (!fmttext)
        return "";
    const tagSplit = splitByTags(fmttext);

    for (let i = 0; i < tagSplit.length; i++) {
        const s = tagSplit[i];
        
        if (s === undefined || s === null || s === "") continue;

        if (s.charAt(0) === "@") {
            var [tag, text] = splitFirstSpace(s);

            switch (tag) {
                case "@b":
                case "@bold":
                    ret.push(`<b>${htmlFromString(text)}</b>`);
                    break;

                case "@note":
                case "@i":
                case "@italic":
                    ret.push(`<i>${htmlFromString(text)}</i>`);
                    break;
                case "@s":
                case "@strike":
                    ret.push(`<s>${htmlFromString(text)}</s>`);
                    break;

                case "@atk":
                    ret.push(attackTagToFull(text));
                    break;

                case "@h":
                    ret.push("<i>Hit: </i>");
                    break;

                case "@dice":
                case "@damage":
                case "@hit":
                case "@d20":
                case "@chance":
                case "@recharge":
                case "@scaledice":
                    const diceinfo = getDiceInfo(tag, text);
                    ret.push(`<b>${diceinfo.toDisplay}</b>`);
                    break;

                case "@skill":
                case "@action":
                case "@sense":
                    
                case "@filter":
                case "@link":
                case "@5etools":
                case "@book":
                case "@adventure":
                case "@spell":
                case "@item":
                case "@invocation":
                case "@class":
                case "@creature":
                case "@condition":
                case "@disease":
                case "@deity":
                case "@background":
                case "@race":
                case "@optfeature":
                case "@reward":
                case "@feat":
                case "@psionic":
                case "@object":
                case "@boon":
                case "@cult":
                case "@trap":
                case "@hazard":
                case "@variantrule":
                case "@table":
                case "@ship":
                    const linkinfo = getLinkInfo(tag, text);
                    if (!linkinfo.href) {
                        ret.push(`<span/>${linkinfo.hText}</span>`);
                    } else {
                        ret.push(`<a href=${linkinfo.href}/>${linkinfo.hText}</a>`);
                    }
                    break;

                case "@area":
                    const hoverinfo = getHoverInfo(tag, text);
                    ret.push(hoverinfo.display);
                    break;
                default:
                    console.log("editor did not render tag "+tag);
                    break;
            }
        } else {
            ret.push(s);
        }
    }
    return ret.join("");
}

function splitByTags(string) {
    let tagDepth = 0;
    let inTag = false;
    let char, char2;
    const out = [];
    let curStr = "";
    for (let i = 0; i < string.length; ++i) {
        char = string.charAt(i);
        char2 = i < string.length - 1 ? string.charAt(i + 1) : null;

        switch (char) {
            case "{":
                if (char2 === "@") {
                    inTag = true;
                    if (tagDepth++ > 0) {
                        curStr += char;
                    } else {
                        out.push(curStr);
                        curStr = "";
                    }
                } else {
                    curStr += char;
                }
                break;
            case "}":
                if (!inTag) {
                    curStr += char;
                } else if (--tagDepth === 0) {
                    inTag = false;
                    out.push(curStr);
                    curStr = "";
                } else {
                    curStr += char;
                }
                break;
            default:
                curStr += char;
        }
    }
    if (curStr.length > 0) out.push(curStr);

    return out;
}

function splitFirstSpace (string) {
	const firstIndex = string.indexOf(" ");
	return firstIndex === -1 ? [string, ""] : [string.substr(0, firstIndex), string.substr(firstIndex + 1)];
};

function attackTagToFull(tagStr) {
    function renderTag (tags) {
        return `${tags.includes("m") ? "Melee " : tags.includes("r") ? "Ranged " : tags.includes("a") ? "Area " : ""}${tags.includes("w") ? "Weapon " : tags.includes("s") ? "Spell " : ""}`;
    }

    const tagGroups = tagStr.toLowerCase().split(",").map(it => it.trim()).filter(it => it).map(it => it.split(""));
    return `${tagGroups.map(it => renderTag(it)).join(" or ")}Attack:`;
}

function getDiceInfo(tag, text, entry) {
    var toRoll;
    var subType;
    var d20mod;
    var successThresh;
    var successMax;
    var displayText;
    var name;
    var prefix;
    var suffix;

    if (entry){
        toRoll = ""
        if (entry.toRoll) {
            for (let i = 0; i < entry.toRoll.length; i++) {
                const r=entry.toRoll[i];
                toRoll=toRoll + ((i>0)?"+":"")+r.number+"d"+r.faces+((r.modifier&&!r.hideModifier)?("+"+r.modifier):"");
            }
        }

    } else if (tag === "@dice" || tag === "@damage" || tag === "@hit" || tag === "@d20" || tag === "@chance" || tag === "@recharge") {
        const [rollText, tdisplayText, tname] = text.split("|");
        if (tdisplayText) displayText = tdisplayText;
        if (tname) name = tname;

        switch (tag) {
            case "@dice": {
                // format: {@dice 1d2 + 3 + 4d5 - 6}
                toRoll = rollText;
                break;
            }
            case "@damage": {
                toRoll = rollText;
                subType = "damage";
                break;
            }
            case "@d20":
            case "@hit": {
                // format: {@hit +1} or {@hit -2}
                const n = Number(rollText);
                const mod = `${n >= 0 ? "+" : ""}${n}`;
                displayText = displayText || mod;
                toRoll = `1d20${mod}`;
                subType = "d20";
                d20mod = mod;
                break;
            }
            case "@chance": {
                // format: {@chance 25|display text|rollbox rollee name}
                toRoll = `1d100`;
                successThresh = Number(rollText);
                break;
            }
            case "@recharge": {
                // format: {@recharge 4}
                toRoll = "1d6";
                const asNum = Number(rollText || 6);
                successThresh = 7 - asNum;
                successMax = 6;
                prefix = `(Recharge `;
                displayText = `${asNum}${asNum < 6 ? `\u20136` : ""}`;
                suffix = `)`;
                break;
            }
        }
    } else if (tag === "@scaledice") {
        // format: {@scaledice 2d6|2-8,9|1d6}
        const [baseRoll, progression, addPerProgress] = text.split("|");
        displayText = addPerProgress;
    }

    function getDiceAsStr () {
        if (successThresh) return `${successThresh} percent`;
        else if (typeof toRoll === "string") return toRoll;
        else {
            // handle legacy format
            let stack = "";
            entry.toRoll.forEach(r => {
                stack += `${r.neg ? "-" : stack === "" ? "" : "+"}${r.number || 1}d${r.faces}${r.mod ? r.mod > 0 ? `+${r.mod}` : r.mod : ""}`
            });
            return stack;
        }
    }
        
    function pack (obj) {
        return `'${JSON.stringify(obj).escapeQuotes()}'`;
    }

    const toDisplay = (prefix||"")+(displayText ?(displayText/*+(toRoll?("("+getDiceAsStr()+")"):"")*/) : getDiceAsStr())+(suffix || "");
        
    return {toDisplay:toDisplay, toRoll:toRoll, subType:subType, successThresh:successThresh, successMax:successMax};
}

function getLinkInfo(tag, text, entry) {
    const HASH_LIST_SEP='&';          // temporary hack
    const HASH_PART_SEP='&';          // temporary hack
    const HASH_SUB_LIST_SEP='&';          // temporary hack
    const HASH_BLANK="";                  // temporary hack
    const CLSS_HASH_FEATURE_KEY = "f";
    const MON_HASH_SCALED = "scaled";
    const SRC_UA_PREFIX = "UA";
    const SRC_UATMC = SRC_UA_PREFIX + "TheMysticClass";
    const SRC_VGM = "VGM";
    const SRC_XGE = "XGE";
    const SRC_OGA = "OGA";
    const SRC_MTF = "MTF";
    const SRC_WDH = "WDH";
    const SRC_WDMM = "WDMM";
    const SRC_GGR = "GGR";
    const SRC_KKW = "KKW";
    const SRC_LLK = "LLK";
    const SRC_AL = "AL";
    const SRC_SCREEN = "Screen";

    const SRC_PHB="PHB";                // temporary hack
    const SRC_DMG="DMG";                // temporary hack
    const SRC_MM="MM";                // temporary hack


    var hText, path, hash, subhashes, htype, hurl, hashPreEncoded;

    if (entry) {
        htype = entry.href.type;
        path = entry.href.path;
        hurl = entry.href.url;
        hText = entry.text;
    } else if (tag === "@filter") {
        // format: {@filter Warlock Spells|spells|level=1;2|class=Warlock}
        const [displayText, page, ...filters] = text.split("|");

        hText = displayText;
        htype= "internal";
        hash = HASH_BLANK;
        path="";
/*        
        subhashes= filters.map(f => {
            const [fname, fvals] = f.split("=").map(s => s.trim()).filter(s => s);
            return {
                key: `filter${fname}`,
                value: fvals.split(";").map(s => s.trim()).filter(s => s).join(HASH_SUB_LIST_SEP)
            }
        }); */
    } else if (tag === "@link") {
        const [displayText, url] = text.split("|");
        let outUrl = url == null ? displayText : url;
        if (!outUrl.startsWith("http")) outUrl = `http://${outUrl}`; // avoid HTTPS, as the D&D homepage doesn't support it
        htype="external";
        hurl=outUrl;
        hText=displayText;
        path = "";
        hash = "";
    } else if (tag === "@5etools") {

        const [displayText, page, thash] = text.split("|");
        path= "";
        path = "";
        hash = "";
        hText=displayText;
    } else if (tag === "@book" || tag === "@adventure") {
        // format: {@tag Display Text|DMG< |chapter< |section >< |number > >}

        const page = tag === "@book" ? "book.html" : "adventure.html";
        const [displayText, book, chapter, section, number] = text.split("|");
/*        hash = `${book}${chapter ? `${HASH_PART_SEP}${chapter}${section ? `${HASH_PART_SEP}${section}${number != null ? `${HASH_PART_SEP}${number}` : ""}` : ""}` : ""}`;
        htype= "internal";
        path = page;
        hashPreEncoded= true
*/
        hText=displayText;
        path = "";
        hash = "";
    } else if (tag === "@deity") {
        const [name, pantheon, source, displayText, ...others] = text.split("|");

        path = "";
        hash = "deities?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
        htype= "internal";
        hText= (displayText || name);

        /*
        hash = `${name}${pantheon ? `${HASH_LIST_SEP}${pantheon}` : ""}${source ? `${HASH_LIST_SEP}${source}` : ""}`;

        htype= "internal";
        hText= (displayText || name)

        path = "deities.html";
        if (!pantheon) hash += `${HASH_LIST_SEP}forgotten realms`;
        if (!source) hash += `${HASH_LIST_SEP}${SRC_PHB}`;

        fauxEntry.href.hover = {
            page: UrlUtil.PG_DEITIES,
            source: source || SRC_PHB
        };
        */
    } else {
        const [name, source, displayText, ...others] = text.split("|");

        htype= "internal";
        hText= (displayText || name);
        switch (tag) {
            case "@spell":

                path = "";
                hash = "spell?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_SPELLS,
                    source: source || SRC_PHB
                };*/
                break;
            case "@item":
                path = "";
                hash = "item?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_ITEMS,
                    source: source || SRC_DMG
                };*/
                break;
            case "@maneuver":
                path = "";
                hash = "";
                break;
            case "@invocation":
                path = "";
                hash = "";
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_ITEMS,
                    source: source || SRC_DMG
                };*/
                break;
            case "@class": {
                /*
                if (others.length) {
                    const scSource = others.length > 1 ? `~${others[1].trim()}` : "~phb";
                    subhashes = [
                        {key: "sub", value: others[0].trim() + scSource},
                        {key: "sources", value: 2}
                    ];
                    if (others.length > 2) {
                        subhashes.push({key: CLSS_HASH_FEATURE_KEY, value: others[2].trim()})
                    }
                }
                */
                const splitClass = name.split(" . ");
                hash = "class?class="+encodeURIComponent(splitClass[0])+(splitClass[1]?("&subclass="+encodeURIComponent(splitClass[1])):"");
                path = "";
                //if (!source) hash += HASH_LIST_SEP + SRC_PHB;
                break;
            }
            case "@creature":
                path = "";
                hash = "monster?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_BESTIARY,
                    source: source || SRC_MM
                };
                // ...|scaledCr}
                if (others.length) {
                    //const targetCrNum = Parser.crToNumber(others[0]);
                    const targetCrNum = others[0];
                    //fauxEntry.href.hover.prelodId = `${MON_HASH_SCALED}:${targetCrNum}`;
                    subhashes = [
                        {key: MON_HASH_SCALED, value: targetCrNum}
                    ];
                    hText = displayText || `${name} (CR ${others[0]})`;
                }*/

                break;
            case "@condition":
                path = "";
                hash = "conditions?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_CONDITIONS_DISEASES,
                    source: source || SRC_PHB
                };*/
                break;
            case "@disease":
                path = "";
                hash = "diseases?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_CONDITIONS_DISEASES,
                    source: source || SRC_DMG
                };*/
                break;
            case "@background":
                path = "";
                hash = "backgrounds?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_BACKGROUNDS,
                    source: source || SRC_PHB
                };*/
                break;
            case "@race":
                hash = "race?id="+encodeURIComponent(name);
                path = "";
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_RACES,
                    source: source || SRC_PHB
                };*/
                break;
            case "@optfeature":
                path = "";
                hash ="";
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_OPT_FEATURES,
                    source: source || SRC_PHB
                };*/
                break;
            case "@reward":
                path = "";
                hash ="";
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_REWARDS,
                    source: source || SRC_DMG
                };*/
                break;
            case "@feat":
                path = "";
                hash = "feats?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_FEATS,
                    source: source || SRC_PHB
                };*/
                break;
            case "@psionic":
                path = "";
//                hash = "psionics?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                hash ="";
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_PSIONICS,
                    source: source || SRC_UATMC
                };*/
                break;
            case "@object":
                path = "";
                hash ="";
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_OBJECTS,
                    source: source || SRC_DMG
                };*/
                break;
            case "@boon":
                path = "";
                hash = "boons?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                break;
            case "@skill":
                path = "";
                hash = "skills?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                break;
            case "@action":
                path = "";
                hash = "actions?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                break;
            case "@sense":
                path = "";
                hash = "senses?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                break;
            case "@cult":
                path = "";
                hash = "cults?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_CULTS_BOONS,
                    source: source || SRC_MTF
                };*/
                break;
            case "@trap":
                path = "";
                hash = "traps?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                break;
            case "@hazard":
                path = "";
                hash = "hazards?id="+encodeURIComponent(name)+(source?"&book="+encodeURIComponent(source):"");
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_TRAPS_HAZARDS,
                    source: source || SRC_DMG
                };*/
                break;
            case "@variantrule":
                path = "";
                hash ="";
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_VARIATNRULES,
                    source: source || SRC_DMG
                };*/
                break;
            case "@table":
                path = "";
                hash ="";
                /*fauxEntry.href.hover = {
                    page: UrlUtil.PG_TABLES,
                    source: source || SRC_DMG
                };*/
                break;
            case "@ship":
                path = "";
                hash ="";
                /*// enable this if/when there's a printed source with ships
                // if (!source) fauxEntry.href.hash += HASH_LIST_SEP + SRC_DMG;
                fauxEntry.href.hover = {
                    page: UrlUtil.PG_SHIPS,
                    source: source || "NONE" // || SRC_DMG // this too
                };*/
                break;
        }
    }

    var href;

    if (htype === "internal") {
        // baseURL is blank by default
        href = `${path}`;
        if (hash) {
            //href += hashPreEncoded ? hash : UrlUtil.encodeForHash(entry.href.hash);
            href += "#"+hash;
        }
        if (subhashes !== undefined) {
            for (let i = 0; i < subhashes.length; i++) {
                const subHash = subhashes[i];
                //href += `${HASH_PART_SEP}${UrlUtil.encodeForHash(subHash.key)}${HASH_SUB_KV_SEP}`;
                href += `${HASH_PART_SEP}${subHash.key}`
                if (subHash.value !== undefined) {
                    //href += UrlUtil.encodeForHash(subHash.value);
                    href += subHash.value;
                } else {
                    // TODO allow list of values
                    throw(new Error("don't know what to do with this"));
                    //href += subHash.values.map(v => UrlUtil.encodeForHash(v)).join(HASH_SUB_LIST_SEP);
                }
            }
        }
    } else if (htype === "external") {
        href = hurl;
    }

    return {href:href, hText:hText};
}

function getHoverInfo(tag, text){
    var display;

    if (tag === "@homebrew") {
        const [newText, oldText] = text.split("|");
        const tooltip = [];
        if (newText && oldText) {
            tooltip.push("<strong>This is a homebrew addition, replacing the following:</strong>");
        } else if (newText) {
            tooltip.push("<strong>This is a homebrew addition.</strong>")
        } else if (oldText) {
            tooltip.push("<strong>The following text has been removed with this homebrew:</strong>")
        }
        if (oldText) {
            tooltip.push(oldText);
        }
//            const onMouseOver = EntryRenderer.hover.createOnMouseHover(tooltip);
//            textStack[0] += `<span class="homebrew-inline" ${onMouseOver}>${newText || "[...]"}</span>`;
        display = newText||"[...]";
    } else if (tag === "@skill" || tag === "@action" || tag === "@sense") {
//            const expander = (() => {
//                switch (tag) {
//                    case "@skill": return Parser.skillToExplanation;
//                    case "@action": return Parser.actionToExplanation;
//                    case "@sense": return Parser.senseToExplanation;
//                }
//            })();
        const [name, displayText] = text.split("|");
//            const onMouseOver = EntryRenderer.hover.createOnMouseHover(expander(name), name);
//            textStack[0] += `<span class="help--hover" ${onMouseOver}>${displayText || name}</span>`;
        display = displayText || name;
    } else if (tag === "@area") {
        const [areaCode, flags, displayText, ...others] = text.split("|");
        const splCode = areaCode.split(">"); // use pos [0] for names without ">"s, and pos [1] for names with (as pos [2] is for sequence ID)
        const renderText = displayText || `${flags && flags.includes("u") ? "A" : "a"}rea ${splCode.length === 1 ? splCode[0] : splCode[1]}`;
        if (typeof BookUtil === "undefined") { // for the roll20 script
            display = renderText;
        } else {
            const area = BookUtil.curRender.headerMap[areaCode] || {entry: {name: ""}}; // default to prevent rendering crash on bad tag
            const onMouseOver = EntryRenderer.hover.createOnMouseHoverEntry(area.entry, true);
            textStack[0] += `<a href="#${BookUtil.curRender.curAdvId},${area.chapter},${UrlUtil.encodeForHash(area.entry.name)}" ${onMouseOver} onclick="BookUtil.handleReNav(this)">${renderText}</a>`;
        }
    }

    return {display:display};
}

module.exports= {htmlFromEntry, htmlFromString, getHoverInfo, getLinkInfo, getDiceInfo};