const React = require('react');
const {campaign,httpAuthRequestWithRetry,featureLookupPackage,addCampaignToPath} = require('../lib/campaign.js');
const Parser = require("../lib/dutils.js").Parser;
const {displayMessage} = require('./notification.jsx');
const {fixupFeature} = require('../lib/character.js');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {TextBasicEdit} = require('./stdedit.jsx');
const {pluralString,abilitiesValues} = require('../lib/stdvalues.js');
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';

let featureHasOptionsFn;
let getFeatureText;
let RenderFeature;
const maxResults=20;

class FeatureComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
        if (!RenderFeature) {
            RenderFeature = require('./features.jsx').RenderFeature;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.feature != this.props.feature) {
            this.startLoad();
        }
    }

    componentDidMount() {
        this.startLoad();
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer=null;
        }
    }

	render() {
        const {displayType, type, subtype, level,feature} = this.props;
        const {matches}=this.state;
        const style = "pa1 fas fa-lightbulb hoverhighlight mr1 "+(matches?"":"gray-10");
        let menu;
        let confirm;
        if (this.state.showValues && matches) {
            const menuList = [];
            for (let i in matches) {
                const m = matches[i];
                const sameDisplayType = (displayType == m.displayType);
                const noConfirm = m.match>=perfectMatch;

                menuList.push(<MenuItem key={i} onClick={noConfirm?this.clickFeature.bind(this, m,false):this.confirmFeature.bind(this, m)}>
                    <div>{sameDisplayType?null:(m.displayType+": ")}{(m.subtype||m.type)}: {m.feature.name||m.emptyName} {(m.level&&(m.level != level))?"(Level "+m.level+")":null}</div>
                    <div className="flex-auto"/>
                    <div className="pl1 gray-90">{(Math.log10(m.match)*10/6).toFixed(1)}</div>
                    <div onClick={this.showFeature.bind(this,m.feature)} className="fas fa-search f3 ml2 pa1 hoverhighlight"/>
                </MenuItem>);
            }
            menu = <Menu
                anchorEl={this.state.anchorEl}
                open
                onClose={this.showValues.bind(this,false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'left',}}
                transformOrigin={{vertical: 'top',horizontal: 'right',}}
            >
                {menuList}
                {this.state.showFeature?<Popover
                    open
                    anchorEl={this.state.showFeatureAnchorEl}
                    onClose={this.showFeature.bind(this,null,null)}
                    classes={{"paper":"mw6 pa1"}}
                    anchorOrigin={{vertical: 'top',horizontal: 'right',}}
                    transformOrigin={{vertical: 'top',horizontal: 'left',}}
                >
                    <RenderFeature feature={this.state.showFeature}/>
                </Popover>:null}
            </Menu>
        }
        if (this.state.confirmFeature) {
            const selectedFeature = this.state.selectedFeature;
            const hasOptions = featureHasOptions(selectedFeature.feature)||selectedFeature.equipment;
            confirm = <Dialog
                open
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle onClose={this.clickFeature.bind(this, null)}>Change Feature?</DialogTitle>
                <DialogContent>
                    <div className="titlecolor f2 mb2">From:</div>
                    <RenderFeature feature={feature}/>
                    <div className="titlecolor f2 mv2">To:</div>
                    <RenderFeature feature={selectedFeature.feature}/>
                </DialogContent>
                <DialogActions>
                    {hasOptions||this.s?<Button onClick={this.clickFeature.bind(this, selectedFeature,true)} color="primary">
                        Modeling Only
                    </Button>:null}
                    <Button onClick={this.clickFeature.bind(this, selectedFeature,false)} color="primary">
                        Yes
                    </Button>
                    <Button onClick={this.clickFeature.bind(this, null)} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>;
        }
        return <span>
            <Tooltip title="suggestions">
                <span className={style} onClick={this.props.loadingMatches||!matches?null:this.showValues.bind(this,true)}/>
            </Tooltip>
            {menu}
            {confirm}
        </span>;
    }

    showValues(showValues,e) {
        //console.log("matches", this.state.matches);
        this.setState({showValues,anchorEl:e&&e.target});
    }

    showFeature(showFeature, e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({showFeature,showFeatureAnchorEl:e&&e.target});
    }

    confirmFeature(selectedFeature) {
        this.setState({selectedFeature, confirmFeature:true, showValues:false});
    }

    clickFeature(match, modelingOnly) {
        if (match) {
            const f = Object.assign({}, match.feature);
            f.id = campaign.newUid();
            if (f.options) {
                f.options = f.options.concat([]);
                for (let i in f.options) {
                    f.options[i] = Object.assign({}, f.options[i]);
                    f.options[i].id = campaign.newUid();
                }
            }
            if (modelingOnly) {
                const oldFeature = this.props.feature;
                f.name = oldFeature.name;
                f.entries = oldFeature.entries;
            }
            this.props.onChange(f);
            if (match.equipment && this.props.onChangeEquipment) {
                const t=this;
                // do this on a delay because this is usually implemented in state which is updated asynchronously
                setTimeout(function (){
                    t.props.onChangeEquipment(match.equipment);
                },50);
            }
        }
        this.setState({showValues:false, confirmFeature:false});
    }

    startLoad() {
        const t=this;
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer=null;
        }
        this.timer = setTimeout(function () {
            t.loadMatches();
            t.timer=null;
        }, 500);
        this.setState({loadingMatches:true});
    }

    loadMatches() {
        const {feature, displayType, type, subtype, level, prevFeature,emptyName,name} = this.props;
        const sr = new SearchFeatureResults(feature, displayType, type, subtype, level, prevFeature,emptyName,name);
        if (featureHasOptions(feature)) {
            this.setState({matches:null});
            return;
        }
        enumFeatures(function (info) { 
            sr.add(info);
        });
        if (sr.matches && sr.matches.length) {
            this.setState({matches:sr.matches});
        } else {
            this.setState({matches:null});
        }
        //console.log("features found", feature, sr.matches);
    }
}

const importInfo = <span>
    Import a character from D&amp;D Beyond by pasting the URL for your character above. 
    <div className="mv2">To get the URL for you character: view your character in D&amp;D Beyond and copy the URL from the browser address bar</div>
    <div className="mb2">Any related race, class, background, feat, and spell definitions that are missing from Shard will automatically be added to support your character. 
    For more information see our <a href="https://shardtabletop.com/howto/how-to-import-a-character-from-dampd-beyond" target="_blank">learning&nbsp;page.</a></div> 
    <div className="red">NOTE: Import is a Beta feature. If you have any problems, please send feedback to <a href="mailto:shard@shardtabletop.com">shard@shardtabletop.com</a> and include the URL for your character and a description of the problem.</div>
</span>;

class ImportCharacter extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

	render() {
        return;
        return <span>
            <Button onClick={this.showImport.bind(this)} className="ml1 minw2" color={this.props.color||"secondary"} variant="outlined" size="small">Import Character</Button>
            <TextBasicEdit show={this.state.showImport} label="URL to Character" text="" info={importInfo} onChange={this.onClickImport.bind(this)}/>
            {this.state.loading?<Dialog
                open={this.state.loading}
            >
                <DialogContent>
                    Downloading character...
                </DialogContent>
            </Dialog>:null}

       </span>;
    }

    showImport() {
        displayMessage(<span>Due to changes in D&D Beyond, import is no longer supported.  Keeping pace with their changes has proved to be impractical.<br/><a href="https://marketplace.shardtabletop.com/m?pub=wotc">See marketplace for WotC character options.</a></span>)
        //this.setState({showImport:true});
    }

    async onClickImport(url) {
        this.setState({showImport:false, loading:true});
        if (url) {
            const split  = url.split("/");
            const pos = split.findIndex(function (a) {return a && (a.toLowerCase()=="characters")});
            if (pos <0) {
                displayMessage("Url does not appear to valid");
            } else {
                const characterNum = split[pos+1];
                try {
                    const fetchURL = "/search?cmd=import&url="+encodeURIComponent("https://character-service.dndbeyond.com/character/v5/character/"+characterNum+"?includeCustomItems=true");

                    const res = await httpAuthRequestWithRetry("GET", fetchURL);
                    const info = JSON.parse(res);
                    console.log("fetched character", info);
                    const name= await createEntriesFromCharacter(info.data);
                    if (name) {

                        if (campaign.getPrefs().playerMode == "ruleset") {
                            campaign.joinCharacterToSharedCampaign(name, campaign.getCurrentCampaign());
                            window.location.href = addCampaignToPath("/#mycharacters?id="+encodeURIComponent(name),true);
                        } else {
                            window.location.href = "/#mycharacters?id="+encodeURIComponent(name);
                        }
            
                        campaign.addUserMRUList("mruCharacters", {description:name});
                    }
                } catch (err) {
                    console.log("Error downloading", err);
                    if (err.status==403) {
                        displayMessage(<span>Access denied to character.  Make sure that your character has been marked for public sharing in order to enable import.  <a href="https://www.shardtabletop.com/howto/how-to-enable-import-for-your-character" target="_blank">See our help article on how to make character public</a></span>);
                    } else {
                        displayMessage("Error downloading character.  "+err.message);
                    }
                }
            }
        }
        this.setState({loading:false});
    }
}

class ImportContent extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

	render() {
        return <div>
            <a onClick={this.showImport.bind(this)}>Import Content</a>: Import content from D&amp;D Beyond 
            <TextBasicEdit show={this.state.showImport} label="Auth token" text="" onChange={this.onClickImport.bind(this)}/>
            {this.state.loading?<Dialog
                open={this.state.loading}
            >
                <DialogContent>
                    Downloading content...
                </DialogContent>
            </Dialog>:null}

       </div>;
    }

    showImport() {
        this.setState({showImport:true});
    }

    async onClickImport(auth) {
        this.setState({showImport:false, loading:true});
        if (auth) {
            try {
                if (true) {
                    const fetchURL = "/search?cmd=import&url="+
                        encodeURIComponent("https://character-service.dndbeyond.com/character/v5/game-data/races?sharingSetting=2")+
                        "&auth="+encodeURIComponent(auth)
                    ;

                    const res = await httpAuthRequestWithRetry("GET", fetchURL);
                    const list = JSON.parse(res).data;
                    //console.log("fetched races", list);
                    for (let i in list) {
                        createRaceFromImport(list[i]);
                    }
                }

                if (true) {
                    const fetchURL = "/search?cmd=import&url="+
                        encodeURIComponent("https://character-service.dndbeyond.com/character/v5/game-data/backgrounds?sharingSetting=2")+
                        "&auth="+encodeURIComponent(auth)
                    ;

                    const res = await httpAuthRequestWithRetry("GET", fetchURL);
                    const list = JSON.parse(res).data;
                    //console.log("fetched backgrounds", list);
                    for (let i in list) {
                        createBackgroundFromImport(list[i]);
                    }
                }

                if (true) {
                    const fetchURL = "/search?cmd=import&url="+
                        encodeURIComponent("https://character-service.dndbeyond.com/character/v5/game-data/feats?sharingSetting=2")+
                        "&auth="+encodeURIComponent(auth)
                    ;

                    const res = await httpAuthRequestWithRetry("GET", fetchURL);
                    const list = JSON.parse(res).data;
                    //console.log("fetched feats", list);
                    for (let i in list) {
                        createFeatFromImport(list[i]);
                    }
                }

                if (true) {
                    const fetchURL = "/search?cmd=import&url="+
                        encodeURIComponent("https://character-service.dndbeyond.com/character/v5/game-data/items?sharingSetting=2")+
                        "&auth="+encodeURIComponent(auth)
                    ;

                    const res = await httpAuthRequestWithRetry("GET", fetchURL);
                    const list = JSON.parse(res).data;
                    //console.log("fetched items", list);
                    for (let i in list) {
                        createItemFromImport(list[i],{});
                    }
                }

                if (true) {
                    const fetchURL = "/search?cmd=import&url="+
                        encodeURIComponent("https://character-service.dndbeyond.com/character/v5/game-data/classes?sharingSetting=2")+
                        "&auth="+encodeURIComponent(auth)
                    ;

                    const res = await httpAuthRequestWithRetry("GET", fetchURL);
                    const list = JSON.parse(res).data;
                    //console.log("fetched classes", list);
                    for (let i in list) {
                        const c = list[i];
                        const cls = createClassFromInfo(list[i], null);
                        if (cls) {
                            if (cls.spellcaster) {
                                const spells = await importSpells(auth,c.id, cls.displayName);
                                addSpellsToClass(cls, spells);
                            }
                            const fetchURL = "/search?cmd=import&url="+
                                encodeURIComponent("https://character-service.dndbeyond.com/character/v5/game-data/subclasses?sharingSetting=2&baseClassId="+c.id)+
                                "&auth="+encodeURIComponent(auth)
                            ;
        
                            const res = await httpAuthRequestWithRetry("GET", fetchURL);
                            const sclist = JSON.parse(res).data;
                            //console.log("fetched subclasses", sclist);
                            for (let i in sclist) {
                                const sc = sclist[i];
                                const scls = createClassFromInfo(sc, cls);
                                if (scls && scls.spellcaster) {
                                    const spells = await importSpells(auth, sc.id, scls.displayName);
                                    addSpellsToClass(scls, spells);
                                }
                            }
                        }
                    }
                }
            } catch (err) {
                console.log("Error downloading", err);
            }
        }
        this.setState({loading:false});
    }
}

function addSpellsToClass(cls, spells) {
    if (cls.edited) {
        const assignedSpellList = (cls.assignedSpellList||[]).concat([]);
        let needUpdate = false;
        for (let i in spells) {
            const s = spells[i];
            if (!assignedSpellList.includes(s)) {
                assignedSpellList.push(s);
                needUpdate=true;
            }
        }
        if (needUpdate) {
            cls = Object.assign({},cls);
            //console.log("updating class spell list", cls.assignedSpellList, assignedSpellList);
            cls.assignedSpellList = assignedSpellList;
            campaign.updateCampaignContent("classes", cls);
        }
    }
}

async function importSpells(auth, classId, className) {
    const spells = [];
    {
        const fetchURL = "/search?cmd=import&url="+
            encodeURIComponent("https://character-service.dndbeyond.com/character/v5/game-data/spells?sharingSetting=2&classLevel=20&classId="+classId)+
            "&auth="+encodeURIComponent(auth)
        ;
        const res = await httpAuthRequestWithRetry("GET", fetchURL);
        const list = JSON.parse(res).data;
        //console.log("fetched spells", list);
        for (let i in list) {
            const s = createSpellFromImport(list[i].definition, className);
            spells.push(s.name);
        }
    }
    {
        const fetchURL = "/search?cmd=import&url="+
            encodeURIComponent("https://character-service.dndbeyond.com/character/v5/game-data/always-known-spells?sharingSetting=2&classLevel=20&classId="+classId)+
            "&auth="+encodeURIComponent(auth)
        ;
        const res = await httpAuthRequestWithRetry("GET", fetchURL);
        const list = JSON.parse(res).data;
        //console.log("fetched always spells", list);
        for (let i in list) {
            const s = createSpellFromImport(list[i].definition, className);
            spells.push(s.name);
        }
    }
    return spells;
}


function ignoreSpecial(string) {
    return string.replace(/[^a-zA-Z0-9 ]/g, '.?');
}

const perfectMatch = 1000000;

class SearchFeatureResults {
    constructor(feature, displayType, type, subtype, level, prevFeature,emptyName,name){
        this.matches = [];
        this.min = 10000000;
        this.displayType=displayType;
        this.type = type;
        this.subtype = subtype;
        this.level=level;
        this.emptyName = emptyName;
        this.name = name;
        this.prevFeature=prevFeature&&prevFeature.toLowerCase();

        if (!getFeatureText) {
            getFeatureText = require('./bookconvert.jsx').getFeatureText;
        }

        this.setFeature(feature);
    }

    setFeature(feature) {
        this.feature = feature;
        if (feature && (feature.name||this.emptyName)) {
            const escaped = ignoreSpecial(feature.name||this.emptyName);
            this.f=new RegExp('\\b'+escaped, "i");
            this.rawText = feature.name||this.emptyName;
        } else {
            this.f=null;
        }
        this.featureText = getFeatureText(feature);
        if (this.featureText) {
            this.featureTokens = tokenizeText(this.featureText);
        }
        this.typeTokens = tokenizeText(this.subtype||this.type||"");
    }

    allowFeatureTextMatching() {
        this.ftMatching = true;
    }

    test(info) {
        if (info.name == this.name) {
            //console.log("ignore self", info);
            return 0;
        }
        if (!info.feature || (!info.feature.name && !info.emptyName)) {
            return 0;
        }
        let val = info.feature.name || info.emptyName || "";
        let m=0;

        let fullMatch=false;

        if (this.f) {
            let pos = val.search(this.f);
            if (pos < 0) {
                if (this.emptyName && info.emptyName && !info.feature.name && !this.feature.name) {
                    pos=0;
                    val="";
                    fullMatch=true;
                } else {
                    const nv = alternateFeatureName(val);
                    if (nv) {
                        val = nv;
                        pos = val.search(this.f)
                    }
                }
            }

            if (pos < 0) {
                return 0;
            } else if (pos == 0) {
                if (val.length == this.rawText.length) {
                    fullMatch=true;
                    m= 1000;
                } else if (val[this.rawText.length]==" ") {
                    m= 200;
                } else {
                    m = 100;
                }
            } else {
                if (this.rawText.length+pos == val.length) {
                    m= 90;
                } else if (val[pos+this.rawText.length]==" "){
                    m= 50;
                } else {
                    m=10;
                }
            }
        } else {
            if ((this.displayType != info.displayType) ||
                (this.level && this.level !=info.level)) {
                return 0;
            }

            if (this.type!=info.type) {
                m = 10;
            } else if (this.subtype != info.subtype) {
                m = 20;
            } else {
                m=100;
            }
        }
        
        let mult = 1;
        if (info.displayType != this.displayType) {
            if (info.type != this.type) {
                mult = 0.1;
            } else {
                mult = 0.15;
            }
        } else if (info.type != this.type) {
            const typeTokens = tokenizeText(info.subtype||info.type);
            const ftMatch = matchTokenized(typeTokens, this.typeTokens);

            mult = Math.max(0.2, ftMatch);
        }

        if (info.option) {
            mult=mult*0.7;
        }
    
        if (this.subtype) {
            if (info.subtype != this.subtype) {
                if (info.subtype) {
                    mult = mult * 0.25;
                } else {
                    mult = mult * 0.5;
                }
            } 
        } else if (info.subtype) {
            mult = mult * 0.5;
        }

        if (this.level) {
            if (this.level==info.level) {
                mult = mult * 3;
            }
        }

        if (this.prevFeature == (info.prevFeature&&info.prevFeature.toLowerCase())) {
            mult=mult * 2;
        }
        fixupFeature(info.feature);
        const hasOptions = featureHasOptions(info.feature) || info.equipment;
        if (hasOptions) {
            mult = mult * 1.1;
        }
        let ftMatch = 0;
        if (this.featureText) {
            const ft = getFeatureText(info.feature);
            if (!hasOptions && !this.ftMatching) {
                // if feature has text then only match features with options
                return 0;
            }
            if (ft) {
                const featureTokens = tokenizeText(ft);
                ftMatch = matchTokenized(featureTokens, this.featureTokens);
                if (ftMatch > 0.5) {
                    let nm = 1;
                    if (ftMatch == 1) {
                        nm = 20;
                    } else if (ftMatch > 0.66) {
                        nm= (1+(ftMatch-0.66)*20)
                    } else {
                        nm= (1+(ftMatch-0.5)*2)
                    }
                    mult=mult*nm;
                }
            }
        }

        m = m*mult*info.pref + ((fullMatch && (ftMatch==1))?perfectMatch:0);
        return {m, ftMatch, fullMatch};
    }

    add(info) {
        const mv = this.test(info);
        if (!mv) {
            return;
        }
        const {m,ftMatch, fullMatch}=mv;
        info.match = m;
        info.ftMatch = ftMatch;
        info.fullMatch = fullMatch;
        if (areAlmostSameDeep(this.feature, info.feature)) {
            return;
        }
        let needPerfect = (m>=perfectMatch);
        for (let i in this.matches) {
            const match = this.matches[i];
            if (match.match >= perfectMatch) {
                needPerfect = true;
                if (m < perfectMatch) {
                    return;
                }
            }
            if ((match.match >= m) && areAlmostSameDeep(info.feature, match.feature)) {
                return;
            }
        }

        if (needPerfect) {
            for (let i = this.matches.length-1; i>=0; i--) {
                if (this.matches[i].match < perfectMatch) {
                    this.matches.splice(i,1);
                }
            }
        }

        /*
        for (let i in this.matches) {
            const match = this.matches[i];
            if (areAlmostSameDeep(match.feature, info.feature)) {
                const p = this.matches[i];
                if (m > match.match) {
                    this.matches[i] = info;
                }
                if ((p.type != info.type) || (p.subtype!=info.subtype)) {
                    this.matches[i].multitype = true;
                }
                return;
            }
        }
        */

        if (this.matches.length > maxResults) {
            // need to kick someone out
            if (m <= this.min) {
                // already full
                return;
            }

            this.matches.push(info);
            this.matches.sort(function (a,b) {return b.match-a.match });
            const last = this.matches.pop();
            this.min = last.match;
        } else {
            if (m < this.min) {
                this.min = m;
            }
            this.matches.push(info);
            this.matches.sort(function (a,b) {return b.match-a.match });
        }
    }
}

function alternateFeatureName(val) {
    const alts = {
        "abilities":"ability score increase",
        "ability score increase":"abilities"
    }
    return alts[val.toLowerCase()];
}

function matchFeatures(features, displayType, type, subtype, level, emptyName) {
    let prevFeature = null;
    let equipment = null;
    for (let i in features) {
        const feature = features[i];
        const sr = new SearchFeatureResults(feature, displayType, type, subtype, level, prevFeature,emptyName,null);
        sr.allowFeatureTextMatching();

        if (!feature.id) {
            feature.id=campaign.newUid();
        }

        if (displayType=="Class" && feature.name=="Ability Score Improvement") {
            feature.options= [
                {
                  "name": "Increase Ability Scores",
                  "ability": {"choose": 2, "from": ["str","dex","con","int","wis","cha"]},
                  "entries": []
                },
                {
                  "name": "Pick Feat",
                  "pickFeat": true,
                  "entries": []
                }
            ];
        } else {
            enumFeatures(function (info) {
                if (info.displayType == displayType) {
                    sr.add(info);
                }
            });
            if (sr.matches && sr.matches.length) {
                const bestMatch = sr.matches[0];
                if (bestMatch.match >= perfectMatch) 
                {
                    //console.log("fixup", feature, bestMatch);
                    features[i] = Object.assign({}, bestMatch.feature);
                    if (bestMatch.equipment) {
                        equipment = bestMatch.equipment;
                    }
                } else if (!["Age", "Speed", "Alignment", "Size", "Languages", "Equipment", "Suggest Characteristics"].includes(feature.name)) {
                    features[i].usage={type:"s"};
                }
            } else {
                features[i].usage={type:"s"};
            }
        }
        if (feature.name) {
            prevFeature=feature.name;
        }
    }
    return equipment;
}


function featureHasOptions(feature) {
    if (!featureHasOptionsFn) {
        featureHasOptionsFn = require('./features.jsx').featureHasOptions;
    }
    if (feature.usage && (Object.keys(feature.usage).length==1)) {
        feature = Object.assign({}, feature);
        delete feature.usage;
    }
    return featureHasOptionsFn(feature);
}

function tokenizeText(ft) {
    ft = ft.replace(/\W/g, " ").toLowerCase();
    const s = ft.split(" ");
    const words = [];
    const ret = {_words:words};
    for (let t of s) {
        if (t.length && !stopWords[t]) {
            ret[t]=true;
            words.push(t);
        }
    }
    return ret;
}

function matchTokenized(a,b) {
    let and =0;
    let match=true;
    for (let i=0; (i<a._words.length) && match; i++) {
        if (a._words[i]!=b._words[i]){
            match = false;
        }
    }
    if (match) {
        //console.log("full match", a,b);
        return 1;
    }
    for (let i in a) {
        if (b[i]) {
            and++;
        }
    }
    const c = Object.assign({}, a);
    Object.assign(c,b)
    //console.log("match=",and / (Object.keys(c).length) * 0.9)
    return and / (Object.keys(c).length) * 0.9;
}

const stopWords= {
    "s":1, "i":1, "a":1, "is":1, "as":1, "of":1, "the":1, "or":1, "and":1, "but":1, "it":1, "on":1, "in":1, "by":1, "to":1, "at":1, "an":1
}

function enumFeatures(fn) {
    enumRaces(fn);
    enumClasses(fn);
    enumBackgrounds(fn);
    enumFeats(fn);
    enumAllCustomList(fn);
}

function enumFeaturesFn(fn, features, displayType, type, subtype, pref, level,emptyName,name, equipment) {
    let prevFeature = null;
    for (let f in features) {
        const feature = features[f];

        if (feature.name || emptyName) {
            fn({
                displayType,
                type,
                subtype,
                prevFeature,
                feature,
                pref,
                level,
                emptyName,
                name,
                equipment:(feature.name=="Equipment")?equipment:null
            });
            prevFeature = feature.name;
            if (feature.options) {
                for (let x in feature.options ){
                    const on = feature.options[x];
                    fn({
                        displayType,
                        type,
                        subtype,
                        feature:on,
                        prevFeature,
                        pref,
                        level,
                        emptyName,
                        option:true,
                        name
                    });
                }
            }
            if (feature.subfeatures) {
                enumFeaturesFn(fn, feature.subfeatures, displayType, type, subtype, pref, level,feature.name||emptyName,name);
            }
        }
    }
}

function enumRaces(fn) {
    const list = campaign.getRaces().concat(campaign.getFeaturePackageInfo().races||[]);
     
    for (let i in list){
        const it=list[i];
        const pref = sourcePref(it.source);
        const baseRace = it.baserace?campaign.getRaceInfo(it.baserace):null;
        const features = it.raceFeatures||[];
        const type = baseRace?baseRace.displayName:it.displayName;
        const subtype = baseRace?it.displayName:null;
        //console.log("race", it.displayName, it.baserace, baseRace && baseRace.displayName, baseRace);

        enumFeaturesFn(fn, features, "Race", type, subtype, pref, 0, null, it.name);
    }
}

function enumFeats(fn) {
    const list = campaign.getSortedFeatsList().concat(campaign.getFeaturePackageInfo().feats||[]);
     
    for (let i in list){
        const it=list[i];
        const pref = sourcePref(it.source);
        const features = it.features||[];

        enumFeaturesFn(fn, features, "Feat", it.displayName, null, pref, 0, it.displayName, it.name);
    }
}

function enumBackgrounds(fn) {
    const list = campaign.getSortedBackgroundsList().concat(campaign.getFeaturePackageInfo().backgrounds||[]);
     
    for (let i in list){
        const it=list[i];
        const features = it.features||[];
        const pref = sourcePref(it.source);

        enumFeaturesFn(fn, features, "Background", it.displayName, null, pref, 0,null, it.name, it.startingEquipment);
    }
}

function enumAllCustomList(fn) {
    const list = campaign.getAllCustomList().concat(campaign.getFeaturePackageInfo().customTypes||[]);
     
    for (let i in list){
        const it=list[i];
        const features = it.features||[];
        const pref = sourcePref(it.source);

        enumFeaturesFn(fn, features, it.type, it.displayName, null, pref, 0, it.displayName,it.name);
    }
}

function enumClasses(fn) {
    const classList = campaign.getClassesListByName();
    const featurePackageClasses = campaign.getFeaturePackageInfo().classes||[];
     
    for (let i in classList){
        const c=classList[i];

        enumClassFeatures(fn, c);
    
        const subclassList = campaign.getSubclasses(c.className)

        for (let x in subclassList){
            const sc=subclassList[x];
    
            enumClassFeatures(fn, sc, c.displayName);
        }
        for (let x in featurePackageClasses){
            const sc=featurePackageClasses[x];
    
            if ((sc.className == c.className) && sc.subclassName) {
                enumClassFeatures(fn, sc, c.displayName);
            }
        }
    }

    for (let i in featurePackageClasses) {
        const c=featurePackageClasses[i];

        if (!c.subclassName) {
            enumClassFeatures(fn, c);
        
            for (let x in featurePackageClasses){
                const sc=featurePackageClasses[x];
        
                if ((sc.className == c.className) && sc.subclassName) {
                    enumClassFeatures(fn, sc, c.displayName);
                }
            }
        }
    }
}

function enumClassFeatures(fn, cls, baseType) {
    const pref = sourcePref(cls.source);

    for (let r=0; r<20; r++){
        enumFeaturesFn(fn, cls.classFeatures[r], cls.subclassName?"Subclass":"Class", baseType || cls.displayName, baseType?cls.displayName:null, pref, r+1, null, cls.name);
    }
}

const shardHandbookId = "079s1xff1eflg8kuaw42";
const shardCoreContentPack = "unz78smuf72g4upk";

function sourcePref(source) {
    if (source==shardHandbookId) {
        return 1.5;
    }
    if (source == shardCoreContentPack) {
        return 1.2;
    }
    return 1;
}

function areAlmostSameDeep(a,b) {
    if ((a == b) || (Number.isNaN(a) && Number.isNaN(b))) {
        return true;
    }

    if ((typeof a != 'object') || (typeof b != 'object')) {
        return false;
    }

    const aisArray = Array.isArray(a);
    const bisArray = Array.isArray(b);
    if (aisArray != bisArray) {
        return false;
    }
    if (aisArray) {
        if (a.length != b.length){
            return false;
        }
    } else {
        if (!a || !b) {
            return false;
        }
    }

    for (let i in a) {
        if (!(["id","auto","gainSubclassFeature"].includes(i)) && !areAlmostSameDeep(a[i], b[i])){
            return false;
        }
    }
    for (let i in b) {
        if (!(["id","auto","gainSubclassFeature"].includes(i)) && !areAlmostSameDeep(a[i], b[i])){
            return false;
        }
    }
    return true;
}

async function createEntriesFromCharacter(data) {
    const newImports={entryIds:{}, entryTypes:{}};
    if (data.race) {
        createRaceFromImport(data.race,newImports);
    }

    if (data.classes) {
        createClassFromCharacter(data.classes, newImports);
    }

    if (data.background && !data.background.hasCustomBackground && data.background.definition){
        createBackgroundFromImport(data.background.definition, newImports);
    }

    createSpells(data,newImports);
    createFeats(data,newImports);
    createItems(data.inventory, newImports);

    //console.log("newImports", newImports);
    await campaign.addNewImports(newImports);
    
    const name = createCharacter(data);

    return name;
}

function createCharacter(data) {
    const {Character} = require('../lib/character.js');

    if (campaign.getMyCharacters().length >= campaign.maxCharacters) {
        displayMessage(<span>You have reached your limit of {campaign.maxCharacters} characters that you can create.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to change your limits.</span>);
        return;
    }
    const notes = data.notes||{};
    const traits = data.traits||{};
    const currency = data.currencies||{};
    const char = {
        name:campaign.newUid(), 
        displayName:data.name,
        full:true,
        hair:data.hair||null,
        alignment:alignmentTypes[data.alignmentId]||null,
        gender:data.gender||null,
        eyes:data.eyes||null,
        height:data.height||null,
        faith:data.faith||null,
        skin:data.skin||null,
        age:data.age||null,
        weight:data.weight||null,
        backstory:notes.backstory||null,
        personality:traits.personalityTraits||null,
        ideals:traits.ideals||null,
        bonds:traits.bonds||null,
        flaws:traits.flaws||null,
        cp:Number(currency.cp||0),
        sp:Number(currency.sp||0),
        ep:Number(currency.ep||0),
        gp:Number(currency.gp||0),
        pp:Number(currency.pp||0),
    };
    const alliesList = [];
    if (notes.allies) {
        alliesList.push(notes.allies);
    }
    if (notes.enemies) {
        alliesList.push("Enemies: "+notes.enemies);
    }
    if (notes.organizations) {
        alliesList.push("Organizations: "+notes.organizations);
    }
    if (alliesList.length) {
        char.allies=alliesList.join("\n");
    }

    const other=[];
    if (notes.otherNotes) {
        other.push(notes.otherNotes);
    }
    if (notes.otherHoldings) {
        other.push("Holdings: "+notes.otherHoldings);
    }
    if (notes.personalPossessions) {
        other.push("Personal Possesions: "+notes.personalPossessions);
    }
    if (traits.appearance) {
        other.push("Appearance: "+traits.appearance);
    }
    if (other.length) {
        char.traits=other.join("\n");
    }

    char.baseAbilities = {};
    for (let i in data.stats) {
        const s = data.stats[i];
        char.baseAbilities[abilityFromId(s.id)] = s.value;
    }

    const character = new Character(char,"mycharacters");
    character.equipment = createItems(data.inventory);
    if (data.race) {
        const race = findEntryFromDisplayName(data.race.fullName, "Race");
        if (race) {
            setRaceChoices(race, character, data);
        }
    }
    if (data.background && !data.background.hasCustomBackground && data.background.definition) {
        const background = findEntryFromDisplayName(data.background.definition.name, "Background");
        if (background) {
            setBackgroundChoices(background, character, data);
        }
    }
    if (data.classes){
        const levels = [{level:0}];
        const sortedClasses = data.classes.concat([]);

        sortedClasses.sort(function (a,b) { return (a.isStartingClass?0:1)-(b.isStartingClass?0:1)});
        for (let cls of sortedClasses) {
            if (cls.definition) {
                const classInfo = findEntryFromDisplayName(cls.definition.name, "Class");
                    
                if (classInfo) {
                    for (let level =1; level<=cls.level; level++) {
                        levels.push({
                            cclass:classInfo.className,
                            level
                        });
                    }
                }
            }
        }
        character.setLevels(levels);
        setClassChoices(character, data);
    }
    if (data.baseHitPoints) {
        character.setProperty("hp",data.baseHitPoints);
    }
    return char.name;
}

const activationTypes={
    "1":{
        "name": "action",
        multiple:false
    },
    "3":{
        "name": "bonus action",
        multiple:false
    },
    "4":{
        "name": "reaction",
        multiple:false
    },
    "6":{
        "name": "minute",
        multiple:true
    },
    "7":{
        "name": "hour",
        multiple:true
    },
};

const alignmentTypes={
    "1":"Lawful Good",
    "2":"Neutral Good",
    "3":"Chaotic Good",
    "4":"Lawful Neutral",
    "5":"Neutral",
    "6":"Chaotic Neutral",
    "7":"Lawful Evil",
    "8":"Neutral Evil",
    "9":"Chaotic Evil",
    "10":"Unaligned",
};
const spellComponentTypes = {
    "1":"v",
    "2":"s",
    "3":"m",
    "4":"r"
};

const importSource="imported";

function createSpells(data, newImports) {
    const classSpells = data.classSpells;
    const classes = data.classes||[];
    for (let i in classSpells) {
        const spells = classSpells[i].spells||[];
        const cls = classes[i];
        const className = (cls && cls.definition && cls.definition.canCastSpells && cls.definition.name) || (cls && cls.subclassDefinition && cls.subclassDefinition.canCastSpells && cls.subclassDefinition.name);
        for (let s in spells) {
            const spellData = spells[s].definition;
            createSpellFromImport(spellData, className, newImports)
            //console.log("add spell", spellData, className, newImports);
        }
    }
}

function createSpellFromImport(spellData, className, newImports) {
    const spell ={
        name:getImportId(spellData.id,"spell"),
        displayName:spellData.name,
        source:importSource,
        entries:[{type:"html", html:fixupHtml(spellData.description||"")}],
        level:spellData.level||0,
        ritual:spellData.ritual||false,
        schoolName:spellData.school||"",
    };

    let foundSpell = findEntryFromDisplayName(spell.displayName, "Spell", spell.level);
    if (foundSpell) {
        //console.log("found spell", spell.displayName);
        if (foundSpell.edited && !(foundSpell.classes||[]).includes(className)) {
            // add spell to assigned spell list
            foundSpell = Object.assign({}, foundSpell);
            foundSpell.classes = (foundSpell.classes||[]).concat([className]);
            campaign.updateCampaignContent("spells", foundSpell);
            //console.log("added spell list to spell", foundSpell.displayName, className, foundSpell.classes);
        }
        if (newImports && (foundSpell.source == featureLookupPackage)) {
            addImportMonsterOptions(foundSpell.summonMonsters, newImports);
        }
        return foundSpell;
    }
    if (newImports) {
        const foundImportSpell = findImportEntryFromDisplayName(spell.displayName, "Spell", spell.level);
        if (foundImportSpell) {
            newImports.entryIds[foundImportSpell.name]=true;
            addImportMonsterOptions(foundImportSpell.summonMonsters, newImports);

            return foundImportSpell;
        }
    }

    if (className) {
        spell.classes = [className];
    }

    const duration = spellData.duration;
    if (duration) {
        let d = duration.durationType||"";
        if (d=="Time") {
            d = (duration.durationInterval||1) + " "+pluralString(duration.durationUnit, duration.durationInterval).toLowerCase();
        }
        if (duration.concentration) {
            d= "Concentration, up to "+d;
        }
        spell.duration=d;
    }

    const activation = spellData.activation;
    if (activation) {
        const at = activationTypes[activation.activationType];
        if (at) {
            spell.time={unit:at.name, number:1};
            if (at.multiple) {
                spell.time.number=activation.activationTime||1;
            }
            if (spellData.castingTimeDescription && spellData.castingTimeDescription.length) {
                spell.time.condition = spellData.castingTimeDescription;
            }
        }
    }

    const range = spellData.range;
    if (range) {
        if (range.origin == "Ranged") {
            spell.range = range.rangeValue+" feet";
        } else {
            spell.range = range.origin||"";
        }
    }

    const components = spellData.components;
    if (components) {
        spell.components={};
        for (let i in components) {
            const c = spellComponentTypes[components[i]];
            spell.components[c]=true;
            if (c=="m" && spellData.componentsDescription) {
                spell.components.m=spellData.componentsDescription;
            }
        }
    }
    campaign.updateCampaignContent("spells", spell);
    return spell;
}

function createFeats(data, newImports) {
    const feats = data.feats;
    for (let f in feats) {
        const featData = feats[f].definition;
        createFeatFromImport(featData, newImports);
    }
}

function createFeatFromImport(featData, newImports) {
    const feat ={
        name:getImportId(featData.id,"feat"),
        displayName:featData.name,
        source:importSource,
        features:[{entries:[{type:"html", html:fixupHtml(featData.description||"")}]}],
    };

    const foundFeat = findEntryFromDisplayName(feat.displayName, "Feat");
    if (foundFeat) {
        //console.log("found feat", feat.displayName);
        return foundFeat;
    }
    if (newImports) {
        const foundImportFeat = findImportEntryFromDisplayName(feat.displayName, "Feat");
        if (foundImportFeat) {
            newImports.entryIds[foundImportFeat.name]=true;
            findImportsFromFeatures(foundImportFeat.features,newImports);
            return foundImportFeat;
        }
    }

    const prerequisites = featData.prerequisites;

    if (prerequisites && prerequisites.length) {
        const prereqs = [];
        for (let i in prerequisites) {
            if (prerequisites[i].description) {
                prereqs.push(prerequisites[i].description);
            }
        }
        feat.prerequisites = prereqs.join(" ");
    }
    campaign.updateCampaignContent("feats", feat);
    return feat;
}

const sizeMap={
    "2":"T",
    "3":"S",
    "4":"M",
    "5":"L",
    "6":"H",
    "7":"G"
}

function createRaceFromImport(raceData,newImports) {
    const raceFeatures = [];
    const baseRaceFeatures = [];
    const isSubRace = raceData.baseRaceId != raceData.entityRaceId;
    const race = {
        name:getImportId(raceData.entityRaceId || raceData.baseRaceId,"race", raceData.entityRaceTypeId || raceData.baseRaceTypeId),
        description:{type:"html", html:fixupHtml(raceData.description)},
        displayName:raceData.fullName,
        source:importSource,
        size:sizeMap[raceData.sizeId]||"M",
        raceFeatures
    }
    const baseRace = {
        name:getImportId(raceData.baseRaceId,"race", raceData.baseRaceTypeId),
        description:{type:"html", html:fixupHtml(raceData.description)},
        displayName:raceData.baseRaceName,
        source:importSource,
        size:sizeMap[raceData.sizeId]||"M",
        raceFeatures:baseRaceFeatures
    }
    let baseRaceName;

    const foundRace = findEntryFromDisplayName(raceData.fullName, "Race");
    if (foundRace) {
        return foundRace;
    }
    if (newImports) {
        const foundImportRace = findImportEntryFromDisplayName(raceData.fullName, "Race");
        if (foundImportRace) {
            newImports.entryIds[foundImportRace.name]=true;
            findImportsFromFeatures(foundImportRace.raceFeatures,newImports);
            return foundImportRace;
        }
    }
    race.defaultArt = createArtForEntry(race.name, race.displayName, raceData.portraitAvatarUrl);
    baseRace.defaultArt = race.defaultArt;

    const raceTraits = raceData.racialTraits||[];
    raceTraits.sort(function (a,b) { return Number(a.definition.displayOrder)-Number(b.definition.displayOrder)});

    for (let i in raceTraits) {
        const rt = raceTraits[i].definition;
        const pos = raceFeatures.findIndex(function (a) {return a.name==rt.name});
        if (pos>=0) {
            const e = raceFeatures[pos].entries[0];

            if (rt.name=="Size") {
                e.html = fixupHtml(rt.description);
            } else {
                e.html = fixupHtml(e.html+" "+rt.description);
            }
        } else {
            raceFeatures.push({
                name:rt.name,
                entries:[{type:"html",html:fixupHtml(rt.description)}]
            });
        }
        if (raceData.baseRaceId == rt.entityRaceId) {
            baseRaceFeatures.push({
                name:rt.name,
                entries:[{type:"html",html:fixupHtml(rt.description)}]
            });
        }
    }
    if (isSubRace) {
        const foundBaseRace = findEntryFromDisplayName(raceData.baseRaceName, "Race");
        if (foundBaseRace) {
            race.baserace = foundBaseRace.name;
            baseRaceName = foundBaseRace.displayName;
        } else {
            matchFeatures(baseRaceFeatures, "Race", baseRace.displayName);
            baseRace.noBaseRace = 1;
            campaign.updateCampaignContent("races", baseRace);
            race.baserace = baseRace.name;
            baseRaceName = baseRace.displayName;
        }
    }
    matchFeatures(raceFeatures, "Race", baseRaceName || race.displayName, baseRaceName?race.displayName:null);
    //console.log("race",race);
    campaign.updateCampaignContent("races", race);
    return race;
}

function setRaceChoices(race, character, data) {
    const choices = (data.choices||{}).race||[];
    const raceTraits = (data.race||{}).racialTraits||[];
    const baseName ="race."+race.name+".";
    const options = {};

    for (let c of choices) {
        const cdata = getAttributesFromChoice(c, data);
        if (cdata) {
            const trait = findFeatureChoice(c, raceTraits);
            if (trait) {
                const feature = matchFeature(trait.definition.name, race.raceFeatures);
                if (feature) {
                    setOption(feature, cdata, baseName+(feature.id||feature.name||""), options, data);
                }
            } else if (cdata.type=="abilityMod" && ["Choose an Ability Score to increase by 1", "Choose an Ability Score to increase by 2"].includes(c.label)) {
                const feature = race.raceFeatures[0];
                const ability = feature.ability||{};
                const num = ("Choose an Ability Score to increase by 2"==c.label)?2:1;
                let av;
                for (let x of abilitiesValues) {
                    if (ability[x]==num) {
                        av = x;
                        break;
                    }
                }
                if (av) {
                    options[baseName+(feature.id||feature.name||"")+".abilitymod."+av] = cdata.value;
                    character.allowRaceOverride = true;
                }
            }
        }
    }
    character.setRace(race.name, options);
}

function setBackgroundChoices(background, character, data) {
    const choices = (data.choices||{}).background||[];
    const backgroundData = data.background.definition;
    const baseName ="background."+background.name+".";
    const options = {};

    for (let c of choices) {
        const cdata = getAttributesFromChoice(c, data);
        if (cdata) {
            let trait;
            switch (cdata.type) {
                case "language":
                    trait = "Languages";
                    break;
                case "skill":
                    trait = "Skill Proficiencies";
                    break;
                case "tool":
                    trait = "Tool Proficiencies";
                    break;
                default:
                    trait = "Feature: "+backgroundData.featureName;
                    break;
            }
            const feature = matchFeature(trait, background.features);
            if (feature) {
                setOption(feature, cdata, baseName+(feature.id||feature.name||""), options, data);
            }
        }
    }
    character.setBackground(background.name, options);
}

function setClassChoices(character, data) {
    const choices = (data.choices||{}).class||[];
    const classes = character.classes.concat([]);
    const classSpells = data.classSpells;

    for (let c in data.classes) {
        const cls = data.classes[c];
        if (cls.definition) {
            const classId = cls.definition.id;
            const classInfo = findEntryFromDisplayName(cls.definition.name, "Class");
            const subclassInfo = findEntryFromDisplayName(cls.subclassDefinition && cls.subclassDefinition.name, "Subclass", classInfo && classInfo.className);
                
            for (let x in classes) {
                if (classes[x].cclass==classInfo.className) {
                    classes[x] = Object.assign({}, classes[x]);
                    // set subclass
                    if (subclassInfo) {
                        classes[x].subclass = subclassInfo.subclassName;
                    }

                    // find selected spells
                    const spells = (classSpells && classSpells[c] && classSpells[c].spells) || [];
                    const selectedSpells = {};
                    for (let s in spells) {
                        const spell = spells[s].definition;
                        if (spell) {
                            const foundSpell = findEntryFromDisplayName(spell.name, "Spell", spell.level);
                            if (foundSpell) {
                                selectedSpells[foundSpell.name.toLowerCase()] = {name:foundSpell.name, level:foundSpell.level, prepared:spells[s].prepared||false};
                            }
                        }
                    }
                    classes[x].selectedSpells = selectedSpells;

                    // check feature options
                    const options = {};

                    for (let c of choices) {
                        const cdata = getAttributesFromChoice(c, data);
                        if (cdata) {
                            const trait = findFeatureChoice(c, cls.classFeatures);
                            if (trait) {
                                const definition = trait.definition;
                                const selclass = (definition.classId == classId)?classInfo:subclassInfo;
                                if (selclass) {
                                    if (definition.name=="Proficiencies" && definition.requiredLevel==1) {
                                        // starting proficiencies
                                        setOption({}, cdata, "", options,data);
                                    } else {
                                        const feature = matchFeature(definition.name, selclass.classFeatures[definition.requiredLevel-1]||[]);
                                        if (feature) {
                                            const baseName="feature"+(Number(definition.requiredLevel)-1)+"."+(feature.id||feature.name||"");
                                            setOption(feature, cdata, baseName, options,data);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    classes[x].options = options;
                }


            }
        }
    }
    character.setClasses(classes);
}

const optionValueRewrite = {
    "Ability Score Improvement":"Increase Ability Scores",
    "Feat":"Pick Feat"
}

function setOption(feature, cdata, baseName, options, data) {
    let {type,value} = cdata;
    switch (type) {
        case "option":
            //value = optionValueRewrite[value]||value;
            if (value) {
                if (feature.options) {
                    const bv = pickBestFeatureOption(feature, value);
                    setOptionUniqueIndex(options, baseName+".selected", bv,-1);
                } else if (feature.customPick) {
                    const bv = pickBestCustom(feature,value);
                    if (bv) {
                        const base = baseName+".customPick";
                        if (!options[base]) {
                            options[base]= {};
                        }
                        options[base][bv.toLowerCase()]=1;
                    }
                }
            }
            break;
        case "language":
            setOptionUniqueIndex(options, baseName+"language", value);
            break;
        case "abilityMod":
            setOptionUniqueIndex(options, baseName+".abilitymod", value,1);
            break;
        case "skill":
            setOptionUniqueIndex(options, baseName+"skill", value);
            if ((feature.name == "Expertise") && !options[baseName+".selected"]) {
                options[baseName+".selected"] = "Choose 2 Skills";
            }
            break;
        case "tool":
            setOptionUniqueIndex(options, baseName+"tool", value);
            if (feature.name == "Expertise") {
                options[baseName+".selected"] = "Thieve's Tools Expertise and Choose Skill";
            }
            break;
        case "spell":{
            const base = baseName+".spellPick";
            if (!options[base]) {
                options[base]= {};
            }
            const spell = campaign.getSpell(value);
            options[base][value.toLowerCase()]={
                level:spell.level,
                name:value,
                prepared:true
            }
            break;
        }
        case "feat":{
            setOptionUniqueIndex(options, baseName+".selectedFeat", value,-1);
            setFeatOptions(options, baseName+".feat.", value, cdata.featComponentId, data);
            break;
        }
        default:
            console.log("unknown option type", type);
    }
}

function pickBestFeatureOption(feature, value) {
    if (!value) {
        return value;
    }
    let ret = value;
    const valueTokens = tokenizeText(value);
    let score = 0;

    for (let i in feature.options) {
        const f = feature.options[i];
        const optionTokens = tokenizeText(f.name||"");
        const ftMatch = matchTokenized(valueTokens, optionTokens);
        if (ftMatch > score) {
            score = ftMatch;
            ret =f.name;
        }
    }
    return ret;
}

function pickBestCustom(feature, value) {
    if (!value) {
        return value;
    }
    const valueTokens = tokenizeText(value);
    let ret = null;
    let score = 0;
    const list = campaign.getSortedCustomList(feature.customPick.customTable);

    for (let i in list) {
        const f = list[i];
        const optionTokens = tokenizeText(f.displayName||"");
        const ftMatch = matchTokenized(valueTokens, optionTokens);
        if (ftMatch > score) {
            score = ftMatch;
            ret =f.id;
        }
    }
    return ret;
}

function setFeatOptions(options,baseName, featName, featComponentId, data) {
    const feats = data.feats||[];
    const feat = campaign.getFeatInfo(featName);
    const featData = feats.find(function (f) {
        return f.componentId == featComponentId;
    });
    if (!featData || !feat) {
        console.log("could not find feat", componentId);
        return;
    }
    const choices = (data.choices||{}).feat||[];
    for (let i in choices) {
        const c = choices[i];
        if (c.componentId == featData.definition.id) {
            const cdata = getAttributesFromChoice(c, data);
            const f = (feat.features||[])[0];
            if (cdata && f) {
                setOption(f, cdata, baseName+(f.id||f.name||""), options,data);
            }
        }
    }
}

function setOptionUniqueIndex(options, base, value, baseNum) {
    let index = baseNum||0;
    if (index < 0) {
        options[base]=value;
        return;
    }
    while (options[base+"."+index]) {
        index++;
    }
    options[base+"."+index]=value;
}

function findFeatureChoice(choice, features) {
    return (features||[]).find(function (t){
        return (t.definition||{}).entityID == choice.componentId;
    });
}

function matchFeature(name, features) {
    return (features||[]).find(function(f){
        return (f.name||"").toLowerCase()==(name||"").toLowerCase();
    });
}


function createClassFromCharacter(classesData,newImports) {
    for (let x in classesData) {
        const classData = classesData[x];
        const classDefinition = classData.definition;
        const subclassDefinition = classData.subclassDefinition;
        if (classDefinition) {
            const cls = createClassFromInfo(classDefinition,null,newImports);
            if (subclassDefinition && cls) {
                createClassFromInfo(subclassDefinition, cls, newImports);
            }
        }
    }
    return ;
}

function createClassFromInfo(classDefinition, parentClass, newImports) {
    const classFeatures = [];
    let classInfo = {
        name:getImportId(classDefinition.id,"class"),
        classFeatures,
        description:{type:"html", html:fixupHtml(classDefinition.description||"")},
        source:importSource,
        displayName:classDefinition.name,
    }

    if (parentClass){
        const foundSubclass = findEntryFromDisplayName(classInfo.displayName, "Subclass", parentClass.className);
        if (foundSubclass) {
            //console.log("found subclass", classInfo.displayName)
            if (newImports) {
                findImportsFromClass(foundSubclass,newImports);
            }

            return foundSubclass;
        }

        if (newImports) {
            const foundImportSubclass = findImportEntryFromDisplayName(classInfo.displayName, "Subclass", parentClass.className);
            if (foundImportSubclass) {
                newImports.entryIds[foundImportSubclass.name]=true;
                findImportsFromClass(foundImportSubclass,newImports);
                return foundImportSubclass;
            }
        }

        classInfo.className=parentClass.className;
        classInfo.subclassName = classInfo.name;
    } else {
        const foundClass = findEntryFromDisplayName(classInfo.displayName, "Class");
        if (foundClass) {
            //console.log("found class", classInfo.displayName)
            if (newImports) {
                findImportsFromClass(foundClass,newImports);
            }
            return foundClass;
        }
        if (newImports) {
            const foundImportClass = findImportEntryFromDisplayName(classInfo.displayName, "Class");
            if (foundImportClass) {
                newImports.entryIds[foundImportClass.name]=true;
                findImportsFromClass(foundImportClass,newImports);
                return foundImportClass;
            }
        }

        classInfo.className=classInfo.name;
        classInfo.hd = {faces:classDefinition.hitDice, number:1};
        classInfo.startingProficiencies = {};
        classInfo.proficiency = [];
    }

    classInfo.defaultArt = createArtForEntry(classInfo.name, classInfo.displayName, classDefinition.avatarUrl);

    const traits = classDefinition.classFeatures||[];
    traits.sort(function (a,b) { 
        const ret = (Number(a.requiredLevel)- Number(b.requiredLevel));
        if (ret != 0) {
            return ret;
        }
        return Number(a.displayOrder)-Number(b.displayOrder)
    });

    for (let i in traits) {
        const t = traits[i];
        const level = Number(t.requiredLevel)-1;

        if (level || (t.name != "Hit Points")) {
            let fl = classFeatures;
            if (!fl[level]) {
                fl[level]=[];
            }
            fl[level].push({
                name:t.name,
                entries:[{type:"html",html:fixupHtml(t.description)}]
            });
        }
    }

    if (parentClass) {
        for (let i=0; i<20; i++) {
            if (parentClass.classFeatures[i] && classFeatures[i]) {
                const pclsFeatures = parentClass.classFeatures[i] ||[];
                const clsFeatures = classFeatures[i] || [];
                for (let pf of pclsFeatures) {
                    const index = clsFeatures.findIndex(function (a) { return a.name == pf.name});
                    if (index >= 0) {
                        clsFeatures.splice(index, 1);
                    }
                }
            }
        }
    }

    for (let i=0; i<20; i++) {
        if (classFeatures[i]) {
            matchFeatures(classFeatures[i], "Class", classDefinition.name);
        }
    }

    //console.log("class",classInfo);
    setSpellInfo(classInfo, classDefinition);
    campaign.updateCampaignContent("classes", classInfo);
    return classInfo;
}

function setSpellInfo(cls, classDefinition) {
    if (!classDefinition.canCastSpells) {
        return;
    }
    const spellRules = classDefinition.spellRules||{};
    cls.abilityDC = abilityFromId(classDefinition.spellCastingAbilityId);

    const levelSpellSlots =(spellRules.levelSpellSlots||[]);
    const maxSS = levelSpellSlots[20]||[];

    if (maxSS[0]) {
        if (maxSS[8]) {
            cls.spellcaster = "full";
        } else if (maxSS[4]) {
            if (levelSpellSlots[1][0]>0){
                cls.spellcaster = "halfplus";
            } else {
                cls.spellcaster = "half";
            }
        } else if (maxSS[3]) {
            cls.spellcaster = "third";
        }
    } else {
        if (maxSS[4]> 2) {
            cls.spellcaster="pact";
        } else if (maxSS[4] >1) {
            cls.spellcaster="halfpact";
        } else if (maxSS[3]) {
            cls.spellcaster="thirdpact";
        }
    }
    if (classDefinition.isRitualSpellCaster) {
        cls.ritualCaster=true;
    }
    if (classDefinition.spellPrepareType){
        cls.prepareSpells=true;
    }
    if (!classDefinition.knowsAllSpells && spellRules.levelSpellKnownMaxes) {
        cls.knownSpells = spellRules.levelSpellKnownMaxes.concat([]);
        cls.knownSpells.shift();
    }
    if ((spellRules.levelCantripsKnownMaxes||[])[20]) {
        cls.knownCantrips = spellRules.levelCantripsKnownMaxes.concat([]);
        cls.knownCantrips.shift();
    }
}

function createBackgroundFromImport(backgroundData, newImports) {
    const backgroundFeatures = [];
    const descriptionOptions=[];
    const background = {
        name:getImportId(backgroundData.id,"background", backgroundData.entityTypeId),
        description:{type:"html", html:fixupHtml(backgroundData.shortDescription)},
        displayName:backgroundData.name,
        source:importSource,
        features:backgroundFeatures,
        descriptionOptions
    }

    const foundBackground = findEntryFromDisplayName(background.displayName, "Background");
    if (foundBackground) {
        //console.log("found background", background.displayName);
        return foundBackground;
    }
    if (newImports) {
        const foundImportBackground = findImportEntryFromDisplayName(background.displayName, "Background");
        if (foundImportBackground) {
            newImports.entryIds[foundImportBackground.name]=true;
            findImportsFromFeatures(foundImportBackground.features,newImports);
            findImportsFromStartingEquipment(foundImportBackground.startingEquipment, newImports);
            return foundImportBackground;
        }
    }

    if (backgroundData.skillProficienciesDescription) {
        backgroundFeatures.push({name:"Skill Proficiencies", entries:[{type:"html", html:fixupHtml(backgroundData.skillProficienciesDescription)}]});
    }
    if (backgroundData.toolProficienciesDescription) {
        backgroundFeatures.push({name:"Tool Proficiencies", entries:[{type:"html", html:fixupHtml(backgroundData.toolProficienciesDescription)}]});
    }
    if (backgroundData.languagesDescription) {
        backgroundFeatures.push({name:"Languages", entries:[{type:"html", html:fixupHtml(backgroundData.languagesDescription)}]});
    }
    if (backgroundData.equipmentDescription) {
        backgroundFeatures.push({name:"Equipment", entries:[{type:"html", html:fixupHtml(backgroundData.equipmentDescription)}]});
    }
    if (backgroundData.featureDescription) {
        backgroundFeatures.push({name:"Feature: "+backgroundData.featureName, entries:[{type:"html", html:fixupHtml(backgroundData.featureDescription)}]});
    }
    if (backgroundData.spellsPreDescription || backgroundData.spellsPostDescription) {
        backgroundFeatures.push({name:"Spells", entries:[{type:"html", html:fixupHtml(backgroundData.spellsPreDescription||"")+fixupHtml(backgroundData.spellsPostDescription||"")}]});
    }
    if (backgroundData.suggestedCharacteristicsDescription) {
        backgroundFeatures.push({name:"Suggest Characteristics", entries:[{type:"html", html:fixupHtml(backgroundData.suggestedCharacteristicsDescription)}]});
    }
    if (backgroundData.contractsDescription) {
        backgroundFeatures.push({name:"Contacts", entries:[{type:"html", html:fixupHtml(backgroundData.contractsDescription)}]});
    }


    if (backgroundData.personalityTraits) {
        descriptionOptions.push(getDescriptionOptions("Personality Trait", backgroundData.personalityTraits));
    }
    if (backgroundData.ideals) {
        descriptionOptions.push(getDescriptionOptions("Ideal", backgroundData.ideals));
    }
    if (backgroundData.bonds) {
        descriptionOptions.push(getDescriptionOptions("Bond", backgroundData.bonds));
    }
    if (backgroundData.flaws) {
        descriptionOptions.push(getDescriptionOptions("Flaw", backgroundData.flaws));
    }

    const equipment = matchFeatures(backgroundFeatures, "Background", background.displayName);
    if (equipment) {
        background.startingEquipment = equipment;
    }
    //console.log("background",background);
    campaign.updateCampaignContent("backgrounds", background);
    return background;
}

function createItems(inventory, newImports) {
    const {mergeItemList} = require('./items.jsx');
    const equipment = {};
    for (let i in inventory) {
        const invBase = inventory[i];
        const inv = invBase.definition;
        equipment[campaign.newUid()] = createItemFromImport(inv, invBase, newImports);
    }
    //console.log("equipment", equipment, mergeItemList(equipment));
    return mergeItemList(equipment);
}

function createItemFromImport(inv, invBase,newImports) {
    let item = {
        name:getImportId(inv.id,"item",inv.entityTypeId),
        displayName:inv.name,
        source:importSource,
        entries:[{type:"html", html:fixupHtml(inv.description||"")}],
        rarity:(inv.rarity!="Common")?((inv.rarity=="none")?"None":(inv.rarity||null)):null,
        reqAttune:inv.canAttune||false,
        weight:inv.weight||0,
        value:inv.value?(inv.value+" gp"):null,
        quantity:inv.bundleSize>1?inv.bundleSize:null
    };
    const found = findEntryFromDisplayName(item.displayName, "Item");

    if (found) {
        //console.log("found item", found, inv);
        return Object.assign({}, found);
    }
    if (newImports) {
        const foundImportItem = findImportEntryFromDisplayName(item.displayName, "Item");
        if (foundImportItem) {
            newImports.entryIds[foundImportItem.name]=true;
            //don't need to copy since not going into character sheet
            //console.log("found import item", found, inv);
            return foundImportItem;
        }
    }
    //console.log("did not found item", found, inv);

    item.defaultArt = createArtForEntry(item.name, item.displayName, inv.avatarUrl);

    if (inv.filterType) {
        switch (inv.filterType) {
            case "Weapon": {
                if (inv.type === "Ammunition" || inv.subType === "Ammunition") {
                    item.type = "A";
                } else {
                    if (inv.attackType == "1") { // 1: Melee, 2: Ranged, null:Ranged 
                        item.type="M";
                    } else {
                        item.type="R";
                    }
                    item.weaponCategory = (inv.categoryId < 2)?"Simple":"Martial";
                    item.weaponProficiency = inv.type||null;
                    item.dmg1 = (inv.damage||{}).diceString?"{@dice "+inv.damage.diceString+"}":null;
                    item.dmgType = getDamageType(inv.damageType);
                    if (inv.range) {
                        item.range = inv.range;
                        if (inv.longRange) {
                            item.range += "/"+inv.longRange;
                        }
                    }
                }
                break;
            }
            case "Armor":
                item.type = armorTypeMap[inv.armorTypeId]||"OTH";
                item.ac = inv.armorClass ||0;
                item.strength = inv.strengthRequirement || null;
                item.stealth = (inv.stealthCheck == 2);
                break;
            case "Wondrous item":
                item.type = "WON";
                break;
            case "Ring":
                item.type = "RG";
                break;
            case "Wand":
                item.type = "WD";
                break;
            case "Rod":
                item.type = "RD";
                break;
                //item = parseWonderous(data);
            case "Staff":
                item.type = "STAFF";
                //item = parseStaff(data, character);
                break;
            case "Potion":
                item.type = "P";
                //item = parsePotion(data, data.definition.type);
                break;
            case "Scroll":
                item.type = "SC";
                //item = parseScroll(data);
                break;
            case "Other Gear":
                item.type = "G";
                //item = otherGear(ddb, data);
                break;
            default:
                console.log("Item filterType not implemented for ",inv.filterType);
                break;
        }
    } else {
        // try parsing it as a custom item
        console.log("no filterType",inv);
    }
    if (inv.properties) {
        const properties = [];
        for (let p of inv.properties) {
            const prop = propertyNameMap[p.name];
            if (prop) {
                properties.push(prop);
                if (prop=="V" && p.notes) {
                    item.dmg2 = "{@dice "+p.notes+"}";
                }
            }
            if (p.name=="Magical" && inv.rarity=="Common") {
                item.rarity="Common";
            }
        }
        if (properties.length) {
            item.property=properties;
        }
    }
    const feature = {};
    if (inv.grantedModifiers) {
        for (let g of inv.grantedModifiers) {
            if (g.type == "bonus") {
                switch (g.subType) {
                    case "armor-class":
                        feature.acBonus = g.value||0;
                        break;
                    case "unarmored-armor-class":
                        feature.acBonus = g.value||0;
                        feature.acBonusType = "noarmornoshield";
                        break;
                    case "magic":
                        feature.attackBonus = g.value||0;
                        feature.damageBonus = g.value||0;
                        break;
                    case "hit-points-per-level":
                        feature.hpLevelMod = g.value||0;
                        break;
                    case "warlock-spell-attacks":
                    case "spell-attacks":
                        feature.spellAttackBonus = g.value||0;
                        break;
                    case "saving-throws":
                        feature.savingThrowBonus = g.value||0;
                        break;
                    case "initiative":
                        feature.initiativeBonus = g.value||0;
                        break;
                    case "warlock-spell-save-dc":
                    case "spell-save-dc":
                        feature.spellDCBonus = g.value||0;
                        break;
                    case "passive-perception":
                        feature.perceptionBonus = g.value||0;
                        break;
                    case "spell-attacks":
                        feature.spellAttackBonus = g.value||0;
                        break;
                    case "xxxxxxxxxxxability-checks":
                        feature.savingThrowBonus = g.value||0;
                        break;
                    case "proficiency-bonus":
                        feature.proficiencyBonus = g.value||0;
                        break;
                    case "strength-score":
                        feature.ability = Object.assign(feature.ability||{}, {str:g.value||0});
                        break;
                    case "dexterity-score":
                        feature.ability = Object.assign(feature.ability||{}, {dex:g.value||0});
                        break;
                    case "constitution-score":
                        feature.ability = Object.assign(feature.ability||{}, {con:g.value||0});
                        break;
                    case "intelligence-score":
                        feature.ability = Object.assign(feature.ability||{}, {int:g.value||0});
                        break;
                    case "wisdom-score":
                        feature.ability = Object.assign(feature.ability||{}, {wis:g.value||0});
                        break;
                    case "charisma-score":
                        feature.ability = Object.assign(feature.ability||{}, {cha:g.value||0});
                        break;

                    case "strength-saving-throws":
                        feature.savingThrowBonusAbilities=["str"];
                        feature.savingThrowBonus = g.value||0;
                        break;
                    case "dexterity-saving-throws":
                        feature.savingThrowBonusAbilities=["dex"];
                        feature.savingThrowBonus = g.value||0;
                        break;
                    case "constitution-saving-throws":
                        feature.savingThrowBonusAbilities=["con"];
                        feature.savingThrowBonus = g.value||0;
                        break;
                    case "intelligence-saving-throws":
                        feature.savingThrowBonusAbilities=["int"];
                        feature.savingThrowBonus = g.value||0;
                        break;
                    case "wisdom-saving-throws":
                        feature.savingThrowBonusAbilities=["wis"];
                        feature.savingThrowBonus = g.value||0;
                        break;
                    case "charisma-saving-throws":
                        feature.savingThrowBonusAbilities=["cha"];
                        feature.savingThrowBonus = g.value||0;
                        break;

                    case "ability-score-maximum":
                        // change ability score max
                    case "hit-points":
                        // cure user ie. potion of healing
                    case "nature":
                    case "intimidation":
                    case "survival":
                    case "arcana":
                    case "history":
                    case "religion":
                    case "acrobatics":
                    case "arcana":
                    case "performance":
                    case "persuasion":
                    case "insight":
                    case "sleight-of-hand":
                    case "magic-item-attack-with-charisma":
                    case "passive-investigation":
                    case "legendary-resistance":
                    case "weapon-attack-range":
                    case "temporary-hit-points":
                    case "speed":
                        break;
                    default:
                        console.log("unknown bonus item modifier subtype", g.subType, g, item, invBase, found);
                }
            } else if (g.type == "set") {
                switch (g.subType) {
                    case "innate-speed-walking":
                    case "speed-walking":
                        feature.speed = {walk:getSpeedVal(g.value)};
                        break;
                    case "innate-speed-swimming":
                    case "speed-swimming":
                        feature.speed = {swim:getSpeedVal(g.value)};
                        break;
                    case "innate-speed-burrowing":
                    case "speed-burrowing":
                        feature.speed = {burrow:getSpeedVal(g.value)};
                        break;
                    case "innate-speed-climbing":
                    case "speed-climbing":
                        feature.speed = {climb:getSpeedVal(g.value)};
                        break;
                    case "innate-speed-flying":
                    case "speed-flying":
                        feature.speed = {fly:getSpeedVal(g.value)};
                        break;
                    case "strength-score":
                        feature.ability = Object.assign(feature.ability||{}, {minstr:g.value||0});
                        break;
                    case "dexterity-score":
                        feature.ability = Object.assign(feature.ability||{}, {mindex:g.value||0});
                        break;
                    case "constitution-score":
                        feature.ability = Object.assign(feature.ability||{}, {mincon:g.value||0});
                        break;
                    case "intelligence-score":
                        feature.ability = Object.assign(feature.ability||{}, {minint:g.value||0});
                        break;
                    case "wisdom-score":
                        feature.ability = Object.assign(feature.ability||{}, {minwis:g.value||0});
                        break;
                    case "charisma-score":
                        feature.ability = Object.assign(feature.ability||{}, {mincha:g.value||0});
                        break;
                    case "unarmored-armor-class": // Barrier Tattoo
                        feature.baseAC = {ac:10+(g.value||0), ability:["dex"], allowShield:true};
                        break;

                    case "armor-class":
                        // nothing to do ac already set on item
                        break;
                    default:
                        console.log("unknown set item modifier subtype", g.subType, g, item, invBase,found);
                }
            } else if (g.type == "resistance") {
                feature.resist=[g.subType.toLowerCase()];
            } else if (g.type == "damage") {
                feature.extraNotes = "";
                if (g.dice && g.dice.diceString) {
                    feature.extraNotes += "<b>"+g.dice.diceString+"</b> "
                }
                if (g.subType) {
                    feature.extraNotes += g.subType + " ";
                }
                if (g.restriction) {
                    feature.extraNotes += g.restriction;
                }
            } else {
                if (!["advantage","immunity"])
                console.log("unknown item modifier type", g.type, g.subType, g, item, invBase, found);
            }
        }
    }
    if (invBase.limitedUse && invBase.limitedUse.maxUses) {
        feature.usage={type:"A", baseCount:invBase.limitedUse.maxUses, restore:"long"};
    }
    if (Object.keys(feature).length) {
        item.feature = feature;
    }
    if (!found) {
        //console.log("need to create item", item.displayName, item, inv);
        campaign.updateCampaignContent("items", item);
    } else {
        //console.log("found item", found, item);
    }
    if (invBase.quantity>1) {
        item=Object.assign({},item);
        item.quantity = invBase.quantity;
    }

    return item;
}

function createArtForEntry(id, displayName, artUrl) {
    if (!artUrl) {
        return null;
    }

    const artName = "art_"+id;
    if (campaign.getArtInfo(artName)) {
        return artName;
    }

    const art = {
        name:artName,
        displayName,
        url:artUrl,
        mapWidth:10,
        artVersionId:campaign.newUid(),
        source:importSource
    }

    const image = new Image();
    image.onload = imageEvent => {
        art.imgWidth = image.naturalWidth;
        art.imgHeight = image.naturalHeight;
        //console.log("creating art", art);
        campaign.updateCampaignContent("art", art);
    };
    image.onerror = function (err) { 
        displayMessage("Error loading image");
        console.log("error loading", err, artUrl);
    };
    image.src = artUrl;

    return artName;
}

function getSpeedVal(speed) {
    if (speed) {
        return {number:speed};
    }
    return  {walking:true};
}

function getDamageType(damageType) {
    if (!damageType) {
        return null;
    }
    damageType=damageType.toLowerCase();
    const dmgs = Parser.DMGTYPE_JSON_TO_FULL;

    for (let dt in dmgs) {
        if (dmgs[dt]==damageType) {
            return dt;
        }
    }
    return null;
}

const propertyNameMap = {
    "Ammunition":null,
    "Ammunition (Firearms)":null,
    "Finesse":"F",
    "Heavy":"H",
    "Light":"L",
    "Loading":"LD",
    "Range":null,
    "Reach":"R",
    "Reload":"RLD",
    "Special":null,
    "Thrown":"T",
    "Two-Handed":"2H",
    "Versatile":"V",
    "Returning":null,
    "Focus":null,
    "Adamantine":null,
    "Magical":null,
    "Silvered":null,
}

const armorTypeMap = {
    "1":"LA",
    "2":"MA",
    "3":"HA",
    "4":"S",
}

function getAttributesFromChoice(choice, data) {
    const choiceDefinitions = (data.choices||{}).choiceDefinitions||[];

    const res = {};
    const label = choice.label;

    if (label && label.match(/^Choose.*Feat$/i)) {
        // feat values not stored in choiceDefinitions
        const feats = data.feats||[];
        const featData = feats.find(function (f) {
            return f.componentId==choice.componentId;
        });

        if (featData) {
            res.type="feat";
            res.featComponentId = choice.componentId;
            const feat = findEntryFromDisplayName(featData.definition.name, "Feat");
            if (!feat) {
                console.log("could not find feat", featData.definition.name);
                return null;
            }
            res.value = feat.name;
            return res;
        } else {
            return null;
        }
    }

    if (!choice.optionValue) {
        //console.log("no option value", choice);
        return null;
    }
    const id = choice.componentTypeId+"-"+choice.type;
    const cd = (choiceDefinitions||[]).find(function (c) {
        return c.id == id;
    });
    if (!cd) {
        //console.log("did not find option value", id, choice, choiceDefinitions);
        return null;
    }
    const value = findChoiceValue(choice.optionValue, cd.options);

    if (!label) {
        res.type = "option";
        res.value=value;
    } else if (label.match(/^Choose.*Language$/i)){
        res.type="language";
        res.value=value;
    } else if (label.match(/(Ability Score|strength|dexterity|constitution|intelligence|wisdom|charisma)/i)){
        res.type="abilityMod";
        if (!value) {
            console.log("could not find ability score", choice.optionValue);
            return null;
        }
        res.value = abilityScoreToAbility[value];
    } else if (label.match(/^Choose.*Spell$/i)) {
        res.type="spell";
        const spell = findEntryFromDisplayName(value, "Spell");
        if (!spell) {
            console.log("could not find spell", value);
            return null;
        }
        res.value = spell.name;
    } else if (label.match(/^Choose.*(Skill|Expertise)$/i)){
        const skills = campaign.getAllSkills();
        if (skills.includes(value)) {
            res.type="skill";
        } else {
            res.type="tool";
        }
        res.value=value;
    } else if (label.match(/^Choose.*(Tool|Instrument|Gaming Set)$/i)){
        res.type="tool";
        res.value=value;
    } else {
        console.log("unknown choice label", choice.label);
    }

    if (res.value) {
        //console.log("found attribute", res);
        return res;
    }
    console.log("did not find value", res, choice, cd );
    return null;
}

const abilityScoreToAbility = {
    "Strength Score":"str",
    "Dexterity Score":"dex",
    "Constitution Score":"con",
    "Intelligence Score":"int",
    "Wisdom Score":"wis",
    "Charisma Score":"cha"
}

function findChoiceValue(id, values) {
    const v = (values||[]).find(function (f) {
        return f.id == id;
    })
    if (v) {
        return v.label;
    }
    return null;
}

function getDescriptionOptions(name, optionsData) {
    const values = [];
    const options = {
        name,
        values
    };

    for (let i in optionsData) {
        values.push(optionsData[i].description);
    }
    return options;
}


function getImportId(id, type, etype) {
    if (!id) {
        console.log("creating new import uid");
        return campaign.newUid();
    }
    return ("import_"+type+"_"+id+(etype?"_"+etype:"")).toLowerCase();
}

// dup in importbook.jsx
const diceDetectPattern = /(\d*d(4|6|8|10|12|20)\s*\+\s*\d+|\d*d(4|6|8|10|12|20)\s*\-\s*\d+|\d*d(4|6|8|100|12|20|10|00)|\+\d+|\s\-\d+)/ig;
function fixupHtml(html) {
    if (!html) {
        return html;
    }

    html = html.replace(/[\r\n]/g, "");
    const wrapper= document.createElement('div');
    wrapper.innerHTML= html;
    stripStyling(wrapper);

    return wrapper.innerHTML.replace(diceDetectPattern, function (x){return "<b>"+x+"</b>"}).replace(/(<hr>|<pre>|<\/pre>)/g,"");
}

function stripStyling(element) {
    for (const child of element.children) {
        if (child.classList.contains("characters-statblock")) {
            child.remove();
        } else {
            child.removeAttribute("class");
            child.removeAttribute("style");
            child.removeAttribute("id");
    
            stripStyling(child);

            const alist=element.getElementsByTagName("a");
            for (let a=0; a<alist.length; a++){
                const th = alist[a];
                if (["www.dndbeyond.com", window.location.host].includes(th.host)) {
                    th.outerHTML = th.innerHTML;
                }
            }
        }
    }
}

function abilityFromId(id) {
    return ["", "str", "dex", "con", "int", "wis", "cha"][Number(id)];
}

function findImportsFromFeatures(features, newImports) {
    for (let i in features) {
        const f = features[i];
        if (f.customPick && f.customPick.customTable) {
            newImports.entryTypes[f.customPick.customTable] = true;
        }
        addImportSpells(f.extraSpells, newImports);
        addImportSpells(f.selectableSpells, newImports);
        addImportSpells(Object.keys(f.castableSpells||{}), newImports);
        if (f.action) {
            addImportMonsterOptions(f.action.monsterSelection, newImports);
        }
        findHtmlRefs(f, newImports);
    }
}

function findImportsFromStartingEquipment(equipment, newImports) {
    if (!equipment) {
        return;
    }
    const {options}=equipment;
    for (let i in options) {
        const os = options[i];
        for (let x in os) {
            const o = os[x];
            for (let id in o.items) {
                const it = campaign.getItem(id);
                if (!it) {
                    newImports.entryIds[id] = true;
                }
            }
        }
    }
}

function findImportsFromClass(cls, newImports) {
    for (let l=0; l<20; l++) {
        findImportsFromFeatures(cls.classFeatures[l], newImports);
    }
    for (let i in cls.customLevels) {
        const cl = cls.customLevels[i];
        if (cl.attributeType=="select" && cl.name) {
            newImports.entryTypes[cl.name] = true;
        }
    }
    addImportSpells(cls.assignedSpellList, newImports);
}

function addImportSpells(spells, newImports) {
    for (let i in spells) {
        const spellid = spells[i];
        if (!campaign.getSpell(spellid)) {
            const spell = findImportSpell(spellid);
            if (spell) {
                newImports.entryIds[spellid]=true;
                addImportMonsterOptions(spell.summonMonsters, newImports);
            }

        }
    }
}

function findImportSpell(spellid) {
    const spells = campaign.getFeaturePackageInfo().spells || [];
    for (let spell of spells) {
        if (spell.name == spellid) {
            return spell;
        }
    }
    return null;
}

function addImportMonsters(selectedMonsters, newImports) {
    for (let mon in selectedMonsters) {
        if (!campaign.getMonster(mon)) {
            newImports.entryIds[mon]=true;
        }
    }
}

function addImportMonsterOptions(select, newImports) {
    for (let i in select) {
        const so = select[i];
        addImportMonsters(so.selected, newImports);
    }
}

function findHtmlRefs(feature, newImports) {
    const entries = feature.entries;
    if (!entries || (entries.length != 1) || !entries[0].html) {
        return;
    }
    const {getLocationInfo} = require('./renderhref.jsx');
    const element= document.createElement('div');
    element.innerHTML= entries[0].html;

    const alist=element.getElementsByTagName("a");
    for (let a=0; a<alist.length; a++){
        const hash = alist[a].hash;
        const page = getLocationInfo(hash||"");
        switch (page.page) {
            case "spell": {
                if (page.id) {
                    //console.log("found spell", page.id);
                    if (!campaign.getSpell(page.id)) {
                        newImports.entryIds[page.id]=true;
                    }
                }
                break;
            }
            case "item": {
                if (page.id) {
                    //console.log("found item", page.id);
                    if (!campaign.getItem(page.id)) {
                        newImports.entryIds[page.id]=true;
                    }
                }
                break;
            }
            case "monster": {
                if (page.id) {
                    //console.log("monster", page.id);
                    if (!campaign.getMonster(page.id)) {
                        newImports.entryIds[page.id]=true;
                    }
                }
                break;
            }
        }
    }
}

function findEntryFromDisplayName(displayName, displayType, type,level) {
    let list;
    if (!displayName) {
        return null;
    }
    switch (displayType) {
        case "Race":
            list = campaign.getRaces();
            break;
        case "Class":
            list = campaign.getClasses();
            break;
        case "Subclass":
            list = campaign.getSubclasses(type);
            break;
        case "Background":
            list = campaign.getAllBackgrounds();
            break;
        case "Spell":
            list = campaign.getAllSpells();
            break;
        case "Item": {
            list = campaign.getSortedItemsList();
            const tdn = tokenizeText(displayName);
            for (let i in list) {
                const it = list[i];
                let sn = it.displayName||"";

                // check the complete string first
                const stitid = tokenizeText(sn);
                if (matchTokenized(tdn,stitid) >= 0.9){
                    return it
                }

                // check without quantity
                if (it.quantity>1) {
                    const qty = " ("+it.quantity+")";
                    if (sn.indexOf(qty)>0) {
                        sn = sn.replace(qty,"");
                    }
                } 
                const titid = tokenizeText(sn);
                if (matchTokenized(tdn,titid) >= 0.9){
                    return it
                }
            }
            return null;
        }
        case "Feat":
            list = campaign.getAllFeats();
            break;
    }
    return findDisplayName(list, displayName, level);
}

function findDisplayName(list, name, level) {
    if (!name) {
        return null;
    }
    name = name.toLowerCase().replace(/\W/g," ");
    for (let i in list) {
        let it = list[i];
        if (((it.displayName||"").toLowerCase().replace(/\W/g," ") == name) && (!(level>=0) || (it.level==level))) {
            return it;
        }
    }
    return null;
}


function findImportEntryFromDisplayName(displayName, displayType, type,level) {
    let list;
    if (!displayName) {
        return null;
    }
    const fpi = campaign.getFeaturePackageInfo();

    switch (displayType) {
        case "Race":
            list = fpi.races||[];
            break;
        case "Class":{
            list = [];
            const classes = fpi.classes||[];
            for (let c of classes) {
                if (!c.subclassName) {
                    list.push(c);
                }
            }
            break;
        }
        case "Subclass":{
            list = [];
            const classes = fpi.classes||[];
            for (let c of classes) {
                if (c.className == type) {
                    list.push(c);
                }
            }
            break;
        }
        case "Background":
            list = fpi.backgrounds||[];
            break;
        case "Spell":
            list = fpi.spells||[];
            break;
        case "Item": {
            list = fpi.items||[];

            const tdn = tokenizeText(displayName);
            for (let i in list) {
                const it = list[i];
                let sn = it.displayName||"";

                // check the complete string first
                const stitid = tokenizeText(sn);
                if (matchTokenized(tdn,stitid) >= 0.9){
                    return it
                }

                // check without quantity
                if (it.quantity>1) {
                    const qty = " ("+it.quantity+")";
                    if (sn.indexOf(qty)>0) {
                        sn = sn.replace(qty,"");
                    }
                } 
                const titid = tokenizeText(sn);
                if (matchTokenized(tdn,titid) >= 0.9){
                    return it
                }
            }
            return null;
        }
        case "Feat":
            list = fpi.feats||[];
            break;
    }
    return findDisplayName(list, displayName, level);
}

export {
    enumFeatures,
    SearchFeatureResults,
    FeatureComplete,
    matchFeatures,
    ImportCharacter,
    ImportContent
};