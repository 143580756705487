const React = require('react');
const {campaign,areSameDeep} = require('../lib/campaign.js');
import Popover from '@material-ui/core/Popover';
import { Stage, Layer, Rect, Image as KImage} from 'react-konva';
//import { Canvg,presets} from 'canvg';
const {SelectVal, CheckVal} = require('./stdedit.jsx');
import { Engine, Render, Runner, Bodies, Composite, Events} from 'matter-js';
import Button from '@material-ui/core/Button';
const {ArtPicker} = require('./renderart.jsx');
const {Chat} = require('../lib/chat.js');
const {imageCache} = require('./imagecache.jsx');


let getDieTypesFromDice,getAdjustedDieType,sidesFromDieType,defaultDiceURL,offsetsFromDieRoll,getCorrectedDiceUrl;
const towerHeight=600;
const towerWidth=360;
const diceFadeTime=1000;
const diceFadeDelay=1000;
const defaultDiceTray = "yvctivbr01shzalq";

class DiceTower extends React.Component {
    constructor(props) {
        super(props);
    
        // create an engine
        this.engine = Engine.create();
        this.engine.enableSleeping=1;
        this.engine.positionIterations=10;
        Events.on(this.engine, "afterUpdate", this.eventTick.bind(this));
        Events.on(this.engine, "collisionStart", this.collision.bind(this))
        //Events.on(this.engine, "collisionActive", this.collision.bind(this))
        //Events.on(this.engine, "collisionEnd", this.collision.bind(this))

        
	    this.state= {};
        const dr = require('./diceroller.jsx');
        getDieTypesFromDice = dr.getDieTypesFromDice;
        getAdjustedDieType = dr.getAdjustedDieType;
        sidesFromDieType=dr.sidesFromDieType;
        offsetsFromDieRoll=dr.offsetsFromDieRoll;
        defaultDiceURL=dr.defaultDiceURL;
        getCorrectedDiceUrl=dr.getCorrectedDiceUrl;
        this.rollDiceFn = this.rollDice.bind(this);
    }

    componentDidMount() {
        const {showInline} = this.props;
        if (!showInline) {
            Chat.setDiceTowerCallback(this.rollDiceFn)
        }
    }

    componentWillUnmount() {
        const {showInline} = this.props;
        if (!showInline) {
            Chat.removeDiceTowerCallback(this.rollDiceFn)
        }
        this.endSimulation();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.chatRoll != this.props.chatRoll) {
            this.rollDice(this.props.chatRoll, null, null);
        }
    }

    rollDice(chat, previousChat, finishRoll) {
        if (!this.diceConfig.showAnnimatedDice) {
            diceSounds.playFullRoll();
            if (finishRoll) {
                finishRoll(chat);
            }
            return;
        }
        const rlist=getRollsFromChat(chat, previousChat);
        //console.log("rlist", rlist);

        this.endSimulation(); 
        this.chat = chat;
        this.rollList = rlist;
        this.finishRoll=finishRoll;
        this.showTower(true);
    }

    get diceConfig() {
        const {character} = this.props;
        const config = character?character.state:campaign.getCampaignDice();
        const {diceUrl=defaultDiceURL, showAnnimatedDice=true, showDiceBackground=true, showDiceBackgroundId=defaultDiceTray} = config;
        return {diceUrl, showAnnimatedDice, showDiceBackground, showDiceBackgroundId};
    }

    startSimulation() {
        this.isFlatRoll=(this.diceConfig.showAnnimatedDice !="Falling Roll");
        this.beginSumulation = Date.now();
        const {roll} = (this.chat||{});

        this.maxBody=10;
        if (this.isFlatRoll) {
            this.engine.gravity.y=0;
        } else {
            this.engine.gravity.y=2;
        }

        // create a renderer
        if (!this.runner ) {
            if (!roll?.dice && !roll?.damages) {
                //console.log("no dice", roll);
                return;
            }

            const blist = [];
            this.addDice(blist, this.rollList);

            if (!blist.length) {
                this.showTower(false);
                return;
            }

            const collisionFilter={mask:0xffffffff,category:0xffffffff};
            const floor = Bodies.rectangle(400, towerHeight+100, 4000, 200, { isStatic: true,collisionFilter});
            const left = Bodies.rectangle(0-100, 0, -200, 4000, { isStatic: true,collisionFilter});
            const right = Bodies.rectangle(towerWidth+100, -1000, 200, 5000, { isStatic: true,collisionFilter});
            floor.friction=0.6;
            left.friction=0;
            left.restitution=0.8;
            right.friction=0;
            right.restitution=0.4;

            if (this.isFlatRoll) {
                const top = Bodies.rectangle(400, -100, 4000, 200, { isStatic: true,collisionFilter});
                top.friction=0.6;
                top.restitution=0.8;
                floor.restitution=0.8;
                blist.push(top);
            } else {
//                const lbumper = Bodies.rectangle(0, 0, 10, 400, { isStatic: true, angle:-Math.PI*0.25,collisionFilter});
                const lbumper = Bodies.circle(220, 175, 10, { isStatic: true, angle:-Math.PI*0.25,collisionFilter});
                lbumper.restitution=0.6;
                lbumper.friction=0;
//                const rbumper = Bodies.rectangle(towerWidth, 175, 10, towerWidth, { isStatic: true, angle:Math.PI*0.25,collisionFilter});
                const rbumper = Bodies.circle(110, 175, 10, { isStatic: true, angle:Math.PI*0.25,collisionFilter});
                rbumper.restitution=0.6;
                rbumper.friction=0;
                const mbumper = Bodies.circle(180, 300, 10, { isStatic: true, angle:Math.PI*0.25,collisionFilter});
                mbumper.restitution=0.6;
                mbumper.friction=0;
                blist.push(lbumper);
                blist.push(rbumper);
                blist.push(mbumper);
            }

            blist.push(floor);
            blist.push(left);
            blist.push(right);

            //console.log("blist", blist.length);
            // add all of the bodies to the world
            Composite.add(this.engine.world, blist);
            
            // run the renderer
            this.startRenderer();

            // create runner
            const runner = Runner.create();
            
            // run the engine
            Runner.run(runner, this.engine);
            this.runner = runner;
            this.lastCollisionCount=0;
        }
        this.sleepCount=0;
        this.beginSleeping=0;
        if (this.stopShowing) {
            clearTimeout(this.stopShowing);
            this.stopShowing=null;
        }
    }

    startRenderer() {
        if (this.showRef && !this.renderEngine) {
            const render = Render.create({
                canvas: this.showRef,
                engine: this.engine,
                options:{background:"transparent",
                    height:towerHeight,
                    width:towerWidth,
                    showAngleIndicator:true,
                    showCollisions:true,
                    wireframeBackground:"transparent"
                }
            });

            Render.run(render);
            this.renderEngine = render;
        }
    }

    endSimulation() {
        //console.log("end bodies", this.engine?.world?.bodies?.concat([]));
        if (this.runner) {
            Runner.stop(this.runner);
            this.runner = null;
        }
        if (this.renderEngine) {
            Render.stop(this.renderEngine);
            this.renderEngine=null;
        }
        if (this.engine) {
            Composite.clear(this.engine.world, false, true);
            Engine.clear(this.engine);
        }
        if (this.finishRoll) {
            this.finishRoll();
            this.finishRoll=null;
        }
    }

    getDiceBackgroundImage() {
        if (!this.diceConfig.showDiceBackground) {
            return null;
        }
        const t=this;
        const id = this.diceConfig.showDiceBackgroundId;
        const art = campaign.getArtInfo(id) || campaign.getArtInfo(defaultDiceTray);
        const url = art?.url;

        if (url) {
            return imageCache.getImage(url, function (foundImage){
                t.setState({diceBackgroundImage:foundImage});
            });
        }
        return null;
    }

    render() {
        const {showInline} = this.props;
        if (!this.state.showTower && !showInline) {
            return null;
        }
        const diceBackgroundImage = this.getDiceBackgroundImage();
        let imageLeft,imageTop,
        left=(window.innerWidth-towerWidth)/2, top=(window.innerHeight-towerHeight)/2;
        if (diceBackgroundImage) {
            imageLeft=(towerWidth-diceBackgroundImage.naturalWidth)/2;
            imageTop=(towerHeight-diceBackgroundImage.naturalHeight)/2;
        }

        const inside = <div style={showInline?{position:"relative", width:towerWidth, height:towerHeight}:{position:"fixed", top, left}}>
            {diceBackgroundImage?<div onClick={this.showTower.bind(this,false)} style={{position:"absolute", top:imageTop, left:imageLeft}}>
                <Stage height={diceBackgroundImage.naturalHeight} width={diceBackgroundImage.naturalWidth} opacity={this.state.opacity} onClick={this.showTower.bind(this,false)} onTap={this.showTower.bind(this,false)}>
                <Layer>
                        <KImage image={diceBackgroundImage}/>
                    </Layer>
                </Stage>
            </div>:null}
            <div onClick={this.showTower.bind(this,false)} style={{position:"absolute", top:0, left:0}}>
                <Stage height={towerHeight} width={towerWidth} opacity={this.state.opacity} onClick={this.showTower.bind(this,false)} onTap={this.showTower.bind(this,false)}>
                    <Layer ref={this.saveRenderLayer.bind(this)}>
                        {this.getDice()}
                    </Layer>
                </Stage>
                {campaign.showDebugDice?<canvas style={{position:"absolute", top:0, left:0}} height={towerHeight} width={towerWidth} ref={this.saveShowRef.bind(this)}></canvas>:null}
            </div>
        </div>;

        if (showInline) {
            return inside;showTower
        }
            
        return <Popover
            open 
            anchorReference="none"
            onClose={this.showTower.bind(this,false)}
            classes={{paper:"bg-transparent",root:"bg-black-40"}}
        >
            {inside}   
        </Popover>;
    }

    saveRenderLayer(saveRenderLayer) {
        this.renderLayer=saveRenderLayer;
    }

    getDice() {
        const t=this;
        const allBodies = Composite.allBodies(this.engine.world);
        const ret=[];
        let pos=0;

        for (let i in allBodies) {
            const b = allBodies[i];
            if (b.dieType && pos<this.maxBody) {
                const diceImage = imageCache.getImage(b.url, function() {
                    t.setState({reset:campaign.newUid()});
                });
                const scale=diceImage?640/diceImage.width:1;

                const offset=offsetsFromDieRoll(b.dieType, b.showRoll);
                ret.push(<Rect
                    ref={(node) => {
                        b.knode = node;
                    }}
                    key={pos}
                    x={b.position.x}
                    y={b.position.y}
                    offsetX={32}
                    offsetY={32+(dicePositionCorrect[b.dieType]||0)}
                    rotation={b.angle*180/Math.PI}
                    fillPatternImage={diceImage}
                    fillPatternOffset={{x:offset.x*64/scale, y:(offset.y*64+1)/scale}}
                    fillPatternScale={{x:scale,y:scale}}
                    fillPatternRepeat="no-repeat"
                    strokeWidth={0}
                    stroke="yellow"
                    width={64}
                    height={64}
                />);
                pos++;
            }
        }
        return ret;
    }

    saveShowRef(r) {
        this.showRef = r;
        if (this.state.showTower) {
            this.startRenderer();
        }
    }

    clearTimeoutAnimation() {
        if (this.timeoutAnimation) {
            clearTimeout(this.timeoutAnimation);
            this.timeoutAnimation=null;
        }
    }

    showTower(showTower) {
        this.setState({showTower,opacity:1});
        if (!showTower) {
            //console.log("end rolling");
            this.endSimulation();
            this.props.onFinishTower?.();
            this.clearTimeoutAnimation();
        } else {
            //this.diceRoll.play();
            const t=this;
            this.startSimulation();
            this.timeoutAnimation = setTimeout(function() {
                t.showTower(false);
                t.timeoutAnimation=null;
            },1000)
        }
    }

    eventTick() {
        const {showInline} = this.props;
        //console.log("tick");
        this.clearTimeoutAnimation();
        const allBodies = Composite.allBodies(this.engine.world);
        let sleeping=0;
        let count=0;
        let ma=0;
        let switched;
        const now=Date.now();

        for (let i in allBodies) {
            const b = allBodies[i];
            if (b.sides) {
                ma=Math.max(ma,b.speed);
                if (b.speed>1) {
                    if (b.speed < 2) {
                        //console.log("ramping friction")
                        b.showRoll = b.actualRoll||b.showRoll;
                        if (this.isFlatRoll) {
                            b.frictionAir=0.1;
                        } else {
                            b.friction=1;
                        }
                    } else {
                        b.finished=false;
                    }
                    
                    const xd=(b.screenX||0)-b.position.x;
                    const yd=(b.screenY||0)-b.position.y;
                    const distance = Math.sqrt((xd*xd)+(yd*yd));
                    const ad = Math.abs(b.angle-(b.startingAngle||0))%6;
                    const rad=(3*b.rerollRand);
                    const rdistance=(32*b.rerollRand);
                    if (!b.finished && ((distance > rdistance) || (ad> rad))) {
                        b.showRoll=randomDieVal(b.sides, b.angle, b.dieRandom);
                        b.screenX=b.position.x;
                        b.screenY=b.position.y;
                        b.startingAngle=b.angle;
                        b.rerollRand=Math.max(0.1,Math.random());
                    }
                } else {
                    if (this.isFlatRoll&&(b.showRoll!=b.actualRoll)) {
                        switched=true;
                    }
                    b.showRoll = b.actualRoll||b.showRoll;
                    b.finished=true;
                    sleeping++;
                }
                if (b.knode) {
                    const offset=offsetsFromDieRoll(b.dieType, b.showRoll);
                    const diceImage = imageCache.getImage(b.url);
                    const scale=diceImage?640/diceImage.width:1;

                    b.knode.fillPatternOffset({x:(offset?.x||0)*64/scale, y:((offset?.y||0)*64+1)/scale});
                    b.knode.x(b.position.x);
                    b.knode.y(b.position.y);
                    b.knode.rotation(b.angle*180/Math.PI);
                }
                count++;
                //const last =b.history[b.history.length-1]?.now||0;
                //b.history.push({x:b.position.x,y:b.position.y, speed:b.speed, t:now-last, now});
            }
        }

        if (sleeping>=count) {
            this.sleepCount = (this.sleepCount||0)+1;
            if (this.finishRoll) {
                this.finishRoll();
                this.finishRoll=null;
            }
    
            if (this.sleepCount >10) {
                const t=this;
                if (!this.stopShowing) {
                    if (!campaign.showDebugDice && !showInline) {
                        this.beginSleeping = now;
                    }
                    t.stopShowing = setTimeout(function() {
                        if (campaign.showDebugDice) {
                            console.log("done")
                            if (t.runner) {
                                Runner.stop(t.runner);
                                t.runner=null;
                            }
                        }else {
                            t.showTower(false);
                        }
                        t.stopShowing=null;
                    },diceFadeTime+diceFadeDelay);
                }
            }      
        } else {
            //console.log("reset sleep count", sleeping, count, ma);
            this.sleepCount=0;
        }
        if (this.renderLayer){
            this.renderLayer.draw();
        }
        if (this.beginSleeping) {
            const diff = now-this.beginSleeping-diceFadeDelay;
            if (diff > 0) {
                const opacity = (diceFadeTime-Math.min(diceFadeTime,diff))/diceFadeTime;
                this.setState({opacity});
            }
        }
        if (this.maxBody < allBodies.length) {
            this.maxBody += 10;
            this.setState({random:Math.random()});
        }
        if (switched && ((now-this.beginSumulation)>150)) {
            diceSounds.playTapHit();
        }
    }

    collision(collisions) {
        let maxDiceCollision=0,maxSurfaceCollision=0;
        const now=Date.now();
        if ((now-this.beginSumulation) < 100) {
            return;
        }
        for (let i in collisions.pairs) {
            const p = collisions.pairs[i];
            if (p.bodyA.knode) {
                if (p.bodyA.dieType && p.bodyB.dieType) {
                    maxDiceCollision = Math.max(maxDiceCollision, p.collision.depth);
                    //console.log("dice collision", p.bodyA, p.bodyB, p.collision.depth, p.collision.penetration, p.collision.tangent, now-this.beginSumulation);
                } else {
                    maxSurfaceCollision = Math.max(maxSurfaceCollision, p.collision.depth);
                    //console.log("collision", p.bodyA, p.bodyB, p.collision.depth, p.collision.penetration, p.collision.tangent, now-this.beginSumulation);
                }
            }
        }
        if (maxSurfaceCollision >=2) {
           //console.log("play", maxDiceCollision,maxSurfaceCollision);
            diceSounds.playFullHit();
        } else if (maxDiceCollision>0.5) {
            //console.log("clink play", maxDiceCollision,maxSurfaceCollision);
            diceSounds.playDieClink();
        } else if (maxSurfaceCollision >=0.1) {
            //console.log("play tap", maxDiceCollision,maxSurfaceCollision);
            diceSounds.playTapHit();
        } else {
            //console.log("no play", maxDiceCollision,maxSurfaceCollision);
        }

        if (collisions.pairs.length > 1) {
            //console.log("found multiple*************************************")
        }

        //console.log("new, collisions",collisions.pairs.concat([]));
    }

    addDice(blist, rollList) {
        if (!rollList) {
            return;
        }

        for (let i in rollList) {
            if (blist.length>100) {
                return;
            }
            const {dieType, sides, actualRoll,diceUrl} = rollList[i]
            let options=this.getDiceOptions(dieType, sides, blist.length, actualRoll,diceUrl);
            
            switch (dieType) {
                case "D4": {
                    blist.push(Bodies.fromVertices(options.startingX, options.startingY, [[{x:0,y:0},{x:29,y:50},{x:-29,y:50} ]], options));
                    break;
                }
                case "D6": {
                    blist.push(Bodies.polygon(options.startingX, options.startingY, 4, 36,options))
                    break;
                }
                case "D8": {
                    blist.push(Bodies.polygon(options.startingX, options.startingY, 6, 27,options))
                    break;
                }
                case "D10": {
                    blist.push(Bodies.fromVertices(options.startingX, options.startingY, [d10Vertices], options));
                    break;
                }
                case "D12": {
                    blist.push(Bodies.polygon(options.startingX, options.startingY, 10, 28,options))
                    break;
                }
                case "D20": {
                    blist.push(Bodies.fromVertices(options.startingX, options.startingY, [[{x:32,y:4},{x:59,y:19},{x:59,y:49},{x:32,y:63},{x:5,y:49},{x:5,y:19} ]], options));
                    break;
                }
                case "D100": {
                    blist.push(Bodies.fromVertices(options.startingX, options.startingY, [d10Vertices], options));
                    options=this.getDiceOptions("D101",sides, blist.length, actualRoll,diceUrl);
                    blist.push(Bodies.fromVertices(options.startingX, options.startingY, [d10Vertices], options));
                    break;
                }
            }
        }
    }
    
    getDiceOptions(dieType,sides, pos, roll, url) {
        const groupSize = this.isFlatRoll?12:20;
        const group = 1 << Math.trunc(pos/groupSize);
        pos = pos %groupSize;
        const options={
            torque:randomDieTorque(),
            restitution:dieRestitution, 
            friction:0.3,
            frictionStatic:0.6,
            dieType, 
            sides, 
            mass:2,
            inverseMass:1/2,
            dieRandom:Math.random(),
            showRoll:randomDie(sides),
            actualRoll:roll,
            url,
            rerollRand:Math.random(),
            collisionFilter:{mask:group, group:group,category:group}
        };

        if (this.isFlatRoll) {
            Object.assign(options,{
                torque:randomDieTorque()/2,
                force:{x:(Math.random()/10+0.1)*((Math.random()>0.5)?1:-1), y:(Math.random()/10+0.1)*((Math.random()>0.5)?1:-1)},
                startingX:(towerWidth/2-diceSpacing)+(pos%2)*diceSpacing,
                startingY:Math.trunc((pos)/2)*diceSpacing+100,
                frictionAir:0.02,
            });
        } else {
            Object.assign(options,{
                torque:randomDieTorque(),
                force: {x:0, y:Math.random()/100+0.005},
                startingX:((pos+1)%2)*120+120,
                startingY:0-Math.trunc((pos+1)/2)*diceSpacing,
            });
        }
        options.history=[{x:options.startingX, y:options.startingY, now:Date.now()}];
        //console.log("options", options);
        return options;
    }
}

const diceSpacing=80;

const d10Vertices=[{x:32,y:7},{x:56,y:20},{x:62,y:34},{x:56,y:49},{x:32,y:62},{x:8,y:49},{x:2,y:34},{x:8,y:20}];
const dicePositionCorrect={
    D10:0,
    D100:0,
    D101:0,
    D8:-1,
    D12:-1,
    D6:-1,
    D4:6
}
function randomDieVal(sides, angle, random) {
    const r=Math.trunc(Math.abs((sides*angle+random*2*Math.PI)*2/Math.PI))%sides+1;
    //console.log('sides', sides, angle, random, r);
    return r;
}

function randomDieTorque() {
    const r = (Math.random()*2+3)*((Math.random()>0.5)?1:-1);
    return r;
}

function randomDie(sides) {
    return Math.trunc(Math.random()*sides)+1;
}
const dieRestitution = 0.2;

/*
async function toPng(svg, width, height) {
    try {
        const preset = presets.offscreen();
        //console.log("toPng", svg, height, width);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext('2d');
        const v = await Canvg.from(ctx, svg, preset);
        //console.log("preset",preset);
    
        v.resize(64*10*2, 64*7*2);
        // Render only first frame, ignoring animations and mouse.
        await v.render();
    
        const blob = await (new Promise(function (resolve, reject) {
            canvas.toBlob(function (blob){
                resolve(blob);
            }, function (err){
                reject(err)
            });
        }));
        const pngUrl = URL.createObjectURL(blob);
    
        return pngUrl;
    } catch (err) {
        alert("failed to get png:"+ err.toString());
        return null;
    }
}
*/

class DiceSounds {
    constructor() {
        this.curDie=0;
        this.curDieClink=0;
        this.curDieTap=0;

        this.lastPlayed = {};
    }

    async fetchSourceData(url) {
        try {
            const response = await fetch(url);
            const buffer = await response.arrayBuffer();
            const decodedData = await this.audioCtx.decodeAudioData(buffer);

            return decodedData;
        } catch (err) {
            console.log("error getting source", url, err);
            return null;
        }
    }

    async playSourceData(name) {
        const {sounds} = require('./renderaudio.jsx');
        const decodedData = await sounds.getSourceData(name);
        if (decodedData) {

            if (name) {
                const lp = this.lastPlayed[name]||0;
                const now = Date.now();
                if ((now-lp) < 50) {
                    return;
                }
                this.lastPlayed[name]=now;
            }

            sounds.playSourceData("dice", decodedData, 0, false);
        }
    }

    playFullRoll() {
        if (campaign.playSounds && campaign.diceVolume) {
            this.playSourceData("/diceroll.mp3");
        }
    }

    playFullHit() {
        if (campaign.playSounds && campaign.diceVolume) {
            this.playSourceData("/diehit.mp3");
        }
    }

    playDieClink() {
        if (campaign.playSounds && campaign.diceVolume) {
            this.playSourceData("/dieclink.mp3");
        }
    }

    playTapHit() {
        if (campaign.playSounds && campaign.diceVolume) {
            this.playSourceData("/dicetap.mp3");
        }
    }
}

function getRollsFromChat(chat, prev) {
    const rlist = [];
    const roll = chat?.roll;
    const proll = prev?.roll;
    if (!roll?.dice && !roll?.damages) {
        //console.log("no dice", roll);
        return rlist;
    }

    addDiceToList(rlist, roll, proll, roll.diceUrl);
    if (roll.damages) {
        for(let d in roll.damages) {
            addDiceToList(rlist, roll.damages[d], (proll?.damages||[])[d], roll.diceUrl);
        }
    }

    return rlist;
}

function addDiceToList(rlist, roll, proll, diceUrl) {
    if (!roll) {
        return;
    }
    const {rolls,dice,changes={}} = roll;
    const {rolls:prolls=[], dice:pdice={},changes:pchanges={}} = proll||{};
    let di=0;
    const dieTypesFound = getDieTypesFromDice(dice);

    for (let d of dieTypesFound) {
        if (dice[d]) {
            //console.log("found die", d, dice[d]);
            const dieType = getAdjustedDieType(d);
            const sides = sidesFromDieType(d);
            for (let i=0; i<dice[d]; i++) {

                if ((i==0) && (dieType=="D20") && roll.didAdvantage && (changes[-1] != pchanges[-1])) {
                    const {altRoll}=roll;

                    const add = {dieType, sides, actualRoll:altRoll, diceUrl:getCorrectedDiceUrl(roll.diceUrl||diceUrl||defaultDiceURL)};
                    rlist.push(add);
                }

                if ((changes[di]!=pchanges[di]) || !pdice[d] || (rolls[di] != prolls[di])) {
                    const add = {dieType, sides, actualRoll:rolls[di], diceUrl:getCorrectedDiceUrl(roll.diceUrl||diceUrl||defaultDiceURL)};
                    rlist.push(add);
                }
                di++;

            }
        }
    }

    if (roll.extraRolls) {
        for (let i in roll.extraRolls) {
            const ed = roll.extraRolls[i];
            const ped = (proll?.extraRolls||[])[i];
            addDiceToList(rlist, ed, ped, roll.diceUrl||diceUrl);
        }
    }
}

const diceSounds = new DiceSounds();

export {
    diceSounds,
    DiceTower
};